import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { articleActions } from '../../store/articleSlice/articleReducers';

const TabCloseListener = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const handleTabClose = (event) => {
    
      if (event.currentTarget === window) {
        dispatch(articleActions.setArticleEmpty());
      }
    };

    window.addEventListener('unload', handleTabClose);

    return () => {
      window.removeEventListener('unload', handleTabClose);
    };
  }, [dispatch]);

  return null;
};

export default TabCloseListener;
