/* eslint-disable */

import {articleActions} from '../../../store/articleSlice/articleReducers'
import {
  addNewArticle,
  createArticle,
  createIntros,
  createOutlines,
  createTitle,
  reCreateIntros,
  reCreateOutlines,
  reCreateTitle,
  setContentFilters,
  setOutputDataCount,
  setUserCreds,
} from '../../../store/articleSlice/articleActions'
import {useDispatch, useSelector} from 'react-redux'
// import SETLANGUAGE from 'next-translate/setLanguage'
// import {handleLanguage} from 'store/authSlice/authActions'
import {useState} from 'react'
import {useRef} from 'react'
import {useEffect} from 'react'
import {CountryLanguages, PointOfView, QualityType, Tone} from '../../../SharedComponents/constants'
import {useIntl} from 'react-intl'
import {useNavigate, useParams} from 'react-router-dom'
import {useLang} from '../../../_metronic/i18n/Metronici18n'
import {toast} from 'react-toastify'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import PrimaryButton from '../../../SharedComponents/buttons/PrimaryButton'
import SecondaryButton from '../../../SharedComponents/buttons/SecondaryButton'
import CustomDropdown from '../../../SharedComponents/LanguageDropdown'
import PopAlert from '../../../SharedComponents/PopAlert'
import {useOnClickOutside} from '../../../SharedComponents/hooks/useOutsideClick'
import {useStepper} from '../../hooks/useStepper'
import ContentMenuBar from '../../modules/dashboard/ContentMenuBar'
import {LANGUAGES} from '../../../SharedComponents/helpers/Language'
import {doc, getDoc} from '@firebase/firestore'
import {firestore} from '../../../firebase/FirebaseConfig'

const ISRAEL = toAbsoluteUrl('/media/spa/Israel.png')
const HAMMENU = toAbsoluteUrl('/media/spa/Hamburger.png')
const CROSSPURPLE = toAbsoluteUrl('/media/spa/cross_purple.png')
const KTOVLI = toAbsoluteUrl('/media/spa/ktovli_logo.png')
const UPGRADE = toAbsoluteUrl('/media/spa/UpgradeIcon.png')
const DASHBOARD_ICON = toAbsoluteUrl('/media/spa/dashboard.png')
const DASHBOARD_ICON_ACTIVE = toAbsoluteUrl('/media/spa/dashboard-active.png')
const CREATE_NEW_ICON = toAbsoluteUrl('/media/spa/creatNew.png')
const CREATE_NEW_ICON_ACTIVE = toAbsoluteUrl('/media/spa/createNew-active.png')

//Defining routes here
const ROUTES = [
  {
    id: 1,
    title: 'DASHBOARD',
    icon: DASHBOARD_ICON,
    route: '/dashboard',
    active: true,
    activeIcon: DASHBOARD_ICON_ACTIVE,
  },
  {
    id: 3,
    title: 'NEW_TEMPLATE',
    icon: CREATE_NEW_ICON,
    route: '/articleWriter',
    active: false,
    activeIcon: CREATE_NEW_ICON_ACTIVE,
  },
]

const MobileNavbar = ({open, setOpen}) => {
  const intl = useIntl()
  const lang = useLang()
  const {user, accessToken} = useSelector((state) => state.login)
  const router = useNavigate()
  // const {articleId} = router.query || {}
  const params = useParams()
  const articleId = Number(params.articleId)

  const {
    UserID,
    Language_en,
    Tone_en,
    Quality_type_en,
    Point_of_view_en,
    isTitleSelected,
    Number_of_outputs,
    Topic_en,
    Topic,
    topic_edited,
    Keywords,
    Keywords_en,

    chosen_title,
    chosen_title_en,
    chosen_outlines,
    chosen_outlines_en,

    isIntroSelected,
    steps,
    isLoading,
    chosen_intro,
    chosen_intro_en,
    isOutlineSelected,
    isTitleSelectedError,
    isIntroSelectedError,
    isEditing,
  } = useSelector((state) =>
    articleId || articleId === 0 ? state.article.articles[articleId] : state.article
  )
  function persistLocaleCookie(customLocale) {
    // SETLANGUAGE(customLocale)
  }
  const [count, setCount] = useState(Number_of_outputs ? Number_of_outputs : 3)
  const [language, setLanguage] = useState(null)
  const [pointOfView, setPointOfView] = useState(null)
  const [tone, setTone] = useState({id: 1, name: 'None', translate: 'NONE'})
  const [quality, setQuality] = useState(null)
  const [isAlert, setIsAlert] = useState(false)
  const [isRedirect, setIsRedirect] = useState(false)
  const [selectedItem, setSelectedItem] = useState()
  const [openMenu, setOpenMenu] = useState()
  const stepper = useStepper(steps)
  const {articles} = useSelector((state) => state.article)
  const dispatch = useDispatch()

  // Setting every initial state
  useEffect(() => {
    setLanguage(
      Language_en
        ? CountryLanguages.find((obj) => obj.name === Language_en)
        : {
            code: 'he',
            name: 'Hebrew',
            direction: 'rtl',
            flag: ISRAEL,
            translate: 'HEBREW',
          }
    )
    setTone(Tone_en != null ? Tone.find((obj) => obj.name === Tone_en) : null)

    setPointOfView(
      Point_of_view_en ? PointOfView.find((obj) => obj.name === Point_of_view_en) : null
    )
    setQuality(
      Quality_type_en
        ? QualityType.find((obj) => obj.name === Quality_type_en)
        : {id: 3, name: 'Pro', translate: 'PRO'}
    )
    // setTags(Object.keys(Keywords).length > 0 ? Keywords : { 1: "" });
    dispatch(
      setUserCreds({
        userID: accessToken,
        Current_credits: user?.credits,
        id: articleId,
      })
    )
  }, [Keywords, Topic, Language_en, Quality_type_en])

  //setting right menu filters
  useEffect(() => {
    handleClickRightMenu()
  }, [tone, pointOfView, language, quality])
  //function that will call to set right menu data
  const handleClickRightMenu = () => {
    let data = {
      Language_en: language?.name,
      Tone_en: tone != null ? tone?.name : null,
      Quality_type_en: quality?.name,
      Point_of_view_en: pointOfView != null ? pointOfView?.name : null,
      id: articleId,
    }
    dispatch(setContentFilters(data))
  }
  const handleClickk = (item) => {
    if (item.title === 'DASHBOARD') {
      setIsAlert(true)
      setSelectedItem(item)
      // replace with your desired URL
    } else {
      handleAddNewTemplate(item.route)
    }
  }
  const handleRedirect = () => {
    window.open(selectedItem.route, '_self')
    setIsAlert(false)
  }
  const handleAddNewTemplate = (url) => {
    const randomNumber = articles ? articles.length : 0
    dispatch(addNewArticle(randomNumber))
    window.open(`${url}/${randomNumber}`, '_blank')
  }
  const ref = useRef()
  useOnClickOutside(ref, () => {
    setOpenMenu(false)
  })
  const handleFooterClick = async () => {
    let userId = localStorage.getItem('access_token')
    if (userId) {
      const docRef = doc(firestore, 'users', userId)
      const snapshot = await getDoc(docRef)
      let userData = snapshot.data()
      // if (!userData?.isLoggedIn) {
      //   // dispatch(handleSession())
      //   return
      // }
    }
    let data = {
      user_id: UserID,
      language_en: Language_en,
      tone_en: Tone_en,
      quality_type_en: Quality_type_en,
      point_of_view_en: Point_of_view_en,
      num_of_outputs: Number_of_outputs,
      topic: Topic.value,
      keywords: Object.keys(tags || {}).length >= 1 ? tags : {},
      id: articleId,
    }
    let introData = {
      user_id: UserID,
      language_en: Language_en,
      tone_en: Tone_en,
      quality_type_en: Quality_type_en,
      point_of_view_en: Point_of_view_en,
      num_of_outputs: Number_of_outputs,
      topic: Topic,
      topic_en: Topic_en,
      topic_edited: topic_edited,
      keywords: Keywords,
      keywords_en: Keywords_en,
      chosen_title: chosen_title,
      chosen_title_en: chosen_title_en,
      id: articleId,
    }
    let outlinesData = {
      ...introData,
      chosen_intro: chosen_intro,
      chosen_intro_en: chosen_intro_en,
      chosen_intro_edited: 1,
      id: articleId,
    }
    let articleData = {
      ...outlinesData,
      chosen_outlines: chosen_outlines?.value,
      chosen_outlines_en: chosen_outlines_en?.value,
      id: articleId,
    }
    if (
      (stepper?.active === 0 && isTitleSelected === false) ||
      (stepper?.active === 0 && Topic.value.length > 0)
    ) {
      let errorList = []
      if (Topic.value.length > 0) {
        if (tags && Object.keys(tags).length > 1) {
          const keys = Object.keys(tags)

          const mappedKeys = keys.map((key) => {
            if (tags[key].length <= 1) {
              errorList.push(parseInt(key))
            }
          })
          setKeywordsError(errorList)
        }

        if (errorList.length === 0 && KeywordsError?.length === 0) {
          if (user?.credits >= 100) {
            if (isTitleSelected || isIntroSelected) {
              dispatch(articleActions.setAllInactiveState(stepper.active))
              dispatch(articleActions.resetTitles({id: articleId}))
              dispatch(articleActions.resetIntros({id: articleId}))
              dispatch(articleActions.resetOutlines({id: articleId}))
            }
            dispatch(createTitle(data, action, intl))
          } else {
            toast.error(intl.formatMessage({id: 'TITLE_WORDS_LIMITATIONS'}))
          }
        }
      } else if (Topic.value.length <= 0) {
        setError(true)
        toast.error(intl.formatMessage({id: 'TOPIC_REQUIRED'}))
      }
    } else if (stepper?.active === 1 && isTitleSelected === false) {
      if (!isEditing) {
        if (user?.credits >= 100) {
          dispatch(reCreateTitle(data, updateCredits, intl))
        } else {
          toast.error(intl.formatMessage({id: 'TITLE_WORDS_LIMITATIONS'}))
        }
      } else {
        toast.error(intl.formatMessage({id: 'SAVE_EDITED'}))
      }
    } else if (stepper?.active === 1 && isTitleSelected === true) {
      if (Topic.value.length != 0 && Topic.value.length < 500) {
        if (!isEditing) {
          if (user?.credits >= 200) {
            if (isIntroSelected) {
              // dispatch(
              //   articleActions.setAllInactiveState(stepper.active)
              // );
              // dispatch(articleActions.resetIntros());
              // dispatch(articleActions.resetOutlines());
            }
            dispatch(createIntros(introData, action, intl))
          } else {
            toast.error(intl.formatMessage({id: 'INTRO_WORDS_LIMITATIONS'}))
          }
        } else {
          toast.error(intl.formatMessage({id: 'SAVE_EDITED'}))
        }
      } else if (Topic.value.length > 500) {
        toast.error(intl.formatMessage({id: 'CHOOSEN_TOPIC_CHARACTER_VALIDATION'}))
      } else {
        toast.error(intl.formatMessage({id: 'CHOOSEN_TOPIC_REQUIRED'}))
      }
    } else if (stepper?.active === 2 && isTitleSelected === true && isIntroSelected === false) {
      if (
        (Topic.value.length != 0 && Topic.value.length < 500) ||
        (chosen_title?.value?.length != 0 && chosen_title?.value?.length < 500)
      ) {
        if (!isEditing) {
          if (user?.credits >= 200) {
            dispatch(reCreateIntros(introData, updateCredits, intl))
          } else {
            toast.error(intl.formatMessage({id: 'INTRO_WORDS_LIMITATIONS'}))
          }
        } else {
          toast.error(intl.formatMessage({id: 'SAVE_EDITED'}))
        }
      } else if (Topic.value.length === 0) {
        toast.error(intl.formatMessage({id: 'CHOOSEN_TOPIC_REQUIRED'}))
      } else if (chosen_title?.value?.length === 0) {
        toast.error(intl.formatMessage({id: 'CHOOSEN_TITLE_REQUIRED'}))
      }
    } else if (stepper?.active === 2 && isTitleSelected === true && isIntroSelected === true) {
      if (
        Topic.value.length != 0 &&
        chosen_title?.value?.length != 0 &&
        chosen_intro?.value?.length != 0
      ) {
        if (!isEditing) {
          if (user?.credits >= 200) {
            if (isOutlineSelected) {
              dispatch(articleActions.setAllInactiveState(stepper.active))
              dispatch(articleActions.resetOutlines())
            }
            dispatch(createOutlines(outlinesData, action, intl))
          } else {
            toast.error(intl.formatMessage({id: 'INTRO_WORDS_LIMITATIONS'}))
          }
        } else {
          toast.error(intl.formatMessage({id: 'SAVE_EDITED'}))
        }
      } else if (Topic.value.length === 0) {
        toast.error(intl.formatMessage({id: 'CHOOSEN_TOPIC_REQUIRED'}))
      } else if (isTitleSelectedError) {
        toast.error(intl.formatMessage({id: 'CHOOSEN_TITLE_REQUIRED'}))
      }
    } else if (
      stepper?.active === 3 &&
      isTitleSelected === true &&
      isIntroSelected === true &&
      isOutlineSelected === false
    ) {
      if (
        Topic.value.length != 0 &&
        chosen_title?.value?.length != 0 &&
        chosen_intro?.value?.length != 0
      ) {
        if (!isEditing) {
          if (user?.credits >= 200) {
            dispatch(reCreateOutlines(outlinesData, updateCredits, intl))
          } else {
            toast.error(intl.formatMessage({id: 'INTRO_WORDS_LIMITATIONS'}))
          }
        } else {
          toast.error(intl.formatMessage({id: 'SAVE_EDITED'}))
        }
      } else if (Topic.value.length === 0) {
        toast.error(intl.formatMessage({id: 'CHOOSEN_TOPIC_REQUIRED'}))
      } else if (isTitleSelectedError) {
        toast.error(intl.formatMessage({id: 'CHOOSEN_TITLE_REQUIRED'}))
      } else if (isIntroSelectedError) {
        toast.error(intl.formatMessage({id: 'CHOOSEN_INTRO_REQUIRED'}))
      }
    } else if (
      stepper?.active === 3 &&
      isTitleSelected === true &&
      isIntroSelected === true &&
      isOutlineSelected === true
    ) {
      const hasEmptyString = Object.values(chosen_outlines.value).some((val) => val.value === '')
      if (
        Topic.value.length != 0 &&
        chosen_title?.value?.length != 0 &&
        chosen_intro?.value?.length != 0 &&
        !hasEmptyString
      ) {
        if (!isEditing) {
          if (user?.credits >= 400) {
            dispatch(articleActions.setAllInactiveState(stepper.active))
            dispatch(articleActions.resetArticle())
            dispatch(createArticle(articleData, action, intl))
          } else {
            toast.error(intl.formatMessage({id: 'ARTICLE_WORDS_LIMITATIONS'}))
          }
        } else {
          toast.error(intl.formatMessage({id: 'SAVE_EDITED'}))
        }
      } else if (hasEmptyString) {
        toast.error(intl.formatMessage({id: 'EMPTY_OUTLINE'}))
      } else if (Topic.length === 0) {
        toast.error(intl.formatMessage({id: 'CHOOSEN_TOPIC_REQUIRED'}))
      } else if (isTitleSelectedError) {
        toast.error(intl.formatMessage({id: 'CHOOSEN_TITLE_REQUIRED'}))
      } else if (isIntroSelectedError) {
        toast.error(intl.formatMessage({id: 'CHOOSEN_INTRO_REQUIRED'}))
      }
    } else if (
      stepper?.active === 4 &&
      isTitleSelected === true &&
      isIntroSelected === true &&
      isOutlineSelected === true
    ) {
      const hasEmptyString = Object.values(chosen_outlines.value).some((val) => val.value === '')
      if (
        Topic.value.length != 0 &&
        chosen_title?.value?.length != 0 &&
        chosen_intro?.value?.length != 0 &&
        !hasEmptyString
      ) {
        if (user?.credits >= 400) {
          setShowModal(true)

          setArticleData(articleData)
          // dispatch(articleActions.resetArticle());
          // dispatch(createArticle(articleData, updateCredits, t));
        } else {
          toast.error(intl.formatMessage({id: 'ARTICLE_WORDS_LIMITATIONS'}))
        }
      } else if (hasEmptyString) {
        toast.error(intl.formatMessage({id: 'EMPTY_OUTLINE'}))
      } else if (Topic.length === 0) {
        toast.error(intl.formatMessage({id: 'CHOOSEN_TOPIC_REQUIRED'}))
      } else if (isTitleSelectedError) {
        toast.error(intl.formatMessage({id: 'CHOOSEN_TITLE_REQUIRED'}))
      } else if (isIntroSelectedError) {
        toast.error(intl.formatMessage({id: 'CHOOSEN_INTRO_REQUIRED'}))
      }
    }
  }
  return (
    <>
      <nav
        className={`t-flex lg:t-hidden t-h-[83px] t-relative t-px-8 md:t-px-16 xl:t-px-24 t-w-full t-border t-items-center t-justify-between t-ease-in-out t-duration-300 t-z-10 t-bg-white `}
      >
        <div className='t-flex t-items-center t-justify-between t-gap-5 t-w-full'>
          <div className='t-px-2 t-py-6 t-h-[60px] t-flex t-items-center'>
            <img src={KTOVLI} alt='' height={100} width={100} className='' />
          </div>

          <div className='t-cursor-pointer' onClick={() => setOpenMenu(!openMenu)}>
            <img
              src={HAMMENU}
              alt='hamburger menu icon'
              width={100}
              height={100}
              className='t-w-[20px] md:t-w-[30px]'
            />
          </div>
        </div>
      </nav>
      {openMenu ? (
        <div
          ref={ref}
          className={`mobile t-overflow-y-auto t-bg-white t-fixed ${
            lang == 'heb' ? ' t-right-0 ' : ' t-left-0 '
          } t-h-full t-w-full 390:t-w-[70%] sm:t-w-[50%] md:t-w-[40%] t-z-20 t-top-0 t-flex t-flex-col lg:t-hidden t-py-8 t-px-5 470:t-px-8 md:t-px-10 xl:t-px-24 t-border t-items-start t-ease-in-out t-duration-300`}
        >
          <div
            className='t-cursor-pointer t-self-end -t-mr-1 470:-t-mr-4'
            onClick={() => setOpenMenu(!openMenu)}
          >
            <img
              src={CROSSPURPLE}
              alt='hamburger menu icon'
              width={100}
              height={100}
              className='t-w-[15px] md:t-w-[20px] '
            />
          </div>
          <div className='t-w-full'>
            <div className=' t-flex t-flex-col t-items-center t-text-[16px] md:t-text-lg'>
              {ROUTES.map((item) => {
                return (
                  <Item
                    key={item.id}
                    title={intl.formatMessage({id: item.title})}
                    open={open}
                    active={router.asPath === item.route}
                    onClick={() => handleClickk(item)}
                  />
                )
              })}
            </div>
            <div className='t-flex t-flex-col t-items-center t-gap-4 t-mt-4 t-text-black t-whitespace-nowrap'>
              <div className='t-flex t-items-center t-gap-8 t-text-[16px] md:t-text-lg'>
                <p className='t-font-bold '>
                  {' '}
                  {user?.plan === 'FREE'
                    ? intl.formatMessage({id: 'FREE_PLAN'})
                    : user?.plan === 'STANDARD'
                    ? intl.formatMessage({id: 'REGULAR_PLAN'})
                    : user?.plan === 'PRO'
                    ? intl.formatMessage({id: 'PRO_PLAN'})
                    : intl.formatMessage({id: 'FREE_PLAN'})}
                  Plan
                </p>
                <div className='t-flex t-text-black t-items-center t-gap-1'>
                  <p>{intl.formatMessage({id: 'WORDS_LEFT'})}:</p>
                  <p className='t-font-bold'>{user?.credits < 0 ? 0 : user.credits}</p>
                </div>
              </div>
              <div className='t-relative t-flex t-flex-col t-gap-3 t-w-full t-mt-1'>
                <SecondaryButton
                  iconPosition={1}
                  isLoading={false}
                  text={intl.formatMessage({id: 'UPGRADE_PLAN'})}
                  onClick={() =>
                    window.open(
                      `https://www.ktovli.com/pricing?lang=${lang}`,
                      '_blank'
                    )
                  }
                  isIcon={true}
                  icon={UPGRADE}
                  className='t-py-[13px] t-font-medium'
                  textClass='t-text-[18.7px]'
                />

                <div className='t-text-black'>
                  <CustomDropdown
                    width='t-w-full'
                    options={LANGUAGES}
                    onSelect={(selectedOption) => {
                      SETLANGUAGE(selectedOption.code)
                      persistLocaleCookie(selectedOption.code)
                      // dispatch(handleLanguage(selectedOption.code))
                    }}
                  />
                </div>
              </div>
              <div className='t-w-full t-h-[1px] t-bg-[#CBD5E1] t-overflow-y-auto' />
              <ContentMenuBar
                nextStep={stepper.nextStep}
                name={
                  stepper?.active === 4
                    ? 'Regenerate Article'
                    : `${intl.formatMessage({id: 'CREATE'})} ${intl.formatMessage({
                        id: `${steps.find((o) => o.id === stepper?.active + 1)?.name}`,
                      })}`
                }
                active={stepper?.active}
                isLastStep={stepper.isLastStep}
                setCount={setCount}
                count={count}
                // executeGoto={executeGoto}
                handleClick={handleFooterClick}
                setLanguage={setLanguage}
                language={language}
                setTone={setTone}
                tone={tone}
                quality={quality}
                setQuality={setQuality}
                stepper={stepper}
              />
            </div>
          </div>
        </div>
      ) : (
        ''
      )}
      {isAlert ? (
        <PopAlert
          isAlert={isAlert}
          setIsAlert={setIsAlert}
          setIsRedirect={setIsRedirect}
          handleRedirect={handleRedirect}
        />
      ) : (
        ''
      )}
    </>
  )
}
const Item = ({Key, title, icon, active, open, onClick, className}) => {
  return (
    <div
      key={Key}
      onClick={onClick}
      className={`${
        !open ? 't-justify-center' : ''
      } t-flex t-px-2 t-py-1.5 t-items-center t-font-normal t-text-gray-900 t-cursor-pointer ${
        active && open
          ? ' t-border-l-[6px] t-border-[#592E84] t-px-[10px] t-cursor-pointer t-pointer-events-none'
          : ''
      }`}
    >
      {icon ? <img src={icon} alt='' height={open ? 20 : 30} width={open ? 20 : 30} /> : ''}

      <p
        className={`t-flex-1 t-text-center t-whitespace-nowrap ${className} ${
          active ? 't-text-[#592E84] t-font-bold' : 't-text-[#303038] t-font-medium'
        }`}
      >
        {title}
      </p>
    </div>
  )
}
export default MobileNavbar
