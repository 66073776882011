// import {Tooltip} from '@material-tailwind/react'
import {useDispatch, useSelector} from 'react-redux'
import TextAreaInput from '../../components/elements/TextAreaInput'
import LoaderGenerate from '../../../SharedComponents/LoaderGenerate'
import TagInput from '../../components/elements/InputTag'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {useIntl} from 'react-intl'
import {useNavigate, useParams} from 'react-router-dom'
import {articleActions} from '../../../store/articleSlice/articleReducers'
import {useLang} from '../../../_metronic/i18n/Metronici18n'
const INFOICON = toAbsoluteUrl('/media/spa/info.png')

const TopicPage = ({
  topic,
  Topic,
  setTopic,
  tags,
  setTags,
  error,
  setError,
  steps,
  stepper,
  language,
  Keywords,
  KeywordsError,
  setKeywordsError,
  Language_en,
  isLoading,
}) => {
  const lang = useLang()
  const intl = useIntl()
  const dispatch = useDispatch()
  // const router = useNavigate()
  // const {articleId} = router.query || {}
  const params = useParams()
  const articleId = Number(params.articleId)

  const onChange = (val) => {
    setError(false)
    if (val.target.value.length < 500) {
      // setTopic(val.target.value);
      if (articleId === 0 || articleId) {
        dispatch(
          articleActions.setEditedTopic({
            value: val.target.value,
            id: articleId,
          })
        )
      } else {
        dispatch(articleActions.setEditedTopic(val.target.value))
      }
    }
  }

  return (
    <>
      {isLoading ? (
        <LoaderGenerate className='t-p-0 t-pt-0' size='t-w-8 t-h-8' fill='#592E84' />
      ) : (
        <div
          dir={lang == 'en' ? 'ltr' : 'rtl'}
          className='t-flex t-max-h-[764px] t-m-auto t-px-2.5 390:t-px-5 sm:t-px-11 t-py-3 sm:t-py-10 t-flex-col'
        >
          <div className=' t-w-full'>
            <TextAreaInput
              type='text'
              label={intl.formatMessage({id: 'INSERT_TOPIC'})}
              name='firstName'
              value={Topic.value}
              language={language}
              showInfoIcon={true}
              handleChange={onChange}
              placeholder={intl.formatMessage({
                id: 'HOW_TO_USE_GENERATIVE_AI_TO_AUTOMATICALLY_CREATE_SEO_CONTENT',
              })}
              rows={2}
              cols={5}
              InputClass=''
              tooltip={intl.formatMessage({id: 'INSERT_TOPIC_TOOLTIP'})}
              error={error}
              setError={setError}
            />
          </div>
          <div className='t-flex t-h-full t-mt-2 t-overflow-y-auto scrollbar-hidden'>
            <div className=' t-w-full t-pt-2 t-overflow-y-auto scrollbar-hidden'>
              <div className='t-flex t-items-center t-gap-2'>
                <p className='t-flex t-text-[16px] t-font-medium t-flex-row  t-text-black '>
                  {intl.formatMessage({id: 'ADD_KEYWORDS'})} {intl.formatMessage({id: 'OPTIONAL'})}
                </p>
                {/* <Tooltip
                  content={intl.formatMessage({id: 'ADD_KEYWORDS_TOOLTIP'})}
                  placement='top-end'
                  className='tooltip'
                >
                  <div>
                    <img src={INFOICON} alt='' height={15} width={15} />
                  </div>
                </Tooltip> */}
              </div>

              <TagInput tags={tags} setTags={setTags} />
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default TopicPage
