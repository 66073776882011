import React, {useState} from 'react'

// ICONS
import {useDispatch, useSelector} from 'react-redux'
import {useEffect} from 'react'
import {toast} from 'react-toastify'
import {Modal} from '../../../SharedComponents/Layouts/Modal'
import PrimaryButton from '../../../SharedComponents/buttons/PrimaryButton'
import SecondaryButton from '../../../SharedComponents/buttons/SecondaryButton'
import LoaderGenerate from '../../../SharedComponents/LoaderGenerate'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {useIntl} from 'react-intl'
import {useNavigate, useParams} from 'react-router-dom'
import {articleActions} from '../../../store/articleSlice/articleReducers'
import {
  getOutlinesFeedBack,
  rephraseOutlineAPI,
  rephraseOutlineArticle,
} from '../../../store/articleSlice/articleActions'
import {Loader} from '../../../SharedComponents/Loader'
const LIKE = toAbsoluteUrl('/media/spa/Like.png')
const DISLIKE = toAbsoluteUrl('/media/spa/Dislike.png')
const REPHRASE = toAbsoluteUrl('/media/spa/Rephrase.png')
const ADDICON = toAbsoluteUrl('/media/spa/plus_fill.png')
const EDIT = toAbsoluteUrl('/media/spa/Edit.png')
const DELETE = toAbsoluteUrl('/media/spa/delete.png')
const LIKE_ACTIVE = toAbsoluteUrl('/media/spa/Like_active.png')
const DISLIKE_ACTIVE = toAbsoluteUrl('/media/spa/Dislike_active.png')

const OutlinePage = ({steps, stepper}) => {
  const intl = useIntl()
  // const router = useNavigate()
  // const {articleId} = router.query || {}
  const params = useParams()
  const articleId = Number(params.articleId)
  const {
    outlines,
    outlines_en,
    chosen_outlines,
    chosen_outlines_en,
    UserID,
    Language_en,
    isLoadingAction,
    isRephrasing,
    isLoading,
  } = useSelector((state) =>
    articleId || articleId === 0 ? state.article.articles[articleId] : state.article
  )

  const dispatch = useDispatch()
  const [tempOutlines, setTempOutlines] = useState(outlines)
  const [tempOutlines_en, setTempOutlines_en] = useState(outlines_en)
  const [selected, setSelected] = useState(chosen_outlines && chosen_outlines.id)
  const [editIndex, setEditIndex] = useState()
  const [rephrasingIndex, setRephrasingIndex] = useState()

  useEffect(() => {
    setTempOutlines(outlines)
    setTempOutlines_en(outlines_en)
  }, [outlines, outlines_en])
  useEffect(() => {
    if (!isRephrasing) {
      setRephrasingIndex()
    }
  }, [isRephrasing])

  const handleOutlineSelected = (key) => {
    let data = {
      chosen_outlines: {
        id: key,
        value: outlines[key],
      },
      chosen_outlines_en: {
        id: key,
        value: outlines_en[key],
      },

      id: articleId,
    }
    dispatch(articleActions.setChoosenOutlines(data))
  }
  const handleOutlineDelete = (key) => {
    if (Object.keys(outlines).length < 2) {
      toast.error(intl.formatMessage({id: 'MUST_HAVE_ONE_OUTLINES'}))
    } else {
      dispatch(
        articleActions.removeOutlineItem(
          articleId || articleId === 0 ? {id: articleId, value: key} : key
        )
      )
    }
  }

  const handleLikeFeedBack = (index) => {
    let selectedOutline = outlines[index]
    if (selectedOutline.like == false) {
      let feedBack = {
        user_id: UserID,
        text: selectedOutline,
        type: 0,
        articleId: articleId,
      }
      dispatch(getOutlinesFeedBack(feedBack, intl))
    }
  }

  const handleDislikeFeedBack = (index) => {
    let selectedOutline = outlines[index]
    if (selectedOutline.dislike == false) {
      let feedBack = {
        user_id: UserID,
        text: selectedOutline,
        type: 1,
        articleId: articleId,
      }
      dispatch(getOutlinesFeedBack(feedBack, intl))
    }
  }

  return (
    <>
      {isLoading ? (
        <LoaderGenerate className='t-p-0 t-pt-0 t-items-center' size='t-w-8 t-h-8' fill='#592E84' />
      ) : (
        <div className='t-w-full t-max-h-[764px]  t-flex t-flex-col t-px-4 sm:t-px-7 t-py-4 t-mt-2'>
          <p className='t-text-[16px] t-font-[500] t-ml-auto t-text-[#303038]'>
            {intl.formatMessage({id: 'SELECT_AND_EDIT_YOUR_GENERATED_OUTLINES'})}
          </p>
          <div className='t-flex t-flex-col t-gap-3 sm:t-gap-4 t-py-3 sm:t-py-4'>
            {Object.keys(tempOutlines).map((item, i) => {
              return (
                <Outline
                  data={tempOutlines[item]}
                  isLike={tempOutlines[item]?.like}
                  isDislike={tempOutlines[item].dislike}
                  itemKey={i}
                  data_en={tempOutlines_en[item]}
                  dataId={item}
                  tempOutlines={tempOutlines}
                  setTempOutlines={setTempOutlines}
                  setTempOutlines_en={setTempOutlines_en}
                  tempOutlines_en={tempOutlines_en}
                  t={intl}
                  setSelected={setSelected}
                  handleLikeFeedBack={handleLikeFeedBack}
                  selected={selected}
                  handleClick={handleOutlineSelected}
                  handleDelete={handleOutlineDelete}
                  outlines={outlines}
                  outlines_en={outlines_en}
                  UserID={UserID}
                  Language_en={Language_en}
                  steps={steps}
                  stepper={stepper}
                  setEditIndex={setEditIndex}
                  editIndex={editIndex}
                  isLoading={isLoadingAction}
                  rephrasingIndex={rephrasingIndex}
                  setRephrasingIndex={setRephrasingIndex}
                  handleDislikeFeedBack={handleDislikeFeedBack}
                  isRephrasing={isRephrasing}
                />
              )
            })}
          </div>
        </div>
      )}
    </>
  )
}

const Outline = ({
  data,
  dataId,
  isLike,
  isDislike,
  tempOutlines,
  setTempOutlines,
  t,
  setSelected,
  selected,
  handleClick,
  handleDelete,
  outlines,
  outlines_en,
  UserID,
  setTempOutlines_en,
  tempOutlines_en,
  handleLikeFeedBack,
  handleDislikeFeedBack,
  data_en,
  Language_en,
  steps,
  stepper,
  itemKey,
  setEditIndex,
  editIndex,
  isLoading,
  rephrasingIndex,
  setRephrasingIndex,
  isRephrasing,
}) => {
  const dispatch = useDispatch()
  // const router = useNavigate()
  // const {articleId} = router.query || {}
  const params = useParams()
  const articleId = Number(params.articleId)
  const intl = useIntl()
  const handleInputChange = (item, event) => {
    let temp = JSON.parse(JSON.stringify(tempOutlines))
    let temp_en = JSON.parse(JSON.stringify(tempOutlines_en))
    if (event.target.value.length <= 500) {
      function changeName(temp, temp_en, index1, index2, newName) {
        if (temp[index1] && temp[index1].outlineobj && temp[index1].outlineobj[index2]) {
          temp[index1].outlineobj[index2].value = newName
          temp[index1].outlineobj[index2].isEdited = true
          temp_en[index1].outlineobj_en[index2].value = newName
          temp_en[index1].outlineobj_en[index2].isEdited = true
          return true
        }
        return false
      }
      const result = changeName(temp, temp_en, dataId, item, event.target.value)
      if (result) {
        setTempOutlines(temp)
        setTempOutlines_en(temp_en)
      }
    }
  }

  const onClickCrossInInput = (item) => {
    if (Object.keys(tempOutlines[dataId].outlineobj).length < 5) {
      toast.error(intl.formatMessage({id: 'MUST_HAVE_FOUR_OUTLINES'}))
    } else {
      let temp = JSON.parse(JSON.stringify(tempOutlines))
      let temp_en = JSON.parse(JSON.stringify(tempOutlines_en))
      delete temp[dataId].outlineobj[item]
      delete temp_en[dataId].outlineobj_en[item]

      let sortedKeys = Object.keys(temp[dataId].outlineobj)
      const sortedObj = {}
      for (let i = 0; i < sortedKeys.length; i++) {
        sortedObj[i] = temp[dataId].outlineobj[sortedKeys[i]]
      }
      temp[dataId].outlineobj = sortedObj

      let sortedKeys_en = Object.keys(temp_en[dataId].outlineobj_en)
      const sortedObj_en = {}
      for (let i = 0; i < sortedKeys_en.length; i++) {
        sortedObj_en[i] = temp_en[dataId].outlineobj_en[sortedKeys_en[i]]
      }
      temp_en[dataId].outlineobj_en = sortedObj_en

      setTempOutlines(temp)
      setTempOutlines_en(temp_en)
    }
  }

  const onClickSave = async () => {
    dispatch(
      articleActions.setIsEditing(
        articleId || articleId === 0 ? {id: articleId, value: false} : false
      )
    )
    const hasEmptyString = Object.values(tempOutlines[dataId].outlineobj).some(
      (val) => val.value.trim() === ''
    )

    if (!hasEmptyString) {
      let val = {
        outlines: tempOutlines[dataId].outlineobj,
        outlines_en: tempOutlines_en[dataId].outlineobj_en,
        key: dataId,
        id: articleId,
      }
      dispatch(articleActions.setEditedOutlines(val))
      setEditIndex()
    } else {
      toast.error(intl.formatMessage({id: 'EMPTY_OUTLINE'}))
    }
  }

  const onClickAdd = (item) => {
    let temp = JSON.parse(JSON.stringify(tempOutlines))
    let temp_en = JSON.parse(JSON.stringify(tempOutlines_en))
    if (Object.keys(temp[dataId].outlineobj).length < 8) {
      const tempInnerObj = {...temp[dataId].outlineobj}
      const tempInnerObj_en = {...temp_en[dataId].outlineobj_en}

      const selectedItem = parseInt(item) + 1
      const newValue = 'New Outline'
      for (let i = Object.keys(tempInnerObj).length - 1; i >= selectedItem; i--) {
        const currentKey = Object.keys(tempInnerObj)[i]
        tempInnerObj[parseInt(currentKey) + 1] = tempInnerObj[currentKey]
      }
      tempInnerObj[selectedItem] = {
        value: newValue,
        isEdited: true,
      }

      for (let i = Object.keys(tempInnerObj_en).length - 1; i >= selectedItem; i--) {
        const currentKey = Object.keys(tempInnerObj_en)[i]
        tempInnerObj_en[parseInt(currentKey) + 1] = tempInnerObj_en[currentKey]
      }
      tempInnerObj_en[selectedItem] = {
        value: newValue,
        isEdited: true,
      }

      temp[dataId].outlineobj = tempInnerObj
      temp_en[dataId].outlineobj_en = tempInnerObj_en

      setTempOutlines(temp)
      setTempOutlines_en(temp_en)
    } else {
      toast.error(intl.formatMessage({id: 'OUTLINES_LIMIT'}))
    }
  }

  // const onClickAdd = () => {
  //   const newKey = Object.keys(tempOutlines[dataId].outlineobj).length
  //     const newItem = {
  //       value: 'New Outline',
  //       isEdited: false,
  //     }

  //     const temp = JSON.parse(JSON.stringify(tempOutlines))
  //     const temp_en = JSON.parse(JSON.stringify(tempOutlines_en))

  //     temp[dataId].outlineobj[newKey] = newItem
  //     temp_en[dataId].outlineobj_en[newKey] = newItem

  //     setTempOutlines(temp)
  //     setTempOutlines_en(temp_en)
  //   } else {
  //     toast.error(intl.formatMessage({id: 'OUTLINES_LIMIT'}))
  //   }
  // }

  const onCancel = () => {
    dispatch(
      articleActions.setIsEditing(
        articleId || articleId === 0 ? {id: articleId, value: false} : false
      )
    )
    setEditIndex()
    setTempOutlines(outlines)
  }
  const action = (item, data) => {
    const temp = {...tempOutlines}
    const temp_en = {...tempOutlines_en}

    const updatedOutlines = {
      ...temp,
      [dataId]: {
        ...temp[dataId],
        [item]: {
          ...temp[dataId][item],
          value: data?.text,
          isEdited: true,
        },
      },
    }

    setTempOutlines(updatedOutlines)
    const updatedOutlines_en = {
      ...temp_en,
      [dataId]: {
        ...temp_en[dataId],
        [item]: {
          ...temp_en[dataId][item],
          value: data?.text,
          isEdited: true,
        },
      },
    }
    setTempOutlines_en(updatedOutlines_en)
  }
  // const RepharaseOutlineText = (key) => {
  //   if (data[key].value.replace(/ /g, '').length != 0) {
  //     let val = {
  //       user_id: UserID,
  //       text: data[key].value,
  //       text_en: data_en[key].value.length == 0 ? '' : data_en[key].value,
  //       language_en: Language_en,
  //       key: key,
  //       id: articleId,
  //     }
  //     setRephrasingIndex(key)
  //     dispatch(rephraseOutlineArticle(val, action, t))
  //   } else {
  //     toast.error(intl.formatMessage({id: 'REPHRASE_EMPTY'}))
  //   }
  // }
  const [openModal, setOpenModal] = useState(false)

  // //////////////
  const rephraseOutlines = (key) => {
    // setRephrasingIndex(key);
    // RepharaseText(key);
    const outlinesArr = Object.values(outlines)
    const index = outlinesArr.findIndex((item) => item === key)

    if (articleId || articleId === 0) {
      dispatch(articleActions.setSelectedRephrase({id: articleId, value: key}))
    } else {
      dispatch(articleActions.setSelectedRephrase(key))
    }
    let data = {
      user_id: UserID,
      outlines: outlines[key],
      outlines_en: outlines_en[key],
      language_en: 'Hebrew',
      id: articleId,
      key: key,
    }
    dispatch(rephraseOutlineAPI(data, t))
  }
  /////////////////

  return (
    <div
      className={`t-relative t-flex t-flex-col t-w-full t-items-end t-justify-end t-border-[2px]  t-rounded-md sm:t-rounded-xl t-bg-[#F8FAFC] t-cursor-pointer ${
        selected == dataId ? 't-border-[#592E84]' : 't-border-[#CBD5E1]'
      } t-py-3 470:t-py-6 t-px-3 470:t-px-6 t-gap-1 `}
      onClick={() => {
        if (editIndex != dataId) {
          setSelected(dataId)
          handleClick(dataId)
        }
      }}
    >
      {editIndex == dataId ? (
        <Modal
          open={openModal}
          onClose={() => {
            setOpenModal(false)
          }}
        >
          <div className='t-m-auto t-w-[95%] sm:t-w-[87%] t-bg-white t-px-6 md:t-px-12 t-pt-16 t-pb-7 t-rounded-xl  t-h-[600px] lg:t-h-[98vh] t-flex t-flex-col t-gap-3 t-my-6'>
            <div className='  t-flex t-flex-col t-gap-2 t-overflow-auto'>
              {Object.keys(data.outlineobj)?.map((item, i) => (
                <div
                  className='t-relative t-flex t-rounded-xl t-flex-row t-items-center t-justify-between t-w-full t-min-h-[50px] t-gap-2 p-2 t-border-[1px] t-border-[#592E84]'
                  key={i}
                >
                  <div className='t-flex t-flex-row t-items-center t-justify-center t-gap-1.5 470:t-gap-3 t-w-[15%]  lg:t-mr-1 '>
                    <Icon
                      Width='t-h-3 sm:t-h-5 t-w-3 sm:t-w-5'
                      icon={ADDICON}
                      onClick={() => onClickAdd(item)}
                      isLoading={isLoading}
                    />
                    <img
                      src={DELETE}
                      className={`t-h-3 sm:t-h-5 t-w-3 sm:t-w-5   ${
                        isLoading ? 't-pointer-events-none t-cursor-default' : 't-cursor-pointer'
                      }`}
                      alt='icon'
                      onClick={() => onClickCrossInInput(item)}
                    />
                  </div>
                  {isRephrasing && rephrasingIndex == i ? (
                    <div className=' t-flex t-justify-center t-items-start'>
                      <Loader
                        className='t-pr-10 t-pt-5 t-items-center t-justify-center t-flex '
                        size='t-w-8 t-h-8'
                        fill='#592E84'
                      />
                    </div>
                  ) : (
                    <input
                      type='text'
                      value={data.outlineobj[item].value}
                      onChange={(event) => handleInputChange(item, event)}
                      className='t-w-full t-text-right t-text-xs md:t-text-[16px] t-bg-WHITE t-pr-3 sm:t-pr-6 lg:t-pr-12 t-text-black t-break-words'
                      dir={Language_en === 'English' || Language_en === 'Russia' ? 'ltr' : 'rtl'}
                    />
                  )}
                </div>
              ))}
            </div>
            <div className='t-flex t-flex-row t-items-center t-justify-center sm:t-justify-end t-gap-4 t-mt-auto'>
              <div className='t-flex t-flex-wrap t-items-center t-gap-4 md:t-gap-8 t-font-semibold t-font-base'>
                <PrimaryButton
                  text={intl.formatMessage({id: 'CANCEL'})}
                  className='t-border t-border-[#592E84] !t-text-[#592E84] t-h-[40px] md:t-h-[50px] t-w-full 390:t-w-32'
                  onClick={(e) => {
                    e.stopPropagation()
                    onCancel()
                  }}
                />
                <SecondaryButton
                  text={intl.formatMessage({id: 'SAVE'})}
                  onClick={(e) => {
                    e.stopPropagation()
                    onClickSave()
                  }}
                  isIcon={false}
                  className='!t-text-white t-h-[40px] md:t-h-[50px] t-w-full 390:t-w-32'
                  textClass=''
                />
              </div>
            </div>
          </div>
        </Modal>
      ) : (
        ''
      )}
      <div className='t-w-full t-flex t-flex-col t-justify-between t-gap-5'>
        {isRephrasing && rephrasingIndex === itemKey ? (
          <Loader
            className='t-pr-10 t-pt-5 t-items-center t-justify-center t-flex '
            size='t-w-8 t-h-8'
            fill='#592E84'
          />
        ) : (
          <div className='t-flex t-flex-col t-items-end t-gap-2 t-font-medium'>
            {Object.keys(data.outlineobj).map(
              (opt, i) =>
                data.outlineobj[opt].value !== undefined && (
                  <p
                    style={{
                      wordBreak: 'break-word',
                    }}
                    className='t-text-right t-pl-5 t-text-xs t-text-[#374151] t-text-[13px] sm:t-text-[16px] t-cursor-pointer t-break-words '
                    key={i}
                    dir={Language_en === 'English' || Language_en === 'Russia' ? 'ltr' : 'rtl'}
                  >
                    {opt}: {data.outlineobj[opt].value}
                  </p>
                )
            )}
          </div>
        )}

        <div className='t-flex t-justify-between t-items-center'>
          <img
            src={DELETE}
            className='t-h-3 sm:t-h-5 t-w-3 sm:t-w-5 t-absolute t-top-3 t-left-3 sm:t-left-5 t-cursor-pointer'
            onClick={(e) => {
              handleDelete(dataId)
              e.stopPropagation()
            }}
            alt='icon'
          />
          <div className='t-flex t-flex-row t-items-center t-gap-5'>
            <div
              className='t-flex t-flex-row t-items-center t-gap-2 t-cursor-pointer'
              onClick={(e) => {
                dispatch(
                  articleActions.setIsEditing(
                    articleId || articleId === 0 ? {id: articleId, value: true} : true
                  )
                )
                setTempOutlines(outlines)
                setTempOutlines_en(outlines_en)
                setEditIndex(dataId)
                setOpenModal(true)

                // setSelected(null)
                // dispatch(articleActions.resetChosenOutline(articleId))
                e.stopPropagation()
              }}
            >
              <img src={EDIT} className='t-h-3 sm:t-h-4 t-w-3 sm:t-w-4' alt='icon' />
            </div>

            <div
              className={`t-flex t-flex-row t-items-center t-z-[999999]  ${
                isRephrasing ? 't-pointer-events-none  t-cursor-default' : 't-cursor-pointer'
              }`}
              onClick={(e) => {
                setRephrasingIndex(itemKey)
                // RepharaseText(id);
                e.stopPropagation()

                // RepharaseOutlineText;
                rephraseOutlines(dataId)
              }}
            >
              <img src={REPHRASE} className='t-h-3 sm:t-h-5 t-w-3 sm:t-w-5' />
            </div>
          </div>
          <div className='t-flex t-flex-row t-ml-auto t-gap-1'>
            <img
              src={isDislike ? DISLIKE_ACTIVE : DISLIKE}
              alt=''
              className={`t-h-3 sm:t-h-5 t-w-3 sm:t-w-5  ${
                isLoading ? 't-pointer-events-none t-cursor-default' : 't-cursor-pointer'
              }`}
              onClick={() => {
                handleDislikeFeedBack(itemKey)
              }}
            />
            <img
              src={isLike ? LIKE_ACTIVE : LIKE}
              alt=''
              className={`t-h-3 sm:t-h-5 t-w-3 sm:t-w-5 ${
                isLoading ? 't-pointer-events-none t-cursor-default' : 't-cursor-pointer'
              }`}
              onClick={() => {
                handleLikeFeedBack(itemKey)
              }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

const Icon = ({name, icon, onClick, isLoading, Width}) => (
  <div
    className={`t-flex t-flex-row t-items-center t-gap-2 ${
      isLoading ? 't-pointer-events-none t-cursor-default' : 't-cursor-pointer'
    }`}
    onClick={onClick}
  >
    {name ? <p className='t-text-SECONDARY t-text-xs'>{name}</p> : ''}
    <img src={icon} className={` ${Width ? Width : 't-h-3 t-w-3'}`} alt='icon' />
  </div>
)

export default OutlinePage
