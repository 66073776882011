import {useDispatch, useSelector} from 'react-redux'

import {CountryLanguages, QualityType, Tone, Feature} from '../../SharedComponents/constants'
import {useIntl} from 'react-intl'
import {useLocation, useParams, useSearchParams} from 'react-router-dom'
import Dropdown from '../components/DropDown'
import {useLang} from '../../_metronic/i18n/Metronici18n'
import SelectedTextContainer from '../components/SelectedTextContainer'
import Footer from '../components/layouts/Footer'
import {speedActions} from '../../store/speedSlice/speedReducers'
import SelectedOutlinesContainer from '../components/SelectedOutlinesContainer'
import {toast} from 'react-toastify'
import {useEffect} from 'react'

const ContentRightBar = ({language, tone, quality, feature, stepper}) => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const params = useParams()
  const [searchParams] = useSearchParams()
  const contentId = Number(params.contentId)
  const feat = searchParams.get('feature')
  const {chosen_title, intros, outlines} = useSelector(
    (state) => state.speed.articles[contentId].articleData
  )
  const {Topic, exampleAdd} = useSelector((state) => state.speed.articles[contentId])

  const lang = useLang()
  const location = useLocation()

  useEffect(() => {
    if (feat) {
      const chosen = Feature.find((feature) => feature.id == feat)
      if (chosen) {
        dispatch(speedActions.updateFeature({index: contentId, value: chosen}))
        const queryParams = new URLSearchParams(location.search)
        queryParams.delete('feature')
        const newSearch = queryParams.toString()
        const newPath = location.pathname + (newSearch ? `?${newSearch}` : '')
        window.history.replaceState(null, '', newPath)
      } else {
        searchParams.delete('feature')
      }
    }
  }, [])
  const updateTopic = (e) => {
    if (e.target.value.length <= 300) {
      dispatch(speedActions.setEditedTopic({index: contentId, value: e.target.value}))
    }
  }
  const updateExample = (e) => {
    if (e.target.value.length <= 600) {
      dispatch(speedActions.setEditedExampleAd({index: contentId, value: e.target.value}))
    }
  }
  const updateTitle = (e) => {
    if (e.target.value.length <= 500) {
      dispatch(speedActions.setEditedTitle({index: contentId, value: e.target.value}))
    }
  }
  const updateIntro = (e) => {
    dispatch(speedActions.setEditedIntro({index: contentId, value: e.target.value}))
  }
  const updateOutline = (e, Id) => {
    if (e.target.value.length <= 500) {
      dispatch(
        speedActions.setEditChosenOutline({
          index: contentId,
          key: Id,
          value: e.target.value,
        })
      )
    }
  }
  const removeOutline = (Id) => {
    if (outlines && Object.keys(outlines).length < 5) {
      toast.error(intl.formatMessage({id: 'MUST_HAVE_FOUR_OUTLINES'}))
    } else {
      dispatch(
        speedActions.removeChosenOutlineItem({
          key: Id,
          index: contentId,
        })
      )
    }
  }
  const handleLanguage = (opt) => {
    dispatch(speedActions.updateLanguageContent({index: contentId, value: opt}))
  }
  const handleTone = (opt) => {
    dispatch(speedActions.updateTone({index: contentId, value: opt}))
  }
  const handleQuality = (opt) => {
    dispatch(speedActions.updateQuality({index: contentId, value: opt}))
  }
  const handleFeature = (opt) => {
    // dispatch(speedActions.setArticleEmpty())
    dispatch(speedActions.updateFeature({index: contentId, value: opt}))
  }
  return (
    <>
      <div
        dir={lang == 'en' ? 'ltr' : 'rtl'}
        className='t-h-[calc(100vh-179px)] t-relative t-w-full t-rounded-xl t-bg-white t-drop-shadow t-border t-z-10  t-pt-6 t-items-center'
      >
        {/*  */}
        <div className='t-overflow-scroll t-w-full t-h-full t-flex t-flex-col'>
          <div className='t-px-7 t-flex t-w-full  t-justify-between t-flex-col t-items-center '>
            <Dropdown
              value={feature}
              setValue={handleFeature}
              options={Feature}
              showIcon={true}
              title={intl.formatMessage({id: 'FEATURE'})}
              disable={stepper.active > 0 ? true : false}
            />
            <Dropdown
              value={language}
              setValue={handleLanguage}
              options={CountryLanguages}
              showIcon={true}
              title={intl.formatMessage({id: 'LANGUAGE'})}
              disable={false}
            />

            <Dropdown
              value={tone}
              setValue={handleTone}
              options={Tone}
              title={intl.formatMessage({id: 'TONE'})}
              disable={false}
            />
            <Dropdown
              value={quality}
              setValue={handleQuality}
              options={QualityType}
              title={intl.formatMessage({id: 'QUALITY'})}
              disable={false}
            />
          </div>
          <hr className='t-px-7 t-w-full my-3' />

          <div className='t-px-7 t-flex t-w-full t-flex-col t-items-center  scrollbar-hidden t-pb-5 '>
            {stepper.active >= 1 && (
              <>
                <SelectedTextContainer
                  data={Topic.value}
                  isError={false}
                  language={language}
                  title={intl.formatMessage({id: 'TOPIC'})}
                  handleUpdate={updateTopic}
                />
                {feature.id === 2 && (
                  <>
                    <SelectedTextContainer
                      data={exampleAdd}
                      isError={false}
                      language={language}
                      title={intl.formatMessage({id: 'EXAMPLE_RIGHTBAR'})}
                      handleUpdate={updateExample}
                      datalength={600}
                    />
                  </>
                )}
                {feature.id === 1 && (
                  <>
                    <SelectedTextContainer
                      data={chosen_title.value}
                      isError={false}
                      language={language}
                      title={intl.formatMessage({id: 'TITLE'})}
                      handleUpdate={updateTitle}
                      datalength={500}
                    />
                    <SelectedTextContainer
                      data={intros?.value}
                      isError={false}
                      language={language}
                      title={intl.formatMessage({id: 'INTRO_RIGHTBAR'})}
                      handleUpdate={updateIntro}
                      datalength={500}
                    />
                  </>
                )}
              </>
            )}
            {stepper.active >= 1 && feature.id === 1 && (
              <div className='t-flex t-flex-col t-ml-auto t-w-full'>
                <div className='t-mt-1 t-flex t-flex-row  t-py-1 t-font-bold  t-text-[#303038] t-text-[12px] t-pr-1'>
                  {intl.formatMessage({id: 'OUTLINES'})}
                </div>
                {outlines &&
                  Object.keys(outlines).map((item, index) => {
                    return (
                      <SelectedOutlinesContainer
                        Id={item}
                        key={index}
                        data={outlines[item]}
                        handleRemove={removeOutline}
                        handleAdd={() => console.log('outline handleAdd')}
                        handleUpdate={updateOutline}
                        isError={false}
                        language={language}
                      />
                    )
                  })}
              </div>
            )}
          </div>
          <div className='t-bg-white t-px-7 t-mt-auto t-w-full lg:t-block t-hidden t-sticky t-bottom-0 t-pb-6 '>
            <Footer />
          </div>
        </div>
      </div>
    </>
  )
}

export default ContentRightBar
