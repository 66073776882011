import {createSlice} from '@reduxjs/toolkit'

const initialState = {
  user: {},
  isLoading: false,
  accessToken: null,
  sessionStartDate: null,
}
export const loginSlice = createSlice({
  name: 'login',
  initialState: {
    user: {},
    isLoading: false,
    accessToken: null,
    sessionStartDate: null,
    // currentLanguage: “en”,
  },
  reducers: {
    setLoader: (state, action) => {
      state.isLoading = action.payload
    },
    setEmail: (state, action) => {
      state.user = {...state.user, email: action.payload}
    },
    setCredits: (state, action) => {
      state.user = {...state.user, credits: action.payload}
    },
    setUserInfoAction: (state, action) => {
      state.user = action.payload
    },
    setAccessTokenAction: (state, action) => {
      state.accessToken = action.payload.userID
      // state.isLoggedIn = action.payload.isLoggedIn
    },
    setSessionStartDateAction: (state, action) => {
      state.sessionStartDate = action.payload
    },
    setLogoutSession: () => {
      return {...initialState}
    },
  },
})

export const userActions = loginSlice.actions
export const userReducer = loginSlice.reducer
