// import {Tooltip} from '@material-tailwind/react'
import {useSelector} from 'react-redux'
import {useNavigate, useParams} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {useLang} from '../../../_metronic/i18n/Metronici18n'
const INFOICON = toAbsoluteUrl('/media/spa/info.png')

const TextAreaInput = ({
  type,
  label,
  name,
  disabled,
  InputClass,
  error,
  handleChange,
  placeholder,
  limit,
  value,
  mainClass,
  showInfoIcon,
  language,
  tooltip,
  setError,
  ...rest
}) => {
  // const router = useNavigate()
  // const {articleId} = router.query || {}
  const params = useParams()
  const articleId = Number(params.articleId)
  const {Language_en} = useSelector((state) =>
    articleId || articleId === 0 ? state.article.articles[articleId] : state.article
  )
  return (
    <div className={`${mainClass} `}>
      {label ? (
        <label
          htmlFor={name ? name : 'inputElement'}
          className={`t-flex t-text-[16px] t-font-medium t-text-[18px] t-flex-row t-items-center t-gap-2 ${
            error ? 't-text-[red]' : 't-text-BLACK'
          }`}
        >
          {label}
          {/* <Tooltip
            content={tooltip}
            placement='top-end'
            className='tooltip'
            style={{backgroundColor: 'red', color: 'white'}}
          >
            <div className='t-flex t-items-center t-justify-center '>
              {showInfoIcon && <img src={INFOICON} alt='' height={17} width={17} className='' />}
            </div>
          </Tooltip> */}
        </label>
      ) : null}
      <div
        className={`t-bg-white t-flex t-flex-row t-items-center t-mt-1 t-w-full t-rounded-md  
        }`}
      >
        <textarea
          value={value}
          className={`t-min-h-[100px] 470:t-min-h-[135px] t-text-[13px] sm:t-text-[16px] t-bg-[#F8FAFC] t-mt-1 t-text-black  t-border t-border-[##E5E7EB] t-rounded-md t-py-3 t-px-2 390:t-px-4 t-placeholder-[#B0B0B0] t-placeholder:   t-w-full t-appearance-none focus:t-outline-none focus:t-shadow-outline ${InputClass}`}
          onChange={handleChange}
          placeholder={placeholder}
          dir={language?.code == 'en' || language?.code == 'ru' ? 'ltr' : 'rtl'}
          //   maxLength={limit}
          {...rest}
        />
      </div>
    </div>
  )
}
export default TextAreaInput
