// //USER ENDPOINTS
export const CREATE_TITLE = "create_title_article_writer";
// export const CREATE_TITLE = "create_title";
export const CREATE_INTRO = "create_intro_article_writer";
export const GET_FEEDBACK = "get_user_feedback_post";
export const REPHRASE_ARTICLE = "rephrase_post_article_writer";
export const CREATE_OUTLINES = "create_outlines_article_writer";
export const CREATE_ARTICLE = "create_article_article_writer";
export const IMPROVE_TEXT = "improve_text_article_writer";
export const ARTICLE_REPHRASE_TEXT = "rephrase_text_from_text_editor";
export const REPHRASE_OUTLINES = "rephrase_outlines_article_writer";
export const SPEED_ARTICLE="create_speed_article";
export const FACEBOOK_AD="create_by_feature";