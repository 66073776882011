import {configureStore} from '@reduxjs/toolkit'
import {persistStore, persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {combineReducers} from 'redux'
import {userReducer} from './auth/login/loginSlice'
import {signUpReducer} from './auth/signUp/signUpSlice'
import {articleReducer} from './articleSlice/articleReducers'
import {historyReducer} from './historySlice/historyReducer'
import {speedReducer} from './speedSlice/speedReducers'
const rootReducer = combineReducers({
  login: userReducer,
  signUp: signUpReducer,
  article: articleReducer,
  speed: speedReducer,
  history: historyReducer,
})

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['history'],
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = configureStore({
  reducer: persistedReducer,
})

const persistor = persistStore(store)

export {store, persistor}
