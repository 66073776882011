import {useRef} from 'react'
import {useOnClickOutside} from '../hooks/useOutsideClick'
import {Portal} from '../../spa/components/Portal'

const Modal = (props) => {
  const ref = useRef(null)

  const {open, onClose, children, outsideClick} = props
  {
    useOnClickOutside(ref, () => {
      onClose()
    })
  }

  return (
    <div>
      <Portal>
        {open && (
          <div
            className='t-w-screen t-h-screen t-z-50 t-right-0 t-top-0 t-fixed t-flex t-justify-center t-items-center'
            style={{backgroundColor: 'rgba(0,0,0,0.6)'}}
          >
            <div className='t-w-full' ref={ref}>
              {children}
            </div>
          </div>
        )}
      </Portal>
    </div>
  )
}

export {Modal}
