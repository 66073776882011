import {useIntl} from 'react-intl'
import {useDispatch, useSelector} from 'react-redux'
import {articleActions} from '../../../store/articleSlice/articleReducers'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {useLang} from '../../../_metronic/i18n/Metronici18n'
const CHECKWHITE = toAbsoluteUrl('/media/spa/checkWhite.png')

const MultiPageSlider = ({stepper, steps, executeGoto}) => {
  const dispatch = useDispatch()
  const intl = useIntl()
  const lang = useLang()
  const {isPending} = stepper
  return (
    <>
      <div className=' lg:t-px-20 xl:t-px-40 t-pb-5 470:t-pb-7  t-flex t-flex-col t-justify-center t-h-full t-py-1 '>
        <div
          className={`t-flex ${
            lang == 'heb' ? 't-flex-row-reverse' : 't-flex-row '
          }  t-items-center t-w-full t-justify-between t-relative t-h-12 md:t-h-14 `}
        >
          {/* <div className='t-flex t-w-full t-h-[3px] t-bg-[#dfdfdf]  t-justify-center t-items-center t-absolute lg:t-top-3'></div> */}
          {steps.map(({id, name, active}, index) => {
            const newactivearray = steps.filter((item) => item.active == true)

            let className = 't-h-6 t-w-6 t-rounded-full t-flex t-justify-center t-items-center '
            if (active) className += 't-bg-transparent t-text-WHITE '
            if (isPending(id)) className += 't-bg-transparent  t-cursor-pointer t-text-WHITE '
            return (
              <div
                key={index}
                className={`${
                  index + 1 == steps.length ? '' : 't-w-full'
                }  t-z-50 t-flex t-flex-col ${lang == 'heb' ? ' t-items-end ' : ' t-items-start '}`}
              >
                {index + 1 == steps.length ? (
                  ''
                ) : (
                  <div
                    className={`${
                      steps[index + 1].active == true ? 't-bg-[#592E84]' : 't-bg-[#CDD5E1]'
                    } t-flex t-w-full t-h-[1px] 470:t-h-[2px] t-relative t-justify-center t-items-center t-top-2 470:t-top-4`}
                  />
                )}

                <div
                  className={`t-relative t-z-50 ${
                    lang == 'heb' ? '-t-mr-[21px]' : '-t-ml-[21px]'
                  }  t-flex t-flex-col 470:t-gap-2 t-justify-between t-items-center`}
                  key={index}
                >
                  <div className='t-flex t-flex-col t-justify-center t-w-10 t-items-center'>
                    <div
                      className={`t-p-1 t-rounded-[50px] t-flex t-items-center t-justify-center t-w-[13px] 470:t-w-[30px] t-h-[13px] 470:t-h-[30px] ${
                        active ? 't-bg-[#592E84]' : 't-bg-[#BFBFBF]'
                      }  t-cursor-pointer`}
                      key={id}
                      onClick={() => {
                        dispatch(articleActions.setIsEditing(false))
                        active && executeGoto(id)
                      }}
                    >
                      {id < newactivearray.length - 1 || steps.length == newactivearray.length ? (
                        <img
                          src={CHECKWHITE}
                          alt=''
                          height={100}
                          width={100}
                          className=' 470:t-w-[60%]'
                        />
                      ) : (
                        <div className='t-h-[3px] 470:t-h-[8px] t-w-[3px] 470:t-w-[8px] t-bg-white t-rounded-full t-flex t-flex-col t-justify-center t-items-center' />
                      )}
                    </div>
                  </div>

                  <div
                    className={`t-absolute t-top-3 470:t-top-10 t-whitespace-nowrap ${
                      active ? 't-text-[#592E84]' : 't-text-[#BFBFBF]'
                    } t-block   t-rounded-[50px] t-bg-transparent t-text-[8px] 390:t-text-[11px] 470:t-text-[16px] 470:t-leading-5 t-mt-1 470:t-mt-0 t-text-center t-font-medium`}
                  >
                    {intl.formatMessage({id: name})}
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </>
  )
}

export default MultiPageSlider
