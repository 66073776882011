import {useDispatch, useSelector} from 'react-redux'
import {useIntl} from 'react-intl'
import {useParams} from 'react-router-dom'
import {useLang} from '../../_metronic/i18n/Metronici18n'
import TextAreaInput from '../components/elements/TextAreaInput'
import TagInput from '../components/elements/InputTag'
import {speedActions} from '../../store/speedSlice/speedReducers'
import LoaderGenerate from '../../SharedComponents/LoaderGenerate'

const TopicPage = ({error, setError, language}) => {
  const lang = useLang()
  const intl = useIntl()
  const dispatch = useDispatch()
  const params = useParams()
  const contentId = Number(params.contentId)
  const {Topic, feature, exampleAdd, isLoading} = useSelector(
    (state) => state.speed.articles[contentId]
  )
  const onChange = (val) => {
    if (val.target.value.length < 500) {
      dispatch(speedActions.setEditTopic({index: contentId, value: val.target.value}))
    }
  }
  const handleExampleAd = (val) => {
    if (val.target.value.length < 500) {
      dispatch(speedActions.setExampleAd({index: contentId, value: val.target.value}))
    }
  }

  return (
    <>
      {isLoading ? (
        <LoaderGenerate className='t-p-0 t-pt-0' size='t-w-8 t-h-8' fill='#592E84' />
      ) : (
        <div
          dir={lang == 'en' ? 'ltr' : 'rtl'}
          className='t-flex t-max-h-[764px] t-m-auto t-px-2.5 390:t-px-5 sm:t-px-11 t-py-3 sm:t-py-10 t-flex-col'
        >
          <div className=' t-w-full'>
            <TextAreaInput
              type='text'
              label={intl.formatMessage({id: 'INSERT_TOPIC'})}
              name='topic'
              value={Topic.value}
              language={language}
              handleChange={onChange}
              placeholder={
                (() => {
                  if (feature.id === 2) {
                    return intl.formatMessage({
                      id: 'TRAVEL_IN_SAVANNAHS',
                    });
                  }
                  // Add more conditions as needed
                  // Default placeholder if none of the conditions match
                  return intl.formatMessage({
                    id: 'HOW_TO_USE_GENERATIVE_AI_TO_AUTOMATICALLY_CREATE_SEO_CONTENT',
                  });
                })()
              }
              rows={2}
              cols={5}
              InputClass=''
              tooltip={intl.formatMessage({id: 'INSERT_TOPIC_TOOLTIP'})}
              error={error}
              setError={setError}
            />
          </div>
          <div className='t-flex t-h-full t-mt-2 t-overflow-y-auto scrollbar-hidden'>
            <div className=' t-w-full t-pt-2 t-overflow-y-auto scrollbar-hidden'>
              {feature.id === 1 ? (
                <>
                  <div className='t-flex t-items-center t-gap-2'>
                    <p className='t-flex t-text-[16px] t-font-medium t-flex-row  t-text-black '>
                      {intl.formatMessage({id: 'ADD_KEYWORDS'})}{' '}
                      {intl.formatMessage({id: 'OPTIONAL'})}
                    </p>
                  </div>
                  <TagInput />
                </>
              ) : (
                <TextAreaInput
                  type='text'
                  label={intl.formatMessage({id: 'EXAMPLE_AD_LABEL'})}
                  sublabel={intl.formatMessage({id: 'EXAMPLE_AD_SUBLABEL'})}
                  name='example ad'
                  value={exampleAdd}
                  language={language}
                  handleChange={handleExampleAd}
                  placeholder={intl.formatMessage({
                    id: 'EXAMPLE_AD_PLACEHOLDER',
                  })}
                  rows={3}
                  cols={6}
                  InputClass=''
                  error={error}
                  setError={setError}
                />
              )}
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default TopicPage
