import {useDispatch, useSelector} from 'react-redux'
// import {articleActions} from 'store/articleSlice/articleReducers'
// import SETLANGUAGE from 'next-translate/setLanguage'

import {toast} from 'react-toastify'
import {useEffect, useState} from 'react'

import Dropdown from '../../components/DropDown'
import {CountryLanguages, PointOfView, QualityType, Tone} from '../../../SharedComponents/constants'
import SelectedTextContainer from '../../components/SelectedTextContainer'
import SelectedOutlinesContainer from '../../components/SelectedOutlinesContainer'
import Footer from '../../components/Layouts/Footer'
import {useIntl} from 'react-intl'
import {useNavigate, useParams} from 'react-router-dom'
import {articleActions} from '../../../store/articleSlice/articleReducers'
import {useLang} from '../../../_metronic/i18n/Metronici18n'

const ContentRightBar = ({
  setLanguage,
  language,
  setTone,
  tone,
  quality,
  setQuality,
  stepper,
  name,
  active,
  isLastStep,
  setCount,
  count,
  disable,
  handleClick,
}) => {
  const intl = useIntl()
  const dispatch = useDispatch()
  // const router = useNavigate()
  // const {articleId} = router.query || {}
  const params = useParams()
  const articleId = Number(params.articleId)
  const {
    Language_en,
    Topic_en,
    isTitleSelected,
    isOutlineSelected,
    chosen_title_en,
    chosen_title,
    isLoading,
    Topic,
    isIntroSelected,
    chosen_intro,
    chosen_outlines,

    isTopicSelectedError,
    isTitleSelectedError,

    isIntroSelectedError,
    isOutlineSelectedError,
  } = useSelector((state) =>
    articleId || articleId === 0 ? state.article.articles[articleId] : state.article
  )
  const {user} = useSelector((state) => state.login)

  const lang = useLang()
  const updateIntro = (e) => {
    if (articleId > -1) {
      const data = {
        value: e.target.value,
        id: articleId,
      }
      dispatch(articleActions.setEditedIntro(data))
    } else {
      dispatch(articleActions.setEditedIntro(e.target.value))
    }
  }
  const updateTitle = (e) => {
    if (e.target.value.length <= 500) {
      if (articleId > -1) {
        const data = {
          value: e.target.value,
          id: articleId,
        }
        dispatch(articleActions.setEditedTitle(data))
      } else {
        dispatch(articleActions.setEditedTitle(e.target.value))
      }
    }
  }
  const updateTopic = (e) => {
    if (e.target.value.length <= 300) {
      if (articleId > -1) {
        const data = {
          value: e.target.value,
          id: articleId,
        }
        dispatch(articleActions.setEditedTopic(data))
      } else {
        dispatch(articleActions.setEditedTopic(e.target.value))
      }
    }
  }

  const updateOutline = (e, Id) => {
    if (e.target.value.length <= 500) {
      if (articleId > -1) {
        dispatch(
          articleActions.setEditChosenOutline({
            id: articleId,
            key: Id,
            val: e.target.value,
          })
        )
      } else {
        dispatch(
          articleActions.setEditChosenOutline({
            key: Id,
            val: e.target.value,
          })
        )
      }
    }
  }
  const handleRemoveClick = (Id) => {
    if (chosen_outlines && Object.keys(chosen_outlines?.value.outlineobj).length < 5) {
      toast.error(intl.formatMessage({id: 'MUST_HAVE_FOUR_OUTLINES'}))
    } else {
      dispatch(
        articleActions.removeChosenOutlineItem({
          key: Id,
          id: articleId,
        })
      )
    }
  }
  const handleAddClick = (Id) => {
    if (Object.keys(chosen_outlines?.value).length < 8) {
      dispatch(articleActions.addChosenOutlineItem(Id))
    } else {
      toast.error(intl.formatMessage({id: 'OUTLINES_LIMIT'}))
    }
  }
  function persistLocaleCookie(customLocale) {
    // SETLANGUAGE(customLocale)
  }

  const [open, setOpen] = useState(false)
  useEffect(() => {
    if (stepper.active < 4) {
      setOpen(true)
    } else {
      setOpen(true)
    }
  }, [stepper.active])

  return open ? (
    <>
      <div
        dir={lang == 'en' ? 'ltr' : 'rtl'}
        className='t-h-[calc(100vh-179px)] t-relative t-w-full t-rounded-xl t-bg-white t-drop-shadow t-border t-z-10  t-pt-6 t-items-center'
      >
        {/*  */}
        <div className='t-overflow-scroll t-w-full t-h-full t-flex t-flex-col'>
          <div className='t-px-7 t-flex t-w-full  t-justify-between t-flex-col t-items-center '>
            <Dropdown
              value={language}
              setValue={setLanguage}
              options={CountryLanguages}
              showIcon={true}
              isLanguages={true}
              className='my-2'
              title={intl.formatMessage({id: 'LANGUAGE'})}
              showInfoIcon={true}
              tooltip={intl.formatMessage({id: 'LANGUAGE_TOOLTIP'})}
              disable={disable}
            />

            <Dropdown
              options={Tone}
              className='my-2'
              value={tone}
              tooltip={intl.formatMessage({id: 'TONE_TOOLTIP'})}
              title={intl.formatMessage({id: 'TONE'})}
              showInfoIcon={true}
              setValue={setTone}
              disable={false}
            />
            <Dropdown
              options={QualityType}
              className='my-2'
              title={intl.formatMessage({id: 'QUALITY'})}
              showInfoIcon={true}
              value={quality}
              setValue={setQuality}
              tooltip={intl.formatMessage({id: 'QUALITY_TYPE_TOOLTIP'})}
              disable={false}
            />
          </div>
          <hr className='t-px-7 t-w-full my-3' />

          <div className='t-px-7 t-flex t-w-full t-flex-col t-items-center  scrollbar-hidden t-pb-5 '>
            {stepper.active >= 1 && (
              <SelectedTextContainer
                data={Topic?.value}
                isError={isTopicSelectedError}
                language={language}
                title={intl.formatMessage({id: 'TOPIC'})}
                handleUpdate={updateTopic}
                datalength={300}
              />
            )}
            {stepper.active >= 2 && isTitleSelected === true && (
              <SelectedTextContainer
                data={chosen_title?.value}
                isError={isTitleSelectedError}
                language={language}
                title={intl.formatMessage({id: 'TITLE'})}
                handleUpdate={updateTitle}
                datalength={500}
              />
            )}
            {stepper.active >= 3 && isIntroSelected === true && (
              <SelectedTextContainer
                data={chosen_intro?.value}
                isError={isIntroSelectedError}
                language={language}
                title={intl.formatMessage({id: 'INTRO_RIGHTBAR'})}
                handleUpdate={updateIntro}
                datalength={500}
              />
            )}

            {stepper?.active >= 4 && isOutlineSelected === true && (
              <div className='t-flex t-flex-col t-ml-auto t-w-full'>
                <div className='t-mt-1 t-flex t-flex-row  t-py-1 t-font-bold  t-text-[#303038] t-text-[12px] t-pr-1'>
                  {intl.formatMessage({id: 'OUTLINES'})}
                </div>
                {chosen_outlines &&
                  Object.keys(chosen_outlines?.value?.outlineobj).map((item, index) => {
                    return (
                      <SelectedOutlinesContainer
                        Id={item}
                        key={index}
                        data={chosen_outlines?.value?.outlineobj[item]}
                        handleRemove={handleRemoveClick}
                        handleAdd={handleAddClick}
                        handleUpdate={updateOutline}
                        isError={isOutlineSelectedError}
                        language={language}
                      />
                    )
                  })}
              </div>
            )}
          </div>
          <div className='t-bg-white t-px-7 t-mt-auto t-w-full lg:t-block t-hidden t-sticky t-bottom-0 t-pb-6 '>
            <Footer
              name={name}
              active={active}
              isLastStep={isLastStep}
              setCount={setCount}
              count={count}
              // executeGoto={executeGoto}
              handleClick={handleClick}
            />
          </div>
        </div>
      </div>
    </>
  ) : stepper.active === 4 ? (
    ''
  ) : null
}

export default ContentRightBar
