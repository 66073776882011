import React, {useEffect, useRef, useState} from 'react'
import {DropDownMenu} from '../../../../_metronic/layout/components/drop-down'
import {useNavigate} from 'react-router-dom'
import {auth, firestore} from '../../../../firebase/FirebaseConfig'
import {
  collection,
  deleteDoc,
  doc,
  documentId,
  endBefore,
  getCountFromServer,
  getDocs,
  limit,
  limitToLast,
  orderBy,
  query,
  startAfter,
  updateDoc,
  where,
} from '@firebase/firestore'
import 'firebase/firestore'
import moment from 'moment'
import {useIntl} from 'react-intl'
import {useDispatch, useSelector} from 'react-redux'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import DeleteConfirmationModal from '../../../../_metronic/layout/components/alert-modal'
import {toast} from 'react-toastify'
import {capitalizeFirstLetter} from '../../../../utils'
import {date} from 'yup'
import {articleActions} from '../../../../store/articleSlice/articleReducers'
import {historyActions, historyReducer} from '../../../../store/historySlice/historyReducer'

const updateArticleList = (data: any) => ({
  type: 'UPDATE_ARTICLE_LIST',
  payload: data,
})
function ArticleHistoryTable() {
  const dispatch = useDispatch()
  const [articleTitle, setArtilceTitle] = useState<string>('')
  const [showTextField, setShowTextField] = useState<any>(false)
  const [showModal, setShowModal] = useState<any>(false)
  const {accessToken: userId} = useSelector((state: any) => state.login)
  const {articleList} = useSelector((state: any) => state.article)
  const {historyArticles, currentPage} = useSelector((state: any) => state.history)
  const {articleCount} = useSelector((state: any) => state)
  const intl = useIntl()

  const rowsPerPage = 10
  // const [sorted, setSorted] = useState([]);
  // const [list, setList] = useState<any>([])
  const navigate = useNavigate()

  const handleAction = async (menu: any, event: any) => {
    if (event?.actionName === 'rename') {
      setArtilceTitle(menu?.article_title)
      setShowTextField({open: true, id: menu?.documentid})
    }

    if (event?.actionName === 'delete') {
      setShowModal({open: true, documentid: menu.documentid, id: menu.id})
    }

    // navigate(`/history/${menu.documentid}`)
  }

  const MENU = [
    {
      title: <span style={{fontWeight: 700}}>{intl.formatMessage({id: 'RENAME_TITLE'})}</span>,
      actionName: 'rename',
      icon: (
        <img alt='icon' style={{height: '20px', width: '20px'}} src={toAbsoluteUrl('/edit.svg')} />
      ),
    },
    {
      title: <span style={{fontWeight: 700}}>{intl.formatMessage({id: 'DELETE_ARTICLE'})}</span>,
      actionName: 'delete',
      icon: (
        <img
          alt='icon'
          style={{height: '20px', width: '20px'}}
          src={toAbsoluteUrl('/delete-outline.svg')}
        />
      ),
    },
  ]
  const [updated, setUpdated] = useState(false)

  const handleUpdateTitle = async (documentid: any, id: any) => {
    if (documentid) {
      const articleRef = doc(firestore, 'articles', documentid)
      updateDoc(articleRef, {
        article_title: articleTitle,
      })
        .then((res: any) => {
          setShowTextField(false)
          setUpdated(!updated)
          const data = {
            id: id,
            title: articleTitle,
          }
          dispatch(historyActions.setTitle(data))
          toast.success(intl.formatMessage({id: 'ARTICLE_TITLE_UPDATED'}))
        })
        .catch((error: any) => {
          setShowTextField(false)
          // handleGetData()
          toast.error(intl.formatMessage({id: 'SOMETHING_WENT_WRONG'}))
        })
    }
  }

  const getTime = (time: any) => {
    return moment.unix(time).format('YYYY-MM-DD HH:mm:ss')
  }

  const paginate = (listItem: any, page: any, rowsPerPage: any) => {
    const start = (page - 1) * rowsPerPage
    const end = start + rowsPerPage
    return listItem.slice(start, end)
  }

  const getWords = (words: any) => {
    const wordCount = words?.trim().split(/\s+/)
    return wordCount?.length === 1 && wordCount[0] === '<h1><br></h1>' ? 0 : wordCount?.length
  }

  const goToArticle = (article: any) => {
    if (article?.documentid && article.id) {
      dispatch(articleActions.setChosenArticle(article))
      navigate(`/history/${article?.documentid}?articleId=${article.id}`)
    }
  }

  //
  const [data, setVisibleData] = useState([])

  const [totalPages, setTotalPages] = useState<any>(null)
  const [snapshots, setSnapshots] = useState<any>([])
  const type = useRef('next')
  const PAGE_SIZE = 10

  useEffect(() => {
    if (totalPages) {
      fetchData()
    }
  }, [currentPage, totalPages, updated])

  const fetchData = async () => {
    let namesQuery = query(
      collection(firestore, 'articles'),
      where('user_id', '==', userId),
      orderBy('article_creation_date', 'desc'),
      limit(PAGE_SIZE)
    )
    if (currentPage > 1) {
      if (type.current === 'next') {
        const lastVisibleDoc = snapshots[snapshots.length - 1]
        const pageSize = Math.ceil(historyArticles?.length / 10)

        if (currentPage * 10 > historyArticles?.length && pageSize < currentPage) {
          namesQuery = query(namesQuery, startAfter(lastVisibleDoc))
          const snapshot = await getDocs(namesQuery)
          const newData: any = snapshot.docs.map((doc) => {
            const articlesList: any[] = []
            const tempData = doc.data()
            tempData.documentid = doc.id

            if (tempData.article?.length > 0 && tempData.article_title) {
              articlesList.push({
                ...tempData.article?.at(-1),
                article_title: tempData.article_title,
                documentid: tempData.documentid,
                type: tempData.type,
              })
            }
            return {...articlesList[0]}
          })
          setVisibleData(newData)
          setSnapshots(snapshot.docs)
          dispatch(historyActions.setHistoryArticles(newData))
        } else {
          const itemtostart = currentPage * 10 - 10
          const itemtoend = itemtostart + 10
          if (currentPage === totalPages) {
            const visibleData = historyArticles?.slice(itemtostart)
            setVisibleData(visibleData)
          } else {
            const visibleData = historyArticles?.slice(itemtostart, itemtoend)
            setVisibleData(visibleData)
          }
          return true
        }
      } else if (type.current === 'previous') {
        const itemtostart = currentPage * 10 - 10
        const itemtoend = itemtostart + 10
        const visibleData = historyArticles?.slice(itemtostart, itemtoend)

        setVisibleData(visibleData)
        return true
      }
    } else {
      if (currentPage * 10 > historyArticles?.length) {
        const snapshot = await getDocs(namesQuery)
        const newData: any = snapshot.docs.map((doc) => {
          const articlesList: any[] = []
          const tempData = doc.data()
          tempData.documentid = doc.id

          if (tempData.article?.length > 0 && tempData.article_title) {
            articlesList.push({
              ...tempData.article?.at(-1),
              article_title: tempData.article_title,
              documentid: tempData.documentid,
              type: tempData.type,
            })
          }
          return {...articlesList[0]}
        })
        setVisibleData(newData)
        setSnapshots(snapshot.docs)
        dispatch(historyActions.setHistoryArticles(newData))
      } else {
        const itemtostart = currentPage * 10 - 10
        const itemtoend = itemtostart + 10
        const visibleData = historyArticles?.slice(itemtostart, itemtoend)
        setVisibleData(visibleData)
      }
    }
  }
  const getTotalpages = async () => {
    const documentsScanned = await getCountFromServer(
      query(collection(firestore, 'articles'), where('user_id', '==', userId))
    )
    const toaltCounts = documentsScanned.data().count
    dispatch(articleActions.setArticleCount(toaltCounts))
    setTotalPages(Math.ceil(toaltCounts / PAGE_SIZE))
  }
  useEffect(() => {
    getTotalpages()
  }, [updated])
  const goToNextPage = () => {
    if (currentPage < totalPages) {
      type.current = 'next'
      dispatch(historyActions.setCurrentPage(currentPage + 1))
    }
  }

  const goToPreviousPage = () => {
    if (currentPage > 1) {
      type.current = 'previous'
      dispatch(historyActions.setCurrentPage(currentPage - 1))
    }
  }

  //
  const renderTableRows = (listItem: any) => {
    return (
      listItem &&
      listItem?.length > 0 &&
      listItem?.map((row: any, index: number) => (
        <tr key={`${index}--articleHistory`} className='text-black'>
          <th
            style={{cursor: 'pointer', paddingLeft: '15px'}}
            onClick={() =>
              (!showTextField?.open || showTextField?.id !== row?.documentid) && goToArticle(row)
            }
            scope='row'
          >
            {showTextField?.open && showTextField?.id === row?.documentid ? (
              <input
                value={articleTitle}
                className='t-w-full'
                onChange={(e: any) => {
                  setArtilceTitle(e.target.value)
                }}
                // onBlur={() => handleUpdateTitle(row?.documentid)}
                onKeyDown={(event) =>
                  event.key == 'Enter' ? handleUpdateTitle(row?.documentid, row?.id) : ''
                }
              />
            ) : (
              <span>{row?.article_title}</span>
            )}
          </th>
          <td style={{cursor: 'pointer'}} onClick={() => goToArticle(row)}>
            {getWords(row?.contentHTML)}
          </td>
          <td
            style={{cursor: 'pointer'}}
            className='sm:t-whitespace-nowrap'
            onClick={() => goToArticle(row)}
          >
            {getTime(row?.creation_date?.seconds)}
          </td>
          <td style={{cursor: 'pointer'}} onClick={() => goToArticle(row)}>
            {row?.plan ? row?.plan : 'FREE'}
          </td>
          <td style={{cursor: 'pointer'}} onClick={() => goToArticle(row)}>
            {row?.type && row?.type}
          </td>
          <td>
            <DropDownMenu
              onClick={(e: any) => {
                handleAction(row, e)
              }}
              dropdownMenu={MENU}
            />
          </td>
        </tr>
      ))
    )
  }

  const paginatedList = paginate(articleList, currentPage, rowsPerPage)
  const handleConfirmDelete = async () => {
    if (showModal?.documentid) {
      const collectionRef = collection(firestore, 'articles')
      const docRef = doc(collectionRef, showModal?.documentid)
      deleteDoc(docRef)
        .then((res: any) => {
          setUpdated(!updated)
          dispatch(historyActions.deleteArticle(showModal?.documentid))
          const itemtostart = currentPage * 10 - 10
          const itemtoend = itemtostart + 10
          const visibleData = historyArticles?.slice(itemtostart, itemtoend)
          setVisibleData(visibleData)
          setShowModal(false)
          toast.success(intl.formatMessage({id: 'ARTICLE_DELETED'}))
        })
        .catch((error: any) => {
          toast.error(intl.formatMessage({id: 'SOMETHING_WENT_WRONG'}))
        })
    }
  }

  return (
    <>
      <style>
        {`.table-hover tbody tr:hover {
  background-color: white; /* change this to the desired color */
}
.table {
    --bs-table-hover-bg:#EFDEFF;
}
.table tr, .table th, .table td {
    border-width:none !important;
    border-bottom: 0.4px solid;
    font-size:16px;
    font-weight:700;
    @media screen and (max-width: 767px) {
      font-size:14px;
      font-weight:400;
    }
}

`}
      </style>
      <DeleteConfirmationModal
        handleConfirmDelete={handleConfirmDelete}
        showModal={showModal}
        setShowModal={setShowModal}
      />
      <table className='table table-hover'>
        <thead>
          <tr>
            <th style={{color: '#592E84', fontWeight: 700, fontSize: '16px'}} scope='col'>
              {intl.formatMessage({id: 'ARTILCE_NAME'})}
            </th>
            <th style={{color: '#592E84', fontWeight: 700, fontSize: '16px'}} scope='col'>
              {intl.formatMessage({id: 'TOTAL_WORDS'})}
            </th>
            <th style={{color: '#592E84', fontWeight: 700, fontSize: '16px'}} scope='col'>
              {intl.formatMessage({id: 'RECENTLY_UPDATED'})}
            </th>
            <th style={{color: '#592E84', fontWeight: 700, fontSize: '16px'}} scope='col'>
              {intl.formatMessage({id: 'PLAN'})}
            </th>
            <th style={{color: '#592E84', fontWeight: 700, fontSize: '16px'}} scope='col'>
              {intl.formatMessage({id: 'TYPE'})}
            </th>
            <th style={{color: '#592E84', fontWeight: 700, fontSize: '16px'}} scope='col'>
              {intl.formatMessage({id: 'ACTION'})}
            </th>
          </tr>
        </thead>
        <tbody>{renderTableRows(data)}</tbody>
      </table>
      <nav aria-label='Page navigation'>
        <ul className='pagination'>
          <li className='page-item'>
            <button className='page-link' onClick={goToPreviousPage} disabled={currentPage === 1}>
              {intl.formatMessage({id: 'PREVIOUS'})}
            </button>
          </li>
          <li className='page-item'>
            <button
              className='page-link'
              onClick={goToNextPage}
              disabled={currentPage === totalPages}
            >
              {intl.formatMessage({id: 'NEXT'})}
            </button>
          </li>
        </ul>
      </nav>
    </>
  )
}

export {ArticleHistoryTable}
