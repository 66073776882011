import React, {useState} from 'react'

export function CommonTextField(props: any) {
  const {label, formik, name, error} = props
  return (
    <>
      <div className='form-group'>
        <label
          style={{color: '#303038', marginBottom: '10px', marginTop: '10px', fontWeight: 700}}
          // className='fs-4'
          htmlFor='formGroupExampleInput'
        >
          {label}
        </label>
        <input className='form-control' {...props} type='text' />
      </div>
      <div style={{marginLeft: '1rem', padding: '5px'}}>
        {(formik && formik.errors[name]) || error ? (
          <div className='error text-danger'>{error ? error : formik.errors[name]}</div>
        ) : null}
      </div>
    </>
  )
}

export function CommonPasswordField(props: any) {
  const {label, formik, name} = props
  const [showPassword, setShowPassword] = useState(false)

  const togglePassword = () => {
    setShowPassword(!showPassword)
  }

  return (
    <>
      <style>
        {`
        .input-group-append{
          z-index: 1001;
          position: absolute;
          right: -12px;
          background-color:inherit !important;
          border:none !important;
          height:50px !important;
      }
        
        .input-group-text{
        color:inherit !important;
        background-color:inherit !important;
        border:none !important;
        height:50px !important;
        z-index:1001 !important;
      }`}
      </style>
      <div className='form-group'>
        <label
          style={{color: '#303038', marginBottom: '10px', marginTop: '10px', fontWeight: 700}}
          htmlFor='password'
        >
          {label}
        </label>
        <div className='input-group'>
          <input
            id='password'
            className='form-control'
            {...props}
            style={{borderTopRightRadius: '12px', borderBottomRightRadius: '12px'}}
            type={showPassword ? 'text' : 'password'}
          />
          <div className='input-group-append'>
            <span className='input-group-text'>
              <button
                className={`btn btn-outline-secondary toggle-password ${
                  showPassword ? 'show-password' : 'hide-password'
                }`}
                type='button'
                onClick={togglePassword}
              >
                <i
                  style={{fontSize: '1.5rem'}}
                  className={`fa ${showPassword ? 'fa-eye-slash' : 'fa-eye'}`}
                ></i>
              </button>
            </span>
          </div>
        </div>
      </div>
      <div style={{marginLeft: '1rem', padding: '5px'}}>
        {formik && formik.errors[name] ? (
          <div className='error text-danger'>{formik.errors[name]}</div>
        ) : null}
      </div>
    </>
  )
}
