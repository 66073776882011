import {useIntl} from 'react-intl'
import {Fragment} from 'react'
import {Menu, Transition} from '@headlessui/react'
import {toAbsoluteUrl} from '../../_metronic/helpers'
const DROPDOWNICON = toAbsoluteUrl('/media/spa/dropdownIcon.png')

const Dropdown = ({value, setValue, options, showIcon = false, title, disable}) => {
  const intl = useIntl()
  return (
    <div className='t-flex t-flex-col t-w-full'>
      <div className='t-flex t-flex-row t-pr-3 t-py-1 t-gap-2 t-items-center t-text-sm xl:t-text-[16px] t-font-medium t-text-[#303038]  '>
        {title}
      </div>
      <Menu as='div' className='t-relative t-inline-block t-text-left t-pb-2 t-mt-1'>
        <div className=''>
          <Menu.Button
            className={`t-inline-flex t-w-full t-text-xs xl:t-text-[16px] t-text-center t-justify-between t-items-center t-rounded-lg ${
              !disable ? 't-bg-[#FFFFFF]' : 't-bg-[#efeded]'
            }  t-bg-opacity-20 t-px-4 t-py-2 t-text-black t-border t-border-[#CBD5E1] t-h-[42px] xl:t-h-[50px] `}
            disabled={disable}
          >
            {showIcon ? (
              <div className='t-flex t-gap-2 t-items-center '>
                <div className='t-w-[20px] t-h-[20px]'>
                  <img
                    src={value?.flag}
                    alt=''
                    className=' t-h-full'
                    style={{objectfit: 'contain'}}
                  />
                </div>
                {value && intl.formatMessage({id: value?.translate})}
              </div>
            ) : (
              value && intl.formatMessage({id: value.translate})
            )}
            <img src={DROPDOWNICON} alt='' height={20} width={20} className='' />
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter='transition ease-out duration-100'
          enterFrom='transform opacity-0 scale-95'
          enterTo='transform opacity-100 scale-100'
          leave='transition ease-in duration-75'
          leaveFrom='transform opacity-100 scale-100'
          leaveTo='transform opacity-0 scale-95'
        >
          <Menu.Items className='t-absolute t-z-50 t-mt-2  t-origin-top-right t-divide-y t-divide-gray-100 t-rounded-md t-bg-white t-shadow-lg t-ring-1 t-ring-black t-ring-opacity-5 focus:t-outline-none  t-w-full'>
            {options?.map((opt, index) => {
              return (
                <div className='t-px-1 t-py-1' key={index}>
                  <Menu.Item>
                    {({active}) => (
                      <button
                        onClick={() => {
                          setValue(opt)
                        }}
                        className={`${
                          active ? 't-bg-[#592E84] t-text-white' : 't-text-gray-900'
                        } t-group t-flex t-w-full t-gap-2 t-items-center t-rounded-md t-px-2 t-py-2 t-text-[11px]`}
                      >
                        {showIcon && (
                          <div className='t-w-[18px] t-h-[18px]'>
                            <img
                              src={opt?.flag}
                              alt=''
                              className='t-h-full object-contain'
                              style={{objectfit: 'contain'}}
                            />
                          </div>
                        )}
                        {intl.formatMessage({id: opt?.translate})}
                      </button>
                    )}
                  </Menu.Item>
                </div>
              )
            })}
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  )
}

export default Dropdown
