import React, {useState} from 'react'
import {BsEyeFill, BsFillEyeSlashFill} from 'react-icons/bs'
import {Link, useLocation, useNavigate} from 'react-router-dom'
import {InputType} from './InputType'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {useDispatch} from 'react-redux'
import {resetPass} from '../../../../store/auth/login/loginActions'
import {useIntl} from 'react-intl'

const initialValues = {
  password: '',
  confirmPass: '',
}

const registrationSchema = Yup.object().shape({
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
  confirmPass: Yup.string()
    .oneOf([Yup.ref('password')], 'Match the password')
    .required('Confirm the password'),
})

function ResetPassword() {
  const intl = useIntl()
  const navigate = useNavigate()
  const location = useLocation()
  const [showPassword, setShowPassword] = useState(false)
  const [confirmPassword, setConfirmPassword] = useState(false)
  const dispatch = useDispatch()
  const queryParams = new URLSearchParams(location.search)

  const handleToggleClick = (e: any) => {
    e.preventDefault()
    setShowPassword(!showPassword)
  }
  const handleConfirmPassword = (e: any) => {
    e.preventDefault()
    setConfirmPassword(!confirmPassword)
  }

  const action = () => {
    navigate('/login')
  }

  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      try {
        const oobCode = queryParams.get('oobCode') || ''
        const mode = queryParams.get('mode') || ''
        const splitUid = queryParams.get('continueUrl')?.split('=')[1] || ''
        let userData = {
          ...values,
          uid: splitUid,
          mode: mode,
          actionCode: oobCode,
        }

        dispatch(resetPass(userData, action, intl))
      } catch (error) {
        console.log(error)
      }
    },
  })

  return (
    <>
      <div
        className='container  d-flex flex-column justify-content-center align-items-center '
        style={{height: '100vh'}}
      >
        <div className='shadow h-75 w-lg-75 w-100 d-flex justify-content-center align-items-center'>
          <form
            className='form w-100  d-flex justify-content-center align-items-center flex-column fv-plugins-bootstrap5 fv-plugins-framework'
            noValidate
            id='kt_login_password_reset_form'
            onSubmit={formik.handleSubmit}
          >
            <div className='text-center mb-10'>
              <div
                style={{
                  height: '70px',
                  width: '70px',
                  borderRadius: '50%',
                  backgroundColor: '#F5EBFF',
                  padding: '13px',
                  margin: 'auto',
                }}
              >
                <img style={{height: '45px', width: '45px'}} src={toAbsoluteUrl('/lock.svg')} />
              </div>
              <p
                className='text-dark fw-bolder mb-3'
                style={{lineHeight: '50px', fontSize: '24px', fontWeight: 700}}
              >
                {intl.formatMessage({id: 'RESET_YOUR_PASSWORD'})}
              </p>
            </div>

            {/* className=' w-lg-75 w-100 px-1 d-flex flex-column align-items-center justify-content-center' */}
            {/* className='d-flex flex-wrap justify-content-center w-lg-75 w-100 my-5 pb-lg-0 px-5' */}
            <div className=' w-lg-75 w-100 px-1 d-flex flex-column justify-content-center'>
              <div style={{position: 'relative'}}>
                <InputType
                  label={intl.formatMessage({id: 'PASSWORD'})}
                  placeholder={intl.formatMessage({id: 'ENTER_YOUR_PASSWORD'})}
                  type={showPassword ? 'text' : 'password'}
                  name='password'
                  onChnage={formik.handleChange}
                  value={formik.values.password}
                />

                <button
                  onClick={(e) => handleToggleClick(e)}
                  className='bg-transparent border-0 '
                  style={{position: 'absolute', top: '40px', right: '5%'}}
                >
                  {showPassword ? (
                    <BsEyeFill size={20} color='#9D9DA6' />
                  ) : (
                    <BsFillEyeSlashFill size={20} color='#9D9DA6' />
                  )}
                </button>
                <div style={{marginLeft: '1rem', padding: '5px'}}>
                  {formik.errors.password ? (
                    <div className='error text-danger'>{formik.errors.password}</div>
                  ) : null}
                </div>
              </div>
              <div style={{position: 'relative'}}>
                <InputType
                  label={intl.formatMessage({id: 'CONFIRM_NEW_PASSWORD'})}
                  placeholder={intl.formatMessage({id: 'ENTER_NEW_PASSWORD_CONFIRM'})}
                  type={confirmPassword ? 'text' : 'password'}
                  name='confirmPass'
                  onChnage={formik.handleChange}
                  value={formik.values.confirmPass}
                />

                <button
                  onClick={(e) => handleConfirmPassword(e)}
                  className='bg-transparent border-0 '
                  style={{position: 'absolute', top: '40px', right: '5%'}}
                >
                  {confirmPassword ? (
                    <BsEyeFill size={20} color='#9D9DA6' />
                  ) : (
                    <BsFillEyeSlashFill size={20} color='#9D9DA6' />
                  )}
                </button>
                <div style={{marginLeft: '1rem', padding: '5px'}}>
                  {formik.errors.confirmPass ? (
                    <div className='error text-danger'>{formik.errors.confirmPass}</div>
                  ) : null}
                </div>
              </div>
            </div>

            <div className='d-flex flex-wrap justify-content-center w-lg-75 w-100 my-5 pb-lg-0 px-5'>
              <button
                type='submit'
                id='kt_password_reset_submit'
                className='btn  px-6 w-100'
                style={{backgroundColor: '#592E84', fontSize: '16px'}}
              >
                <span className='indicator-label text-light fw-bold fs-4'>
                  {intl.formatMessage({id: 'RESET_PASSWORD'})}
                </span>
                {/* {loading && (
                <span className='indicator-progress'>
                  Please wait...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )} */}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}

export default ResetPassword
