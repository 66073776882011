import React, {FC} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {ArticleHistoryTable} from './components/HistoryTable'

const HistoryPageWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <style>
        {` .headingClass{
        @media screen and (max-width: 767px) {
          text-align: center;
        }`}
      </style>
      <p className='headingClass text-black' style={{fontSize: '19px', fontWeight: 700}}>
        {intl.formatMessage({id: 'HISTORY_TABLE'})}
      </p>
      <ArticleHistoryTable />
    </>
  )
}

export default HistoryPageWrapper
