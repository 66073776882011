import React, {useEffect, useRef} from 'react'
import {useDispatch} from 'react-redux'
import {useNavigate} from 'react-router-dom'
import {logout} from '../../../store/auth/login/loginActions'

const IdleTimerContainer = () => {
  const dispatch = useDispatch()
  const timeoutRef = useRef(null)
  const protectedRoutes = ['/auth', '/auth/registration', '/auth/login', '/auth/forgot-password']
  const navigate = useNavigate()
  const userId = localStorage.getItem('access_token')
  const broadcastChannel = useRef(new BroadcastChannel('idleTimerChannel'))

  useEffect(() => {
    const resetTimer = () => {
      const action = () => {
        navigate('/auth')
      }
      clearTimeout(timeoutRef.current)
      timeoutRef.current = setTimeout(() => {
        const currentPath = window.location.pathname
        const lastActivity = localStorage.getItem('lastActivity')

        if (protectedRoutes.includes(currentPath)) {
          localStorage.setItem('lastActivity', Date.now().toString())
          broadcastChannel.current.postMessage({type: 'activity'}) // Notify other tabs of activity
        } else if (lastActivity && Date.now() - parseInt(lastActivity) >= 30 * 60 * 1000) {
          alert('SESSION EXPIRED AFTER INACTIVITY')
          dispatch(logout(action, userId))
        }
      }, 90 * 60 * 1000) // 90 minutes in milliseconds
    }

    const clearTimer = () => {
      clearTimeout(timeoutRef.current)
    }

    const handleBroadcastMessage = (event) => {
      if (event.data.type === 'activity') {
        resetTimer()
      }
    }

    const events = [
      'mousemove',
      'keydown',
      'wheel',
      'DOMMouseScroll',
      'mouseWheel',
      'mousedown',
      'touchstart',
      'touchmove',
      'MSPointerDown',
      'MSPointerMove',
    ]

    const onEvent = () => {
      localStorage.setItem('lastActivity', Date.now().toString())
      broadcastChannel.current.postMessage({type: 'activity'}) // Notify other tabs of activity
      resetTimer()
    }

    events.forEach((event) => {
      document.addEventListener(event, onEvent)
    })

    broadcastChannel.current.addEventListener('message', handleBroadcastMessage)

    resetTimer()

    return () => {
      events.forEach((event) => {
        document.removeEventListener(event, onEvent)
      })
      broadcastChannel.current.removeEventListener('message', handleBroadcastMessage)
      clearTimer()
    }
  }, [dispatch])

  return null
}

export default IdleTimerContainer
