import React, {useEffect, useRef, useState} from 'react'
import {useOnClickOutside} from '../../../../app/pages/setting/hooks/useOnClickOutside'
import {toAbsoluteUrl} from '../../../helpers'
import {useLang} from '../../../i18n/Metronici18n'

export function DropDownMenu({dropdownMenu, onClick}: any) {
  const lang = useLang()
  const [isOpen, setIsOpen] = useState(false)
  const dropdownRef = useRef<HTMLDivElement>(null)
  useOnClickOutside(dropdownRef, () => setIsOpen(false))

  function handleToggle() {
    setIsOpen(!isOpen)
  }

  return (
    <div className='dropdown'>
      <img
        src={toAbsoluteUrl('/media/icons/duotune/general/gen053.svg')}
        className='dropdown-toggle'
        id='dropdownMenuButton'
        data-toggle='dropdown'
        aria-haspopup='true'
        aria-expanded={isOpen ? 'true' : 'false'}
        onClick={handleToggle}
        style={{cursor: 'pointer'}}
      />
      <div
        className={`dropdown-menu ${isOpen ? 'show' : ''}`}
        ref={dropdownRef}
        aria-labelledby='dropdownMenuButton'
        style={{[lang === 'heb' ? 'marginRight' : 'marginLeft']: '-130px'}}
      >
        {dropdownMenu?.map((menu: any) => (
          <div
            className='dropdown-item'
            style={{
              cursor: 'pointer',
              color: menu?.color ? menu?.color : '',
              display: 'flex',
              alignItems: 'center',
              gap: '10px',
            }}
            onClick={() => onClick && onClick(menu)}
          >
            {menu.icon} {menu?.title}
          </div>
        ))}
      </div>
    </div>
  )
}
