import axios from 'axios'
import {Document, HeadingLevel, ImageRun, Packer, Paragraph, TextRun, UnderlineType} from 'docx'
import {saveAs} from 'file-saver'

const getImage = async (url) => {
  const response = await axios.get(url, {
    responseType: 'arraybuffer',
  })
  // return Buffer.from(response.data, "binary");
  const arrayBuffer = response.data
  const uint8Array = new Uint8Array(arrayBuffer)
  const imageBlob = new Blob([uint8Array], {type: response.headers['content-type']})

  return imageBlob
}

const downloadDoc = async ({articleData}) => {
  const doc = new Document({
    sections: [
      {
        children: [
          // new Paragraph({
          //   children: [
          //     new TextRun({
          //       text: articleData?.chosen_title.value,
          //       bold: true,
          //     }),
          //   ],
          // }),
          // new Paragraph({
          //   children: [
          //     new ImageRun({
          //       data: imageData, // pass the Uint8Array data instead of the URL
          //       transformation: {
          //         width: 500,
          //         height: 300,
          //       },
          //     }),
          //   ],
          // }),
          // new Paragraph({
          //   children: [
          //     new TextRun({
          //       text: articleData?.intros?.value,
          //       bold: true,
          //     }),
          //   ],
          // }),
          ...Object.keys(articleData.outlines).map(
            (item) =>
              new Paragraph({
                children: [
                  new TextRun({
                    text: articleData.outlines[item].value,
                    bold: true,
                  }),
                  new TextRun({
                    text: articleData.paragraphs[item],
                  }),
                ],
              })
          ),
        ],
      },
    ],
  })

  Packer.toBlob(doc).then((blob) => {
    saveAs(blob, 'download.doc')
  })
}

const getStyles = ({
  bold = null,
  italic = null,
  strike = null,
  underline = null,
  header = null,
}) => ({
  color: '#000000',
  ...(bold
    ? {
        bold: true,
      }
    : {}),
  ...(italic
    ? {
        italic: true,
      }
    : {}),
  ...(strike
    ? {
        strike: true,
      }
    : {}),
  // ...(header
  //   ? {
  //       bold: true,
  //       heading: HeadingLevel[`HEADING_${header}`],
  //     }
  //   : {}),
  ...(underline
    ? {
        type: UnderlineType.SINGLE,
        color: null,
      }
    : {}),
})
const createTextRun = ({text = '', attributes}, Language_en) =>
  new TextRun({
    rightToLeft: Language_en !== 'English' && Language_en !== 'Russia' && true,
    text,
    ...getStyles(attributes || {}),
  })

const createImageRun = async ({image}) => {
  const buffer = await getImage(image)
  return [
    new ImageRun({
      data: buffer,
      transformation: {
        width: 800,
        height: '300',
      },
    }),
  ]
}
const createParagraph = async ({embed, textRuns, attributes}, Language_en) =>
  new Paragraph({
    ...getStyles(attributes || {}),
    children: embed
      ? await createImageRun(embed)
      : textRuns.map((tr) => createTextRun(tr, Language_en)),

    direction: Language_en === 'English' || Language_en === 'Russia' ? 'ltr' : 'rtl',
    alignment: 'both',
    // Language_en === 'English' || Language_en === 'Russia'
    //   ? 'left'
    //   : 'right'
    bidirectional: true,
  })

const deltaToDoc = async (delta = null, Language_en) => {
  if (delta === null || !delta.ops) {
    throw new Error('No delta or delta ops was provided.')
  }

  const quillParser = await import('quilljs-parser')

  const parsedQuill = quillParser.parseQuillDelta(delta)
  const docEls = await Promise.all(
    parsedQuill.paragraphs.map(async (value) => {
      return createParagraph(value, Language_en)
    })
  )

  const doc = new Document({
    sections: [
      {
        children: [...docEls.filter((el) => el !== null)],
      },
    ],
  })
  Packer.toBlob(doc).then((blob) => {
    const url = window.URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = url
    a.download = '_xdownload.docx'
    a.click()
    window.URL.revokeObjectURL(url)
  })

  // Packer.toBlob(doc).then(blob => {
  //   saveAs(blob, '_xdownload.docx');
  // });
}

export {downloadDoc, deltaToDoc}
