import clsx from 'clsx'
import {useIntl} from 'react-intl'
import {useSelector} from 'react-redux'
import {useNavigate} from 'react-router-dom'
import {LANDINGPAGE_URL} from '../../../../constants'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import {useLang} from '../../../i18n/Metronici18n'
import {HeaderNotificationsMenu, HeaderUserMenu, Search, ThemeModeSwitcher} from '../../../partials'
import {useLayout} from '../../core'
import {SecondayButton} from '../button'

const itemClass = 'ms-1 ms-lg-3'
const btnClass =
  'btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-35px h-35px w-md-40px h-md-40px'
const userAvatarClass = 'symbol-35px symbol-md-40px'
const btnIconClass = 'svg-icon-1'

const Navbar = () => {
  const lang = useLang()
  const {user, accessToken} = useSelector((state: any) => state.login)
  const {config} = useLayout()
  const intl = useIntl()
  const navigate = useNavigate()

  const NAVBAR_MENU = [
    {
      title: intl.formatMessage({id: 'HOME'}),
      link: `${LANDINGPAGE_URL}?userEmail=${user?.email}&userId=${accessToken}&lang=${lang}`,
    },
    {
      title: intl.formatMessage({id: 'ABOUT_US'}),
      // link: `${LANDINGPAGE_URL}/#aboutUsDiv?lang=${lang}`,
      link: `${LANDINGPAGE_URL}/?lang=${lang}#aboutUsDiv`,
    },
    {
      title: intl.formatMessage({id: 'PRICING'}),
      link: `${LANDINGPAGE_URL}/pricing?userEmail=${user?.email}&userId=${accessToken}&lang=${lang}`,
    },
    {
      title: intl.formatMessage({id: 'BLOG'}),
      link: `${LANDINGPAGE_URL}/blog?userEmail=${user?.email}&userId=${accessToken}&lang=${lang}`,
    },
  ]

  return (
    <>
      <style>
        {`.navbarItemClass{
        @media screen and (max-width: 767px) {
          display: none;
        }
      }`}
      </style>

      <div
        // className='app-navbar flex-shrink-0'
        className='flex-shrink-0'
        style={{display: 'flex', alignItems: 'center', gap: '15px'}}
      >
        {/* <div className={clsx('app-navbar-item align-items-stretch', itemClass)}>
        <Search />
      </div> */}

        {/* <div className={clsx('app-navbar-item', itemClass)}>
        <div id='kt_activities_toggle' className={btnClass}>
          <KTSVG path='/media/icons/duotune/general/gen032.svg' className={btnIconClass} />
        </div>
      </div> */}
        {/* 
      <div className={clsx('app-navbar-item', itemClass)}>
        <div
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          className={btnClass}
        >
          <KTSVG path='/media/icons/duotune/general/gen022.svg' className={btnIconClass} />
        </div>
        <HeaderNotificationsMenu />
      </div> */}

        {/* <div className={clsx('app-navbar-item', itemClass)}>
        <div className={clsx('position-relative', btnClass)} id='kt_drawer_chat_toggle'>
          <KTSVG path='/media/icons/duotune/communication/com012.svg' className={btnIconClass} />
          <span className='bullet bullet-dot bg-success h-6px w-6px position-absolute translate-middle top-0 start-50 animation-blink' />
        </div>
      </div> */}

        {NAVBAR_MENU?.map((nav) => (
          <div
            key={`${nav},--ds`}
            className={clsx('navbarItemClass app-navbar-item-top', itemClass)}
          >
            <p style={{fontWeight: 700, fontSize: '16px', cursor: 'pointer', color: '#303038'}}>
              <a
                target='_self'
                href={nav.link}
                style={{fontWeight: 700, fontSize: '16px', cursor: 'pointer', color: '#303038'}}
              >
                {nav?.title}
              </a>
            </p>
          </div>
        ))}

        <div className={clsx('app-navbar-item', itemClass)}>
          <SecondayButton
            startIcon={
              <img
                style={{marginRight: '10px'}}
                src={toAbsoluteUrl('/media/dashboard/thunder.svg')}
                alt='icon'
              />
            }
            btnStyle={{
              height: '40px',
              width: '150px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              fontSize: '18px',
            }}
            onClick={() =>
              window.open(
                `${LANDINGPAGE_URL}/pricing?userEmail=${user?.email}&userId=${accessToken}&lang=${lang}`,
                '_self'
              )
            }
            name={intl.formatMessage({id: 'UPGRADE'})}
          />
        </div>

        <div className={clsx('app-navbar-item', itemClass)}>
          <div
            className={clsx('cursor-pointer symbol', userAvatarClass)}
            data-kt-menu-trigger="{default: 'click'}"
            data-kt-menu-attach='parent'
            data-kt-menu-placement='bottom-end'
          >
            <img
              src={toAbsoluteUrl('/media/avatars/300-1.jpg')}
              style={{marginTop: '0px !important'}}
              alt=''
            />
          </div>
          <HeaderUserMenu />
        </div>

        {/* {config.app?.header?.default?.menu?.display && (
          <div className='app-navbar-item d-lg-none ms-2 me-n3' title='Show header menu'>
            <div
              className='btn btn-icon btn-active-color-primary w-35px h-35px'
              id='kt_app_header_menu_toggle'
            >
              <KTSVG
                color='inherit'
                path='/media/icons/duotune/text/txt001.svg'
                className={btnIconClass}
              />
            </div>
          </div>
        )} */}
      </div>
    </>
  )
}

export {Navbar}
