import React, {FC, useEffect, useRef, useState} from 'react'
import {PageTitle} from '../../../_metronic/layout/core'
import {ArticleHistoryTable} from './components/HistoryTable'
import ReactQuill, {Quill} from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import './article.css'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {DropDownMenu} from '../../../_metronic/layout/components/drop-down'
import DeleteConfirmationModal from '../../../_metronic/layout/components/alert-modal'
import {deltaToDoc} from './components/downloadDoc'
import {useLocation, useNavigate, useParams} from 'react-router-dom'
import {
  arrayRemove,
  arrayUnion,
  collection,
  deleteDoc,
  doc,
  documentId,
  query,
  updateDoc,
  where,
} from '@firebase/firestore'
import {firestore} from '../../../firebase/FirebaseConfig'
import {toast} from 'react-toastify'
import {useIntl} from 'react-intl'
import sanitizeHtml from 'sanitize-html'
import {v4 as uuidv4} from 'uuid'
import {useLang} from '../../../_metronic/i18n/Metronici18n'
import {useMediaQuery} from 'react-responsive'
import {useSelector} from 'react-redux'

const ArticleEditorWrapper = () => {
  const isTabletOrMobile = useMediaQuery({
    query: '(max-width: 767px)',
  })
  const lang = useLang()
  const location = useLocation()
  const [showTextField, setShowTextField] = useState(false)
  const queryParams = new URLSearchParams(location.search)
  const intl = useIntl()
  const [showModal, setShowModal] = useState(false)
  const navigate = useNavigate()
  const [articleTitle, setArtilceTitle] = useState('')
  const quillRef = useRef(null)
  const [value, setValue] = useState('')
  const [quiltext, setQuilText] = useState('')
  const [count, setCount] = useState(0)
  const [delta, setDelta] = useState(null)
  const [language, setLanguage] = useState('English')
  const {id} = useParams()
  const article = useSelector((state) => state.article.chosenArticle)
  const articleId = queryParams.get('articleId') || ''

  let Quill

  const MENU = [
    {
      title: intl.formatMessage({id: 'SAVE_ARTICLE'}),
      actionName: 'saveArticle',
      icon: (
        <img
          alt='icon'
          style={{height: '17px', width: '17px'}}
          src={toAbsoluteUrl('/save-(1).svg')}
        />
      ),
      link: '/history/1',
    },
    {
      title: intl.formatMessage({id: 'DOWNLOAD_AS_DOC'}),
      actionName: 'docDownload',
      icon: <img style={{marginRight: '5px'}} src={toAbsoluteUrl('/download.svg')} />,
      link: '/history/1',
    },
    // {
    //   title: 'Download as PDF',
    //    actionName:'pdfDownload',
    //   icon: <img style={{marginRight: '5px'}} src={toAbsoluteUrl('/download.svg')} />,
    //   link: '/history/1',
    // },
    {
      title: intl.formatMessage({id: 'DELETE_ARTICLE'}),
      color: '#D92D20',
      actionName: 'delete',
      icon: <img style={{marginRight: '5px'}} src={toAbsoluteUrl('/delete.svg')} alt='' />,
      link: '/history/1',
    },
  ]
  // function undoChange() {
  //   quill.history.undo()
  // }
  // function redoChange() {
  //   quill.history.redo()
  // }
  const modules = {
    toolbar: {
      container: [
        [{header: [1, 2, false]}],
        ['bold', 'italic', 'underline', 'strike'],
        [{list: 'ordered'}, {list: 'bullet'}],
        [{align: ''}, {align: 'center'}, {align: 'right'}, {align: 'justify'}],
      ],
    },
  }

  const handleEditorChange = (content, delta, source, editor) => {
    setQuilText(editor.getText())
    setValue(content)
    setDelta(delta)
  }

  useEffect(() => {
    if (value) {
      const wordCount = value?.trim().split(/\s+/)
      setCount(wordCount.length === 1 && wordCount[0] === '<h1><br></h1>' ? 0 : wordCount.length)
    } else {
      setCount(0)
    }
  }, [value])

  const handleSave = async () => {
    const HTML = value

    if (id) {
      const articleRef = doc(firestore, 'articles', id)
      const newArticle = {
        id: uuidv4(),
        contentHTML: HTML,
        creation_date: new Date(),
      }
      await updateDoc(articleRef, {
        article: arrayUnion(newArticle),
      })
      // toast.success('Article saved successfully ')
      toast.success(intl.formatMessage({id: 'ARTICLE_SAVED'}))
    }
  }

  const handleAction = (menu) => {
    if (quillRef?.current && menu.actionName === 'docDownload') {
      const editor = quillRef?.current.getEditor()
      const upe = quillRef?.current.makeUnprivilegedEditor(editor)
      const contents = upe.getContents()
      deltaToDoc(contents, article.language)
    }
    if (menu.actionName === 'delete') {
      setShowModal(true)
    }

    if (menu.actionName === 'saveArticle') {
      handleSave()
    }
  }

  useEffect(() => {
    if (article) {
      setLanguage(article.language)
      setValue(article?.contentHTML)
      setArtilceTitle(article.article_title)
    }
  }, [article])

  const handleConfirmDelete = async () => {
    if (id) {
      const collectionRef = collection(firestore, 'articles')
      const docRef = doc(collectionRef, id)
      deleteDoc(docRef)
        .then((res) => {
          toast.success('Article deleted successfully!')
          navigate('/history')
        })
        .catch((error) => {
          toast.success('Something went wrong')
        })
    }
  }

  const handleUpdateTitle = async () => {
    if (id) {
      const articleRef = doc(firestore, 'articles', id)
      updateDoc(articleRef, {
        article_title: articleTitle,
      })
        .then((res) => {
          setShowTextField(false)
          toast.success('Article title updated successfully!')
        })
        .catch((error) => {
          setShowTextField(false)
          toast.success('Something went wrong')
        })
    }
  }
  const handleUndo = (event) => {
    event.preventDefault()
    event.stopPropagation()
    quillRef.current.focus()
    quillRef.current.getEditor().history.undo && quillRef.current.getEditor().history.undo()
  }

  const handleRedo = (event) => {
    event.preventDefault()
    event.stopPropagation()
    quillRef.current.focus()
    quillRef.current.getEditor().history.redo && quillRef.current.getEditor().history.redo()
  }
  const handleGetTitle = (title) => {
    if (title.length > 20) {
      return title.slice(0, 20) + '...'
    }
    return title
  }

  const formats = [
    'header',
    'font',
    'size',
    'bold',
    'italic',
    'underline',
    'align',
    'strike',
    'script',
    'blockquote',
    'background',
    'list',
    'bullet',
    'indent',
    'link',
    'image',
    'color',
    'code-block',
  ]

  return (
    <>
      <style>
        {`
        ..ql-picker-label{
          margin-right: ${lang === 'heb' ? '20px' : '0px'};
        }
        .ql-snow .ql-picker.ql-header .ql-picker-label::before, .ql-snow .ql-picker.ql-header .ql-picker-item::before{
          margin-right: ${lang === 'heb' ? '30px' : '0px'};
        }
        .ql-editor {  
          direction: ${language === 'English' || language === 'Russia' ? 'ltr' : 'rtl'};
          text-align: ${language === 'English' || language === 'Russia' ? 'left' : 'right'};
        }
        `}
      </style>
      <DeleteConfirmationModal
        handleConfirmDelete={handleConfirmDelete}
        showModal={showModal}
        setShowModal={setShowModal}
      />

      {/* <PageTitle breadcrumbs={[]}>Article Editor</PageTitle> */}

      <p>
        <img
          style={{color: '#303038', cursor: 'pointer'}}
          onClick={() => navigate('/history')}
          src={toAbsoluteUrl(
            `/media/icons/duotune/arrows/${lang === 'heb' ? 'arr064.svg' : 'arr063.svg'}`
          )}
          alt='icon'
        />

        {showTextField ? (
          <input
            value={articleTitle}
            onChange={(e) => {
              setArtilceTitle(e.target.value)
            }}
            onBlur={() => handleUpdateTitle()}
          />
        ) : (
          <span
          // onClick={(e: any) => {
          //   if (e.detail === 2) {
          //     setShowTextField(true)
          //   }
          // }}
          >
            {articleTitle}
          </span>
        )}
      </p>

      <div
        style={{
          // zIndex: 10002,
          position: 'absolute',
          [lang === 'heb' ? 'left' : 'right']: isTabletOrMobile ? '20px' : '100px',
          marginTop: '10px',
          display: 'flex',
          gap: '30px',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
        }}
        id='counter'
      >
        <img
          alt='icon'
          style={{
            float: 'right',
            cursor: 'pointer',
            height: '20px',
            width: '20px',
          }}
          onClick={handleSave}
          src={toAbsoluteUrl('/save-(1).svg')}
        />
        <div className='text-black d-flex gap-2' dir={lang === 'heb' ? 'ltr' : 'ltr'}>
          <img
            onClick={lang === 'heb' ? handleRedo : handleUndo}
            alt='icon'
            style={{
              height: '17px',
              width: '17px',
              cursor: 'pointer',
            }}
            src={toAbsoluteUrl('/Undo.svg')}
          />
          <img
            onClick={lang === 'heb' ? handleUndo : handleRedo}
            alt='icon'
            style={{
              height: '17px',
              width: '17px',
              cursor: 'pointer',
            }}
            src={toAbsoluteUrl('/Redo.svg')}
          />
        </div>
        <div style={{display: 'flex', gap: '20px'}}>
          <p
            style={{
              color: '#303038',
              fontSize: '13px',
              fontWeight: 400,
            }}
          >
            {' '}
            {intl.formatMessage({id: 'WORDS'})}: {count}
          </p>
          <DropDownMenu
            onClick={(e) => {
              handleAction(e)
            }}
            dropdownMenu={MENU}
          />
        </div>
      </div>
      <ReactQuill
        ref={quillRef}
        modules={modules}
        theme='snow'
        value={value}
        sanitize={false}
        onChange={handleEditorChange}
        scrollingContainer='html'
      />
    </>
  )
}

export default ArticleEditorWrapper
