import {getDoc} from 'firebase/firestore'
import {doc, updateDoc} from 'firebase/firestore'
// import {authActions} from 'store/authSlice/authReducers'
// import {handleSession} from 'store/authSlice/authActions'

// ////

import {useStepper} from './hooks/useStepper'
import SecondaryButton from '../SharedComponents/buttons/SecondaryButton'
import MultiPageStepper from './modules/dashboard/MultiPageStepper'
import {CountryLanguages, PointOfView, QualityType, Tone} from '../SharedComponents/constants'
import IntroPage from './modules/dashboard/IntroPage'
import TitlePage from './modules/dashboard/TitlePage'
import TopicPage from './modules/dashboard/TopicPage'
import NextGenerate from './components/elements/NextGenerate'
import {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {toast} from 'react-toastify'
import ArticlePage from './modules/dashboard/ArticlePage'
import OutlinePage from './modules/dashboard/OutlinePage'
import {Modal} from '../SharedComponents/Layouts/Modal'
import PrimaryButton from '../SharedComponents/buttons/PrimaryButton'
import WelcomeModal from './components/WelcomeModal'
import PopAlert from '../SharedComponents/PopAlert'
import ContentRightBar from './modules/dashboard/ContentRightBar'

import {QuillProvider} from '../SharedComponents/context/QuillContext'
import {toAbsoluteUrl} from '../_metronic/helpers'
import {useIntl} from 'react-intl'
import {useLocation, useNavigate, useParams, useSearchParams} from 'react-router-dom'
import {articleActions} from '../store/articleSlice/articleReducers'
import {
  createArticle,
  createIntros,
  createOutlines,
  createTitle,
  reCreateIntros,
  reCreateOutlines,
  reCreateTitle,
  setContentFilters,
  setOutputDataCount,
  setUserCreds,
} from '../store/articleSlice/articleActions'
import {firestore} from '../firebase/FirebaseConfig'
import {userActions} from '../store/auth/signUp/signUpSlice'
import {userActions as loggedInUserActions} from '../store/auth/login/loginSlice'

import {useLang} from '../_metronic/i18n/Metronici18n'
import './globals.css'
const ISRAEL = toAbsoluteUrl('/media/spa/Israel.png')

const ArticleWriter = () => {
  const dispatch = useDispatch()

  // useEffect(() => {
  //   const handleTabClose = (event) => {
  //     // Cancel the event to show the confirmation message
  //     event.preventDefault()
  //     // Chrome requires returnValue to be set
  //     event.returnValue = ''

  //     // Prompt the user with a confirmation message
  //     const confirmationMessage = 'Are you sure you want to leave this page?'
  //     event.returnValue = confirmationMessage
  //   }

  //   const handleUnload = () => {
  //     // Dispatch an action to clear Redux Persist only if the user confirmed leaving the page
  //     // dispatch({ type: 'CLEAR_PERSISTED_STATE' });

  //     dispatch(articleActions.setArticleEmpty())
  //   }

  //   // Listen for the 'beforeunload' event to show the confirmation message
  //   window.addEventListener('beforeunload', handleTabClose)

  //   // Listen for the 'unload' event to clear Redux Persist if the user confirmed leaving the page
  //   window.addEventListener('unload', handleUnload)

  //   // Clean up the event listeners on component unmount
  //   return () => {
  //     window.removeEventListener('beforeunload', handleTabClose)
  //     // window.removeEventListener('unload', handleUnload)
  //   }
  // }, [dispatch])

  useEffect(() => {
    if (!sessionStorage.getItem('firstVisit')) {
      // Clear the local storage
      dispatch(articleActions.setArticleEmpty())
      sessionStorage.setItem('firstVisit', true)
    }
    if (localStorage.getItem('ArticleWriter') === '1') {
      setIsWelcome(true)
    }
  }, [])

  const intl = useIntl()
  const {user, accessToken} = useSelector((state) => state.login)

  const params = useParams()

  const articleId = Number(params.articleId)
  const {
    UserID,
    Language_en,
    Tone_en,
    Quality_type_en,
    Point_of_view_en,
    isTitleSelected,
    Number_of_outputs,
    Topic_en,
    Topic,
    topic_edited,
    Keywords,
    Keywords_en,

    chosen_title,
    chosen_title_en,
    chosen_outlines,
    chosen_outlines_en,

    isIntroSelected,
    steps,
    isLoading,
    chosen_intro,
    chosen_intro_en,
    isOutlineSelected,
    isTitleSelectedError,
    isIntroSelectedError,
    isEditing,
  } = useSelector((state) =>
    articleId || articleId === 0 ? state.article.articles[articleId] : state.article
  )

  const [count, setCount] = useState(Number_of_outputs ? Number_of_outputs : 3)
  const stepper = useStepper(steps)
  const [language, setLanguage] = useState(null)
  const [tone, setTone] = useState({id: 1, name: 'None', translate: 'NONE'})
  const [pointOfView, setPointOfView] = useState(null)
  const [quality, setQuality] = useState(null)
  const [topic, setTopic] = useState('')
  const [tags, setTags] = useState({})
  const [error, setError] = useState(false)
  const [KeywordsError, setKeywordsError] = useState([])
  const [selectedTitle, setSelectedTitle] = useState()
  const [showModal, setShowModal] = useState(false)
  const [articleData, setArticleData] = useState()

  const [isTempTitleSelected, setIsTempTitleSelected] = useState(false)

  // Setting every initial state
  useEffect(() => {
    setLanguage(
      Language_en
        ? CountryLanguages.find((obj) => obj.name === Language_en)
        : {
            code: 'he',
            name: 'Hebrew',
            direction: 'rtl',
            flag: ISRAEL,
            translate: 'HEBREW',
          }
    )
    setTone(Tone_en != null ? Tone.find((obj) => obj.name === Tone_en) : null)

    setPointOfView(
      Point_of_view_en ? PointOfView.find((obj) => obj.name === Point_of_view_en) : null
    )
    setQuality(
      Quality_type_en
        ? QualityType.find((obj) => obj.name === Quality_type_en)
        : {id: 3, name: 'Pro', translate: 'PRO'}
    )
    // setTags(Object.keys(Keywords).length > 0 ? Keywords : { 1: "" });
    dispatch(
      setUserCreds({
        userID: accessToken,
        Current_credits: user?.credits,
        id: articleId,
      })
    )
  }, [Keywords, Topic, Language_en, Quality_type_en])

  //setting right menu filters
  useEffect(() => {
    handleClickRightMenu()
  }, [tone, pointOfView, language, quality])

  //Updating footer count
  useEffect(() => {
    if (articleId || articleId === 0) {
      dispatch(setOutputDataCount({value: count, id: articleId}))
    } else {
      dispatch(setOutputDataCount(count))
    }
  }, [count])

  //function that will call to set right menu data
  const handleClickRightMenu = () => {
    let data = {
      Language_en: language?.name,
      Tone_en: tone != null ? tone?.name : null,
      Quality_type_en: quality?.name,
      Point_of_view_en: pointOfView != null ? pointOfView?.name : null,
      id: articleId,
    }
    dispatch(setContentFilters(data))
  }
  // const updateCredits = async (cred) => {
  //   let NewCredits = user?.credits - cred
  //   dispatch(loggedInUserActions.setCredits(NewCredits))
  //   const userRef = doc(firestore, 'users', UserID)
  //   await updateDoc(userRef, {
  //     credits: NewCredits,
  //   })
  //   if (articleId || articleId === 0) {
  //     // stepper.nextStep()
  //   }
  // }
  const updateCredits = async (cred) => {
    const userRef = doc(firestore, 'users', UserID)
    const snapshot = await getDoc(userRef)
    let data = snapshot.data()
    // let NewCredits = user?.credits - cred
    let NewCredits = data?.credits - cred
    await updateDoc(userRef, {
      credits: NewCredits,
    })
    const newsnapshot = await getDoc(userRef)
    let newdata = newsnapshot.data()
    dispatch(loggedInUserActions.setCredits(newdata?.credits))
    if (articleId || articleId === 0) {
      // stepper.nextStep()
    }
  }
  const action = (creds) => {
    updateCredits(creds)
    stepper.nextStep()
  }
  //Footer button functionality
  const handleFooterClick = async () => {
    let userId = localStorage.getItem('access_token')
    if (userId) {
      const docRef = doc(firestore, 'users', userId)
      const snapshot = await getDoc(docRef)
      let userData = snapshot.data()
      // if (!userData?.isLoggedIn) {
      //   // dispatch(handleSession())
      //   return
      // }
    }
    let data = {
      user_id: UserID,
      language_en: Language_en,
      tone_en: Tone_en,
      quality_type_en: Quality_type_en,
      point_of_view_en: Point_of_view_en,
      num_of_outputs: Number_of_outputs,
      topic: Topic.value,
      keywords: Object.keys(tags || {}).length >= 1 ? tags : {},
      id: articleId,
    }
    let introData = {
      user_id: UserID,
      language_en: Language_en,
      tone_en: Tone_en,
      quality_type_en: Quality_type_en,
      point_of_view_en: Point_of_view_en,
      num_of_outputs: Number_of_outputs,
      topic: Topic,
      topic_en: Topic_en,
      topic_edited: topic_edited,
      keywords: Keywords,
      keywords_en: Keywords_en,
      chosen_title: chosen_title,
      chosen_title_en: chosen_title_en,
      id: articleId,
    }
    let outlinesData = {
      ...introData,
      chosen_intro: chosen_intro,
      chosen_intro_en: chosen_intro_en,
      chosen_intro_edited: 1,
      id: articleId,
    }
    let articleData = {
      ...outlinesData,
      chosen_outlines: chosen_outlines?.value,
      chosen_outlines_en: chosen_outlines_en?.value,
      id: articleId,
    }
    if (
      (stepper?.active === 0 && isTitleSelected === false) ||
      (stepper?.active === 0 && Topic.value.length > 0)
    ) {
      let errorList = []
      if (Topic.value.length > 0) {
        if (tags && Object.keys(tags).length > 1) {
          const keys = Object.keys(tags)

          const mappedKeys = keys.map((key) => {
            if (tags[key].length <= 1) {
              errorList.push(parseInt(key))
            }
          })
          setKeywordsError(errorList)
        }

        if (errorList.length === 0 && KeywordsError?.length === 0) {
          if (user?.credits >= 100) {
            if (isTitleSelected || isIntroSelected) {
              const data = {
                value: stepper.active,
                id: articleId,
              }
              dispatch(articleActions.setAllInactiveState(data))
              dispatch(articleActions.resetTitles({id: articleId}))
              dispatch(articleActions.resetIntros({id: articleId}))
              dispatch(articleActions.resetOutlines({id: articleId}))
            }

            dispatch(createTitle(data, action, intl))
          } else {
            toast.error(intl.formatMessage({id: 'TITLE_WORDS_LIMITATIONS'}))
          }
        }
      } else if (Topic.value.length <= 0) {
        setError(true)
        toast.error(intl.formatMessage({id: 'TOPIC_REQUIRED'}))
      }
    } else if (stepper?.active === 1 && isTitleSelected === false) {
      if (!isEditing) {
        if (user?.credits >= 100) {
          dispatch(reCreateTitle(data, updateCredits, intl))
        } else {
          toast.error(intl.formatMessage({id: 'TITLE_WORDS_LIMITATIONS'}))
        }
      } else {
        toast.error(intl.formatMessage({id: 'SAVE_EDITED'}))
      }
    } else if (stepper?.active === 1 && isTitleSelected === true) {
      if (Topic.value.length != 0 && Topic.value.length < 500) {
        if (!isEditing) {
          if (user?.credits >= 200) {
            if (isIntroSelected) {
              const data = {
                value: stepper.active,
                id: articleId,
              }
              dispatch(articleActions.setAllInactiveState(data))
              dispatch(articleActions.resetIntros())
              dispatch(articleActions.resetOutlines())
            }
            dispatch(createIntros(introData, action, intl))
          } else {
            toast.error(intl.formatMessage({id: 'INTRO_WORDS_LIMITATIONS'}))
          }
        } else {
          toast.error(intl.formatMessage({id: 'SAVE_EDITED'}))
        }
      } else if (Topic.value.length > 500) {
        toast.error(intl.formatMessage({id: 'CHOOSEN_TOPIC_CHARACTER_VALIDATION'}))
      } else {
        toast.error(intl.formatMessage({id: 'CHOOSEN_TOPIC_REQUIRED'}))
      }
    } else if (stepper?.active === 2 && isTitleSelected === true && isIntroSelected === false) {
      if (
        (Topic.value.length != 0 && Topic.value.length < 500) ||
        (chosen_title?.value?.length != 0 && chosen_title?.value?.length < 500)
      ) {
        if (!isEditing) {
          if (user?.credits >= 200) {
            dispatch(reCreateIntros(introData, updateCredits, intl))
          } else {
            toast.error(intl.formatMessage({id: 'INTRO_WORDS_LIMITATIONS'}))
          }
        } else {
          toast.error(intl.formatMessage({id: 'SAVE_EDITED'}))
        }
      } else if (Topic.value.length === 0) {
        toast.error(intl.formatMessage({id: 'CHOOSEN_TOPIC_REQUIRED'}))
      } else if (chosen_title?.value?.length === 0) {
        toast.error(intl.formatMessage({id: 'CHOOSEN_TITLE_REQUIRED'}))
      }
    } else if (stepper?.active === 2 && isTitleSelected === true && isIntroSelected === true) {
      if (
        Topic.value.length != 0 &&
        chosen_title?.value?.length != 0 &&
        chosen_intro?.value?.length != 0
      ) {
        if (!isEditing) {
          if (user?.credits >= 200) {
            if (isOutlineSelected) {
              const data = {
                value: stepper.active,
                id: articleId,
              }
              dispatch(articleActions.setAllInactiveState(data))
              dispatch(articleActions.resetOutlines())
            }
            dispatch(createOutlines(outlinesData, action, intl))
          } else {
            toast.error(intl.formatMessage({id: 'INTRO_WORDS_LIMITATIONS'}))
          }
        } else {
          toast.error(intl.formatMessage({id: 'SAVE_EDITED'}))
        }
      } else if (Topic.value.length === 0) {
        toast.error(intl.formatMessage({id: 'CHOOSEN_TOPIC_REQUIRED'}))
      } else if (isTitleSelectedError) {
        toast.error(intl.formatMessage({id: 'CHOOSEN_TITLE_REQUIRED'}))
      }
    } else if (
      stepper?.active === 3 &&
      isTitleSelected === true &&
      isIntroSelected === true &&
      isOutlineSelected === false
    ) {
      if (
        Topic.value.length != 0 &&
        chosen_title?.value?.length != 0 &&
        chosen_intro?.value?.length != 0
      ) {
        if (!isEditing) {
          if (user?.credits >= 200) {
            dispatch(reCreateOutlines(outlinesData, updateCredits, intl))
          } else {
            toast.error(intl.formatMessage({id: 'INTRO_WORDS_LIMITATIONS'}))
          }
        } else {
          toast.error(intl.formatMessage({id: 'SAVE_EDITED'}))
        }
      } else if (Topic.value.length === 0) {
        toast.error(intl.formatMessage({id: 'CHOOSEN_TOPIC_REQUIRED'}))
      } else if (isTitleSelectedError) {
        toast.error(intl.formatMessage({id: 'CHOOSEN_TITLE_REQUIRED'}))
      } else if (isIntroSelectedError) {
        toast.error(intl.formatMessage({id: 'CHOOSEN_INTRO_REQUIRED'}))
      }
    } else if (
      stepper?.active === 3 &&
      isTitleSelected === true &&
      isIntroSelected === true &&
      isOutlineSelected === true
    ) {
      const hasEmptyString = Object.values(chosen_outlines.value).some((val) => val.value === '')
      if (
        Topic.value.length != 0 &&
        chosen_title?.value?.length != 0 &&
        chosen_intro?.value?.length != 0 &&
        !hasEmptyString
      ) {
        if (!isEditing) {
          if (user?.credits >= 400) {
            const data = {
              value: stepper.active,
              id: articleId,
            }
            dispatch(articleActions.setAllInactiveState(data))
            dispatch(articleActions.resetArticle())
            dispatch(createArticle(articleData, action, intl))
          } else {
            toast.error(intl.formatMessage({id: 'ARTICLE_WORDS_LIMITATIONS'}))
          }
        } else {
          toast.error(intl.formatMessage({id: 'SAVE_EDITED'}))
        }
      } else if (hasEmptyString) {
        toast.error(intl.formatMessage({id: 'EMPTY_OUTLINE'}))
      } else if (Topic.length === 0) {
        toast.error(intl.formatMessage({id: 'CHOOSEN_TOPIC_REQUIRED'}))
      } else if (isTitleSelectedError) {
        toast.error(intl.formatMessage({id: 'CHOOSEN_TITLE_REQUIRED'}))
      } else if (isIntroSelectedError) {
        toast.error(intl.formatMessage({id: 'CHOOSEN_INTRO_REQUIRED'}))
      }
    } else if (
      stepper?.active === 4 &&
      isTitleSelected === true &&
      isIntroSelected === true &&
      isOutlineSelected === true
    ) {
      const hasEmptyString = Object.values(chosen_outlines.value).some((val) => val.value === '')
      if (
        Topic.value.length != 0 &&
        chosen_title?.value?.length != 0 &&
        chosen_intro?.value?.length != 0 &&
        !hasEmptyString
      ) {
        if (user?.credits >= 400) {
          setShowModal(true)

          setArticleData(articleData)
          // dispatch(articleActions.resetArticle());
          // dispatch(createArticle(articleData, updateCredits, intl));
        } else {
          toast.error(intl.formatMessage({id: 'ARTICLE_WORDS_LIMITATIONS'}))
        }
      } else if (hasEmptyString) {
        toast.error(intl.formatMessage({id: 'EMPTY_OUTLINE'}))
      } else if (Topic.length === 0) {
        toast.error(intl.formatMessage({id: 'CHOOSEN_TOPIC_REQUIRED'}))
      } else if (isTitleSelectedError) {
        toast.error(intl.formatMessage({id: 'CHOOSEN_TITLE_REQUIRED'}))
      } else if (isIntroSelectedError) {
        toast.error(intl.formatMessage({id: 'CHOOSEN_INTRO_REQUIRED'}))
      }
    }
  }
  const lang = useLang()
  const handleRegenrate = () => {
    dispatch(articleActions.resetArticle())
    dispatch(createArticle(articleData, updateCredits, intl))
    setShowModal(false)
  }

  const executeGoto = (index) => {
    stepper.goto(index)
  }
  const [isWelcome, setIsWelcome] = useState(false)
  const [isAlert, setIsAlert] = useState(false)

  return (
    <div
      className={`t-relative t-text-[16px] t-w-full t-pt-2 sm:t-pt-2 t-px-8 md:t-px-24 t-ease-in-out t-duration-300 t-h-full t-flex t-flex-col t-justify-start t-gap-3 470:t-gap-2`}
    >
      <QuillProvider>
        <div
          className={`t-flex t-flex-col t-justify-between t-bg-white t-h-[70px] 470:t-h-[85px] ${
            lang == 'heb'
              ? ' t-pr-6 t-pl-2 470:t-pr-12 470:t-pl-7 '
              : 't-pl-6 t-pr-2 470:t-pl-12 470:t-pr-7 '
          } md:t-px-24 t-py-2 t-drop-shadow t-border t-rounded-lg 470:t-rounded-xl ${
            stepper.active === 4 ? 't-w-full' : 't-w-full'
          }  `}
        >
          <MultiPageStepper stepper={stepper} steps={steps} executeGoto={executeGoto} />
        </div>

        <div className='t-w-full t-grid t-grid-cols-2 lg:t-grid-cols-3 t-justify-between t-gap-5 '>
          <div
            className={`t-col-span-2 t-drop-shadow t-border t-bg-white t-rounded-lg 470:t-rounded-xl t-h-[calc(100vh-185px)] 470:t-h-[calc(100vh-179px)]`}
          >
            <div className=' t-bg-white-regular t-h-full t-overflow-y-auto'>
              {stepper?.active === 0 && (
                <TopicPage
                  // setTopic={setTopic}
                  // topic={Topic.value}
                  tags={tags}
                  setTags={setTags}
                  error={error}
                  setError={setError}
                  Topic={Topic}
                  steps={steps}
                  stepper={stepper}
                  language={language}
                  Keywords={Keywords}
                  KeywordsError={KeywordsError}
                  setKeywordsError={setKeywordsError}
                  isLoading={isLoading}
                />
              )}
              {stepper?.active === 1 && (
                <TitlePage
                  steps={steps}
                  stepper={stepper}
                  selectedTitle={selectedTitle}
                  setSelectedTitle={setSelectedTitle}
                  isTempTitleSelected={isTempTitleSelected}
                  setIsTempTitleSelected={setIsTempTitleSelected}
                />
              )}
              {stepper?.active === 2 && <IntroPage steps={steps} stepper={stepper} />}
              {stepper?.active === 3 && <OutlinePage steps={steps} stepper={stepper} />}
              {stepper?.active === 4 && <ArticlePage />}

              <div className='t-flex t-flex-col t-gap-6 lg:t-hidden t-sticky t-bottom-0 t-bg-white t-px-2.5 390:t-px-5 sm:t-px-11 t-pb-2 md:t-pb-4'>
                <div className='t-h-[1px] t-w-full t-bg-[#CBD5E1]' />
                <NextGenerate
                  name={
                    stepper?.active === 4
                      ? 'Regenerate Article'
                      : `${intl.formatMessage({id: 'CREATE'})}  ${intl.formatMessage({
                          id: `${steps.find((o) => o.id === stepper?.active + 1)?.name}`,
                        })}`
                  }
                  active={stepper?.active}
                  isLastStep={stepper.isLastStep}
                  setCount={setCount}
                  count={count}
                  // executeGoto={executeGoto}
                  handleClick={handleFooterClick}
                />
              </div>
            </div>
          </div>
          <div className='t-w-full t-hidden lg:t-block'>
            <ContentRightBar
              name={
                stepper?.active === 4
                  ? 'Regenerate Article'
                  : `${intl.formatMessage({id: 'CREATE'})} ${intl.formatMessage({
                      id: `${steps.find((o) => o.id === stepper?.active + 1)?.name}`,
                    })}`
              }
              active={stepper?.active}
              isLastStep={stepper.isLastStep}
              setCount={setCount}
              count={count}
              // executeGoto={executeGoto}
              handleClick={handleFooterClick}
              setLanguage={setLanguage}
              language={language}
              setPointOfView={setPointOfView}
              pointOfView={pointOfView}
              setTone={setTone}
              tone={tone}
              quality={quality}
              setQuality={setQuality}
              disable={stepper?.active != 0 ? true : false}
              stepper={stepper}
            />
          </div>
        </div>
      </QuillProvider>
      <Modal
        open={showModal}
        onClose={() => {
          setShowModal(false)
        }}
      >
        <div className='t-z-20 t-w-[95%] 470:t-w-[420px] t-m-auto t-h-full t-font-medium t-bg-white t-text-black t-rounded-md t-mb-[5px]'>
          <div className='t-flex t-flex-col t-items-center t-justify-between t-text-center t-gap-[16px] t-px-4 t-py-6'>
            <span
              className='t-flex t-text-sm 470:t-text-[16px] t-justify-center t-items-center t-text-black t-h-full t-w-full'
              dir={Language_en === 'English' || Language_en === 'Russia' ? 'ltr' : 'rtl'}
            >
              {intl.formatMessage({id: 'REGENERATE_MESSAGE'})}
            </span>

            <div className='t-flex t-items-center t-gap-2'>
              <SecondaryButton
                // isLoading={isLoading}
                text={intl.formatMessage({id: 'YES'})}
                onClick={handleRegenrate}
                isIcon={false}
                className='!t-text-white t-w-32 t-border t-border-[#592E84]'
                textClass='t-text-[15px]'
              />
              <PrimaryButton
                // isLoading={isLoading}
                text={intl.formatMessage({id: 'NO'})}
                className='t-border t-border-PRIMARY !t-text-black t-w-32'
                onClick={() => {
                  setShowModal(false)
                }}
                textClass='t-text-[15px]'
              />
            </div>
          </div>
        </div>
      </Modal>
      {isWelcome ? (
        <WelcomeModal isLoading={isLoading} isWelcome={isWelcome} setIsWelcome={setIsWelcome} />
      ) : (
        ''
      )}
      {isAlert ? <PopAlert isLoading={isLoading} isAlert={isAlert} setIsAlert={setIsAlert} /> : ''}
    </div>
  )
}

export default ArticleWriter
