import {useState} from 'react'
import * as Yup from 'yup'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import {InputType} from './InputType'
import {IoMdArrowBack} from 'react-icons/io'
import {forgotPasswordSendEmail} from '../../../../store/auth/login/loginActions'
import {useDispatch} from 'react-redux'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {useIntl} from 'react-intl'

const initialValues = {
  email: '',
}

const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
})

export function ForgotPassword() {
  const intl = useIntl()
  const [loading, setLoading] = useState(false)
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)

  const dispatch = useDispatch()
  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: (values, {resetForm}) => {
      const {email} = values
      dispatch(forgotPasswordSendEmail(email, intl))
      resetForm({})
    },
  })

  return (
    <div
      className='container  d-flex flex-column justify-content-center align-items-center '
      style={{height: '100vh'}}
    >
      <div className='shadow h-75 w-lg-75 w-100 d-flex justify-content-center align-items-center'>
        <form
          className='form w-100  d-flex justify-content-center align-items-center flex-column fv-plugins-bootstrap5 fv-plugins-framework'
          noValidate
          id='kt_login_password_reset_form'
          onSubmit={formik.handleSubmit}
        >
          <div className='text-center mb-10'>
            <div
              style={{
                height: '70px',
                width: '70px',
                borderRadius: '50%',
                backgroundColor: '#F5EBFF',
                padding: '13px',
                margin: 'auto',
              }}
            >
              <img style={{height: '45px', width: '45px'}} src={toAbsoluteUrl('/key.svg')} />
            </div>
            <p
              className='text-dark fw-bolder mb-3'
              style={{lineHeight: '50px', fontSize: '24px', fontWeight: 700, fontStyle: 'italic'}}
            >
              {intl.formatMessage({id: 'FORGOT_YOUR_PASSWORD'})}
            </p>
          </div>

          <div className=' w-lg-75 w-100 px-1 d-flex flex-column align-items-center justify-content-center'>
            <InputType
              label={intl.formatMessage({id: 'EMAIL'})}
              placeholder={intl.formatMessage({id: 'ENTER_YOUR_EMAIL'})}
              type='text'
              name='email'
              onChnage={formik.handleChange}
              value={formik.values.email}
            />
          </div>

          <div className='w-lg-75 w-100 px-4 '>
            {formik.errors.email ? (
              <div className='error text-danger'>{formik.errors.email}</div>
            ) : null}
          </div>

          <div className='d-flex flex-wrap justify-content-center w-lg-75 w-100 my-5 pb-lg-0 px-5'>
            <button
              type='submit'
              id='kt_password_reset_submit'
              className='btn  px-6 w-100'
              style={{backgroundColor: '#592E84', fontSize: '16px'}}
            >
              <span className='indicator-label text-light fw-bold fs-4'>
                {intl.formatMessage({id: 'SEND_PASSWORD_RESET_LINK'})}
              </span>
              {loading && (
                <span className='indicator-progress'>
                  Please wait...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
          <div className=' w-lg-75 w-100 d-flex flex-wrap justify-content-center'>
            <Link to='/auth/login'>
              <button
                type='button'
                id='kt_login_password_reset_form_cancel_button'
                className='btn btn-light w-100'
                disabled={formik.isSubmitting || !formik.isValid}
                style={{background: 'transparent', fontSize: '16px', color: '#52525B'}}
              >
                <span className='fw-normal fs-4'>
                  <IoMdArrowBack color='#52525B' size={18} fontWeight='bold' />
                  {intl.formatMessage({id: 'BACK_TO_LOGIN'})}
                </span>
              </button>
            </Link>
          </div>
        </form>
      </div>
    </div>
  )
}
