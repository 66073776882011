import {firestore, auth} from '../../../firebase/FirebaseConfig'
import {userActions} from './signUpSlice'
import {doc, setDoc} from '@firebase/firestore'
import {createUserWithEmailAndPassword, sendEmailVerification, getAuth} from '@firebase/auth'
import {toast} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

// const OAuth = getAuth()
export const signUp =
  (userData: any, setLoading: any, intl: any, lang: any) => async (dispatch: any) => {
    dispatch(userActions.setLoader(true))

    try {
      let values = {
        name: userData.name,
        firstName: userData?.firstName,
        lastName: userData.lastName,
        email: userData.email,
        registeredBy: userData?.registeredBy || 'signup',
        activation: userData?.activation || true,
        credits: userData?.credits || 10000,
        plan: 'FREE',
      }
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        userData.email,
        userData.password
      )

      const userId: any = auth?.currentUser?.uid
      const docRef = doc(firestore, 'users', userId)
      await setDoc(docRef, values).then((res) => {
        console.log('res after setting doc', res)
      })
      // change the default language
      // OAuth.languageCode = 'iw'
      var actionCodeSettings = {
        url: `${process.env.REACT_APP_NEXT_PUBLIC_FRONTEND_URL}/activation?uid=${auth?.currentUser?.uid}&lng=${lang}`,
        handleCodeInApp: true,
      }
      console.log('actionCOdeSettings', actionCodeSettings)
      let curUser: any = auth?.currentUser
      toast.success(intl.formatMessage({id: 'USER_REGISTERED'}))
      sendEmailVerification(curUser, actionCodeSettings)
        .then(function () {
          toast.success(intl.formatMessage({id: 'CHECK_MAIL'}))

          dispatch(userActions.setLoader(false))
          // dispatch(setPaymentPlan(userId, 'free'))
        })
        .catch(function (error) {
          toast.error(intl.formatMessage({id: 'SOMETHING_WENT_WRONG'}))
          dispatch(userActions.setLoader(false))
          console.log('error', error)
        })
        .finally(() => {
          setLoading(false)
        })
    } catch (error: any) {
      console.log('error', error)

      dispatch(userActions.setLoader(false))
      if (error?.code && error?.code === 'auth/email-already-in-use') {
        toast.error(intl.formatMessage({id: 'EMAIL_ALREADY_REGISTERED'}))
      } else {
        toast.error(intl.formatMessage({id: 'SOMETHING_WENT_WRONG'}))
      }
    }
  }