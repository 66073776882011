import {toast} from 'react-toastify'
import axiosInstance from '../../SharedComponents/api'
import {
  ARTICLE_REPHRASE_TEXT,
  CREATE_ARTICLE,
  FACEBOOK_AD,
  IMPROVE_TEXT,
  SPEED_ARTICLE,
} from '../../SharedComponents/api/EndPoints'
import {speedActions} from './speedReducers'

export const speedArticle = (data, action, intl) => async (dispatch) => {
  const index = data.index
  delete data.index
  try {
    dispatch(speedActions.setLoader({index: index, value: true}))
    await axiosInstance.post(SPEED_ARTICLE, data).then((response) => {
      dispatch(speedActions.setContentData({index: index, value: {...response?.data}}))
      dispatch(speedActions.createHTMLData({index: index, value: {...response?.data}}))

      action && action(response?.data?.credits)
      dispatch(speedActions.setLoader({index: index, value: false}))
    })
  } catch (error) {
    dispatch(speedActions.setLoader({index: index, value: false}))
    toast.error(intl.formatMessage({id: 'SOMETHING_WENT_WRONG'}))
  }
}
export const facebookAd = (data, action, intl) => async (dispatch) => {
  const index = data.index
  delete data.index
  try {
    dispatch(speedActions.setLoader({index: index, value: true}))
    await axiosInstance.post(FACEBOOK_AD, data).then((response) => {
      dispatch(speedActions.setContentData({index: index, value: {...response?.data}}))
      dispatch(speedActions.createHTMLData({index: index, value: {...response?.data}}))
      action && action(response?.data?.credits)
      dispatch(speedActions.setLoader({index: index, value: false}))
    })
  } catch (error) {
    dispatch(speedActions.setLoader({index: index, value: false}))

    toast.error(intl.formatMessage({id: 'SOMETHING_WENT_WRONG'}))
  }
}
export const articeRephraseText = (data, action, intl) => async (dispatch) => {
  const index = data.index
  delete data.index
  try {
    dispatch(speedActions.setLoaderAction({index: index, value: true}))
    await axiosInstance.post(ARTICLE_REPHRASE_TEXT, data).then((response) => {
      action && action(response.data)
      dispatch(speedActions.setLoaderAction({index: index, value: false}))
    })
  } catch (error) {
    dispatch(speedActions.setLoaderAction({index: index, value: false}))
    toast.error(intl.formatMessage({id: 'SOMETHING_WENT_WRONG'}))
  }
}
export const improveArticeText = (data, action, intl) => async (dispatch) => {
  const index = data.index
  delete data.index
  try {
    dispatch(speedActions.setLoaderAction({index: index, value: true}))
    await axiosInstance.post(IMPROVE_TEXT, data).then((response) => {
      action && action(response.data)
      dispatch(speedActions.setLoaderAction({index: index, value: false}))
    })
  } catch (error) {
    dispatch(speedActions.setLoaderAction({index: index, value: false}))
    toast.error(intl.formatMessage({id: 'SOMETHING_WENT_WRONG'}))
  }
}
export const regenerateArticle = (data, action, intl) => async (dispatch) => {
  const index = data.index
  delete data.index
  try {
    dispatch(speedActions.setLoader({index: index, value: true}))
    await axiosInstance.post(CREATE_ARTICLE, data).then((response) => {
      dispatch(speedActions.setContentData({index: index, value: {...response?.data}}))
      dispatch(speedActions.createHTMLData({index: index, value: {...response?.data}}))

      action && action(response?.data?.credits)
      dispatch(speedActions.setLoader({index: index, value: false}))
    })
  } catch (error) {
    toast.error(intl.formatMessage({id: 'SOMETHING_WENT_WRONG'}))
    dispatch(speedActions.setLoader({index: index, value: false}))
  }
}
