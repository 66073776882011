import {toAbsoluteUrl} from '../../_metronic/helpers'
const USA = toAbsoluteUrl('/media/spa/USA.png')
const ISRAEL = toAbsoluteUrl('/media/spa/Israel.png')
const Russia = toAbsoluteUrl('/media/spa/russia.png')
const Saudi = toAbsoluteUrl('/media/spa/saudi-arabia.png')
const FEATURE = toAbsoluteUrl('/media/spa/feature.png')
const FACEBOOK = toAbsoluteUrl('/media/spa/facebook.png')

export const CountryLanguages = [
  {
    code: 'he',
    name: 'Hebrew',
    direction: 'rtl',
    flag: ISRAEL,
    translate: 'HEBREW',
  },
  {
    code: 'en',
    name: 'English',
    direction: 'ltr',
    flag: USA,

    translate: 'ENGLISH',
  },
  {
    code: 'ar',
    name: 'Arabic',
    direction: 'rtl',
    flag: Saudi,
    translate: 'ARABIC',
  },
  {
    code: 'ru',
    name: 'Russia',
    direction: 'ltr',
    flag: Russia,
    translate: 'RUSSIA',
  },
]
export const Tone = [
  {id: 1, name: 'None', translate: 'NONE'},
  {id: 2, name: 'Assertive', translate: 'ASSERTIVE'},
  {id: 3, name: 'Casual', translate: 'CASUAL'},
  {id: 4, name: 'Convincing', translate: 'CONVINCING'},
  {id: 5, name: 'Enthusiastic', translate: 'ENTHUSIASTIC'},
  {id: 6, name: 'Formal', translate: 'FORMAL'},
  {id: 7, name: 'Humorous', translate: 'HUMOROUS'},
  {id: 8, name: 'Inspirational', translate: 'INSPIRATIONAL'},
  {id: 9, name: 'Joyful', translate: 'JOYFUL'},
  {id: 10, name: 'Passionate', translate: 'PASSIONATE'},
  {id: 11, name: 'Urgent', translate: 'URGENT'},
]
export const QualityType = [
  {id: 1, name: 'Regular', translate: 'REGULAR'},
  {id: 2, name: 'Pro', translate: 'PRO'},
]
export const Feature = [
  {id: 1, name: 'Speed Article', translate: 'SPEED_ARTICLE', flag: FEATURE},
  {id: 2, name: 'Facebook Ad', translate: 'FACEBOOK_AD', flag: FACEBOOK},
]
export const PointOfView = [
  {id: 1, name: 'First Person', translate: 'FIRST_PERSON'},
  {id: 2, name: 'Second Person', translate: 'SECOND_PERSON'},
  {id: 3, name: 'Third Person', translate: 'THIRD_PERSON'},
]
