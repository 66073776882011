import {useDispatch, useSelector} from 'react-redux'
import {toast} from 'react-toastify'
import {useIntl} from 'react-intl'
import {useParams} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import SecondaryButton from '../../../SharedComponents/buttons/SecondaryButton'
import PrimaryButton from '../../../SharedComponents/buttons/PrimaryButton'
import {useQuillContext} from '../../../SharedComponents/context/QuillContext'
import {useLang} from '../../../_metronic/i18n/Metronici18n'
import {facebookAd, regenerateArticle, speedArticle} from '../../../store/speedSlice/speedActions'
import {useStepper} from '../../hooks/useStepper'
import {doc, getDoc, updateDoc} from '@firebase/firestore'
import {firestore} from '../../../firebase/FirebaseConfig'
import {userActions} from '../../../store/auth/login/loginSlice'
import {useEffect, useState} from 'react'
import {speedActions} from '../../../store/speedSlice/speedReducers'
import {Modal} from '../../../SharedComponents/Layouts/Modal'
const NEXT = toAbsoluteUrl('/media/spa/whiteArrow_left.png')

const Footer = () => {
  const intl = useIntl()
  const lang = useLang()
  const params = useParams()
  const contentId = Number(params.contentId)
  const dispatch = useDispatch()

  const {
    feature,
    language_content,
    Tone_en,
    Quality_type_en,
    Topic,
    Topic_en,
    Keywords,
    exampleAdd,
    isLoading,
    steps,
    stepper: currentStep,
  } = useSelector((state) => state.speed.articles[contentId])
  const {chosen_title, chosen_title_en, outlines_en, outlines, intros_en, intros} = useSelector(
    (state) => state.speed.articles[contentId].articleData
  )
  const {accessToken, user} = useSelector((state) => state.login)
  const [BtnName, setBtnName] = useState()
  const [confirmModal, setConfirmModal] = useState(false)
  const stepper = useStepper(steps)

  const updateCredits = async (cred) => {
    const userRef = doc(firestore, 'users', accessToken)
    const snapshot = await getDoc(userRef)
    let data = snapshot.data()
    let NewCredits = data?.credits - cred
    await updateDoc(userRef, {
      credits: NewCredits,
    })
    const newsnapshot = await getDoc(userRef)
    let newdata = newsnapshot.data()
    dispatch(userActions.setCredits(newdata?.credits))
  }
  const action = (creds) => {
    updateCredits(creds)
    stepper.nextStep()
  }
  const actionRegenerate = (creds) => {
    updateCredits(creds)
  }

  const handleClick = () => {
    if (currentStep === 0) {
      if (feature.id === 1) {
        if (Topic == '') {
          toast.error('PLEASE CHOOSE A TOPIC')
        } else {
          if (user?.credits >= 500) {
            const data = {
              index: contentId,
              user_id: accessToken,
              feature: feature.name,
              language: language_content.name,
              tone: Tone_en.name,
              quality_type: Quality_type_en.name,
              topic: Topic.value,
              keywords: Keywords,
            }
            dispatch(speedArticle(data, action, intl))
          } else {
            toast.error(intl.formatMessage({id: 'SPEED_ARTICLE_WORDS_LIMITATIONS'}))
          }
        }
      } else if (feature.id === 2) {
        if (Topic == '') {
          toast.error('PLEASE CHOOSE A TOPIC')
        } else {
          if (user?.credits >= 100) {
            const data = {
              index: contentId,
              user_id: accessToken,
              feature: feature.name,
              language: language_content.name,
              tone: Tone_en.name,
              quality_type: Quality_type_en.name,
              topic: Topic.value,
              example: exampleAdd,
            }
            dispatch(facebookAd(data, action, intl))
          } else {
            toast.error(intl.formatMessage({id: 'FACEBOOK_AD_WORDS_LIMITATIONS'}))
          }
        }
      }
    } else if (currentStep === 1) {
      setConfirmModal(true)
    }
  }
  useEffect(() => {
    if (currentStep === 0) {
      if (feature.id === 1) {
        setBtnName(intl.formatMessage({id: 'CREATE_ARTICLE'}))
      } else if (feature.id === 2) {
        setBtnName(intl.formatMessage({id: 'CREATE_ADD'}))
      }
    } else if (currentStep === 1) {
      if (feature.id === 1) {
        setBtnName(intl.formatMessage({id: 'REGENERATE_ARTICLE'}))
      } else if (feature.id === 2) {
        setBtnName(intl.formatMessage({id: 'REGENERATE_ADD'}))
      }
    }
  }, [currentStep, feature])
  const {setListening} = useQuillContext()
  const handleRegenrate = () => {
    dispatch(speedActions.resetArticle(contentId))
    if (feature.id === 1) {
      if (user?.credits >= 500) {
        const data = {
          index: contentId,
          user_id: accessToken,
          topic_en: Topic_en,
          topic_edited: 0,
          topic: Topic,
          tone_en: 'None',
          quality_type_en: 'Pro',
          point_of_view_en: null,
          feature: feature.name,
          language_en: language_content.name,
          keywords_en: Keywords,
          keywords: Keywords,
          id: NaN,
          chosen_title_en: chosen_title_en,
          chosen_title: chosen_title,
          chosen_outlines_en: outlines_en,
          chosen_outlines: outlines,
          chosen_intro_en: intros_en,
          chosen_intro_edited: 1,
          chosen_intro: intros,
        }
        dispatch(regenerateArticle(data, updateCredits, intl))
      } else {
        toast.error(intl.formatMessage({id: 'SPEED_ARTICLE_WORDS_LIMITATIONS'}))
      }
    } else if (feature.id === 2) {
      if (user?.credits >= 100) {
        const data = {
          index: contentId,
          user_id: accessToken,
          feature: feature.name,
          language: language_content.name,
          tone: Tone_en.name,
          quality_type: Quality_type_en.name,
          topic: Topic.value,
          example: exampleAdd,
        }
        dispatch(facebookAd(data, actionRegenerate, intl))
      } else {
        toast.error(intl.formatMessage({id: 'FACEBOOK_AD_WORDS_LIMITATIONS'}))
      }
    }
    setConfirmModal(false)
  }
  return (
    <div
      className={` t-w-full t-ease-in-out t-duration-300 t-h-15 t-flex t-flex-row t-justify-between  t-z-10`}
    >
      <div className={`t-w-full t-flex-col t-gap-4 t-flex t-items-center t-py-1`}>
        <div className='t-w-full t-bg-[#592E84] t-rounded-lg t-h-[50px] t-flex t-justify-center t-items-center'>
          <SecondaryButton
            iconPosition={1}
            isLoading={isLoading}
            text={BtnName}
            onClick={() => {
              handleClick()
            }}
            isIcon={true}
            icon={NEXT}
            textClass='t-text-[16px] t-font-semibold'
          />
        </div>

        <div className='t-flex t-flex-col  t-gap-4 t-w-full '>
          {stepper && stepper.active === 0 ? null : (
            <PrimaryButton
              text={intl.formatMessage({id: 'DOWNLOAD_DOC'})}
              isLoading={isLoading}
              className='t-bg-PRIMARY !t-text-white t-w-full t-py-4 t-text-[18px] t-font-medium'
              onClick={async () => {
                setListening(true)
              }}
            />
          )}
        </div>
      </div>
      <Modal
        open={confirmModal}
        onClose={() => {
          setConfirmModal(false)
        }}
      >
        <div className='t-z-20 t-w-[95%] 470:t-w-[420px] t-m-auto t-h-full t-font-medium t-bg-white t-text-black t-rounded-md t-mb-[5px]'>
          <div className='t-flex t-flex-col t-items-center t-justify-between t-text-center t-gap-[16px] t-px-4 t-py-6'>
            <span
              className='t-flex t-text-sm 470:t-text-[16px] t-justify-center t-items-center t-text-black t-h-full t-w-full'
              dir={lang === 'en' || lang === 'ru' ? 'ltr' : 'rtl'}
            >
              {intl.formatMessage({id: 'REGENERATE_MESSAGE'})}
            </span>

            <div className='t-flex t-items-center t-gap-2'>
              <SecondaryButton
                // isLoading={isLoading}
                text={intl.formatMessage({id: 'YES'})}
                onClick={handleRegenrate}
                isIcon={false}
                className='!t-text-white t-w-32 t-border t-border-[#592E84]'
                textClass='t-text-[15px]'
              />
              <PrimaryButton
                // isLoading={isLoading}
                text={intl.formatMessage({id: 'NO'})}
                className='t-border t-border-PRIMARY !t-text-black t-w-32'
                onClick={() => {
                  setConfirmModal(false)
                }}
                textClass='t-text-[15px]'
              />
            </div>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default Footer