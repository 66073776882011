import clsx from 'clsx'
import {FC} from 'react'
import {useIntl} from 'react-intl'
import {toAbsoluteUrl} from '../../../helpers'
import {useLang, setLanguage} from '../../../i18n/Metronici18n'
import './headerStyles/HeaderStyles.css'

const languages = [
  {
    lang: 'heb',
    name: 'Hebrew',
    flag: toAbsoluteUrl('/media/flags/israel.svg'),
    sidebarClass: 'sidebar-right',
  },
  {
    lang: 'en',
    name: 'English',
    flag: toAbsoluteUrl('/media/flags/united-states.svg'),
    sidebarClass: 'sidebar-left',
  },
  // {
  //   lang: 'zh',
  //   name: 'Mandarin',
  //   flag: toAbsoluteUrl('/media/flags/china.svg'),
  //   sidebarClass: 'sidebar-right',
  // },
]
const Languages: FC = () => {
  const lang = useLang()
  const currentLanguage = languages.find((x) => x.lang === lang)

  const handleSetLanguage = (language: any) => {
    setLanguage(language.lang)
    document.body.classList.remove(currentLanguage?.sidebarClass || 'sidebar-left')
    document.body.classList.add(language.sidebarClass)
  }
  const intl = useIntl()

  // const changeDirection = () => {
  //   const newDirection = 'rtl'
  //   document.getElementById('html-element')?.setAttribute('direction', 'rtl')
  //   document.getElementById('html-element')?.setAttribute('dir', 'rtl')
  //   document.getElementById('html-element')?.setAttribute('style', 'direction: rtl')
  // }

  return (
    <div
      className='menu-item px-5'
      data-kt-menu-trigger='hover'
      data-kt-menu-placement='left-start'
      data-kt-menu-flip='bottom'
    >
      <a href='#' className='menu-link px-5'>
        <span className='menu-title position-relative'>
          {intl.formatMessage({id: 'LANGUAGE'})}
          <span
            className={`fs-8 rounded bg-light px-3 py-2 position-absolute translate-middle-y top-50  ${
              lang === 'heb' ? ' start-0 ' : ' end-0 '
            }`}
          >
            {currentLanguage?.name}{' '}
            <img
              className='w-15px h-15px rounded-1 ms-2'
              src={currentLanguage?.flag}
              alt='metronic'
            />
          </span>
        </span>
      </a>

      <div className='menu-sub menu-sub-dropdown2 w-175px py-4'>
        {languages.map((language) => (
          <div
            className='menu-item px-3'
            key={language.lang}
            onClick={() => {
              handleSetLanguage(language)
            }}
          >
            <a
              href='#'
              className={clsx('menu-link d-flex px-5', {
                active: language.lang === currentLanguage?.lang,
              })}
            >
              <span className='symbol symbol-20px me-4'>
                <img className='rounded-1' src={language.flag} alt='metronic' />
              </span>
              {language.name}
            </a>
          </div>
        ))}
      </div>
    </div>
  )
}

export {Languages}
