import React from 'react'
import {useSelector} from 'react-redux'
import SecondaryButton from '../../SharedComponents/buttons/SecondaryButton'
import {useIntl} from 'react-intl'
import {toAbsoluteUrl} from '../../_metronic/helpers'
import {useLang} from '../../_metronic/i18n/Metronici18n'
const Welcome_HE = toAbsoluteUrl('/media/spa/Welcome_he.png')
const Welcome = toAbsoluteUrl('/media/spa/Welcome.png')
const NEXT = toAbsoluteUrl('/media/spa/whiteArrow.png')

const WelcomeModal = ({isLoading, isWelcome, setIsWelcome}) => {
  const intl = useIntl()
  const lang = useLang()
  return (
    <div
      onClick={() => {
        setIsWelcome(false)
        localStorage.setItem('ArticleWriter', '0')
      }}
      className='t-fixed t-top-0 t-left-0 t-h-[100vh] t-w-[100vw] t-bg-[rgb(0,0,0,0.5)] t-z-[9999] t-flex t-justify-center t-items-center'
    >
      <div
        onClick={(e) => e.stopPropagation()}
        className='t-bg-white t-text-black t-h-[92%] t-overflow-y-auto t-w-[87%] t-rounded-xl t-border-2 t-border-[#592E84] t-p-5 t-pb-20 t-flex t-justify-center t-items-center'
      >
        <div
          dir={lang == 'en' ? 'ltr' : 'rtl'}
          className='t-relative t-w-full md:t-w-[90%] lg:t-w-[80%] t-overflow-y-auto t-text-center t-flex t-flex-col t-items-center t-gap-4 sm:t-gap-7 t-h-full t-pb-5'
        >
          <h1 className='t-text-[#592E84] t-font-bold t-text-[16px] 470:t-text-[20px] sm:t-text-[24px] md:t-text-[32px]'>
            {intl.formatMessage({id: 'WELCOME'})}
          </h1>
          <p className='t-font-medium t-text-[16px] sm:t-text-[20px] md:t-text-[24px] t-font-[#303038] t-text-center sm:t-text-start t-self-start'>
            {intl.formatMessage({id: 'FOLLOW_STEPS'})}
          </p>
          <div
            className=' t-w-full t-text-start t-flex t-flex-col t-gap-2'
            dir={lang == 'en' ? 'ltr' : 'rtl'}
          >
            <div className='t-w-full t-flex t-flex-col t-gap-2'>
              <p className='t-font-medium t-text-[16px] md:t-text-[20px] t-font-[#303038]'>
                {intl.formatMessage({id: 'HEADING_1'})}
              </p>
              <p className='t-text-[13px] sm:t-text-sm md:t-text-[16px]'>
                {intl.formatMessage({id: 'DESCRIPTION_1'})}
              </p>
            </div>
            <div className='t-w-full t-flex t-flex-col t-gap-1.5'>
              <p className='t-font-medium t-text-[16px] md:t-text-[20px] t-font-[#303038]'>
                {intl.formatMessage({id: 'HEADING_2'})}
              </p>
              <p className='t-text-[13px] sm:t-text-sm md:t-text-[16px]'>
                {intl.formatMessage({id: 'DESCRIPTION_2'})}
              </p>
            </div>
            <div className='t-w-full t-flex t-flex-col t-gap-1.5'>
              <p className='t-font-medium t-text-[16px] md:t-text-[20px] t-font-[#303038]'>
                {intl.formatMessage({id: 'HEADING_3'})}
              </p>
              <p className='t-text-[13px] sm:t-text-sm md:t-text-[16px]'>
                {intl.formatMessage({id: 'DESCRIPTION_3'})}
              </p>
            </div>
            <div className='t-w-full t-flex t-flex-col t-gap-1.5'>
              <p className='t-font-medium t-text-[16px] md:t-text-[20px] t-font-[#303038]'>
                {intl.formatMessage({id: 'HEADING_4'})}
              </p>
              <p className='t-text-[13px] sm:t-text-sm md:t-text-[16px]'>
                {intl.formatMessage({id: 'DESCRIPTION_4'})}
              </p>
            </div>
            <div className='t-w-full t-flex t-flex-col t-gap-1.5'>
              <p className='t-font-medium t-text-[16px] md:t-text-[20px] t-font-[#303038]'>
                {intl.formatMessage({id: 'HEADING_5'})}
              </p>
              <p className='t-text-[13px] sm:t-text-sm md:t-text-[16px]'>
                {intl.formatMessage({id: 'DESCRIPTION_5'})}
              </p>
            </div>
          </div>
          {lang == 'heb' ? (
            <img
              src={Welcome_HE}
              alt=''
              height={100}
              width={100}
              className='t-w-full 390:t-w-[70%]'
            />
          ) : (
            <img src={Welcome} alt='' height={100} width={100} className='t-w-full 390:t-w-[70%]' />
          )}

          <SecondaryButton
            isLoading={isLoading}
            width='t-w-[80%] 470:t-w-[40%] lg:t-w-[25%]'
            className='t-h-[40px] sm:t-h-[50px] t-py-4 t-whitespace-nowrap t-fixed t-bottom-[4rem] md:t-bottom-12'
            text={intl.formatMessage({id: 'LETS_START'})}
            onClick={() => {
              setIsWelcome(false)
              localStorage.setItem('ArticleWriter', '0')
            }}
            isIcon={true}
            icon={NEXT}
            textClass='t-text-[16px]'
          />
        </div>
      </div>
    </div>
  )
}

export default WelcomeModal
