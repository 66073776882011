import {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {useDispatch, useSelector} from 'react-redux'
import {useNavigate, useParams} from 'react-router-dom'
import {toast} from 'react-toastify'
import {
  getUserIntrosFeedback,
  rephraseIntroArticle,
} from '../../../store/articleSlice/articleActions'
import {articleActions} from '../../../store/articleSlice/articleReducers'
import LoaderGenerate from '../../../SharedComponents/LoaderGenerate'
import TextContainer from '../../components/TextContainer'

const IntroPage = ({steps, stepper}) => {
  const intl = useIntl()
  // const router = useNavigate()
  // const {articleId} = router.query || {}
  const params = useParams()
  const articleId = Number(params.articleId)
  const dispatch = useDispatch()
  const {
    isLoading,
    titles_en,
    titles,
    chosen_title_en,
    UserID,
    Language_en,
    intros,
    intros_en,
    chosen_intro,
    chosen_intro_en,
    isLoadingAction,
  } = useSelector((state) =>
    articleId || articleId === 0 ? state.article.articles[articleId] : state.article
  )
  const [selected, setSelected] = useState()

  useEffect(() => {
    setSelected(chosen_intro && chosen_intro.id)
  }, [chosen_intro])
  const [editIndex, setEditIndex] = useState()

  const handleIntroSelected = (key) => {
    const index = intros.findIndex((num) => num.id === key)
    let data = {
      chosen_intro: {
        id: key,
        value: intros[index].intro,
        isEdited: intros[index].isEdited,
      },
      chosen_intro_en: {
        id: key,
        value: intros_en[index].intro,
        isEdited: intros_en[index].isEdited,
      },
      introSelected: true,
      id: articleId,
    }

    dispatch(articleActions.setChoosenIntro(data))
  }

  const handleFeedbackLike = (key) => {
    const index = intros.findIndex((num) => num.id === key)

    let data = {
      user_id: UserID,
      text: intros[index],
      type: 1,
      key: index,
      id: articleId,
    }
    dispatch(getUserIntrosFeedback(data, intl))
  }
  const handleFeedbackDisLike = (key) => {
    const index = intros.findIndex((num) => num.id === key)
    let data = {
      user_id: UserID,
      text: intros[index],
      type: 2,
      key: index,
      id: articleId,
    }
    dispatch(getUserIntrosFeedback(data, intl))
  }
  const RepharaseIntroText = (key) => {
    const index = intros.findIndex((num) => num.id === key)
    dispatch(
      articleActions.setSelectedRephrase(
        articleId || articleId === 0 ? {id: articleId, value: key} : key
      )
    )
    let data = {
      user_id: UserID,
      text: intros[index]?.intro,
      text_en: intros_en[index]?.intro,
      language_en: Language_en,
      id: articleId,
    }
    dispatch(rephraseIntroArticle(data, intl))
  }
  const handleIntroDelete = (key) => {
    if (Object.keys(intros).length < 2) {
      toast.error(intl.formatMessage({id: 'MUST_HAVE_ONE_INTRO'}))
    } else {
      dispatch(
        articleActions.removeIntroItem(
          articleId || articleId === 0 ? {id: articleId, value: key} : key
        )
      )
    }
  }
  const handleIntroEdited = (index, data) => {
    let val = {
      key: index,
      value: data,
      id: articleId,
    }

    if (data.length != 0) {
      dispatch(articleActions.setIntroEditedText(val))
      setEditIndex()
    } else {
      toast.error(intl.formatMessage({id: 'INTRO_NOT_EMPTY'}))
    }
  }

  return (
    <>
      {isLoading ? (
        <LoaderGenerate className='t-p-0 t-pt-0' size='t-w-8 t-h-8' fill='#592E84' />
      ) : (
        <div className='t-flex t-max-h-[764px] t-m-auto t-mx-4 sm:t-mx-7 t-py-4 t-flex-col t-mt-1 md:t-mt-4'>
          <p className=' t-text-right t-text-[16px] t-font-medium t-text-black'>
            {intl.formatMessage({id: 'SELECT_AND_EDIT_YOUR_GENERATED_INTROS'})}
          </p>
          <div className='t-w-full'>
            <div className='t-text-[#374151]'>
              {intros_en.map((key, i) => (
                <TextContainer
                  key={i}
                  data={intros[i]?.intro}
                  isLike={intros[i]?.like}
                  isDislike={intros[i]?.dislike}
                  dir={Language_en === 'English' || Language_en === 'Russia' ? 'ltr' : 'rtl'}
                  index={i}
                  id={intros[i].id}
                  selected={selected}
                  setSelected={setSelected}
                  handleClick={handleIntroSelected}
                  handleFeedbackLike={handleFeedbackLike}
                  handleFeedbackDisLike={handleFeedbackDisLike}
                  RepharaseText={RepharaseIntroText}
                  handleDelete={handleIntroDelete}
                  setEditIndex={setEditIndex}
                  editIndex={editIndex}
                  handleEdited={handleIntroEdited}
                  textLimit={3000}
                  isLoading={isLoadingAction}
                />
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default IntroPage
