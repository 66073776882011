import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { speedActions } from "../../store/speedSlice/speedReducers";

const useStepper = (steps) => {
  const dispatch = useDispatch();
  const params = useParams()
  const contentId = Number(params.contentId)
  const { stepper } = useSelector(state =>state.speed.articles[contentId]);
  const {articles} = useSelector(state => state.speed);

  const nextStep = () => {
    const tempStepper = articles[contentId]?.stepper
    if (tempStepper !== steps.length) {
        dispatch(speedActions.setActiveStepper({value:stepper + 1,index:contentId}));
        dispatch(speedActions.setActiveState({value:stepper + 1,index:contentId}));
    }
  };
  const goto = (val) => {
      dispatch(speedActions.setActiveStepper({value:val,index:contentId}));
  };

  const isLastStep = () =>  articles[contentId]?.stepper === steps.length - 1;
  const isPending = (value) => value >  articles[contentId]?.stepper;
  const active = articles[contentId]?.stepper;

  return {
    nextStep,
    active,
    isLastStep,
    isPending,
    goto,
  };
};

export { useStepper };
