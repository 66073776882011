import axios from 'axios'
import {Document, HeadingLevel, ImageRun, Packer, Paragraph, TextRun, UnderlineType} from 'docx'
import {saveAs} from 'file-saver'
window.Buffer = window.Buffer || require('buffer').Buffer

const getImage = async (url) => {
  const response = await axios.get(url, {
    responseType: 'arraybuffer',
  })
  return Buffer.from(response.data, 'binary')
}

const getStyles = ({
  bold = null,
  italic = null,
  strike = null,
  underline = null,
  header = null,
}) => ({
  color: '#000000',
  ...(bold
    ? {
        bold: true,
      }
    : {}),
  ...(italic
    ? {
        italic: true,
      }
    : {}),
  ...(strike
    ? {
        strike: true,
      }
    : {}),
  // ...(header
  //   ? {
  //       bold: true,
  //       heading: HeadingLevel[`HEADING_${header}`],
  //     }
  //   : {}),
  ...(underline
    ? {
        type: UnderlineType.SINGLE,
        color: null,
      }
    : {}),
})

const createTextRun = ({text = '', attributes}, Language_en) =>
  new TextRun({
    rightToLeft: Language_en !== 'English' && Language_en !== 'Russia' && true,
    text,
    ...getStyles(attributes || {}),
  })

const createImageRun = async ({image}) => {
  const buffer = await getImage(image)
  return [
    new ImageRun({
      data: buffer,
      transformation: {
        width: 800,
        height: '300',
      },
    }),
  ]
}
const createParagraph = async ({embed, textRuns, attributes}, Language_en) =>
  new Paragraph({
    ...getStyles(attributes || {}),
    children: embed
      ? await createImageRun(embed)
      : textRuns.map((tr) => createTextRun(tr, Language_en)),

    direction: Language_en === 'English' || Language_en === 'Russia' ? 'ltr' : 'rtl',
    alignment: 'both',
    // Language_en === 'English' || Language_en === 'Russia'
    //   ? 'left'
    //   : 'right',
    bidirectional: true,
  })

const deltaToDoc = async (delta, Language_en) => {
  if (delta === null || !delta.ops) {
    throw new Error('No delta or delta ops was provided.')
  }

  const quillParser = await import('quilljs-parser')

  const parsedQuill = quillParser.parseQuillDelta(delta)
  const docEls = await Promise.all(
    parsedQuill.paragraphs.map(async (value) => {
      return createParagraph(value, Language_en)
    })
  )
  const doc = new Document({
    sections: [
      {
        children: [...docEls.filter((el) => el !== null)],
      },
    ],
  })
  Packer.toBlob(doc).then((blob) => {
    const url = window.URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = url
    a.download = '_xdownload.docx'
    a.click()
    window.URL.revokeObjectURL(url)
  })

  // Packer.toBlob(doc).then(blob => {
  //   saveAs(blob, '_xdownload.docx');
  // });
}

export {deltaToDoc}
