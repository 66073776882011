import axios from "axios";
let token = "";
if (typeof window !== "undefined" && window.localStorage) {
  token = window.localStorage.getItem("access_token");
}

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_PUBLIC_API_URL,
});

// if (authToken) {
//   axiosInstance.defaults.headers.common['Authorization'] =
//     'Bearer ' + authToken;
// }

axiosInstance.interceptors.request.use(
  (config) => {
    const authToken =
      typeof window !== "undefined" && localStorage.getItem("authToken");
    if (authToken) {
      config.headers["Authorization"] = `Bearer ${authToken}`;
      config.headers["Accept"] = "application/json";
      config.headers["Content-Type"] = "application/json";
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const ResponseInterceptor = (response) => {
  return response;
};

axiosInstance.interceptors.response.use(ResponseInterceptor, (error) =>
  Promise.reject(error)
);

export default axiosInstance;
