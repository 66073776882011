import {createSlice} from '@reduxjs/toolkit'
import {useSelector} from 'react-redux'
import {toAbsoluteUrl} from '../../_metronic/helpers'
const ISRAEL = toAbsoluteUrl('/media/spa/Israel.png')
const FEATURE = toAbsoluteUrl('/media/spa/feature.png')

const speedState = {
  language_content: {
    code: 'he',
    name: 'Hebrew',
    direction: 'rtl',
    flag: ISRAEL,
    translate: 'HEBREW',
  },
  Tone_en: {id: 1, name: 'None', translate: 'NONE'},
  Quality_type_en: {id: 2, name: 'Pro', translate: 'PRO'},
  feature: {id: 1, name: 'Speed Article', translate: 'SPEED_ARTICLE', flag: FEATURE},
  Topic: {id: 0, value: '', isEdited: false},
  Topic_en: {id: 0, value: '', isEdited: false},
  Keywords: {},
  exampleAdd: '',
  stepper: 0,
  isEditing: false,
  articleData: {},
  articleHTML: '',
  steps: [
    {id: 0, name: 'STEP1', active: true},
    {id: 1, name: 'STEP2', active: false},
  ],
  currentArticleID: '',
  isLoading: false,
  isLoadingAction: false,
}

const initialState = {
  articles: [{...speedState}],
}
export const speedSlice = createSlice({
  name: 'speed',
  initialState: initialState,
  reducers: {
    setArticleEmpty: () => {
      return {...initialState}
    },
    addNewArticle: (state, action) => {
      const index = action.payload
      state.articles.splice(index, 0, {...speedState})
    },
    updateLanguageContent: (state, action) => {
      const {index, value} = action.payload
      state.articles[index].language_content = value
    },
    updateTone: (state, action) => {
      const {index, value} = action.payload
      state.articles[index].Tone_en = value
    },
    updateQuality: (state, action) => {
      const {index, value} = action.payload
      state.articles[index].Quality_type_en = value
    },
    updateFeature: (state, action) => {
      console.log('updateFeature', action.payload)
      const {index, value} = action.payload
      state.articles[index].stepper = 0
      state.articles[index].articleData = {}
      state.articles[index].articleHTML = ''
      state.articles[index].steps = [
        {id: 0, name: 'STEP1', active: true},
        {id: 1, name: 'STEP2', active: false},
      ]
      state.articles[index].currentArticleID = ''
      state.articles[index].feature = value
    },
    setEditTopic: (state, action) => {
      const {index, value} = action.payload
      state.articles[index].Topic.value = value
      state.articles[index].Topic_en.value = value
      state.articles[index].Topic.isEdited = true
      state.articles[index].Topic_en.isEdited = true
    },
    setExampleAd: (state, action) => {
      const {index, value} = action.payload
      state.articles[index].exampleAdd = value
    },
    setTags: (state, action) => {
      const {index, key, value} = action.payload
      if (key) {
        state.articles[index].Keywords[key] = value
      } else {
        state.articles[index].Keywords = value
      }
    },
    setContentData: (state, action) => {
      console.log('setContentData', action.payload)
      const {index, value} = action.payload
      state.articles[index].articleData = value
    },
    setActiveStepper: (state, action) => {
      const {index, value} = action.payload
      state.articles[index].stepper = value
    },
    setActiveState: (state, action) => {
      const {index, value} = action.payload
      const newState = JSON.parse(JSON.stringify(state.articles[index]))
      let tempSteps = [...newState.steps]
      tempSteps = tempSteps.map((obj) => {
        if (obj.id === value) {
          obj.active = true
        }
        return obj
      })
      state.articles[index].steps = tempSteps
    },
    setIsEditing: (state, action) => {
      const {index, value} = action.payload
      state.articles[index].isEditing = value
    },
    createHTMLData: (state, action) => {
      const {index, value} = action.payload
      const htmlString = `
      <h1 >${value?.chosen_title?.value}</h1>
       <br />
       ${value?.picture_url ? `<img src=${value?.picture_url} />` : ''}

       <br />
       <p>${value?.picture_credit}</p>
       <br />
       <p>${value?.intros?.value}</p>
       <br />
       ${
         value &&
         Object.keys(value).length > 0 &&
         Object?.keys(value?.outlines)
           ?.map(
             (item) => `<h4>${value?.outlines[item]?.value}</h4><p>${value?.paragraphs[item]}</p>`
           )
           .join('<br/><br/>')
       }
       `
      console.log('createHTMLData', htmlString)

      state.articles[index].articleHTML = htmlString
    },
    setArticleHTML: (state, action) => {
      const {index, value} = action.payload
      let articleArray = state.articles
      articleArray[index].articleHTML = value
      state.articles = articleArray
    },

    setArticleID: (state, action) => {
      const {index, value} = action.payload
      state.articles[index].currentArticleID = value
    },
    setEditedTopic: (state, action) => {
      const {index, value} = action.payload
      if (state.articles[index].Topic.isEdited == false) {
        state.articles[index].Topic.isEdited = true
      }
      state.articles[index].Topic = {
        ...state.articles[index].Topic,
        value: value,
      }
    },
    setEditedExampleAd: (state, action) => {
      const {index, value} = action.payload
      state.articles[index].exampleAdd = value
    },
    setEditedTitle: (state, action) => {
      const {index, value} = action.payload
      if (state.articles[index].articleData.chosen_title.isEdited === false) {
        state.articles[index].articleData.chosen_title.isEdited = true
      }
      state.articles[index].articleData.chosen_title.value = value
    },
    setEditedIntro: (state, action) => {
      const {index, value} = action.payload
      if (state.articles[index].articleData.intros.isEdited === false) {
        state.articles[index].articleData.intros.isEdited = true
      }
      state.articles[index].articleData.intros.value = value
    },
    setEditChosenOutline: (state, action) => {
      const {index, key, value} = action.payload
      state.articles[index].articleData.outlines[key].value = value
      state.articles[index].articleData.outlines[key].isEdited = true
    },
    removeChosenOutlineItem: (state, action) => {
      const {index, key} = action.payload
      const myState = JSON.parse(JSON.stringify(state.articles[index].articleData))
      let chosen_outlines = myState.outlines
      delete chosen_outlines[key]
      const sortedKeys = Object.keys(chosen_outlines).sort()
      const sortedObj = {}
      for (let i = 0; i < sortedKeys.length; i++) {
        sortedObj[i] = chosen_outlines[sortedKeys[i]]
      }
      chosen_outlines = sortedObj
      state.articles[index].articleData.outlines = chosen_outlines
    },
    setLoader: (state, action) => {
      const {index, value} = action.payload
      state.articles[index].isLoading = value
    },
    setLoaderAction: (state, action) => {
      const {index, value} = action.payload
      state.articles[index].isLoadingAction = value
    },
    resetArticle: (state, action) => {
      state.articles[action.payload].articleHTML = ''
      state.articles[action.payload].currentArticleID = ''
    },
  },
})

export const useArticleSelector = () => useSelector((state) => state.speedSlice)

export const speedActions = speedSlice.actions
export const speedReducer = speedSlice.reducer
