import React from 'react'
import {toAbsoluteUrl} from '../../../../helpers'
import {useLang} from '../../../../i18n/Metronici18n'

const SideBarPlan = ({title, value, icon}: any) => {
  const lang = useLang()

  return (
    <div
      style={{
        background: 'white',
        width: '208px',
        height: '80px',
        borderRadius: '10px',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        color: 'black',
      }}
    >
      <div style={{padding: '10px', width: '10%'}}>
        <img alt='icon' src={toAbsoluteUrl('/media/dashboard/divider.svg')} />
      </div>
      <div
        style={{
          padding: lang === 'heb' ? '20px 0px 10px 20px' : '20px 20px 10px 0px',
          width: '60%',
        }}
      >
        <div>
          <p style={{fontSize: '10px', fontWeight: 600}}>{title}</p>
          <p style={{fontSize: '19px', fontWeight: 700}}>{value}</p>
        </div>
      </div>

      <div style={{padding: '10px', width: '30%'}}>
        <div
          style={{
            width: '27px',
            height: '27px',
            background: '#7C4CAD',
            color: 'white',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '50%',
          }}
        >
          {icon}
        </div>
      </div>
    </div>
  )
}
export default SideBarPlan
