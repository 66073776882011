import {useDispatch, useSelector} from 'react-redux'
import {toast} from 'react-toastify'
import {useIntl} from 'react-intl'
import {useParams} from 'react-router-dom'
import {CountryLanguages, Feature, QualityType, Tone} from '../../SharedComponents/constants'
import {useLang} from '../../_metronic/i18n/Metronici18n'
import Dropdown from '../components/DropDown'
import SelectedTextContainer from '../components/SelectedTextContainer'
import SelectedOutlinesContainer from '../components/SelectedOutlinesContainer'
import {speedActions} from '../../store/speedSlice/speedReducers'
const ContentMenuBar = ({language, tone, quality, stepper, feature, setOpenMenu}) => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const params = useParams()
  const contentId = Number(params.contentId)
  const {chosen_title, intros, outlines} = useSelector(
    (state) => state.speed.articles[contentId].articleData
  )
  const {Topic, exampleAdd} = useSelector((state) => state.speed.articles[contentId])

  const lang = useLang()

  const updateTopic = (e) => {
    if (e.target.value.length <= 300) {
      dispatch(speedActions.setEditedTopic({index: contentId, value: e.target.value}))
    }
  }
  const updateExample = (e) => {
    if (e.target.value.length <= 600) {
      dispatch(speedActions.setEditedExampleAd({index: contentId, value: e.target.value}))
    }
  }
  const updateTitle = (e) => {
    if (e.target.value.length <= 500) {
      dispatch(speedActions.setEditedTitle({index: contentId, value: e.target.value}))
    }
  }
  const updateIntro = (e) => {
    dispatch(speedActions.setEditedIntro({index: contentId, value: e.target.value}))
  }
  const updateOutline = (e, Id) => {
    if (e.target.value.length <= 500) {
      dispatch(
        speedActions.setEditChosenOutline({
          index: contentId,
          key: Id,
          value: e.target.value,
        })
      )
    }
  }
  const removeOutline = (Id) => {
    if (outlines && Object.keys(outlines).length < 5) {
      toast.error(intl.formatMessage({id: 'MUST_HAVE_FOUR_OUTLINES'}))
    } else {
      dispatch(
        speedActions.removeChosenOutlineItem({
          key: Id,
          index: contentId,
        })
      )
    }
  }

  const handleLanguage = (opt) => {
    dispatch(speedActions.updateLanguageContent({index: contentId, value: opt}))
  }
  const handleTone = (opt) => {
    dispatch(speedActions.updateTone({index: contentId, value: opt}))
  }
  const handleQuality = (opt) => {
    dispatch(speedActions.updateQuality({index: contentId, value: opt}))
  }
  const handleFeature = (opt) => {
    dispatch(speedActions.updateFeature({index: contentId, value: opt}))
    setOpenMenu(false)
  }
  return (
    <>
      <div
        dir={lang === 'en' ? 'ltr' : 'rtl'}
        className='t-relative t-flex t-flex-col t-w-full  t-items-center'
      >
        {/*  */}

        <div className='t-flex t-w-full  t-justify-between t-flex-col t-items-center '>
          <Dropdown
            value={feature}
            setValue={handleFeature}
            options={Feature}
            showIcon={true}
            title={intl.formatMessage({id: 'FEATURE'})}
            disable={stepper.active > 0 ? true : false}
          />

          <Dropdown
            value={language}
            setValue={handleLanguage}
            options={CountryLanguages}
            showIcon={true}
            title={intl.formatMessage({id: 'LANGUAGE'})}
            disable={false}
          />

          <Dropdown
            value={tone}
            setValue={handleTone}
            options={Tone}
            title={intl.formatMessage({id: 'TONE'})}
            disable={false}
          />
          <Dropdown
            value={quality}
            setValue={handleQuality}
            options={QualityType}
            title={intl.formatMessage({id: 'QUALITY'})}
            disable={false}
          />
        </div>
        <div className='t-w-full t-h-[1px] t-my-3 t-bg-[#CBD5E1]' />

        <div className='t-flex t-w-full t-flex-col t-items-center  scrollbar-hidden t-pb-10 '>
          {stepper.active >= 1 && (
            <>
              <SelectedTextContainer
                data={Topic.value}
                isError={false}
                language={language}
                title={intl.formatMessage({id: 'TOPIC'})}
                handleUpdate={updateTopic}
              />
              {feature.id === 2 && (
                <>
                  <SelectedTextContainer
                    data={exampleAdd}
                    isError={false}
                    language={language}
                    title={intl.formatMessage({id: 'EXAMPLE_RIGHTBAR'})}
                    handleUpdate={updateExample}
                    datalength={600}
                  />
                </>
              )}
              {feature.id === 1 && (
                <>
                  <SelectedTextContainer
                    data={chosen_title.value}
                    isError={false}
                    language={language}
                    title={intl.formatMessage({id: 'TITLE'})}
                    handleUpdate={updateTitle}
                    datalength={500}
                  />
                  <SelectedTextContainer
                    data={intros?.value}
                    isError={false}
                    language={language}
                    title={intl.formatMessage({id: 'INTRO_RIGHTBAR'})}
                    handleUpdate={updateIntro}
                    datalength={500}
                  />
                </>
              )}
            </>
          )}

          {stepper.active >= 1 && feature.id === 1 && (
            <div className='t-flex t-flex-col t-ml-auto t-w-full'>
              <div className='t-mt-1 t-flex t-flex-row  t-py-1 t-font-bold  t-text-[#303038] t-text-[12px] t-pr-1'>
                {intl.formatMessage({id: 'OUTLINES'})}
              </div>
              {outlines &&
                Object.keys(outlines).map((item, index) => {
                  return (
                    <SelectedOutlinesContainer
                      Id={item}
                      key={index}
                      data={outlines[item]}
                      handleRemove={removeOutline}
                      handleAdd={() => console.log('outline handleAdd')}
                      handleUpdate={updateOutline}
                      isError={false}
                      language={language}
                    />
                  )
                })}
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default ContentMenuBar
