import {useRef} from 'react'
import useAutosizeTextArea from '../../SharedComponents/hooks/useAutosizeTextArea'
import {useLang} from '../../_metronic/i18n/Metronici18n'

const SelectedTextContainer = ({data, language, isError, title, handleUpdate}) => {
  const lang = useLang()
  const textareaRef = useRef(null)
  useAutosizeTextArea(textareaRef.current, data)

  return (
    <div dir={lang == 'en' ? 'ltr' : 'rtl'} className='t-flex t-flex-col  t-w-full t-mt-1'>
      <div className='t-flex t-flex-row  t-py-1  t-font-[700] t-text-[#303038] t-text-[12px] t-pr-1 '>
        {title}
      </div>
      <textarea
        ref={textareaRef}
        value={data}
        type={'text'}
        className={`t-border t-border-[#CBD5E1] t-rounded-md  t-bg-[#F8FAFC] t-py-2 t-px-5 t-min-h-[60px] t-text-[#9e9e9e]  t-text-[12px] t-min-w-full t-resize-none ${
          isError ? 't-border-2 t-border-[red]' : ''
        } `}
        // dir={language?.code == 'en' || language?.code == 'ru' ? 'ltr' : 'rtl'}
        onChange={(e) => {
          handleUpdate(e)
        }}
      />
    </div>
  )
}

export default SelectedTextContainer
