import {useEffect, useRef, useState} from 'react'
import {doc, getDoc, onSnapshot} from 'firebase/firestore'
import {useNavigate} from 'react-router-dom'
import {useLang} from '../../../_metronic/i18n/Metronici18n'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {Loader} from '../../../SharedComponents/Loader'
import TabCloseListener from '../../../SharedComponents/utils/TabCloseListener'
import {firestore} from '../../../firebase/FirebaseConfig'
import NavBar from './Navbar'
import MobileNavbar from './MobileNavbar'
const AUTHLOADER = toAbsoluteUrl('/media/spa/LoaderAuth.png')

const NON_PROTECTED = ['/activation']
export async function getServerSideProps(context) {
  return {
    props: {},
  }
}

const Layout = ({children}) => {
  const router = useNavigate()

  const searchParams = new URLSearchParams(router.asPath)
  const [loading, setLoading] = useState(false)

  const params = {}
  for (let p of searchParams) {
    params[p[0]] = p[1]
  }

  const lang = useLang()
  const [open, setOpen] = useState(true)

  const moveToDashBoard = () => {
    window.location.href = process.env.NEXT_PUBLIC_API_DASHBOARD_URL
  }

  useEffect(() => {
    persistLocaleCookie(lang)
  }, [lang])

  function persistLocaleCookie(customLocale) {
    // setLanguage(customLocale)
  }

  useEffect(() => {
    async function checkCorrectRoute() {
      if (!router.isReady) return
      let storageToken = localStorage.getItem('authToken')
      setLoading(true)
      if (!localStorage.getItem('access_token')) {
        moveToDashBoard()
        return
      }
      if (localStorage.getItem('access_token')) {
        let currentUserId = localStorage.getItem('access_token')
        const docRef = doc(firestore, 'users', currentUserId)
        const unsubscribe = onSnapshot(docRef, (snapshot) => {
          const data = snapshot.data()
        })
        return () => {
          unsubscribe()
        }
      }
    }
    checkCorrectRoute()
  }, [router.isReady])

  if (loading) {
    return (
      <div className='t-flex t-flex-col t-items-center t-justify-center t-w-screen t-h-screen t-bg-white'>
        <div className='t-flex t-flex-col t-bg-white t-justify-center t-items-center'>
          <img
            src={AUTHLOADER}
            alt=''
            className='t-pt-2 t-pb-1 t-items-center t-w-[200px] t-h-[320px]'
            style={{overflow: 'hidden'}}
          />
          <Loader className='' size='t-w-10 t-h-10' fill='#592E84' />
        </div>
      </div>
    )
  }

  return (
    <>
      <div id='portal' />
      <TabCloseListener />
      {router?.asPath?.includes(NON_PROTECTED) && (
        <div className='t-h-[100vh] t-w-[100vw] t-flex  t-bg-[green]'>{children}</div>
      )}

      <div className='t-flex t-flex-col t-justify-start t-items-start t-bg-[#F8FAFC]'>
        <NavBar open={open} setOpen={setOpen} />
        <MobileNavbar open={open} setOpen={setOpen} />
        <div
          className={`t-flex t-flex-col t-items-center t-justify-between t-h-full t-ease-in-out t-duration-300 t-w-full `}
        >
          {children}
        </div>
      </div>
    </>
  )
}

export default Layout
