import { useRef, useEffect, useState, ReactNode } from "react";
import { createPortal } from "react-dom";
// import styles from "./Overlay.module.css";

export const Portal = (props) => {
  const ref = useRef(null);
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    ref.current = document.getElementById("portal");
    setMounted(true);
  }, []);

  return mounted && ref.current
    ? createPortal(<div>{props.children}</div>, ref.current)
    : null;
};
