import {useEffect} from 'react'
import {useState} from 'react'
import {useIntl} from 'react-intl'
import {useDispatch, useSelector} from 'react-redux'
import {useNavigate, useParams} from 'react-router-dom'
import {toast} from 'react-toastify'
import {getUserFeedback, rephrasePostArticle} from '../../../store/articleSlice/articleActions'
import {articleActions} from '../../../store/articleSlice/articleReducers'
import LoaderGenerate from '../../../SharedComponents/LoaderGenerate'
import TextContainer from '../../components/TextContainer'

const TitlePage = ({steps, stepper}) => {
  const intl = useIntl()
  // const router = useNavigate()
  // const {articleId} = router.query || {}
  const params = useParams()
  const articleId = Number(params.articleId)
  const dispatch = useDispatch()

  const {
    isLoading,
    titles_en,
    titles,
    chosen_title_en,
    UserID,
    Language_en,
    chosen_title,
    isLoadingAction,
  } = useSelector((state) =>
    articleId || articleId === 0 ? state.article.articles[articleId] : state.article
  )

  const [selected, setSelected] = useState()
  useEffect(() => {
    setSelected(chosen_title && chosen_title.id)
  }, [chosen_title])

  const [editIndex, setEditIndex] = useState()

  const handleTitleSelected = (key) => {
    const index = titles.findIndex((num) => num.id === key)

    let data = {
      chosen_title: {
        id: key,
        value: titles[index].title,
        isEdited: titles[index].isEdited,
      },
      chosen_title_en: {
        id: key,
        value: titles_en[index].title,
        isEdited: titles_en[index].isEdited,
      },
      titleSelected: true,
      id: articleId,
    }

    dispatch(articleActions.setChoosenTitle(data))
  }

  const handleFeedbackLike = (key) => {
    const index = titles.findIndex((num) => num.id === key)
    if (!titles[index].like) {
      let data = {
        user_id: UserID,
        text: titles[index]?.title,
        type: 1,
        key: index,
        id: articleId,
      }
      dispatch(getUserFeedback(data, intl))
    }
  }
  const handleFeedbackDisLike = (key) => {
    const index = titles.findIndex((num) => num.id === key)
    if (!titles[index].dislike) {
      let data = {
        user_id: UserID,
        text: titles[index],
        type: 2,
        key: index,
        id: articleId,
      }
      dispatch(getUserFeedback(data, intl))
    }
  }
  const RepharaseTitleText = (key) => {
    const index = titles.findIndex((num) => num.id === key)
    if (articleId) {
      dispatch(articleActions.setSelectedRephrase({id: articleId, value: key}))
    } else {
      dispatch(articleActions.setSelectedRephrase(key))
    }
    let data = {
      user_id: UserID,
      text: titles[index]?.title,
      text_en: titles_en[index]?.title,
      language_en: Language_en,
      id: articleId,
    }
    dispatch(rephrasePostArticle(data, intl))
  }
  const handleTitleDelete = (key) => {
    if (Object.keys(titles).length < 2) {
      toast.error(intl.formatMessage({id: 'MUST_HAVE_ONE_TITLE'}))
    } else {
      dispatch(
        articleActions.removeItem(articleId || articleId === 0 ? {id: articleId, value: key} : key)
      )
    }
  }
  const handleTitleEdited = (index, data) => {
    let val = {
      key: index,
      value: data,
      id: articleId,
    }
    if (data.length != 0) {
      dispatch(articleActions.setEditedText(val))
      setEditIndex()
    } else {
      toast.error(intl.formatMessage({id: 'TITLE_NOT_EMPTY'}))
    }
  }

  return isLoading ? (
    <LoaderGenerate className='t-p-0 t-pt-0' size='t-w-8 t-h-8' fill='#592E84' />
  ) : (
    <div className='t-flex  t-m-auto t-mx-4 sm:t-mx-7 t-py-4 t-flex-col t-mt-1 sm:t-mt-3'>
      <p className=' t-text-right t-text-[16px] font-medium t-text-BLACK'>
        {intl.formatMessage({id: 'SELECT_AND_EDIT_YOUR_GENERATED_TITLE'})}
      </p>
      <div className='t-w-full'>
        <div className=' t-text-[#7C7C7C]'>
          {titles.map((item, i) => {
            return (
              <TextContainer
                key={i}
                data={titles[i].title}
                isLike={titles[i].like}
                isDislike={titles[i].dislike}
                dir={Language_en === 'English' || Language_en === 'Russia' ? 'ltr' : 'rtl'}
                index={i}
                id={titles[i].id}
                selected={selected}
                setSelected={setSelected}
                handleClick={handleTitleSelected}
                handleFeedbackLike={handleFeedbackLike}
                handleFeedbackDisLike={handleFeedbackDisLike}
                RepharaseText={RepharaseTitleText}
                handleDelete={handleTitleDelete}
                setEditIndex={setEditIndex}
                editIndex={editIndex}
                handleEdited={handleTitleEdited}
                textLimit={500}
                isLoading={isLoadingAction}
              />
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default TitlePage
