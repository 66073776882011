import React from 'react'

export function PrimaryButton(props: any) {
  const {name} = props
  return (
    <>
      <style>
        {`.primaryBTN{
         background-color: #592E84 !important;
         font-size: 16px !important;
         font-weight: 600;
         width:160px;
         color:white;
         height:50px;
         white-space: nowrap;
         @media screen and (max-width: 767px) {
          width: 312px;
         }
        }

        .primaryBTN:hover{
        background-color: #592E84 !important;
        font-size: 16px !important;
        font-weight: 600;
        width:160px;
        color:white;
        height:50px;
        }
         
        `}
      </style>

      <button
        style={{margin: '0px !important'}}
        type='button'
        className='btn primaryBTN fs-1'
        {...props}
      >
        {name}
      </button>
    </>
  )
}

export function SecondayButton(props: any) {
  const {name, btnStyle, startIcon, endIcon} = props
  return (
    <>
      <style>
        {`.secondaryBTN{
           background-color: #FFFFFF !important;
           font-size: 16px !important;
           font-weight: 600;
           width:160px;
           color:#592E84;
           height:50px;
           white-space: nowrap;
           @media screen and (max-width: 767px) {
            width: 312px;
           }
          }
  
          .secondaryBTN:hover{
          background-color: #FFFFFF !important;
          font-size: 16px !important;
          font-weight: 600;
          width:160px;
          color:#592E84;
          height:50px;
          white-space: nowrap;
          }
           
          `}
      </style>

      <button
        style={{margin: '0px !important', border: '1px solid', ...btnStyle}}
        type='button'
        className='btn secondaryBTN fs-1'
        {...props}
      >
        {startIcon && startIcon}
        {name}
        {endIcon && endIcon}
      </button>
    </>
  )
}
