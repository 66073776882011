import {createSlice} from '@reduxjs/toolkit'

const initialState = {
  user: {},
  isLoading: false,
  accessToken: null,
  sessionStartDate: null,
}
export const signUpSlice = createSlice({
  name: 'signin',
  initialState: {
    user: {},
    isLoading: false,
    accessToken: null,
    sessionStartDate: null,
  },
  reducers: {
    setLoader: (state, action) => {
      state.isLoading = action.payload
    },
    setCredits: (state, action) => {
      state.user = {...state.user, credits: action.payload}
    },
    setUserInfoAction: (state, action) => {
      state.user = action.payload
    },
    setAccessTokenAction: (state, action) => {
      state.accessToken = action.payload.userID
      // state.isLoggedIn = action.payload.isLoggedIn
    },
    setSessionStartDateAction: (state, action) => {
      state.sessionStartDate = action.payload
    },
  },
})

export const userActions = signUpSlice.actions
export const signUpReducer = signUpSlice.reducer
