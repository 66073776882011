/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {auth, firestore} from '../../../../firebase/FirebaseConfig'
import {Link, useNavigate} from 'react-router-dom'
import {useFormik} from 'formik'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {useAuth} from '../core/Auth'
import {InputType} from './InputType'
import {BsEyeFill, BsFillEyeSlashFill} from 'react-icons/bs'
import '../style/authStyle.css'
import {useDispatch} from 'react-redux'
import {loginUser, oAuth, setUserInfo} from '../../../../store/auth/login/loginActions'
import {doc, getDoc} from '@firebase/firestore'
import {useMediaQuery} from 'react-responsive'
import {useIntl} from 'react-intl'
import {useLang} from '../../../../_metronic/i18n/Metronici18n'
import {GoogleAuthProvider, signInWithPopup} from '@firebase/auth'

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
})

const initialValues = {
  email: '',
  password: '',
}

export function Login() {
  const lang = useLang()
  const intl = useIntl()
  const isMobile = useMediaQuery({query: '(max-width:992px)'})
  const [loading, setLoading] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [isGoogleLogin, setIsGoogleLogin] = useState(false)

  const handleToggleClick = (e: any) => {
    e.preventDefault()
    setShowPassword(!showPassword)
  }

  const navigate = useNavigate()

  const goToDashboard = () => {
    setLoading(false)
    navigate('/dashboard')
  }

  const action = () => {
    setIsGoogleLogin(false)
    navigate('/')
  }

  const googelSignIn = async () => {
    try {
      const googleProvider = new GoogleAuthProvider()
      // googleProvider.addScope('https://www.googleapis.com/auth/userinfo.email')
      // googleProvider.addScope('openid')
      // googleProvider.addScope('email')
      // googleProvider.addScope('profile')
      // setIsGoogleLogin(true)
      const res = await signInWithPopup(auth, googleProvider)
      const user: any = res.user

      const {
        providerId: registeredBy,
        email,
        photoURL: profile_photo,
        displayName: name,
      } = user?.providerData[0]
      let userData = {
        registeredBy,
        email,
        profile_photo,
        activation: true,
        credits: 10000,
        name,
        plan: 'FREE',
      }

      // this code gets only one doc (USER)
      const userId: any = auth?.currentUser?.uid
      const docRef = doc(firestore, 'users', userId)
      const snapshot = await getDoc(docRef)

      let data = snapshot.data()

      if (data != undefined) {
        dispatch(setUserInfo(data, userId, action))
      } else {
        dispatch(oAuth(userData, action, intl))
      }
      localStorage?.setItem('authToken', user?.accessToken)
    } catch (error) {
      console.log('error in google login', error)
    }
  }

  const dispatch = useDispatch<any>()
  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        dispatch(loginUser(values, goToDashboard, setLoading, intl))
      } catch (error) {
        console.error(error)
        setStatus('The login details are incorrect')
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  return (
    <div
      className='container  d-flex  justify-content-center align-items-center'
      style={{height: isMobile ? '100vh' : '100vh', paddingTop: '30px', paddingBottom: '30px'}}
    >
      <div
        className={`row custom-row shadow-lg h-${
          isMobile ? '85' : '100'
        } d-flex  justify-content-center align-items-center box`}
        dir={lang === 'heb' ? 'rtl' : 'ltr'}
        style={{borderRadius: '5px'}}
      >
        <div
          className='col-md-4 d-lg-block h-100 pl-0 pr-0'
          // style={{backgroundColor: 'rgba(240, 226, 252, 0.5)', padding: 0}}
          style={{
            backgroundImage: `url(${toAbsoluteUrl('/SignIn-BG.svg')})`,
            display: isMobile ? 'none' : '',
          }}
        >
          {lang === 'heb' ? (
            <img
              style={{height: '100%', width: '100%'}}
              alt='img'
              src={toAbsoluteUrl('/welcome-img_heb.png')}
            />
          ) : (
            <img
              style={{height: '100%', width: '100%'}}
              alt='img'
              src={toAbsoluteUrl('/welcome-img.svg')}
            />
          )}

          {/* <div
            style={{
              height: '100%',
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <p style={{fontSize: '32px', fontWeight: 700, margin: '0px auto 10px auto'}}>
              Welcome Back!
            </p>
            <img alt='img' src={toAbsoluteUrl('/mobile-sign.svg')} />
          </div> */}
        </div>
        {/* ------------------form area-------------------- */}

        <div className='col-md-8 col-12 h-100 d-flex  flex-column justify-content-center align-items-center '>
          <div className='w-lg-95 w-100 text-black' style={{color: '#00000'}}>
            <form>
              <h3
                className={`px-4 d-lg-none d-md-none d-block text-lg-start text-center text-black`}
                style={{fontStyle: 'italic', color: '#592E84', fontSize: isMobile ? '20px' : ''}}
              >
                {intl.formatMessage({id: 'WELCOME_BACK'})}
              </h3>
              <h3
                className={`${lang === 'heb' ? 'text-lg-end' : 'text-lg-start'} px-4  text-center`}
                style={{
                  fontStyle: 'italic',
                  marginBottom: '20px',
                  color: '#000000',
                  fontWeight: 700,
                  fontSize: isMobile ? '20px' : '24px',
                  fontFamily: 'IBM Plex Sans',
                  textAlign: 'end',
                }}
              >
                {intl.formatMessage({id: 'LOGIN_INTO_SITE'})}
              </h3>
              <div className='w-100 d-flex justify-content-center'>
                <hr className='divider w-75 d-lg-none d-block'></hr>
              </div>

              <div className='d-flex flex-column'>
                <InputType
                  label={intl.formatMessage({id: 'EMAIL'})}
                  placeholder={intl.formatMessage({id: 'ENTER_YOUR_EMAIL'})}
                  type='text'
                  name='email'
                  onChnage={formik.handleChange}
                  value={formik.values.email}
                />
                <div style={{marginLeft: '1rem', padding: '5px'}}>
                  {formik.errors.email ? (
                    <div className='error text-danger'>{formik.errors.email}</div>
                  ) : null}
                </div>
                <div style={{position: 'relative'}}>
                  <InputType
                    label={intl.formatMessage({id: 'PASSWORD'})}
                    placeholder={intl.formatMessage({id: 'ENTER_YOUR_PASSWORD'})}
                    type={showPassword ? 'text' : 'password'}
                    name='password'
                    onChnage={formik.handleChange}
                    value={formik.values.password}
                  />

                  <button
                    onClick={(e) => handleToggleClick(e)}
                    className='bg-transparent border-0 '
                    style={{
                      position: 'absolute',
                      top: '40px',
                      [lang === 'heb' ? 'left' : 'right']: '5%',
                    }}
                  >
                    {showPassword ? (
                      <BsEyeFill size={20} color='#9D9DA6' />
                    ) : (
                      <BsFillEyeSlashFill size={20} color='#9D9DA6' />
                    )}
                  </button>
                  <div style={{marginLeft: '1rem', padding: '5px'}}>
                    {formik.errors.password ? (
                      <div className='error text-danger'>{formik.errors.password}</div>
                    ) : null}
                  </div>
                </div>
              </div>

              <div
                className=' px-4 w-100 py-6 d-flex justify-content-between'
                style={{
                  alignItems: 'center',
                }}
              >
                <div className=' d-flex justify-content-between align-items-center'>
                  <input type='checkbox' value='Remember Me' />
                  <label style={{color: '#52525B', fontSize: '13px'}}>
                    &nbsp;&nbsp;{intl.formatMessage({id: 'REMEMBER_ME'})}
                  </label>
                </div>
                <Link to='/auth/forgot-password'>
                  <div className='d-flex justify-content-center align-items-center  h-25'>
                    <p className='mt-3 cursor-pointer' style={{color: '#592E84', fontSize: '13px'}}>
                      {intl.formatMessage({id: 'FORGOT_PASSWORD'})}
                    </p>
                  </div>
                </Link>
              </div>

              <div className='px-4 w-100 d-flex flex-lg-row flex-column align-items-center '>
                <button
                  onClick={() => formik.handleSubmit()}
                  disabled={loading}
                  className=' px-6 fw-bold sign-in-button'
                  style={{width: '160px !important'}}
                >
                  {intl.formatMessage({id: 'CONNECT'})}
                </button>

                {'  '}
                <p
                  style={{fontSize: '16px'}}
                  className='d-flex justify-content-center align-items-center mt-3 fw-light'
                >
                  <span className='fw-bold' style={{color: '#303038', fontSize: '16px'}}>
                    &nbsp; or &nbsp;
                  </span>{' '}
                  {intl.formatMessage({id: 'DONT_HAVE_ACCOUNT'})} &nbsp;{' '}
                  <Link to='/auth/registration'>
                    <span
                      className='fw-bold cursor-pointer'
                      style={{color: '#592E84', fontSize: '16px'}}
                    >
                      {intl.formatMessage({id: 'SIGN_UP'})}
                    </span>
                  </Link>
                </p>
              </div>
            </form>
            <div className='d-flex justify-content-center flex-row  w-100 px-4 mt-6'>
              <button
                onClick={() => {
                  googelSignIn()
                }}
                className='w-100 bg-transparent fw-normal auth-button border border-warning'
              >
                <div className='d-flex justify-content-center align-items-center'>
                  <img
                    alt='Logo'
                    src={toAbsoluteUrl('/media/svg/brand-logos/google-icon.svg')}
                    className=' mx-3'
                    style={{height: '21.35px', width: '20px'}}
                  />
                  <p className='' style={{color: '#592E84', fontSize: '16px'}}>
                    {intl.formatMessage({id: 'SIGN_IN_WITH_GOOGLE'})}
                  </p>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
