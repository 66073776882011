import {useRef, useState} from 'react'
import {useSelector} from 'react-redux'
import {toAbsoluteUrl} from '../../_metronic/helpers'
import {useLang} from '../../_metronic/i18n/Metronici18n'
import useAutosizeTextArea from '../../SharedComponents/hooks/useAutosizeTextArea'
const DELETE = toAbsoluteUrl('/media/spa/delete.png')
const PLUS = toAbsoluteUrl('/media/spa/plus_grey.png')

const SelectedOutlinesContainer = ({
  data,
  Id,
  handleRemove,
  handleAdd,
  handleUpdate,
  isError,
  language,
}) => {
  const lang = useLang()
  const textareaRef = useRef(null)

  useAutosizeTextArea(textareaRef.current, data)

  return (
    <div className='t-flex t-w-full'>
      {/* <div className='t-flex t-w-1/6'>
        {/* <div className='t-flex h-full t-items-center t-justify-center'>
          <img
            src={PLUS}
            alt=''
            height={20}
            width={20}
            className='mr-2 px-1 t-cursor-pointer'
            onClick={() => {
              handleAdd(Id);
            }}
          />
        </div> 
        
      </div> */}
      <div className='t-relative t-my-1 t-flex t-w-full' dir={lang == 'en' ? 'ltr' : 'rtl'}>
        <textarea
          dir={language?.code == 'en' || language?.code == 'ru' ? 'ltr' : 'rtl'}
          ref={textareaRef}
          value={data.value}
          type={'text'}
          className={` t-border t-border-[#CBD5E1] t-rounded-md t-min-w-full t-bg-[#F8FAFC] t-py-2  ${
            lang == 'en' && (language?.code == 'he' || language?.code == 'ar')
              ? 't-pr-5 t-pl-10'
              : lang == 'heb' && (language?.code == 'he' || language?.code == 'ar')
              ? 't-pr-5 t-pl-10'
              : lang == 'en' && (language?.code == 'en' || language?.code == 'ru')
              ? 't-pr-10 t-pl-5'
              : lang == 'heb' && (language?.code == 'en' || language?.code == 'ru')
              ? 't-pr-10 t-pl-5'
              : ''
          }  t-text-[#9e9e9e] t-text-[14px] t-min-h-[40px] t-resize-none ${
            isError ? 't-border-2 t-border-[red]' : ''
          } `}
          onChange={(e) => {
            handleUpdate(e, Id)
          }}
        />
        <div className='t-flex h-full t-items-center t-justify-center'>
          <img
            src={DELETE}
            alt=''
            height={22}
            width={22}
            className={`t-absolute ${
              lang == 'en' && (language?.code == 'he' || language?.code == 'ar')
                ? 't-left-5'
                : lang == 'heb' && (language?.code == 'he' || language?.code == 'ar')
                ? 't-left-5'
                : lang == 'en' && (language?.code == 'en' || language?.code == 'ru')
                ? 't-right-5'
                : lang == 'heb' && (language?.code == 'en' || language?.code == 'ru')
                ? 't-right-5'
                : ''
            }  t-w-4 t-cursor-pointer`}
            onClick={() => handleRemove(Id)}
          />
        </div>
      </div>
    </div>
  )
}

export default SelectedOutlinesContainer
