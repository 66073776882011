import {useDispatch, useSelector} from 'react-redux'
// import {logout} from 'store/authSlice/authActions'
// import SETLANGUAGE from 'next-translate/setLanguage'
// import {handleLanguage} from 'store/authSlice/authActions'
import {useIntl} from 'react-intl'
import {useLang} from '../../../_metronic/i18n/Metronici18n'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {addNewArticle} from '../../../store/articleSlice/articleActions'
import SecondaryButton from '../../../SharedComponents/buttons/SecondaryButton'
import {LANGUAGES} from '../../../SharedComponents/helpers/Language'
import CustomDropdown from '../../../SharedComponents/LanguageDropdown'
import PopAlert from '../../../SharedComponents/PopAlert'
import {useOnClickOutside} from '../../../SharedComponents/hooks/useOutsideClick'
import {Modal} from '../../../SharedComponents/Layouts/Modal'
import {useRef, useState} from 'react'
import {useNavigate} from 'react-router-dom'
// import {logout} from '../../../../store/auth/login/loginActions'
import {logout} from '../../../store/auth/login/loginActions'

const KTOVLI = toAbsoluteUrl('/media/spa/ktovli_logo.png')
const UPGRADE = toAbsoluteUrl('/media/spa/UpgradeIcon.png')
const PROFILE = toAbsoluteUrl('/media/spa/profile.jpg')
const DASHBOARD_ICON = toAbsoluteUrl('/media/spa/dashboard.png')
const DASHBOARD_ICON_ACTIVE = toAbsoluteUrl('/media/spa/dashboard-active.png')
const CREATE_NEW_ICON = toAbsoluteUrl('/media/spa/creatNew.png')
const CREATE_NEW_ICON_ACTIVE = toAbsoluteUrl('/media/spa/createNew-active.png')

//Defining routes here
const ROUTES = [
  {
    id: 1,
    title: 'DASHBOARD',
    icon: DASHBOARD_ICON,
    route: '/dashboard',

    active: true,
    activeIcon: DASHBOARD_ICON_ACTIVE,
  },
  {
    id: 3,
    title: 'NEW_TEMPLATE',
    icon: CREATE_NEW_ICON,
    route: '/articleWriter',
    active: false,
    activeIcon: CREATE_NEW_ICON_ACTIVE,
  },
  // {
  //   id: 2,
  //   title: "SAVED_POSTS",
  //   icon: TEMPLATES_ICON,
  //   route: "/saved-post",
  //   active: false,
  //   activeIcon: TEMPLATES_ICON_ACTIVE,
  // },

  // {
  //   id: 4,
  //   title: "SETTINGS",
  //   icon: SETTING_ICON,
  //   route: "/settings",
  //   active: false,
  //   activeIcon: SETTING_ICON_ACTIVE,
  // },
]

const NavBar = ({open, setOpen}) => {
  function persistLocaleCookie(customLocale) {
    // SETLANGUAGE(customLocale)
  }
  const [language, setLanguage] = useState(null)
  const [isAlert, setIsAlert] = useState(false)
  const [isRedirect, setIsRedirect] = useState(false)
  const [selectedItem, setSelectedItem] = useState()
  const [openMenu, setOpenMenu] = useState()
  const [profile, setProfile] = useState(false)

  const {user} = useSelector((state) => state.login)
  const lang = useLang()
  const {articles} = useSelector((state) => state.article)
  const dispatch = useDispatch()
  const intl = useIntl()
  // const action = () => {
  //   router.push("/login");
  // };
  const handleClickk = (item) => {
    if (item.title === 'DASHBOARD') {
      setSelectedItem(item)
      setIsAlert(true)
    } else {
      handleAddNewTemplate(item.route)
    }
  }
  const navigate = useNavigate()
  const action = () => {
    navigate('/')
  }
  const logOutUser = () => {
    const userId = localStorage.getItem('access_token')
    dispatch(logout(action, userId))
  }
  const handleRedirect = () => {
    window.open(selectedItem.route, '_self')
    setIsAlert(false)
  }
  const handleAddNewTemplate = (url) => {
    const randomNumber = articles ? articles.length : 0
    dispatch(addNewArticle(randomNumber))
    window.open(`${url}/${randomNumber}`, '_blank')
  }
  const ref = useRef()
  useOnClickOutside(ref, () => {
    setOpenMenu(false)
  })
  return (
    <>
      <nav
        dir={lang == 'en' ? 'ltr' : 'rtl'}
        className={`t-relative t-hidden lg:t-flex t-h-[70px] t-px-16 xl:t-px-24 t-w-full t-border t-items-center t-justify-between t-ease-in-out t-duration-300 t-z-10 t-bg-white `}
      >
        <div className='t-flex t-items-center t-gap-5'>
          <div className='t-px-2 t-py-6 t-h-[60px] t-flex t-items-center'>
            <img src={KTOVLI} alt='' height={100} width={100} className='' />
          </div>

          <div className=' t-flex t-flex-row t-items-center t-justify-center t-gap-3 t-text-[16px]'>
            {ROUTES.map((item) => {
              return (
                <Item
                  key={item.id}
                  title={intl.formatMessage({id: item.title})}
                  open={open}
                  active={item.active}
                  onClick={() => handleClickk(item)}
                />
              )
            })}

            {/* <Item
            key={"11"}
            title={intl.formatMessage({id: "LOGOUT"})}
          
            open={open}
            active={false}
            onClick={() => {
              dispatch(articleActions.setArticleEmpty());
              dispatch(logout(action));
            }}
          /> */}
          </div>
        </div>
        <div className='t-flex t-flex-row t-items-center t-text-[16px] t-gap-6 t-text-black t-whitespace-nowrap'>
          <p className='t-font-bold'>
            {user.plan === 'FREE'
              ? intl.formatMessage({id: 'FREE_PLAN'})
              : user.plan === 'STANDARD'
              ? intl.formatMessage({id: 'REGULAR_PLAN'})
              : user.plan === 'PRO'
              ? intl.formatMessage({id: 'PRO_PLAN'})
              : intl.formatMessage({id: 'FREE_PLAN'})}
          </p>
          <div className='t-flex t-text-black t-items-center t-gap-1'>
            <p>{intl.formatMessage({id: 'WORDS_LEFT'})}:</p>
            <p className='t-font-bold'>{user?.credits < 0 ? 0 : user.credits}</p>
          </div>
          <div className='t-relative t-flex'>
            <SecondaryButton
              iconPosition={1}
              isLoading={false}
              text={intl.formatMessage({id: 'UPGRADE_PLAN'})}
              onClick={() => window.open('https://www.ktovli.com/pricing', '_blank')}
              isIcon={true}
              icon={UPGRADE}
              className='t-py-[10px]'
              textClass='t-text-[18.72px] t-font-medium'
            />
          </div>
        </div>
        <div className='t-absolute t-right-5 xl:t-right-10 t-top-4'>
          <div className='t-relative'>
            <img
              src={PROFILE}
              alt=''
              className='t-w-8 t-h-8 t-cursor-pointer'
              onClick={() => setProfile(!profile)}
            />

            <Modal
              id='profile_modal'
              open={profile}
              outsideClick
              onClose={() => {
                setProfile(false)
              }}
            >
              <div className='t-z-[999999] t-absolute t-border t-bg-[#592E84] t-text-white t-w-[200px] t-py-3 t-rounded-md t-top-[55px] t-right-8'>
                <div className=' t-mx-3 t-flex t-flex-col t-gap-2'>
                  {/* <div className="t-flex t-items-center t-gap-2">
                    <img
                      src={PROFILE}
                      alt=""
                      className="t-w-8 t-h-8 t-rounded-sm"
                    />
                    <p className="t-text-[10px]"></p>
                  </div> */}
                  <div className='t-bg-white t-rounded-sm'>
                    <CustomDropdown
                      width='t-w-full'
                      options={LANGUAGES}
                      onSelect={(selectedOption) => {
                        // SETLANGUAGE(selectedOption.code)
                        persistLocaleCookie(selectedOption.code)
                        // dispatch(handleLanguage(selectedOption.code))
                      }}
                    />
                  </div>
                  <p
                    onClick={logOutUser}
                    className='hover:t-bg-white hover:t-text-black t-cursor-pointer t-px-2 t-py-1 t-w-full t-rounded-sm t-text-sm'
                  >
                    Sign Out
                  </p>
                </div>
              </div>
            </Modal>
          </div>
        </div>
      </nav>

      {isAlert ? (
        <PopAlert
          isAlert={isAlert}
          setIsAlert={setIsAlert}
          setIsRedirect={setIsRedirect}
          handleRedirect={handleRedirect}
        />
      ) : (
        ''
      )}
    </>
  )
}
const Item = ({Key, title, icon, active, open, onClick, className}) => {
  return (
    <div
      key={Key}
      onClick={onClick}
      className={`${
        !open ? 't-justify-center' : ''
      } t-flex t-px-2 t-py-1.5 t-items-center t-font-normal t-text-gray-900 t-cursor-pointer ${
        active && open ? 't-px-[10px] t-cursor-pointer' : ''
      }`}
    >
      {icon ? <img src={icon} alt='' height={open ? 20 : 30} width={open ? 20 : 30} /> : ''}

      <p
        className={`t-flex-1 t-text-center t-whitespace-nowrap ${className} ${
          active ? 't-text-[#592E84] t-font-bold' : 't-text-[#303038] t-font-medium'
        }`}
      >
        {title}
      </p>
    </div>
  )
}
export default NavBar

// export default () => {
//   return <></>;
// };
