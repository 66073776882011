import {createSlice} from '@reduxjs/toolkit'

import {useSelector} from 'react-redux'

const initialState = {
  historyArticles: [],
  currentPage: 1,
  totalPages: '',
}

export const historySlice = createSlice({
  name: 'article',
  initialState: initialState,
  reducers: {
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload
    },
    setHistoryArticles: (state, action) => {
      if (state.historyArticles.length > 0) {
        state.historyArticles = [...state.historyArticles, ...action.payload]
      } else {
        state.historyArticles = action.payload
      }
    },
    setTitle: (state, action) => {
      console.log('acsdsds', action)
      const itemIndex = state.historyArticles.findIndex(item => item.id === action.payload.id);

      if (itemIndex !== -1) {
        const updatedItem = { ...state.historyArticles[itemIndex], article_title: action.payload.title };
        
        // Create a new array with the updated item
        const newArray = [
          ...state.historyArticles.slice(0, itemIndex),
          updatedItem,
          ...state.historyArticles.slice(itemIndex + 1)
        ];
        state.historyArticles = newArray
      
      } else {
        console.log('Item not found in the array');
      }
    },
    deleteArticle: (state, action) => {
      const itemIndex = state.historyArticles.findIndex(item => item.documentid === action.payload);
      console.log('deleteArticle action', action.payload,'\n\nitemIndex',itemIndex)

      if (itemIndex !== -1) {
        console.log('Item found');
        state.historyArticles.splice(itemIndex, 1);
      } else {
        console.log('Item not found in the array');
      }
    },
  },
})

export const useHistorySelector = () => useSelector((state) => state.historySlice)

export const historyActions = historySlice.actions
export const historyReducer = historySlice.reducer
