import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { articleActions } from "../../store/articleSlice/articleReducers";

const useStepper = (steps) => {
  const dispatch = useDispatch();
  const params = useParams()

  const articleId = Number(params.articleId)
  // const router = useNavigate()  
  // const {articleId} = router.query || {}
  const { stepper } = useSelector(state => articleId || articleId === 0 ?state.article.articles[articleId] :state.article);
  const {articles:articleData} = useSelector(state => state.article);
  const setActive = (val) => {
    if(articleId === 0 || articleId && articleData){
      dispatch(articleActions.setActiveStepper({value:val,id:articleId}));
    } else {
      dispatch(articleActions.setActiveStepper(val));
    }
  };

  const nextStep = () => {
    const tempStepper = articleId === 0 || articleId ? articleData[articleId]?.stepper : stepper
    if (tempStepper !== steps.length) {
      if((articleId === 0 || articleId) && articleData){
        dispatch(articleActions.setActiveStepper({value:stepper + 1,id:articleId}));
        dispatch(articleActions.setActiveState({value:stepper + 1,id:articleId}));
      } else {
        dispatch(articleActions.setActiveStepper(stepper + 1));
        dispatch(articleActions.setActiveState(stepper + 1));
      }
    }
  };

  const previousStep = () => {
    const tempStepper = articleId === 0 || articleId && articleData ?articleData[articleId]?.stepper :stepper
    if (tempStepper !== 0) {
      if(articleId === 0 || articleId){
        dispatch(articleActions.setActiveStepper({value:stepper - 1,id:articleId}));
        dispatch(articleActions.setActiveState({value:stepper - 1,id:articleId}));
      } else {
        dispatch(articleActions.setActiveStepper(stepper - 1));
        dispatch(articleActions.setActiveState(stepper - 1));
      }
    }
  };

  const goto = (val) => {
    if(articleId === 0 || articleId){

      dispatch(articleActions.setActiveStepper({value:val,id:articleId}));
    } else {
      dispatch(articleActions.setActiveStepper(val));
    }
    // dispatch(articleActions.setActiveState(stepper));
  };

  const isLastStep = () => articleId === 0 || articleId && articleData ? articleData[articleId]?.stepper === steps.length - 1 : stepper === steps.length - 1;
  const isFirstStep = () => articleId === 0 || articleId && articleData ? articleData[articleId]?.stepper === 0 : stepper === 0;
  const isPassed = (value) => value < (articleId === 0 || articleId && articleData ? articleData[articleId]?.stepper : stepper);
  const isActive = (value) => value === (articleId === 0 || articleId && articleData ? articleData[articleId]?.stepper : stepper);
  const isPending = (value) => value > (articleId === 0 || articleId && articleData ? articleData[articleId]?.stepper : stepper);
  const active = articleId === 0 || articleId && articleData ? articleData[articleId]?.stepper : stepper;

  return {
    nextStep,
    active,
    previousStep,
    isLastStep,
    setActive,
    isFirstStep,
    isPassed,
    isPending,
    isActive,
    goto,
  };
};

export { useStepper };
