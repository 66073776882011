import {useState} from 'react'
import {useSelector} from 'react-redux'
import {useContext, createContext, useEffect} from 'react'

import {deltaToDoc} from '../../spa/components/DownloadDoc'
import {useNavigate, useParams} from 'react-router-dom'

const quillContext = createContext({
  delta: null,
  listening: false,
  setDelta: () => {},
  setListening: () => {},
})

export const useQuillContext = () => useContext(quillContext)
export const QuillProvider = ({children}) => {
  // const router = useNavigate()
  // const {articleId} = router.query || {}
  const params = useParams()
  const articleId = Number(params.articleId)
  const [delta, setDelta] = useState(null)
  const [listening, setListening] = useState(false)
  const {language_en} = useSelector((state) =>
    articleId || articleId === 0 ? state.article.articles[articleId] : state.article
  )
  useEffect(() => {
    if (delta !== null) {
      deltaToDoc(delta, language_en)
      setDelta(null)
    }
  }, [delta])
  return (
    <quillContext.Provider value={{delta, setDelta, listening, setListening}}>
      {children}
    </quillContext.Provider>
  )
}
