import React from 'react'

interface Props {
  label: string
  placeholder: string
  type: any
  name: string
  onChnage: any
  value: any
}

function InputType({label, placeholder, type, name, onChnage, value}: Props) {
  return (
    <div className='d-flex flex-column px-4  w-100'>
      <label
        style={{
          color: '#303038',
          marginBottom: '5px',
          fontWeight: 600,
          fontSize: '16px',
        }}
      >
        {label}
      </label>
      <input
        type={type}
        name={name}
        onChange={onChnage}
        value={value}
        placeholder={placeholder}
        className='p-4 boredr-0 w-100 placeholder-color t-text-black'
        style={{
          borderRadius: '10px',
          backgroundColor: '#F8FAFC',
          border: '1px solid #CBD5E1',
          height: '45px',
        }}
      />
    </div>
  )
}

export {InputType}
