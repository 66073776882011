import {useIntl} from 'react-intl'
import ArrowUp from '../../../../assets/icons/dashboardSvgs/arrow-up.svg'
import {useLang} from '../../../../i18n/Metronici18n'

type Props = {
  className: string
  description: string
  color: string
  img: string
  title: string
  handleClick?: any
  isComingSoon: any
  firstIcon: any
  secondIcon: any
  betaWord: string
  normalText: any
  firstIconBg: any
}

const CardsWidget20 = ({
  className,
  description,
  color,
  img,
  title,
  handleClick,
  isComingSoon,
  firstIcon,
  secondIcon,
  betaWord,
  normalText,
  firstIconBg,
}: Props) => {
  const lang = useLang()
  const intl = useIntl()
  return (
    <>
      <style>
        {`
        .firstIcon{
          width: 35px;
          height: 35px;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: #FAF7F9;
          border-radius: 6px;
        }
        .comingSoon{
          position: absolute;
          top: -10px;
          left: -35px;
          transform: rotate(330deg);
          width: 113px !important;
          height: 34px !important;
          background: #592E84;
          color: white;
          font-size: 13px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 10px;
      
          font-weight: 700;
        }
        .comingSoonHeb{
          position: absolute;
          top: -10px;
          right: -35px;
          transform: rotate(40deg);
          width: 113px !important;
          height: 34px !important;
          background: #592E84;
          color: white;
          font-size: 13px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 10px;
         
          font-weight: 700;
        }
      .cardBtn{
        background-image: linear-gradient(180deg, rgba(239, 230, 225, 0) 51%, #EFDEFF 100%);
          width:215px !important;
          border-radius:18px !important;
          @media screen and (max-width: 767px) {
            margin: auto;
          }
        }
    .cardBtn:hover{
      transform: translate(5px, 5px);
      transition: transform 0.5s;
      border: 1px solid #592E84 !important;
    }

    }
    `}
      </style>

      <div
        className={`card card-flush cursor-pointer cardBtn  d-flex justify-content-center align-items-center Regular shadow  bgi-no-repeat bg-white bgi-size-contain bgi-position-x-end hover-overlay ripple shadow-1-strong ${className}`}
        onClick={handleClick}
        role='button'
      >
        {isComingSoon && (
          <div className={lang === 'heb' ? 'comingSoonHeb' : 'comingSoon'}>
            {intl.formatMessage({id: 'SOON'})}
          </div>
        )}
        <div
          style={{
            display: 'flex',
            width: '95%',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <p style={{width: '30px', height: '30px'}} />

          <div className={firstIconBg ? 'firstIcon' : ''}>{firstIcon && firstIcon}</div>
          <div style={{width: '30px', height: '30px'}}>
            {secondIcon && <img src={ArrowUp} alt='icon2' />}
          </div>
        </div>
        <div className='card-header  d-flex fd-column  justify-content-center align-items-center w-100'>
          <span
            style={{fontWeight: 700, fontSize: '20px'}}
            className={`fs-20px fw-bold text-center me-2 lh-1 text-black ${
              normalText ? '' : 'text-nowrap'
            }`}
          >
            {title}
          </span>
        </div>
        <p
          className='text-center'
          style={{fontSize: '13px', lineHeight: '16px', padding: '10px 22px 0px 22px'}}
        >
          {description}
        </p>
        <span className='text-center purple-color mb-4 me-2 lh-1' style={{fontSize: '8px'}}>
          {betaWord}
        </span>
      </div>
    </>
  )
}
export {CardsWidget20}
