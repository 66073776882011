// import {initializeApp} from 'firebase/app'
import {getFirestore} from '@firebase/firestore'
import {getAuth} from '@firebase/auth'
import {initializeApp} from '@firebase/app'
// import {getAuth} from 'firebase/auth'

const firebaseConfig = {
  apiKey: 'AIzaSyC7VAGZzpmudul3lVnLifkY5SZHFrB0CIY',
  authDomain: 'ktovli-c1604.firebaseapp.com',
  projectId: 'ktovli-c1604',
  storageBucket: 'ktovli-c1604.appspot.com',
  messagingSenderId: '356011700016',
  appId: '1:356011700016:web:3088603b1f2b3a689862f5',
  measurementId: 'G-F3KEN2PN9T',
}
// Initialize Firebase
export const app = initializeApp(firebaseConfig)
export const firestore = getFirestore(app)
export const auth = getAuth(app)
