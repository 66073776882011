import {useEffect} from 'react'
import {useIntl} from 'react-intl'
import {toAbsoluteUrl} from '../../../../helpers'
import {SidebarMenuItem} from './SidebarMenuItem'
import SideBarPlan from './sidebar-plans'
import UpgradePlanBtn from './upgrade-plan-btn'
import {collection, query, where, getCountFromServer} from '@firebase/firestore'
import {firestore} from '../../../../../firebase/FirebaseConfig'
import {useDispatch, useSelector} from 'react-redux'
import {articleActions} from '../../../../../store/articleSlice/articleReducers'

const SidebarMenuMain = () => {
  const dispatch = useDispatch()

  const {user, accessToken: userId} = useSelector((state: any) => state.login)
  const {articleCount} = useSelector((state: any) => state.article)
  const intl = useIntl()
  const getData = async () => {
    const documentsScanned = await getCountFromServer(
      query(collection(firestore, 'articles'), where('user_id', '==', userId))
    )
    const toaltCounts = documentsScanned.data().count
    dispatch(articleActions.setArticleCount(toaltCounts))
    console.log({toaltCounts})
  }
  useEffect(() => {
    getData()
  })

  const SIDEBAR_PLANS = [
    {
      id: 1,
      title: intl.formatMessage({id: 'CURRENT_PLAN'}),
      value: intl.formatMessage({id: user?.plan || 'FREE'}),
      icon: <img alt='icon' src={toAbsoluteUrl('/media/dashboard/freeIcon.svg')} />,
    },
    {
      id: 2,
      title: intl.formatMessage({id: 'MONTHLY_CREDIT'}),
      value: `${user?.credits < 0 ? 0 : user?.credits || 0}/10000`,
      icon: <img alt='icon' src={toAbsoluteUrl('/media/dashboard/monthIcon.svg')} />,
    },
    {
      id: 1,
      title: intl.formatMessage({id: 'ITEMS_GENERATED'}),
      value: articleCount ? articleCount : '--',
      icon: <img alt='icon' src={toAbsoluteUrl('/media/dashboard/guaranteeIcon.svg')} />,
    },
  ]

  return (
    <>
      <SidebarMenuItem
        to='/dashboard'
        icon='/dashboard.svg'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      />
      <SidebarMenuItem
        to='/history'
        icon='/media/icons/duotune/general/history.svg'
        title={intl.formatMessage({id: 'ARTICLE_HISTORY'})}
        fontIcon='bi-layers'
      />
      <SidebarMenuItem
        to='/setting'
        icon='/setting.svg'
        title={intl.formatMessage({id: 'SETTING'})}
        fontIcon='bi-layers'
      />

      <br />
      <br />
      <div
        style={{
          marginTop: '50px',
          display: 'flex',
          flexDirection: 'column',
          gap: '10px',
        }}
      >
        {SIDEBAR_PLANS?.map((value, index) => (
          <SideBarPlan key={`${index}---sideplans`} {...value} />
        ))}
      </div>
      <br />
      <br />
      <br />
      <UpgradePlanBtn
        btnStyle={{
          display: 'flex',
          justifyContent: 'space-between',
          paddingLeft: '15px',
        }}
      />
    </>
  )
}

export {SidebarMenuMain}