import {useState} from 'react'
import {useIntl} from 'react-intl'
import {useDispatch, useSelector} from 'react-redux'
import {useParams} from 'react-router-dom'
import {toast} from 'react-toastify'
import {speedActions} from '../../../store/speedSlice/speedReducers'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
const Cross_Tag = toAbsoluteUrl('/media/spa/Cross_Tag.png')

export default function TagInput() {
  const params = useParams()
  const contentId = Number(params.contentId)
  const dispatch = useDispatch()
  const [newTag, setNewTag] = useState('')
  const intl = useIntl()
  const {Keywords} = useSelector((state) => state.speed.articles[contentId])
  const handleInputChange = (e) => {
    setNewTag(e.target.value)
  }

  const handleAddClick = () => {
    if (newTag.trim() !== '') {
      if (!Keywords || Object.keys(Keywords).length === 0) {
        dispatch(
          speedActions.setTags({
            value: newTag,
            key: 1,
            index: contentId,
          })
        )
      }
      if (Keywords && Object.keys(Keywords).length < 5) {
        const newKey = Object.keys(Keywords).length + 1
        dispatch(
          speedActions.setTags({
            value: newTag,
            key: newKey,
            index: contentId,
          })
        )
      } else {
        toast.error(intl.formatMessage({id: 'KEYWORD_LIMIT'}))
      }
      setNewTag('')
    }
  }

  const handleTagDelete = (key) => {
    const updatedTags = Object.keys(Keywords)
      .filter((tagKey) => tagKey !== key)
      .map((tagKey, index) => ({
        [index + 1]: Keywords[tagKey],
      }))

    const newTags = Object.assign({}, ...updatedTags)
    dispatch(speedActions.setTags({index: contentId, value: newTags}))
  }
  const handleInputKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleAddClick()
    }
  }

  return (
    <div className='t-text-black t-mt-2 t-flex t-flex-col t-gap-2'>
      <div className='t-border t-border-[#CBD5E1] t-w-full t-flex t-items-center t-h-[40px] 470:t-h-[65px] t-rounded-lg t-bg-[#F8FAFC] t-px-5'>
        <input
          type='text'
          value={newTag}
          onChange={handleInputChange}
          placeholder={intl.formatMessage({id: 'TAG_PLACEHOLDER'})}
          className='t-bg-transparent t-w-full t-text-[13px] 470:t-text-[16px] '
          onKeyDown={handleInputKeyDown}
        />
        <button
          className='t-whitespace-nowrap t-text-[#592E84] t-font-medium t-text-[13px] 470:t-text-[16px]'
          onClick={handleAddClick}
        >
          {intl.formatMessage({id: 'ADD'})}
        </button>
      </div>
      <div className='t-flex t-flex-wrap t-gap-2'>
        {Keywords &&
          Object.keys(Keywords).map((key) => (
            <div
              className='t-text-white t-bg-[#592E84] t-px-2 py-[3px] sm:py-[2px] t-text-[9px] 470:t-text-[13px] t-rounded-full t-flex t-items-center t-gap-1 t-cursor-pointer'
              key={key}
              onClick={() => handleTagDelete(key)}
            >
              <p>{Keywords[key]} </p>
              <img
                src={Cross_Tag}
                width={100}
                height={100}
                alt='cross'
                className='t-w-[12px] t-h-[12px]'
              />
            </div>
          ))}
      </div>
    </div>
  )
}
