import {useState} from 'react'
import {useSelector} from 'react-redux'
import {setLanguage, useLang} from '../_metronic/i18n/Metronici18n'

export default function CustomDropdown({options, onSelect, width}) {
  const [isOpen, setIsOpen] = useState(false)
  const [selectedOption, setSelectedOption] = useState(null)

  const lang = useLang()
  const currentLanguage = options.find((x) => x.code === lang)

  // const handleOptionClick = (option) => {
  //   setSelectedOption(option)
  //   onSelect(option)
  //   setIsOpen(false)
  // }
  const handleSetLanguage = (language) => {
    setLanguage(language.code)
    document.body.classList.remove(currentLanguage?.sidebarClass || 'sidebar-left')
    document.body.classList.add(language.sidebarClass)
    // onSelect(language)
    setIsOpen(false)
  }

  return (
    <div className='t-relative t-text-black'>
      <div
        className='t-flex t-items-center t-justify-between t-border t-border-gray-300 t-rounded-sm t-px-1 t-h-[20px] t-cursor-pointer'
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className='t-text-[#592E84] t-font-medium t-text-[12px]'>
          {/* {lang ? lang.charAt(0).toUpperCase() + lang.slice(1) : selectedOption} */}
          {lang}
        </span>
        <svg className='t-w-4 t-h-4 t-mt-1 t-fill-current t-text-black' viewBox='0 0 20 20'>
          <path
            fillRule='evenodd'
            d='M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z'
            clipRule='evenodd'
          ></path>
        </svg>
      </div>
      {isOpen && (
        <div
          className={`${
            width ? width : ' t-w-32 '
          } t-absolute t-z-10 t-right-0  t-mt-2 t-bg-white t-border t-border-gray-300 t-rounded-md t-shadow-lg`}
        >
          {options.map((option, index) => (
            <div
              key={index}
              className='t-px-3 t-py-2 t-cursor-pointer hover:t-bg-gray-100 t-rounded-md'
              onClick={() => handleSetLanguage(option)}
            >
              {option.name}
            </div>
          ))}
        </div>
      )}
    </div>
  )
}
