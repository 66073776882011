import React from 'react'
import PrimaryButton from './buttons/PrimaryButton'
import {useSelector} from 'react-redux'
import SecondaryButton from './buttons/SecondaryButton'
import {useIntl} from 'react-intl'
import {useLang} from '../_metronic/i18n/Metronici18n'

const PopAlert = ({isLoading, setIsAlert, setIsRedirect, handleRedirect}) => {
  const intl = useIntl()
  const lang = useLang()
  return (
    <div
      onClick={() => setIsAlert(false)}
      className='t-fixed t-top-0 t-left-0 t-h-[100vh] t-w-[100vw] t-bg-[rgb(0,0,0,0.5)] t-z-[9999] t-flex t-justify-center t-items-center'
    >
      <div
        onClick={(e) => e.stopPropagation()}
        className='t-bg-white t-text-black t-w-[95%] 470:t-w-[400px] rounded-xl p-5 t-flex t-justify-center t-items-center'
      >
        <div className='t-text-center t-flex t-flex-col t-items-center t-gap-7 t-h-full'>
          <p className='t-text-[#303038] t-font-medium '>
            {intl.formatMessage({id: 'ALERT_TEXT'})}
          </p>
          <div
            className='t-flex t-items-center t-gap-3 t-w-full t-font-medium t-px-2'
            dir={lang == 'en' ? 'ltr' : 'rtl'}
          >
            {' '}
            <PrimaryButton
              text={intl.formatMessage({id: 'NO_TEXT'})}
              className='t-bg-white !t-text-[#303038] t-border t-w-full'
              onClick={() => {
                setIsAlert(false)
              }}
            />
            <SecondaryButton
              isLoading={isLoading}
              className='t-border t-border-[#592E84] t-w-full '
              text={intl.formatMessage({id: 'YES_TEXT'})}
              onClick={handleRedirect}
              textClass='t-text-[15px]'
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default PopAlert
