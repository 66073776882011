import {Suspense, useEffect} from 'react'
import {Outlet, useSearchParams} from 'react-router-dom'
import {I18nProvider} from '../_metronic/i18n/i18nProvider'
import {setLanguage} from '../_metronic/i18n/Metronici18n'
import {LayoutProvider, LayoutSplashScreen} from '../_metronic/layout/core'
import {MasterInit} from '../_metronic/layout/MasterInit'
import {AuthInit} from './modules/auth'

const App = () => {
  const [searchParams, setSearchParams] = useSearchParams()

  useEffect(() => {
    const params = new URLSearchParams(searchParams)
    const queryLanguage = params.get('lang')

    if (queryLanguage) {
      params.delete('lang')
      setSearchParams(params.toString())
      setLanguage(queryLanguage)
    }
  }, [searchParams, setSearchParams])
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <I18nProvider>
        <LayoutProvider>
          <AuthInit>
            <Outlet />
            <MasterInit />
            {/* <div id='portal' /> */}
          </AuthInit>
        </LayoutProvider>
      </I18nProvider>
    </Suspense>
  )
}

export {App}
