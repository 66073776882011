import {useIntl} from 'react-intl'
import {Fragment} from 'react'
import {toAbsoluteUrl} from '../../_metronic/helpers'
// import {Menu,} from '@headlessui/react'
import {Menu, Transition} from '@headlessui/react'
import {Tooltip} from '@material-tailwind/react'

// import {Menu, Transition} from '@headlessui/react'
const INFOICON = toAbsoluteUrl('/media/spa/info.png')
const DROPDOWNICON = toAbsoluteUrl('/media/spa/dropdownIcon.png')

const Dropdown = ({
  value,
  setValue,
  options,
  showIcon,
  isLanguages,
  className,
  selectClassName,
  title,
  tooltip,
  showInfoIcon,
  disable,
}) => {
  const intl = useIntl()
  return (
    <div className='t-flex t-flex-col t-w-full'>
      <div className='t-flex t-flex-row t-pr-3 t-py-1 t-gap-2 t-items-center t-text-sm xl:t-text-[16px] t-font-medium t-text-[#303038]  '>
        {title}
        {/* {showInfoIcon && (
          <Tooltip content={tooltip} placement='top-end' className='tooltip'>
            <div>
              <img src={INFOICON} alt='' height={15} width={15} />
            </div>
          </Tooltip>
        )} */}
      </div>
      <Menu as='div' className='t-relative t-inline-block t-text-left t-pb-2 t-mt-1'>
        <div className=''>
          <Menu.Button
            className={`t-inline-flex t-w-full t-text-xs xl:t-text-[16px] t-text-center t-justify-between t-items-center t-rounded-lg ${
              !disable ? 't-bg-[#FFFFFF]' : 't-bg-[#efeded]'
            }  t-bg-opacity-20 t-px-4 t-py-2 t-font-medium t-text-black t-border t-border-[#CBD5E1] t-h-[42px] xl:t-h-[50px] `}
            disabled={disable}
            // displayName="hello"
          >
            {showIcon ? (
              <div className='t-flex t-gap-1 t-items-center '>
                <img src={value?.flag} alt='' height={20} width={20} className='t-mr-1' />
                {value && intl.formatMessage({id: value.translate})}
              </div>
            ) : (
              value && intl.formatMessage({id: value.translate})
            )}
            <img src={DROPDOWNICON} alt='' height={20} width={20} className='' />
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter='transition ease-out duration-100'
          enterFrom='transform opacity-0 scale-95'
          enterTo='transform opacity-100 scale-100'
          leave='transition ease-in duration-75'
          leaveFrom='transform opacity-100 scale-100'
          leaveTo='transform opacity-0 scale-95'
        >
          <Menu.Items className='t-absolute t-z-50 t-mt-2  t-origin-top-right t-divide-y t-divide-gray-100 t-rounded-md t-bg-white t-shadow-lg t-ring-1 t-ring-black t-ring-opacity-5 focus:t-outline-none  t-w-full'>
            {options?.map((opt, index) => {
              return (
                <div className='t-px-1 t-py-1' key={index}>
                  <Menu.Item>
                    {({active}) => (
                      <button
                        onClick={() => {
                          setValue(opt)
                        }}
                        className={`${
                          active ? 't-bg-[#592E84] t-text-white' : 't-text-gray-900'
                        } t-group t-flex t-w-full t-items-center t-rounded-md t-px-2 t-py-2 t-text-[11px]`}
                      >
                        {showIcon && (
                          <>
                            <img src={opt?.flag} alt='' height={20} width={20} className='t-pr-1' />
                          </>
                        )}
                        {intl.formatMessage({id: opt?.translate})}
                      </button>
                    )}
                  </Menu.Item>
                </div>
              )
            })}
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  )
}

export default Dropdown
