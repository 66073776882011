import React, {useState} from 'react'
import {Button, Modal} from 'react-bootstrap'
import {toAbsoluteUrl} from '../../../helpers'

function DeleteConfirmationModal(props: any) {
  const {showModal, setShowModal, handleConfirmDelete} = props
  const handleCloseModal = () => setShowModal(false)
  const onConfimDelete = () => {
    handleConfirmDelete()
    handleCloseModal()
  }

  return (
    <>
      <style>
        {`
        .modal-footer {
        display: flex;
      }

.modal-footer .btn {
  width: 170px;
}



.modal-content{
  width: 400px
}



`}
      </style>

      <Modal
        style={{
          display: 'flex !important',
          alignItems: 'center',
          textAlign: 'center',
          top: '30%',
          left: '10%',
        }}
        show={showModal}
        onHide={handleCloseModal}
      >
        <img
          alt='icon'
          src={toAbsoluteUrl('/redInfo.svg')}
          style={{width: '48px', height: '48px', margin: 'auto', marginTop: '20px'}}
        />
        <Modal.Body>
          <p style={{fontSize: '14px', fontWeight: 700}}>Are you sure to delete this Article?</p>
        </Modal.Body>
        <Modal.Footer style={{borderTop: 'none'}}>
          <Button
            variant='secondary'
            style={{backgroundColor: '#FFFFFF', border: '1px solid #D0D5DD', fontWeight: 700}}
            onClick={handleCloseModal}
          >
            Cancel
          </Button>
          <Button
            style={{backgroundColor: '#D92D20', fontWeight: 700}}
            variant='danger'
            onClick={onConfimDelete}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default DeleteConfirmationModal
