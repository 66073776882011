// import setLanguage from 'next-translate/setLanguage'
import {useEffect, useRef, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
// import {activateAccount, logout, setUserInfo} from 'store/authSlice/authActions'
import MobileNavbar from './MobileNavbar'
import {doc, getDoc, onSnapshot} from 'firebase/firestore'
import {useNavigate} from 'react-router-dom'
import {useLang} from '../../../_metronic/i18n/Metronici18n'
import NavBar from './Navbar'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
// import IdleTimerContainer from '../IdleTimerContainer'
import {Loader} from '../../../SharedComponents/Loader'
import TabCloseListener from '../../../SharedComponents/utils/TabCloseListener'
import {firestore} from '../../../firebase/FirebaseConfig'
const AUTHLOADER = toAbsoluteUrl('/media/spa/LoaderAuth.png')

const NOT_NAV_SCREENS = [
  // "/signup",
  // "/login",
  // "/forgotPassword",
  // "/resetPassword",
  '/login-to-spa',
]
const NON_PROTECTED = ['/activation']
const AUTH_PROTECTED = ['/settings', '/saved-post', '/new-template/[articleId]', '/']
// asd
export async function getServerSideProps(context) {
  return {
    props: {}, // will be passed to the page component as props
  }
}

const AuthResticted = ({children}) => {
  const dispatch = useDispatch()

  const router = useNavigate()
  const prevPath = router.asPath !== router.pathname ? document.referrer : null

  // const {userId} = router.query
  const searchParams = new URLSearchParams(router.asPath)
  const [isValid, setIsValid] = useState(null)
  const [loading, setLoading] = useState(false)

  ////////////////////

  // const handleLogin = async (data) => {
  //   if (data) {
  //     if (data != undefined && data.isLoggedIn) {
  //       // dispatch(setUserInfo(data, userId, authToken))
  //     }
  //   }
  // }

  const params = {}
  for (let p of searchParams) {
    params[p[0]] = p[1]
  }

  const lang = useLang()
  const [checkedRoute, setCheckedRoute] = useState(false)
  const [open, setOpen] = useState(true)

  const moveToDashBoard = () => {
    window.location.href = process.env.NEXT_PUBLIC_API_DASHBOARD_URL
  }

  useEffect(() => {
    persistLocaleCookie(lang)
  }, [lang])

  function persistLocaleCookie(customLocale) {
    // setLanguage(customLocale)
  }

  useEffect(() => {
    /** managing dashboard auth with the SPA auth
       we have to manually set the authorization token with the firebase state "isLoggedIn"
    **/
    async function checkCorrectRoute() {
      if (!router.isReady) return
      let storageToken = localStorage.getItem('authToken')
      setLoading(true)
      // if (!userId && !localStorage.getItem('access_token')) {
      if (!localStorage.getItem('access_token')) {
        moveToDashBoard()
        return
      }
      if (localStorage.getItem('access_token')) {
        // if (userId || localStorage.getItem('access_token')) {
        // let currentUserId = userId || localStorage.getItem('access_token')
        let currentUserId = localStorage.getItem('access_token')
        const docRef = doc(firestore, 'users', currentUserId)
        const unsubscribe = onSnapshot(docRef, (snapshot) => {
          const data = snapshot.data()
          // if (data?.isLoggedIn) {
          //   setIsValid(true)
          //   setLoading(false)
          //   handleLogin(data)
          // } else {
          //   setIsValid(false)
          //   // dispatch(logout())
          // }
        })
        return () => {
          unsubscribe()
        }
      }
    }
    checkCorrectRoute()
  }, [router.isReady])

  if (loading) {
    return (
      <div className='t-flex t-flex-col t-items-center t-justify-center t-w-screen t-h-screen t-bg-white'>
        <div className='t-flex t-flex-col t-bg-white t-justify-center t-items-center'>
          <img
            src={AUTHLOADER}
            alt=''
            className='t-pt-2 t-pb-1 t-items-center t-w-[200px] t-h-[320px]'
            style={{overflow: 'hidden'}}
          />
          <Loader className='' size='t-w-10 t-h-10' fill='#592E84' />
        </div>
      </div>
    )
  }

  return (
    <>
      <div id='portal' />
      {/* {NOT_NAV_SCREENS.includes(router.pathname) && (
        <AuthLayout>{children}</AuthLayout>
      )} */}
      <TabCloseListener />
      {router?.asPath?.includes(NON_PROTECTED) && (
        <div className='t-h-[100vh] t-w-[100vw] t-flex  t-bg-[green]'>{children}</div>
      )}

      {/* {AUTH_PROTECTED?.includes(router.pathname) && isValid && ( */}
      <div className='t-flex t-flex-col t-justify-start t-items-start t-bg-[#F8FAFC]'>
        <NavBar open={open} setOpen={setOpen} />
        <MobileNavbar open={open} setOpen={setOpen} />
        {/* <IdleTimerContainer /> */}
        <div
          className={`t-flex t-flex-col t-items-center t-justify-between t-h-full t-ease-in-out t-duration-300 t-w-full `}
        >
          {/* <Header open={open} setOpen={setOpen} /> */}
          {children}
          {/* <Footer open={open} setOpen={setOpen} /> */}
        </div>
      </div>
      {/* )} */}
      {/* <div className='t-h-[100vh] t-w-[100vw] t-flex t-flex-row t-justify-center t-items-start'>
        {children}
      </div> */}
    </>
  )
}

export default AuthResticted
