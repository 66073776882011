import {toast} from 'react-toastify'
import {articleActions} from './articleReducers'
import {
  CREATE_TITLE,
  GET_FEEDBACK,
  REPHRASE_ARTICLE,
  CREATE_INTRO,
  CREATE_OUTLINES,
  CREATE_ARTICLE,
  IMPROVE_TEXT,
  ARTICLE_REPHRASE_TEXT,
  REPHRASE_OUTLINES,
} from '../../SharedComponents/api/EndPoints'
import axiosInstance from '../../SharedComponents/api'

export const setUserCreds = (userData, action) => async (dispatch) => {
  try {
    dispatch(articleActions.setCurrUserInfo(userData))
  } catch (error) {
    dispatch(articleActions.setLoader(userData.id > -1 ? {value: false, id: userData.id} : false))
  }
}

export const addNewArticle = (id, _) => async (dispatch) => {
  try {
    dispatch(articleActions.addNewArticle(id))
  } catch (error) {}
}

export const setContentFilters = (data, action) => async (dispatch) => {
  try {
    dispatch(articleActions.setLoader(data.id > -1 ? {value: true, id: data.id} : true))
    dispatch(articleActions.setContentFiltersData(data))
    dispatch(articleActions.setLoader(data.id > -1 ? {value: false, id: data.id} : false))
  } catch (error) {
    dispatch(articleActions.setLoader(data.id > -1 ? {value: false, id: data.id} : false))
  }
}
export const setOutputDataCount = (data, action) => async (dispatch) => {
  try {
    dispatch(articleActions.setLoader(data.id > -1 ? {value: true, id: data.id} : true))
    dispatch(articleActions.setOutputDataCountValue(data))
    dispatch(articleActions.setLoader(data.id > -1 ? {value: false, id: data.id} : false))
  } catch (error) {
    dispatch(articleActions.setLoader(data.id > -1 ? {value: false, id: data.id} : false))
  }
}

export const createTitle = (data, action, intl) => async (dispatch) => {
  try {
    dispatch(
      articleActions.setLoader(
        data.id > -1
          ? {
              id: data.id,
              value: true,
            }
          : true
      )
    )

    dispatch(articleActions.setTopic(data?.topic))
    dispatch(
      articleActions.setKeywords(data.id ? {value: data?.keywords, id: data.id} : data?.keywords)
    )

    await axiosInstance.post(CREATE_TITLE, data).then((response) => {
      dispatch(articleActions.setCreateTitleData({...response?.data, id: data.id}))

      dispatch(
        articleActions.setLoader(
          data.id > -1
            ? {
                id: data.id,
                value: false,
              }
            : false
        )
      )

      action && action(response?.data?.credits)
    })
  } catch (error) {
    dispatch(
      articleActions.setLoader(
        data.id > -1
          ? {
              id: data.id,
              value: false,
            }
          : false
      )
    )
    toast.error(intl.formatMessage({id: 'SOMETHING_WENT_WRONG'}))
  }
}
export const reCreateTitle = (data, updateCredits, intl) => async (dispatch) => {
  try {
    dispatch(articleActions.setLoader(true))
    await axiosInstance.post(CREATE_TITLE, data).then((response) => {
      dispatch(articleActions.setCreateTitleData({...response?.data, id: data.id}))
      updateCredits && updateCredits(response?.data?.credits)
      dispatch(articleActions.setLoader(data.id > -1 ? {value: false, id: data.id} : false))
    })
  } catch (error) {
    dispatch(articleActions.setLoader(data.id > -1 ? {value: false, id: data.id} : false))
    toast.error(intl.formatMessage({id: 'SOMETHING_WENT_WRONG'}))
  }
}

export const createIntros = (data, action, intl) => async (dispatch) => {
  try {
    dispatch(articleActions.setLoader(data.id > -1 ? {value: true, id: data.id} : true))
    const tempObject = {...data}
    delete tempObject.id
    await axiosInstance.post(CREATE_INTRO, tempObject).then((response) => {
      dispatch(articleActions.setCreateIntrosData({...response?.data, id: data.id}))
      action && action(response?.data?.credits)
    })
    dispatch(articleActions.setLoader(data?.id > -1 ? {value: false, id: data.id} : false))
  } catch (error) {
    dispatch(articleActions.setLoader(data.id > -1 ? {value: false, id: data.id} : false))
    toast.error(intl.formatMessage({id: 'SOMETHING_WENT_WRONG'}))
  }
}
export const reCreateIntros = (data, updateCredits, intl) => async (dispatch) => {
  try {
    dispatch(articleActions.setLoader(data.id > -1 ? {value: true, id: data.id} : true))
    await axiosInstance.post(CREATE_INTRO, data).then((response) => {
      dispatch(articleActions.setCreateIntrosData({...response?.data, id: data.id}))
      dispatch(articleActions.setLoader(data.id > -1 ? {value: false, id: data.id} : false))
      updateCredits && updateCredits(response?.data?.credits)
    })
  } catch (error) {
    dispatch(articleActions.setLoader(data.id > -1 ? {value: false, id: data.id} : false))
    toast.error(intl.formatMessage({id: 'SOMETHING_WENT_WRONG'}))
  }
}

export const createOutlines = (data, action, intl) => async (dispatch) => {
  try {
    dispatch(articleActions.setLoader(data.id > -1 ? {value: true, id: data.id} : true))
    await axiosInstance.post(CREATE_OUTLINES, data).then((response) => {
      dispatch(articleActions.setCreateOutlinesData({...response?.data, id: data.id}))
      action && action(response?.data?.credits)
    })
    dispatch(articleActions.setLoader(data.id > -1 ? {value: false, id: data.id} : false))
  } catch (error) {
    dispatch(articleActions.setLoader(data.id > -1 ? {value: false, id: data.id} : false))
    toast.error(intl.formatMessage({id: 'SOMETHING_WENT_WRONG'}))
  }
}
export const reCreateOutlines = (data, updateCredits, intl) => async (dispatch) => {
  try {
    dispatch(articleActions.setLoader(data.id > -1 ? {value: true, id: data.id} : true))
    await axiosInstance.post(CREATE_OUTLINES, data).then((response) => {
      dispatch(
        articleActions.setCreateOutlinesData({
          ...response?.data,
          id: data.id,
        })
      )
      updateCredits && updateCredits(response?.data?.credits)
    })
    dispatch(articleActions.setLoader(data.id > -1 ? {value: false, id: data.id} : false))
  } catch (error) {
    dispatch(articleActions.setLoader(data.id > -1 ? {value: false, id: data.id} : false))
    toast.error(intl.formatMessage({id: 'SOMETHING_WENT_WRONG'}))
  }
}
export const createArticle = (data, action, intl) => async (dispatch) => {
  try {
    dispatch(articleActions.setLoader(data.id > -1 ? {value: true, id: data.id} : true))

    let payload;

    const isLike = ("like" in data.chosen_outlines) && ("like" in data.chosen_outlines_en)

    if (isLike) {
      payload = {
        ...data,
        chosen_outlines:  {...data.chosen_outlines.outlineobj},
        chosen_outlines_en:  {...data.chosen_outlines_en.outlineobj_en}
      };
    } else {
      payload = data;
    }
    console.log("createArticle for regeneration",payload)
    await axiosInstance.post(CREATE_ARTICLE, payload).then((response) => {
       dispatch(articleActions.setCreateArticleData({...response?.data, id: data.id}))
      action && action(response?.data?.credits)
    })
    dispatch(articleActions.setLoader(data.id > -1 ? {value: false, id: data.id} : false))
  } catch (error) {
    dispatch(articleActions.setLoader(data.id > -1 ? {value: false, id: data.id} : false))
    toast.error(intl.formatMessage({id: 'SOMETHING_WENT_WRONG'}))
  }
}

export const getUserFeedback = (data, intl) => async (dispatch) => {
  try {
    dispatch(articleActions.setLoaderAction(data.id ? {value: true, id: data.id} : true))
    let feedbackData = {
      user_id: data?.user_id,
      text: data.text,
      type: data.type,
    }
    await axiosInstance.post(GET_FEEDBACK, feedbackData).then((response) => {
      toast.success(intl.formatMessage({id: 'THANKS_FOR_FEEDBACK'}))
      dispatch(articleActions.setFeedback(data))
    })
    dispatch(articleActions.setLoaderAction(data.id ? {value: false, id: data.id} : false))
  } catch (error) {
    dispatch(articleActions.setLoaderAction(data.id ? {value: false, id: data.id} : false))
    toast.error(intl.formatMessage({id: 'SOMETHING_WENT_WRONG'}))
  }
}
export const getUserIntrosFeedback = (data, intl) => async (dispatch) => {
  try {
    dispatch(articleActions.setLoaderAction(data.id ? {value: true, id: data.id} : true))
    let feedbackData = {
      user_id: data?.user_id,
      text: data.text,
      type: data.type,
    }
    await axiosInstance.post(GET_FEEDBACK, feedbackData).then((response) => {
      toast.success(intl.formatMessage({id: 'THANKS_FOR_FEEDBACK'}))
      dispatch(articleActions.setIntrosFeedback(data))
    })
    dispatch(articleActions.setLoaderAction(data.id ? {value: false, id: data.id} : false))
  } catch (error) {
    dispatch(articleActions.setLoaderAction(data.id ? {value: false, id: data.id} : false))
    toast.error(intl.formatMessage({id: 'SOMETHING_WENT_WRONG'}))
  }
}
export const rephraseOutlineAPI = (data, intl) => async (dispatch) => {

  try {
    dispatch(articleActions.setRephrasing(data.id > -1 ? {value: true, id: data.id} : true))
    dispatch(articleActions.setLoaderAction(data.id > -1 ? {value: true, id: data.id} : true))
    const newData = {
      user_id: data.user_id,
      outlines: {},
      outlines_en: {},
      language_en: data.language_en,
    }

    const {outlineobj} = data.outlines
    const {outlineobj_en} = data.outlines_en

    
    const modifiedData = {
      user_id: data.user_id,
      language_en: data.language_en,
      outlines: outlineobj,
      outlines_en: outlineobj_en,
    }

    const response = await axiosInstance.post(REPHRASE_OUTLINES, modifiedData)    
    toast.success('Rephrase Outline Successful')

    dispatch(
      articleActions.setRephrasedOutlinedText({
        ...response.data,
        id: parseInt(data.id),
        key: parseInt(data.key),
      })
    )
    dispatch(articleActions.setRephrasing(data.id > -1 ? {value: false, id: data.id} : false))
    dispatch(articleActions.setLoaderAction(data.id > -1 ? {value: false, id: data.id} : false))
  } catch (error) {
    dispatch(articleActions.setRephrasing(data.id > -1 ? {value: false, id: data.id} : false))
    dispatch(articleActions.setLoaderAction(data.id > -1 ? {value: false, id: data.id} : false))
    toast.error(intl.formatMessage({id: 'SOMETHING_WENT_WRONG'}))
  }
}
export const rephrasePostArticle = (data, intl) => async (dispatch) => {
  try {
    dispatch(articleActions.setRephrasing(data.id > -1 ? {value: true, id: data.id} : true))
    dispatch(articleActions.setLoaderAction(data.id > -1 ? {value: true, id: data.id} : true))

    await axiosInstance.post(REPHRASE_ARTICLE, data).then((response) => {
      
      dispatch(articleActions.setRephrasedText({...response.data, id: data.id}))
      toast.success(response?.data?.response)
      dispatch(articleActions.setRephrasing(data.id > -1 ? {value: false, id: data.id} : false))
      dispatch(articleActions.setLoaderAction(data.id > -1 ? {value: false, id: data.id} : false))
    })
  } catch (error) {
    dispatch(articleActions.setRephrasing(data.id > -1 ? {value: false, id: data.id} : false))
    dispatch(articleActions.setLoaderAction(data.id > -1 ? {value: false, id: data.id} : false))
    toast.error(intl.formatMessage({id: 'SOMETHING_WENT_WRONG'}))
  }
}
export const rephraseOutlineArticle = (data, action, intl) => async (dispatch) => {
  try {
    dispatch(articleActions.setRephrasing(data.id ? {value: true, id: data.id} : true))
    dispatch(articleActions.setLoaderAction(data.id ? {value: true, id: data.id} : true))
    await axiosInstance.post(REPHRASE_ARTICLE, data).then((response) => {
      action && action(data.key, response.data)
      dispatch(articleActions.setRephrasing(data.id ? {value: false, id: data.id} : false))
      dispatch(articleActions.setLoaderAction(data.id ? {value: false, id: data.id} : false))
    })
  } catch (error) {
    dispatch(articleActions.setLoaderAction(data.id ? {value: false, id: data.id} : false))
    dispatch(articleActions.setRephrasing(data.id ? {value: false, id: data.id} : false))
    toast.error(intl.formatMessage({id: 'SOMETHING_WENT_WRONG'}))
  }
}

export const rephraseIntroArticle = (data, intl) => async (dispatch) => {
  try {
    dispatch(articleActions.setRephrasing(data.id > -1 ? {value: true, id: data.id} : true))
    dispatch(articleActions.setLoaderAction(data.id > -1 ? {value: true, id: data.id} : true))
    await axiosInstance.post(REPHRASE_ARTICLE, data).then((response) => {
      dispatch(articleActions.setRephrasedIntroText({...response.data, id: data.id}))
      toast.success(response?.data?.response)
      dispatch(articleActions.setRephrasing(data.id > -1 ? {value: false, id: data.id} : false))
      dispatch(articleActions.setLoaderAction(data.id > -1 ? {value: false, id: data.id} : false))
    })
  } catch (error) {
    dispatch(articleActions.setRephrasing(data.id > -1 ? {value: false, id: data.id} : false))
    dispatch(articleActions.setLoaderAction(data.id > -1 ? {value: false, id: data.id} : false))
    toast.error(intl.formatMessage({id: 'SOMETHING_WENT_WRONG'}))
  }
}

export const improveArticeText = (data, action, intl) => async (dispatch) => {
  try {
    dispatch(articleActions.setLoaderAction(data.id ? {value: true, id: data.id} : true))
    await axiosInstance.post(IMPROVE_TEXT, data).then((response) => {
      action && action(response.data)
    })
    dispatch(articleActions.setLoaderAction(data.id ? {value: false, id: data.id} : false))
  } catch (error) {
    dispatch(articleActions.setLoaderAction(data.id ? {value: false, id: data.id} : false))

    toast.error(intl.formatMessage({id: 'SOMETHING_WENT_WRONG'}))
  }
}
export const articeRephraseText = (data, action, intl) => async (dispatch) => {
  try {
    dispatch(articleActions.setLoaderAction(data.id ? {value: true, id: data.id} : true))
    await axiosInstance.post(ARTICLE_REPHRASE_TEXT, data).then((response) => {
      action && action(response.data)
    })
    dispatch(articleActions.setLoaderAction(data.id ? {value: false, id: data.id} : false))
  } catch (error) {
    dispatch(articleActions.setLoaderAction(data.id ? {value: false, id: data.id} : false))

    toast.error(intl.formatMessage({id: 'SOMETHING_WENT_WRONG'}))
  }
}

// ------

export const getOutlinesFeedBack = (data, intl) => async (dispatch) => {
  try {
    await axiosInstance.post(GET_FEEDBACK, data).then((response) => {
      toast.success(intl.formatMessage({id: 'THANKS_FOR_FEEDBACK'}))
      dispatch(articleActions.setOutlinesFeedBack(data))
    })
  } catch (error) {
    toast.error(intl.formatMessage({id: 'SOMETHING_WENT_WRONG'}))
  }
}
