// import {Tooltip} from '@material-tailwind/react'
import {useSelector} from 'react-redux'
import {useParams} from 'react-router-dom'

const TextAreaInput = ({
  type,
  label,
  sublabel,
  name,
  disabled,
  InputClass,
  error,
  handleChange,
  placeholder,
  limit,
  value,
  mainClass,
  language,
  setError,
  ...rest
}) => {
  const params = useParams()
  const contentId = Number(params.contentId)
  const {language_content} = useSelector((state) => state.speed.articles[contentId])
  return (
    <div className={`${mainClass} `}>
      {label ? (
        <label
          htmlFor={name ? name : 'inputElement'}
          className={`t-flex t-font-medium t-text-[18px] t-flex-row t-items-center t-gap-2 ${
            error ? 't-text-[red]' : 't-text-BLACK'
          }`}
        >
          {label}
        </label>
      ) : null}
      {sublabel ? (
        <label
          htmlFor={name ? name : 'inputElement'}
          className={`t-flex t-font-medium t-text-[14px] md:t-text-[16px] t-flex-row t-items-center t-gap-2 ${
            error ? 't-text-[red]' : 't-text-[#7C7C7C]'
          }`}
        >
          {sublabel}
        </label>
      ) : null}
      <div className={`t-bg-white t-flex t-flex-row t-items-center t-mt-1 t-w-full t-rounded-md`}>
        <textarea
          value={value}
          className={`t-min-h-[100px] 470:t-min-h-[135px] t-text-[13px] sm:t-text-[16px] t-bg-[#F8FAFC] t-mt-1 t-text-black  t-border t-border-[##E5E7EB] t-rounded-md t-py-3 t-px-2 390:t-px-4 t-placeholder-[#B0B0B0] t-placeholder:   t-w-full t-appearance-none focus:t-outline-none focus:t-shadow-outline ${InputClass}`}
          onChange={handleChange}
          placeholder={placeholder}
          dir={language_content?.direction}
          {...rest}
        />
      </div>
    </div>
  )
}
export default TextAreaInput
