import {Loader} from '../Loader'

const SecondaryButton = ({
  iconPosition,
  text,
  className,
  width,
  onClick,
  textClass,
  type,
  isLoading,
  isIcon,
  icon,
}) => {
  return (
    <div
      className={`t-bg-[#592E84] t-text-WHITE t-text-sm lg:t-text-sm t-px-4 t-py-2 lg:t-px-4 t-flex t-items-center t-justify-center t-rounded-[0.5rem] ${
        isLoading ? 't-pointer-events-none t-cursor-default' : 't-cursor-pointer'
      } ${className} ${width ? width : 't-w-full'}`}
      onClick={onClick}
      type={type}
    >
      <div
        dir={iconPosition == 1 ? 'rtl' : 'ltr'}
        className='t-flex t-items-center t-justify-center t-gap-2'
      >
        {isLoading && (
          <div>{<Loader className='t-p-0 t-pt-0' size='t-w-4 t-h-4' fill='#58cfe4' />}</div>
        )}
        <p className={textClass}> {text}</p>{' '}
        {isIcon && <img src={icon} alt='' height={18} width={18} />}
      </div>
    </div>
  )
}

export default SecondaryButton
