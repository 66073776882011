/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect} from 'react'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {CardsWidget20} from '../../../_metronic/partials/widgets'
import {useIntl} from 'react-intl'
import {useDispatch, useSelector} from 'react-redux'
import {setLanguage, useLang} from '../../../_metronic/i18n/Metronici18n'
import {useMediaQuery} from 'react-responsive'
import {articleActions} from '../../../store/articleSlice/articleReducers'
import localStorage from 'redux-persist/es/storage'
import {useSearchParams} from 'react-router-dom'
import {speedActions} from '../../../store/speedSlice/speedReducers'

const DashboardWrapper: FC = () => {
  const [searchParams, setSearchParams] = useSearchParams()

  useEffect(() => {
    const params = new URLSearchParams(searchParams)
    const queryLanguage = params.get('lang')

    if (queryLanguage) {
      params.delete('lang')
      setSearchParams(params.toString())
      setLanguage(queryLanguage)
    }
  }, [searchParams, setSearchParams])

  const isTabletOrMobile = useMediaQuery({query: '(max-width: 992px)'})
  const is1250 = useMediaQuery({query: '(max-width: 1250px)'})
  const lang = useLang()
  const {user, accessToken: userId} = useSelector((state: any) => state.login)
  const intl = useIntl()
  const accessToken = localStorage && localStorage.getItem('authToken')
  const dispatch = useDispatch()
  console.log('lang', lang)
  const ACTIVE_CARDS = [
    {
      id: 1,
      title: intl.formatMessage({id: 'ARTICLE_GENERATOR'}),
      subTitle: '',
      type: 'Beta',
      secondIcon: true,
      isComingSoon: false,
      description: intl.formatMessage({id: 'ARTICLE_GENERATOR_PARA'}),
      betaWord: intl.formatMessage({id: 'BETA'}),
      handleClick: () => (
        localStorage.setItem('ArticleWriter', '1'),
        dispatch(articleActions.setArticleEmpty()),
        (window.location.href = '/articleWriter')
      ),
      normalText: false,
      firstIconBg: true,
      firstIcon: <img alt='icon' src={toAbsoluteUrl('/media/dashboard/page.svg')} />,
    },
    {
      id: 2,
      title: intl.formatMessage({id: 'QUICK_ARTICLE'}),
      subTitle: '',
      type: '',
      secondIcon: true,
      isComingSoon: false,
      description: intl.formatMessage({id: 'QUICK_ARTICLE_PARA'}),
      betaWord: intl.formatMessage({id: 'BETA'}),
      handleClick: () => {
        dispatch(speedActions.setArticleEmpty())
        const feature = 1
        const url = `/speedWriter/0?feature=${feature}`
        window.location.href = url
      },
      normalText: false,
      firstIconBg: true,
      firstIcon: <img alt='icon' src={toAbsoluteUrl('/lightning.svg')} />,
    },
    {
      id: 3,
      title: intl.formatMessage({id: 'FACEBOOK_AD'}),
      subTitle: '',
      type: '',
      secondIcon: true,
      isComingSoon: false,
      description: intl.formatMessage({id: 'FACEBOOK_AD_PARA'}),
      betaWord: intl.formatMessage({id: 'BETA'}),
      handleClick: () => {
        dispatch(speedActions.setArticleEmpty())
        const feature = 2
        const url = `/speedWriter/0?feature=${feature}`
        window.location.href = url
      },
      normalText: false,
      firstIconBg: true,
      firstIcon: <img alt='icon' src={toAbsoluteUrl('/media/dashboard/page.svg')} />,
    },
  ]

  const CARDS_LIST = [
    {
      id: 1,
      title: intl.formatMessage({id: 'CONTENT_WRITING'}),
      subTitle: '',
      type: '',
      // firstIcon: (
      //   <i className='fa-brands fa-facebook-f' style={{fontSize: '1.5rem', color: 'black'}} />
      // ),
      secondIcon: false,
      isComingSoon: true,
      description: intl.formatMessage({id: 'CONTENT_WRITING_PARA'}),
      betaWord: '',
      normalText: false,
      firstIconBg: true,
      firstIcon: <img alt='icon' src={toAbsoluteUrl('/content-rewriter.svg')} />,
    },
    {
      id: 2,
      title: intl.formatMessage({id: 'JOB_DESCRIPTION'}),
      subTitle: '',
      type: '',
      // firstIcon: (
      //   <i className='fa-brands fa-facebook-f' style={{fontSize: '1.5rem', color: 'black'}} />
      // ),
      secondIcon: false,
      isComingSoon: true,
      description: intl.formatMessage({id: 'JOB_DESCRIPTION_PARA'}),
      betaWord: '',
      normalText: false,
      firstIconBg: true,
      firstIcon: <img alt='icon' src={toAbsoluteUrl('/JD.svg')} />,
    },
    {
      id: 3,
      title: intl.formatMessage({id: 'CV'}),
      subTitle: '',
      type: '',
      // firstIcon: (
      //   <i className='fa-brands fa-facebook-f' style={{fontSize: '1.5rem', color: 'black'}} />
      // ),
      secondIcon: false,
      isComingSoon: true,
      description: intl.formatMessage({id: 'CV_PARA'}),
      betaWord: '',
      normalText: false,
      firstIconBg: true,
      firstIcon: <img alt='icon' src={toAbsoluteUrl('/cvIcon.svg')} />,
    },
    {
      id: 4,
      title: intl.formatMessage({id: 'TEXT_SUMMARY'}),
      subTitle: '',
      type: '',
      // firstIcon: (
      //   <i className='fa-brands fa-facebook-f' style={{fontSize: '1.5rem', color: 'black'}} />
      // ),
      secondIcon: false,
      isComingSoon: true,
      description: intl.formatMessage({id: 'TEXT_SUMMARY_PARA'}),
      betaWord: '',
      normalText: false,
      firstIconBg: true,
      firstIcon: <img alt='icon' src={toAbsoluteUrl('/text-summary.svg')} />,
    },
    {
      id: 5,
      title: intl.formatMessage({id: 'YAD_2'}),
      subTitle: '',
      type: '',
      // firstIcon: (
      //   <i className='fa-brands fa-facebook-f' style={{fontSize: '1.5rem', color: 'black'}} />
      // ),
      secondIcon: false,
      isComingSoon: true,
      description: intl.formatMessage({id: 'YAD_2_PARA'}),
      betaWord: '',
      normalText: false,
      firstIconBg: false,
      firstIcon: <img alt='icon' src={toAbsoluteUrl('/yadIcon.svg')} />,
    },
    {
      id: 6,
      title: intl.formatMessage({id: 'SOCIAL_MEDIA_POST'}),
      subTitle: '',
      type: '',
      // firstIcon: (
      //   <i className='fa-brands fa-facebook-f' style={{fontSize: '1.5rem', color: 'black'}} />
      // ),
      secondIcon: false,
      isComingSoon: true,
      description: intl.formatMessage({id: 'SOCIAL_MEDIA_POST_PARA'}),
      betaWord: '',
      normalText: true,
      firstIconBg: true,
      firstIcon: <img alt='icon' src={toAbsoluteUrl('/smPost.svg')} />,
    },
  ]
  return (
    <>
      <style>
        {`
        
        .comingSoonClass{
        @media screen and (max-width: 767px) {
          text-align: center;
        }
      }
      .firstIcon{
        width: 35px;
        height: 35px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #FAF7F9;
        border-radius: 6px;
      }
      `}
      </style>
      {/* <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle> */}

      <p className='fs-24px text-black' style={{fontStyle: 'italic', fontWeight: 600}}>
        {intl.formatMessage({id: 'HELLO'})}{' '}
        <span className='fs-32px' style={{color: '#592E84', fontWeight: 700}}>
          {user?.firstName ? user?.firstName : user?.name}
        </span>{' '}
        , {intl.formatMessage({id: 'CHOOSE_CONTENT_TYPE'})}
      </p>
      <br />
      <div className='t-flex t-flex-wrap t-gap-8 t-mb-4 t-justify-center t-w-fit'>
        {ACTIVE_CARDS.map((value, index) => (
          <div key={index}>
            <CardsWidget20
              className='h-md-100 mb-5 mb-xl-10 w-100 w-md-75 text-black'
              color='#F1416C'
              img={toAbsoluteUrl('/media/patterns/vector-1.png')}
              {...value}
            />
          </div>
        ))}
      </div>

      <p
        className='fs-24px comingSoonClass text-black'
        style={{fontStyle: 'italic', fontWeight: 700}}
      >
        {intl.formatMessage({id: 'SOON'})}
      </p>
      <br />
      <br />
      <div
        className={`t-flex t-flex-wrap t-p-[15px] t-gap-16 t-justify-${
          isTabletOrMobile ? 'center' : lang === 'heb' ? 'start' : 'start'
        } `}
        style={{
          position: isTabletOrMobile ? 'relative' : 'absolute',
          height: isTabletOrMobile ? '43vh' : '',
          overflow: isTabletOrMobile ? 'auto' : '',
          width: lang === 'heb' && !isTabletOrMobile ? '80%' : '',
        }}
      >
        {CARDS_LIST?.map((value, index) => (
          <div
            key={index}
            style={{
              marginBottom: '24px',
              marginTop: '24px',
              width: '190px !important',
              gap: '10px',
            }}
            className={` t-flex t-items-center t-justify-center`}
          >
            <CardsWidget20
              className='h-md-100 mb-5 mb-xl-10 w-100 w-md-75 text-black '
              color='#F1416C'
              img={toAbsoluteUrl('/media/patterns/vector-1.png')}
              {...value}
            />
          </div>
        ))}
      </div>

      {/* <DashboardPage /> */}
    </>
  )
}

export {DashboardWrapper}
