import React from 'react'
import {useIntl} from 'react-intl'
import {useSelector} from 'react-redux'
import {LANDINGPAGE_URL} from '../../../../../constants'
import {toAbsoluteUrl} from '../../../../helpers'
import {useMediaQuery} from 'react-responsive'
import {useLang} from '../../../../i18n/Metronici18n'

const UpgradePlanBtn = ({btnStyle}: any) => {
  const lang = useLang()
  const isTabletOrMobile = useMediaQuery({query: '(max-width: 992px)'})
  const {user, accessToken} = useSelector((state: any) => state.login)
  const intl = useIntl()
  return (
    <>
      {/* margin:auto auto auto 10px !important; */}
      <style>
        {`
        
        .upgradeplanBTN{
         background-color: #592E84 !important;
         font-size: 18px;
         font-weight: 600;
         width:180px;
         margin: ${
           lang !== 'heb' ? 'auto auto auto 10px !important' : 'auto 10px auto auto !important'
         };
        @media screen and (max-width: 992px) {
          margin: auto !important;
        }
        }

        .upgradeplanBTN:hover{
        background-color: #592E84 !important;
        font-size: 18px;
        font-weight: 600;
        width:180px;
        margin: ${
          lang !== 'heb' ? 'auto auto auto 10px !important' : 'auto 10px auto auto !important'
        };
        @media screen and (max-width: 992px) {
          margin: auto !important;
        }
        }
        .no-hover:not(:active):not(:focus):hover {
            background-color: #592E84 !important;
            font-size: 18px;
            font-weight: 600;
            width:180px;
            margin: ${
              lang !== 'heb' ? 'auto auto auto 10px !important' : 'auto 10px auto auto !important'
            };
            @media screen and (max-width: 992px) {
              margin: auto !important;
            }
          }
        `}
      </style>

      <p
        // href='/metronic8/react/demo1/dashboard'
        onClick={() =>
          window.open(
            `${LANDINGPAGE_URL}/pricing?userEmail=${user?.email}&userId=${accessToken}&lang=${lang}`,
            '_self'
          )
        }
        className='btn btn-primary upgradeplanBTN no-hover'
        role='button'
        aria-pressed='true'
        style={{
          ...btnStyle,
        }}
      >
        <img src={toAbsoluteUrl('/media/dashboard/thunder.svg')} alt='icon' />{' '}
        {intl.formatMessage({id: 'UPGRADE_PLAN'})}
      </p>
    </>
  )
}

export default UpgradePlanBtn
