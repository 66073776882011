import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useParams} from 'react-router-dom'
import {QuillProvider} from '../SharedComponents/context/QuillContext'
import {speedActions} from '../store/speedSlice/speedReducers'
import {useLang} from '../_metronic/i18n/Metronici18n'
import Footer from './components/layouts/Footer'
import {useStepper} from './hooks/useStepper'
import ArticlePage from './modules/ArticlePage'
import ContentRightBar from './modules/ContentRightBar'
import MultiPageStepper from './modules/MultiPageStepper'
import TopicPage from './modules/TopicPage'

const SpeedArticle = () => {
  const lang = useLang()
  const [error, setError] = useState(false)
  const {articles} = useSelector((state) => state.speed)
  const params = useParams()
  const dispatch = useDispatch()
  const contentId = Number(params.contentId)
  const {steps} = useSelector((state) => state.speed.articles[contentId])
  const stepper = useStepper(steps)

  const executeGoto = (index) => {
    stepper.goto(index)
  }
  useEffect(() => {
    if (!sessionStorage.getItem('firstVisit')) {
      dispatch(speedActions.setArticleEmpty())
      sessionStorage.setItem('firstVisit', true)
    }
  }, [])

  return (
    <div
      className={`t-relative t-text-[16px] t-w-full t-pt-2 sm:t-pt-2 t-px-8 md:t-px-24 t-ease-in-out t-duration-300 t-h-full t-flex t-flex-col t-justify-start t-gap-3 470:t-gap-2`}
    >
      <QuillProvider>
        <div
          className={`t-flex t-flex-col t-justify-between t-bg-white t-h-[70px] 470:t-h-[85px] ${
            lang == 'heb'
              ? ' t-pr-8 t-pl-2 470:t-pr-16 470:t-pl-8 '
              : 't-pl-8 t-pr-2 470:t-pl-16 470:t-pr-8 '
          }  md:t-px-24 t-py-2 t-drop-shadow t-border t-rounded-lg 470:t-rounded-xl  `}
        >
          <MultiPageStepper stepper={stepper} steps={steps} executeGoto={executeGoto} />
        </div>

        <div className='t-w-full t-grid t-grid-cols-2 lg:t-grid-cols-3 t-justify-between t-gap-5 '>
          <div
            className={`t-col-span-2 t-drop-shadow t-border t-bg-white t-rounded-lg 470:t-rounded-xl t-h-[calc(100vh-185px)] 470:t-h-[calc(100vh-179px)]`}
          >
            <div className=' t-bg-white-regular t-h-full t-overflow-y-auto'>
              {stepper.active == 0 && <TopicPage error={error} setError={setError} />}
              {stepper?.active === 1 && <ArticlePage />}
              <div className='t-flex t-flex-col t-gap-6 lg:t-hidden t-sticky t-bottom-0 t-bg-white t-px-2.5 390:t-px-5 sm:t-px-11 t-pb-2 md:t-pb-4'>
                <div className='t-h-[1px] t-w-full t-bg-[#CBD5E1]' />
                <Footer />
              </div>
            </div>
          </div>
          <div className='t-w-full t-hidden lg:t-block'>
            <ContentRightBar
              handleClick={() => alert('clicked')}
              language={articles[contentId].language_content}
              tone={articles[contentId].Tone_en}
              quality={articles[contentId].Quality_type_en}
              feature={articles[contentId].feature}
              stepper={stepper}
            />
          </div>
        </div>
      </QuillProvider>
    </div>
  )
}

export default SpeedArticle
