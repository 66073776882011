import {arrayUnion, collection, doc, setDoc, updateDoc} from 'firebase/firestore'
import {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {toast} from 'react-toastify'
import * as sanitizeHtml from 'sanitize-html'
import {v4 as uuidv4} from 'uuid'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import SecondaryButton from '../../../SharedComponents/buttons/SecondaryButton'
import PrimaryButton from '../../../SharedComponents/buttons/PrimaryButton'

import {useQuillContext} from '../../../SharedComponents/context/QuillContext'
import {useIntl} from 'react-intl'
import {useLang} from '../../../_metronic/i18n/Metronici18n'
import {useNavigate, useParams} from 'react-router-dom'
import {articleActions} from '../../../store/articleSlice/articleReducers'
import {firestore} from '../../../firebase/FirebaseConfig'
const DOWNLOAD = toAbsoluteUrl('/media/spa/Download.png')
const NEXT = toAbsoluteUrl('/media/spa/whiteArrow_left.png')

const Footer = ({name, active, handleClick}) => {
  const intl = useIntl()
  const lang = useLang()
  // const router = useNavigate()
  // const {articleId} = router.query || {}
  const params = useParams()
  const articleId = Number(params.articleId)
  const {
    isTitleSelected,
    isIntroSelected,
    isLoading,
    isOutlineSelected,
    UserID,
    articleData,
    articleHTML,
    currentArticleID,
  } = useSelector((state) =>
    articleId || articleId === 0 ? state.article.articles[articleId] : state.article
  )

  const {Language_en} = useSelector((state) =>
    articleId || articleId === 0 ? state.article.articles[articleId] : state.article
  )
  const {setListening} = useQuillContext()
  // const { delta } = quillContext;

  const [btnName, setBtnName] = useState('')
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    if (active === 0) {
      setBtnName(`${name}`)
    } else if (active === 1 && isTitleSelected === false) {
      setBtnName(intl.formatMessage({id: 'REGENERATE_TITLE'}))
    } else if (active === 1 && isTitleSelected === true) {
      setBtnName(`${name}`)
    } else if (active === 2 && isIntroSelected === false) {
      setBtnName(intl.formatMessage({id: 'REGENERATE_INTRO'}))
    } else if (active === 2 && isIntroSelected === true) {
      setBtnName(`${name}`)
    } else if (active === 3 && isOutlineSelected === false) {
      setBtnName(intl.formatMessage({id: 'REGENERATE_OUTLINE'}))
    } else if (active === 3 && isOutlineSelected === true) {
      setBtnName(` ${name}`)
    } else if (active === 4) {
      setBtnName(intl.formatMessage({id: 'REGENERATE_ARTICLE'}))
    }
  }, [isTitleSelected, active, isIntroSelected, isOutlineSelected, lang])
  const handleSave = async () => {
    const HTML = sanitizeHtml(articleHTML)
    setLoading(true)
    if (!currentArticleID) {
      const collectionRef = collection(firestore, 'articles')
      const docRef = doc(collectionRef)

      dispatch(articleActions.setArticleID(docRef.id))
      const newDoc = await setDoc(docRef, {
        user_id: UserID,
        article: [
          {
            id: uuidv4(),
            contentHTML: HTML,
            contentBaner: articleData?.picture_url,
            creation_date: new Date(),
          },
        ],
      })
    } else {
      const articleRef = doc(firestore, 'articles', currentArticleID)
      const newArticle = {
        id: uuidv4(),
        contentHTML: HTML,
        creation_date: new Date(),
      }
      await updateDoc(articleRef, {
        article: arrayUnion(newArticle),
      })
      // toast.success('Article saved successfully ')
      toast.success(intl.formatMessage({id: 'ARTICLE_SAVED'}))
    }
    setLoading(false)
  }

  // Convert HTML to image using html-to-image

  return (
    <div
      className={` t-w-full t-ease-in-out t-duration-300 t-h-15 t-flex t-flex-row t-justify-between  t-z-10`}
    >
      <div
        className={`t-w-full t-flex-col t-gap-4 t-flex t-items-center ${
          active === 4 ? 't-justify-between' : ''
        }  t-py-1`}
      >
        <div className='t-w-full t-bg-[#592E84] t-rounded-lg t-h-[50px] t-flex t-justify-center t-items-center'>
          <SecondaryButton
            iconPosition={1}
            isLoading={isLoading}
            text={btnName}
            onClick={() => {
              handleClick()
            }}
            isIcon={true}
            icon={NEXT}
            textClass='t-text-[16px] t-font-semibold'
          />
        </div>
        {active !== 4 ? (
          ''
        ) : (
          <div className='t-flex t-flex-col  t-gap-4 t-w-full '>
            {/* <PDFDownloadLink
              document={
                <PdfDocument data={articleHTML} language={Language_en} />
              }
              fileName={articleData?.chosen_title?.value}
              className="t-z-20"
            >
              <PrimaryButton
                text={intl.formatMessage({id: 'DOWNLOAD_PDF'})}
                
                className="t-bg-PRIMARY !t-text-white t-py-4"
              />
            </PDFDownloadLink> */}
            <PrimaryButton
              text={intl.formatMessage({id: 'DOWNLOAD_DOC'})}
              isLoading={isLoading}
              className='t-bg-PRIMARY !t-text-white t-w-full t-py-4 t-text-[18px] t-font-medium'
              onClick={async () => {
                setListening(true)
              }}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default Footer
