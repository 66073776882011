import {createSlice} from '@reduxjs/toolkit'
import {data} from 'autoprefixer'
import {ScatterController} from 'chart.js'

import {useSelector} from 'react-redux'

const initialArticleObject = {
  UserID: '',
  Language_en: 'Hebrew',
  Tone_en: 'None',
  Quality_type_en: 'Pro',
  Point_of_view_en: null,
  Number_of_outputs: '',
  Topic: {id: 0, value: '', isEdited: false},
  Topic_en: {id: 0, value: '', isEdited: false},
  topic_edited: 0,
  Keywords: {},
  Keywords_en: {},
  titles: [],
  titles_en: [],
  chosen_title: {},
  chosen_title_en: {},
  chosen_title_edited: 0,
  intros: [],
  intros_en: [],
  chosen_intro: {},
  chosen_intro_en: {},
  chosen_intro_edited: 0,
  outlines: {},
  outlines_en: {},
  chosen_outlines: {},
  chosen_outlines_en: {},
  chosen_outlines_edited: 0,
  paragraphs: {},
  paragraphs_en: {},
  picture_url: '',
  isTitleSelected: false,
  isIntroSelected: false,
  isOutlineSelected: false,
  isArticleSelected: false,
  stepper: 0,
  selectedRephraseTextId: null,
  isTopicSelectedError: false,
  isTitleSelectedError: false,
  isIntroSelectedError: false,
  isOutlineSelectedError: false,
  isArticleSelectedError: false,
  isLoadingAction: false,
  isRephrasing: false,
  articleData: {},
  articleHTML: '',
  currentArticleID: '',
  isEditing: false,
  steps: [
    {id: 0, name: 'TOPIC', active: true},
    {id: 1, name: 'TITLE', active: false},
    {id: 2, name: 'INTRO', active: false},
    {id: 3, name: 'OUTLINES', active: false},
    {id: 4, name: 'ARTICLE', active: false},
  ],
  isLoading: false,
  chosenArticle: null,
  articleCount: null,
 
}

const initialState = {
  articles: [],
  UserID: '',
  Language_en: 'Hebrew',
  Tone_en: 'None',
  Quality_type_en: 'Pro',
  Point_of_view_en: null,
  Number_of_outputs: '',
  Topic: {id: 0, value: '', isEdited: false},
  Topic_en: {id: 0, value: '', isEdited: false},
  topic_edited: 0,
  Keywords: {},
  Keywords_en: {},
  titles: [],
  titles_en: [],
  chosen_title: {},
  chosen_title_en: {},
  chosen_title_edited: 0,
  intros: [],
  intros_en: [],
  chosen_intro: {},
  chosen_intro_en: {},
  chosen_intro_edited: 0,
  outlines: {},
  outlines_en: {},
  chosen_outlines: {},
  chosen_outlines_en: {},
  chosen_outlines_edited: 0,
  paragraphs: {},
  paragraphs_en: {},
  picture_url: '',
  isTitleSelected: false,
  isIntroSelected: false,
  isOutlineSelected: false,
  isArticleSelected: false,
  stepper: 0,
  selectedRephraseTextId: null,
  isTopicSelectedError: false,
  isTitleSelectedError: false,
  isIntroSelectedError: false,
  isOutlineSelectedError: false,
  isArticleSelectedError: false,
  isLoadingAction: false,
  isRephrasing: false,
  articleData: {},
  articleHTML: '',
  currentArticleID: '',
  isEditing: false,
  steps: [
    {id: 0, name: 'TOPIC', active: true},
    {id: 1, name: 'TITLE', active: false},
    {id: 2, name: 'INTRO', active: false},
    {id: 3, name: 'OUTLINES', active: false},
    {id: 4, name: 'ARTICLE', active: false},
  ],
  articleList: [],
  chosenArticle: null,
  articleCount: null,
  isLoading: false,
}

export const articleSlice = createSlice({
  name: 'article',
  initialState: initialState,
  reducers: {
    addNewArticle: (state, action) => {
      if (action.payload === 0) {
        state.articles.push({
          id: action.payload,
          ...initialArticleObject,
          positionIndex: 0,
        })
      } else {
        const positionIndex = state.articles.length
        state.articles.push({
          id: action.payload,
          ...initialArticleObject,
          positionIndex,
        })
      }
    },
    setLoader: (state, action) => {
      const findIndex = parseInt(action?.payload?.id)
      if (findIndex > -1) {
        state.articles[findIndex].isLoading = action.payload?.value
      } else {
        state.isLoading = action.payload
        // state.articles[0].isLoading = action.payload
      }
    },
    setLoaderAction: (state, action) => {
      const findIndex = parseInt(action?.payload?.id)
      if (findIndex > -1) {
        state.articles[findIndex].isLoadingAction = action.payload?.value
      } else {
        state.isLoadingAction = action.payload
      }
    },
    setIsEditing: (state, action) => {
      const findIndex = parseInt(action?.payload?.id)
      if (findIndex > -1) {
        state.articles[findIndex].isEditing = action.payload?.value
      } else {
        state.isEditing = action.payload
      }
    },
    setRephrasing: (state, action) => {
      const findIndex = parseInt(action?.payload?.id)
      if (findIndex > -1) {
        state.articles[findIndex].isRephrasing = action.payload.value
      } else {
        state.isRephrasing = action.payload
      }
    },
    setCurrUserInfo: (state, action) => {
      const findIndex = parseInt(action?.payload?.id)
      if (findIndex > -1) {
        state.articles[findIndex].UserID = action.payload.userID
      } else {
        state.UserID = action.payload.userID
      }
    },

    setArticleID: (state, action) => {
      console.log("setArticleID", action.payload)
      const findIndex = parseInt(action?.payload?.id)
      if (findIndex > -1) {
        state.articles[findIndex].currentArticleID = action.payload.currentArticleID
      } else {
        state.currentArticleID = action.payload
      }
    },
    setTopic: (state, action) => {
      const findIndex = parseInt(action?.payload?.id)
      if (findIndex > -1) {
        state.articles[findIndex].Topic = {
          id: 0,
          value: action.payload.value,
          isEdited: false,
        }
        state.articles[findIndex].Topic_en = {
          id: 0,
          value: '',
          isEdited: false,
        }
        if (state.articles[findIndex].Topic.value.length === 0) {
          state.articles[findIndex].isTopicSelectedError = true
        } else {
          state.articles[findIndex].isTopicSelectedError = false
        }
      } else {
        state.Topic = {
          id: 0,
          value: action.payload,
          isEdited: false,
        }
        state.Topic_en = {id: 0, value: '', isEdited: false}
        if (state.Topic.value.length === 0) {
          state.isTopicSelectedError = true
        } else {
          state.isTopicSelectedError = false
        }
      }
    },
    setEditedTopic: (state, action) => {
      const findIndex = parseInt(action?.payload?.id)
      if (findIndex > -1) {
        if (state.articles[findIndex].Topic.isEdited == 0) {
          state.articles[findIndex].Topic.isEdited = 1
        }
        state.articles[findIndex].Topic = {
          ...state.articles[findIndex].Topic,
          value: action.payload.value,
        }
        if (state.articles[findIndex].Topic.value.length == 0) {
          state.articles[findIndex].isTopicSelectedError = true
        } else {
          state.articles[findIndex].isTopicSelectedError = false
        }
      } else {
        if (state.Topic.isEdited == 0) {
          state.Topic.isEdited = 1
        }
        state.Topic = {...state.Topic, value: action.payload}
        if (state.Topic.value.length == 0) {
          state.isTopicSelectedError = true
        } else {
          state.isTopicSelectedError = false
        }
      }
    },
    setKeywords: (state, action) => {
      const findIndex = parseInt(action?.payload?.id)
      if (findIndex > -1) {
        state.articles[findIndex].Keywords = action.payload.value
      } else {
        state.Keywords = action.payload
      }
    },
    setEditedTitle: (state, action) => {
      const findIndex = parseInt(action?.payload?.id)
      if (findIndex > -1) {
        if (state.articles[findIndex].chosen_title.isEdited === 0) {
          state.articles[findIndex].chosen_title.isEdited = 1
        }
        state.articles[findIndex].chosen_title.value = action.payload.value
        state.articles[findIndex].titles[state.articles[findIndex].chosen_title.id].title =
          action.payload.value
        if (
          state.articles[findIndex].titles[state.articles[findIndex].chosen_title.id].isEdited == 0
        ) {
          state.articles[findIndex].titles[state.articles[findIndex].chosen_title.id].isEdited = 0
          state.articles[findIndex].titles_en[
            state.articles[findIndex].chosen_title.id
          ].isEdited = 0
        }
        if (state.articles[findIndex].chosen_title.value.length === 0) {
          state.articles[findIndex].isTitleSelectedError = true
        } else {
          state.articles[findIndex].isTitleSelectedError = false
        }
      } else {
        if (state.chosen_title.isEdited === 0) {
          state.chosen_title.isEdited = 1
        }
        state.chosen_title.value = action.payload
        state.titles[state.chosen_title.id].title = action.payload
        if (state.titles[state.chosen_title.id].isEdited == 0) {
          state.titles[state.chosen_title.id].isEdited = 0
          state.titles_en[state.chosen_title.id].isEdited = 0
        }
        if (state.chosen_title.value.length === 0) {
          state.isTitleSelectedError = true
        } else {
          state.isTitleSelectedError = false
        }
      }
    },
    setEditedIntro: (state, action) => {
      const findIndex = parseInt(action?.payload?.id)
      if (findIndex > -1) {
        if (state.articles[findIndex].chosen_intro.isEdited === 0) {
          state.articles[findIndex].chosen_intro.isEdited = 1
        }
        state.articles[findIndex].chosen_intro.value = action.payload.value
        state.articles[findIndex].intros[state.articles[findIndex].chosen_intro.id].intro =
          action.payload.value
        if (state.articles[findIndex].chosen_intro.value.length === 0) {
          state.articles[findIndex].isIntroSelectedError = true
        } else {
          state.articles[findIndex].isIntroSelectedError = false
        }
      } else {
        if (state.chosen_intro.isEdited === 0) {
          state.chosen_intro.isEdited = 1
        }
        state.chosen_intro.value = action.payload
        state.intros[state.chosen_intro.id].intro = action.payload
        if (state.chosen_intro.value.length === 0) {
          state.isIntroSelectedError = true
        } else {
          state.isIntroSelectedError = false
        }
      }
    },
    setEditChosenOutline: (state, action) => {
      const findIndex = parseInt(action?.payload?.id)
      if (findIndex > -1) {
        state.articles[findIndex].chosen_outlines.value.outlineobj[action.payload.key].value =
          action.payload.val
        state.articles[findIndex].chosen_outlines.value.outlineobj[
          action.payload.key
        ].isEdited = true
        state.articles[findIndex].outlines[state.articles[findIndex].chosen_outlines.id].outlineobj[
          action.payload.key
        ].value = action.payload.val
        state.articles[findIndex].outlines[state.articles[findIndex].chosen_outlines.id].outlineobj[
          action.payload.key
        ].isEdited = true
      } else {
        state.chosen_outlines.value.outlineobj[action.payload.key].value = action.payload.val
        state.chosen_outlines.value.outlineobj[action.payload.key].isEdited = true
        state.outlines[state.chosen_outlines.id].outlineobj[action.payload.key].value =
          action.payload.val
        state.outlines[state.chosen_outlines.id].outlineobj[action.payload.key].isEdited = true
      }
    },
    // removeChosenOutlineItem: (state, action) => {
    //   const findIndex = parseInt(action?.payload?.id)
    //   if (findIndex > -1) {
    //     /// new
    //     const myState = JSON.parse(JSON.stringify(state.articles[findIndex]))
    //     const payload = action.payload

    //     let chosen_outlines = myState.chosen_outlines
    //     let chosen_outlines_en = myState.chosen_outlines_en

    //     delete chosen_outlines.value[payload]
    //     delete chosen_outlines_en.value[payload]

    //     const sortedKeys = Object.keys(chosen_outlines.value).sort()
    //     const sortedKeys_en = Object.keys(chosen_outlines_en.value).sort()
    //     const sortedObj = {}
    //     const sortedObj_en = {}
    //     for (let i = 0; i < sortedKeys.length; i++) {
    //       sortedObj[i] = chosen_outlines.value[sortedKeys[i]]
    //     }
    //     for (let i = 0; i < sortedKeys_en.length; i++) {
    //       sortedObj_en[i] = chosen_outlines_en.value[sortedKeys_en[i]]
    //     }
    //     chosen_outlines.value = sortedObj
    //     chosen_outlines_en.value = sortedObj_en

    //     state.articles[findIndex].chosen_outlines.value = chosen_outlines.value
    //     state.articles[findIndex].chosen_outlines_en.value = chosen_outlines_en.value
    //     state.articles[findIndex].outlines[state.articles[findIndex].chosen_outlines.id] =
    //       state.articles[findIndex].chosen_outlines.value
    //     state.articles[findIndex].outlines_en[state.articles[findIndex].chosen_outlines.id] =
    //       state.articles[findIndex].chosen_outlines_en.value
    //   } else {
    //     /// old
    //     const myState = JSON.parse(JSON.stringify(state))
    //     const payload = action.payload
    //     let chosen_outlines = myState.chosen_outlines
    //     let chosen_outlines_en = myState.chosen_outlines_en
    //     // delete the selected outline by payload(index)
    //     delete chosen_outlines.value.outlineobj[payload]
    //     delete chosen_outlines_en.value.outlineobj_en[payload]

    //     const reassignKeysInAscendingOrder = (obj) => {

    //       const keys = Object.keys(obj).map(Number);
    //       keys.sort((a, b) => a - b);

    //       const result = {};
    //       keys.forEach((key, index) => {
    //         result[index + 1] = obj[key];
    //       });

    //       return result;
    //     };

    //     const sortedOutlines = reassignKeysInAscendingOrder(chosen_outlines.value.outlineobj)
    //     // const sortedOutlines_en = reassignKeysInAscendingOrder(chosen_outlines.value.outlineobj_en)
    //     // state.chosen_outlines.value.outlineobj = sortedOutlines
    //     // state.chosen_outlines_en.value.outlineobj_en = sortedOutlines_en

    //     // const sortedKeys = Object.keys(chosen_outlines.value).sort()
    //     // const sortedKeys_en = Object.keys(
    //     //   chosen_outlines_en.value
    //     // ).sort()
    //     // const sortedObj = {}
    //     // const sortedObj_en = {}
    //     // for (let i = 0; i < sortedKeys.length; i++) {
    //     //   sortedObj[i] = chosen_outlines.value[sortedKeys[i]]
    //     // }
    //     // for (let i = 0; i < sortedKeys_en.length; i++) {
    //     //   sortedObj_en[i] = chosen_outlines_en.value[sortedKeys_en[i]]
    //     // }
    //     // chosen_outlines.value = sortedObj
    //     // chosen_outlines_en.value = sortedObj_en

    //     // state.chosen_outlines.value = chosen_outlines.value
    //     // state.chosen_outlines_en.value = chosen_outlines_en.value
    //     // state.outlines[state.chosen_outlines.id] =
    //     //   state.chosen_outlines.value
    //     // state.outlines_en[state.chosen_outlines.id] =
    //     //   state.chosen_outlines_en.value
    //   }
    // },

    removeChosenOutlineItem: (state, action) => {
      const findIndex = parseInt(action?.payload?.id)
      if (findIndex > -1) {
        /// new
        const myState = JSON.parse(JSON.stringify(state.articles[findIndex]))
        const payload = action.payload.key

        let chosen_outlines = myState.chosen_outlines
        let chosen_outlines_en = myState.chosen_outlines_en

        delete chosen_outlines.value.outlineobj[payload]
        delete chosen_outlines_en.value.outlineobj_en[payload]

        const sortedKeys = Object.keys(chosen_outlines.value.outlineobj).sort()
        const sortedKeys_en = Object.keys(chosen_outlines_en.value.outlineobj_en).sort()
        const sortedObj = {}
        const sortedObj_en = {}
        for (let i = 0; i < sortedKeys.length; i++) {
          sortedObj[i] = chosen_outlines.value.outlineobj[sortedKeys[i]]
        }
        for (let i = 0; i < sortedKeys_en.length; i++) {
          sortedObj_en[i] = chosen_outlines_en.value.outlineobj_en[sortedKeys_en[i]]
        }
        chosen_outlines.value.outlineobj = sortedObj
        chosen_outlines_en.value.outlineobj_en = sortedObj_en

        state.articles[findIndex].chosen_outlines.value.outlineobj =
          chosen_outlines.value.outlineobj
        state.articles[findIndex].chosen_outlines_en.value.outlineobj_en =
          chosen_outlines_en.value.outlineobj_en

        state.articles[findIndex].outlines[
          state.articles[findIndex].chosen_outlines.id
        ].outlineobj = state.articles[findIndex].chosen_outlines.value.outlineobj
        state.articles[findIndex].outlines_en[
          state.articles[findIndex].chosen_outlines.id
        ].outlineobj_en = state.articles[findIndex].chosen_outlines_en.value.outlineobj_en
      } else {
        /// old
        const myState = JSON.parse(JSON.stringify(state))
        const payload = action.payload.key

        let chosen_outlines = myState.chosen_outlines
        let chosen_outlines_en = myState.chosen_outlines_en

        delete chosen_outlines.value.outlineobj[payload]
        delete chosen_outlines_en.value.outlineobj_en[payload]

        const sortedKeys = Object.keys(chosen_outlines.value.outlineobj).sort()
        const sortedKeys_en = Object.keys(chosen_outlines_en.value.outlineobj_en).sort()
        const sortedObj = {}
        const sortedObj_en = {}
        for (let i = 0; i < sortedKeys.length; i++) {
          sortedObj[i] = chosen_outlines.value.outlineobj[sortedKeys[i]]
        }
        for (let i = 0; i < sortedKeys_en.length; i++) {
          sortedObj_en[i] = chosen_outlines_en.value.outlineobj_en[sortedKeys_en[i]]
        }
        chosen_outlines.value.outlineobj = sortedObj
        chosen_outlines_en.value.outlineobj_en = sortedObj_en

        state.chosen_outlines.value.outlineobj = chosen_outlines.value.outlineobj
        state.chosen_outlines_en.value.outlineobj_en = chosen_outlines_en.value.outlineobj_en
        state.outlines[state.chosen_outlines.id].outlineobj = state.chosen_outlines.value.outlineobj
        state.outlines_en[state.chosen_outlines.id].outlineobj_en =
          state.chosen_outlines_en.value.outlineobj_en
      }
    },

    addChosenOutlineItem: (state, action) => {
      const findIndex = parseInt(action?.payload?.id)
      if (findIndex > -1) {
        /// new
        const myState = JSON.parse(JSON.stringify(state.articles[findIndex]))
        const payload = action.payload

        let chosen_outlines = myState.chosen_outlines
        let chosen_outlines_en = myState.chosen_outlines_en

        const selectedItem = parseInt(payload) + 1
        const newValue = ''
        for (let i = Object.keys(chosen_outlines?.value).length - 1; i >= selectedItem; i--) {
          const currentKey = Object.keys(chosen_outlines?.value)[i]
          chosen_outlines.value[parseInt(currentKey) + 1] = chosen_outlines?.value[currentKey]
        }
        chosen_outlines.value[selectedItem] = {
          value: newValue,
          isEdited: true,
        }

        for (let i = Object.keys(chosen_outlines_en?.value).length - 1; i >= selectedItem; i--) {
          const currentKey = Object.keys(chosen_outlines_en?.value)[i]
          chosen_outlines_en.value[parseInt(currentKey) + 1] = chosen_outlines_en?.value[currentKey]
        }
        chosen_outlines_en.value[selectedItem] = {
          value: newValue,
          isEdited: true,
        }

        state.articles[findIndex].chosen_outlines.value = chosen_outlines.value
        state.articles[findIndex].chosen_outlines_en.value = chosen_outlines_en.value
        state.articles[findIndex].outlines[state.articles[findIndex].chosen_outlines.id] =
          state.articles[findIndex].chosen_outlines.value
        state.articles[findIndex].outlines_en[state.articles[findIndex].chosen_outlines.id] =
          state.articles[findIndex].chosen_outlines_en.value
      } else {
        /// OLD
        const myState = JSON.parse(JSON.stringify(state))
        const payload = action.payload

        let chosen_outlines = myState.chosen_outlines
        let chosen_outlines_en = myState.chosen_outlines_en

        const selectedItem = parseInt(payload) + 1
        const newValue = ''
        for (let i = Object.keys(chosen_outlines?.value).length - 1; i >= selectedItem; i--) {
          const currentKey = Object.keys(chosen_outlines?.value)[i]
          chosen_outlines.value[parseInt(currentKey) + 1] = chosen_outlines?.value[currentKey]
        }
        chosen_outlines.value[selectedItem] = {
          value: newValue,
          isEdited: true,
        }

        for (let i = Object.keys(chosen_outlines_en?.value).length - 1; i >= selectedItem; i--) {
          const currentKey = Object.keys(chosen_outlines_en?.value)[i]
          chosen_outlines_en.value[parseInt(currentKey) + 1] = chosen_outlines_en?.value[currentKey]
        }
        chosen_outlines_en.value[selectedItem] = {
          value: newValue,
          isEdited: true,
        }

        state.chosen_outlines.value = chosen_outlines.value
        state.chosen_outlines_en.value = chosen_outlines_en.value
        state.outlines[state.chosen_outlines.id] = state.chosen_outlines.value
        state.outlines_en[state.chosen_outlines.id] = state.chosen_outlines_en.value
      }
    },

    setContentFiltersData: (state, action) => {
      const findIndex = parseInt(action?.payload?.id)
      if (findIndex > -1) {
        /// new
        state.articles[findIndex].Language_en = action.payload.Language_en
        state.articles[findIndex].Tone_en = action.payload.Tone_en
        state.articles[findIndex].Quality_type_en = action.payload.Quality_type_en
        state.articles[findIndex].Point_of_view_en = action.payload.Point_of_view_en
      } else {
        // old
        state.Language_en = action.payload.Language_en
        state.Tone_en = action.payload.Tone_en
        state.Quality_type_en = action.payload.Quality_type_en
        state.Point_of_view_en = action.payload.Point_of_view_en
      }
    },
    setOutputDataCountValue: (state, action) => {
      const findIndex = parseInt(action?.payload?.id)
      if (findIndex > -1) {
        // new
        state.articles[findIndex].Number_of_outputs = action.payload.value
      } else {
        // old
        state.Number_of_outputs = action.payload
      }
    },
    setFeedback: (state, action) => {
      const findIndex = parseInt(action?.payload?.id)
      if (findIndex > -1) {
        // new
        const myState = JSON.parse(JSON.stringify(state.articles[findIndex]))
        if (action.payload.type === 1) {
          if (myState.titles[action.payload.key].dislike === true) {
            myState.titles[action.payload.key].dislike = false
            myState.titles_en[action.payload.key].dislike = false
          }

          myState.titles[action.payload.key].like = true
          myState.titles_en[action.payload.key].like = true
        } else {
          if (myState.titles[action.payload.key].like === true) {
            myState.titles[action.payload.key].like = false
            myState.titles_en[action.payload.key].like = false
          }
          myState.titles[action.payload.key].dislike = true
          myState.titles_en[action.payload.key].dislike = true
        }

        state.articles[findIndex].titles = myState.titles
        state.articles[findIndex].titles_en = myState.titles_en
      } else {
        // old
        const myState = JSON.parse(JSON.stringify(state))
        if (action.payload.type === 1) {
          if (myState.titles[action.payload.key].dislike === true) {
            myState.titles[action.payload.key].dislike = false
            myState.titles_en[action.payload.key].dislike = false
          }

          myState.titles[action.payload.key].like = true
          myState.titles_en[action.payload.key].like = true
        } else {
          if (myState.titles[action.payload.key].like === true) {
            myState.titles[action.payload.key].like = false
            myState.titles_en[action.payload.key].like = false
          }
          myState.titles[action.payload.key].dislike = true
          myState.titles_en[action.payload.key].dislike = true
        }

        state.titles = myState.titles
        state.titles_en = myState.titles_en
      }
    },
    setIntrosFeedback: (state, action) => {
      const findIndex = parseInt(action?.payload?.id)
      if (findIndex > -1) {
        // new
        const myState = JSON.parse(JSON.stringify(state.articles[findIndex]))

        if (action.payload.type === 1) {
          if (myState.intros[action.payload.key].dislike === true) {
            myState.intros[action.payload.key].dislike = false
            myState.intros_en[action.payload.key].dislike = false
          }
          myState.intros[action.payload.key].like = true
          myState.intros_en[action.payload.key].like = true
        } else {
          if (myState.intros[action.payload.key].like === true) {
            myState.intros[action.payload.key].like = false
            myState.intros_en[action.payload.key].like = false
          }
          myState.intros[action.payload.key].dislike = true
          myState.intros_en[action.payload.key].dislike = true
        }

        state.articles[findIndex].intros = myState.intros
        state.articles[findIndex].intros_en = myState.intros_en
      } else {
        // old
        const myState = JSON.parse(JSON.stringify(state))
        if (action.payload.type === 1) {
          if (myState.intros[action.payload.key].dislike === true) {
            myState.intros[action.payload.key].dislike = false
            myState.intros_en[action.payload.key].dislike = false
          }

          myState.intros[action.payload.key].like = true
          myState.intros_en[action.payload.key].like = true
        } else {
          if (myState.intros[action.payload.key].like === true) {
            myState.intros[action.payload.key].like = false
            myState.intros_en[action.payload.key].like = false
          }
          myState.intros[action.payload.key].dislike = true
          myState.intros_en[action.payload.key].dislike = true
        }

        state.intros = myState.intros
        state.intros_en = myState.intros_en
      }
    },
    setOutlinesFeedBack: (state, action) => {
      const findIndex = parseInt(action?.payload?.articleId)
      const currentOutline = parseInt(action.payload?.text?.id)
      const feedBackType = action.payload?.type
      if (findIndex > -1) {
        const myState = JSON.parse(JSON.stringify(state.articles[findIndex]))
        const state_outlines = myState.outlines[currentOutline]
        const state_outlines_en = myState.outlines_en[currentOutline]

        if (feedBackType === 1) {
          state_outlines.like = false
          state_outlines.dislike = true
          state_outlines_en.like = false
          state_outlines_en.dislike = true
        }
        if (feedBackType === 0) {
          state_outlines.like = true
          state_outlines.dislike = false
          state_outlines_en.like = true
          state_outlines_en.dislike = false
        }
        state.articles[findIndex].outlines[currentOutline] = state_outlines
        state.articles[findIndex].outlines_en[currentOutline] = state_outlines_en
      } else {
        const myState = JSON.parse(JSON.stringify(state))
        const state_outlines = myState.outlines[currentOutline]
        const state_outlines_en = myState.outlines_en[currentOutline]
        if (feedBackType === 1) {
          state_outlines.like = false
          state_outlines.dislike = true
          state_outlines_en.like = false
          state_outlines_en.dislike = true
        } else if (feedBackType === 0) {
          state_outlines.like = true
          state_outlines.dislike = false
          state_outlines_en.like = true
          state_outlines_en.dislike = false
        }
        state.outlines[currentOutline] = state_outlines
        state.outlines_en[currentOutline] = state_outlines_en
      }
    },

    setCreateTitleData: (state, action) => {
      const findIndex = parseInt(action?.payload?.id)
      if (findIndex > -1) {
        // new
        state.articles[findIndex].Topic_en.value = action.payload.topic_en
        state.articles[findIndex].Keywords_en = action.payload.keywords_en
        state.articles[findIndex].Keywords_en = action.payload.keywords_en
        let arrtitle = []
        let arrtitle_en = []
        Object.keys(action.payload.titles).map((item) => {
          let temp = {
            id: item,
            title: action.payload.titles[item],
            like: false,
            dislike: false,
            isEdited: false,
          }
          arrtitle.push(temp)
        })
        state.articles[findIndex].titles = arrtitle

        Object.keys(action.payload.titles_en).map((item) => {
          let temp = {
            id: item,
            title: action.payload.titles_en[item],
            like: false,
            dislike: false,
            isEdited: false,
          }
          arrtitle_en.push(temp)
        })
        state.articles[findIndex].titles_en = arrtitle_en
        return
      } else {
        // old
        state.Topic_en.value = action.payload.topic_en
        state.Keywords_en = action.payload.keywords_en
        state.Keywords_en = action.payload.keywords_en
        let arrtitle = []
        let arrtitle_en = []
        Object.keys(action.payload.titles).map((item) => {
          let temp = {
            id: item,
            title: action.payload.titles[item],
            like: false,
            dislike: false,
            isEdited: false,
          }
          arrtitle.push(temp)
        })
        state.titles = arrtitle
        Object.keys(action.payload.titles_en).map((item) => {
          let temp = {
            id: item,
            title: action.payload.titles_en[item],
            like: false,
            dislike: false,
            isEdited: false,
          }
          arrtitle_en.push(temp)
        })
        state.titles_en = arrtitle_en
      }
    },
    setCreateIntrosData: (state, action) => {
      const findIndex = parseInt(action?.payload?.id)
      if (findIndex > -1) {
        // new
        state.articles[findIndex].Topic = action.payload.topic
        state.articles[findIndex].Topic_en = action.payload.topic_en
        state.articles[findIndex].chosen_title = action.payload.chosen_title
        state.articles[findIndex].chosen_title_en = action.payload.chosen_title_en

        let arrintros = []
        let arrintros_en = []
        Object.keys(action.payload.intros).map((item) => {
          let temp = {
            id: item,
            intro: action.payload.intros[item],
            like: false,
            dislike: false,
            isEdited: false,
          }
          arrintros.push(temp)
        })

        state.articles[findIndex].intros = arrintros

        Object.keys(action.payload.intros_en).map((item) => {
          let temp = {
            id: item,
            intro: action.payload.intros_en[item],
            like: false,
            dislike: false,
            isEdited: false,
          }
          arrintros_en.push(temp)
        })

        state.articles[findIndex].intros_en = arrintros_en
      } else {
        // old
        state.Topic = action.payload.topic
        state.Topic_en = action.payload.topic_en
        state.chosen_title = action.payload.chosen_title
        state.chosen_title_en = action.payload.chosen_title_en

        let arrintros = []
        let arrintros_en = []
        Object.keys(action.payload.intros).map((item) => {
          let temp = {
            id: item,
            intro: action.payload.intros[item],
            like: false,
            dislike: false,
            isEdited: false,
          }
          arrintros.push(temp)
        })

        state.intros = arrintros

        Object.keys(action.payload.intros_en).map((item) => {
          let temp = {
            id: item,
            intro: action.payload.intros_en[item],
            like: false,
            dislike: false,
            isEdited: false,
          }
          arrintros_en.push(temp)
        })

        state.intros_en = arrintros_en
      }
    },
    setCreateOutlinesData: (state, action) => {
      const findIndex = parseInt(action?.payload?.id)
      if (findIndex > -1) {
        // new
        state.articles[findIndex].Topic = action.payload.topic
        state.articles[findIndex].Topic_en = action.payload.topic_en
        state.articles[findIndex].chosen_title = action.payload.chosen_title
        state.articles[findIndex].chosen_title_en = action.payload.chosen_title_en
        state.articles[findIndex].chosen_intro = action.payload.intros
        state.articles[findIndex].chosen_intro_en = action.payload.intros_en
        const output = {}
        const output_en = {}
        const input = action.payload.outlines
        const input_en = action.payload.outlines_en

        for (const key in input) {
          if (Object.hasOwnProperty.call(input, key)) {
            output[key] = {
              like: false,
              dislike: false,
              id: key,
              outlineobj: {},
            }
            const nestedInput = input[key]
            let id = 0
            for (const nestedKey in nestedInput) {
              if (Object.hasOwnProperty.call(nestedInput, nestedKey)) {
                output[key].outlineobj[nestedKey] = {
                  value: nestedInput[nestedKey],
                  isEdited: false,
                }
              }
            }
          }
        }

        for (const key in input_en) {
          if (Object.hasOwnProperty.call(input_en, key)) {
            output_en[key] = {
              like: false,
              dislike: false,
              id: key,
              outlineobj_en: {},
            }
            const nestedInput = input_en[key]
            for (const nestedKey in nestedInput) {
              if (Object.hasOwnProperty.call(nestedInput, nestedKey)) {
                output_en[key].outlineobj_en[nestedKey] = {
                  value: nestedInput[nestedKey],
                  isEdited: false,
                }
              }
            }
          }
        }

        state.articles[findIndex].outlines = output
        state.articles[findIndex].outlines_en = output_en
      } else {
        // old
        state.Topic = action.payload.topic
        state.Topic_en = action.payload.topic_en
        state.chosen_title = action.payload.chosen_title
        state.chosen_title_en = action.payload.chosen_title_en
        state.chosen_intro = action.payload.intros
        state.chosen_intro_en = action.payload.intros_en
        const output = {}
        const output_en = {}
        const input = action.payload.outlines
        const input_en = action.payload.outlines_en

        for (const key in input) {
          if (Object.hasOwnProperty.call(input, key)) {
            output[key] = {
              like: false,
              dislike: false,
              id: key,
              outlineobj: {},
            }
            const nestedInput = input[key]

            let id = 0
            for (const nestedKey in nestedInput) {
              if (Object.hasOwnProperty.call(nestedInput, nestedKey)) {
                output[key].outlineobj[nestedKey] = {
                  value: nestedInput[nestedKey],
                  isEdited: false,
                }
              }
            }
          }
        }

        for (const key in input_en) {
          if (Object.hasOwnProperty.call(input_en, key)) {
            output_en[key] = {
              like: false,
              dislike: false,
              id: key,
              outlineobj_en: {},
            }
            const nestedInput = input_en[key]
            for (const nestedKey in nestedInput) {
              if (Object.hasOwnProperty.call(nestedInput, nestedKey)) {
                output_en[key].outlineobj_en[nestedKey] = {
                  value: nestedInput[nestedKey],
                  isEdited: false,
                }
              }
            }
          }
        }

        state.outlines = output
        state.outlines_en = output_en
      }
    },
    setCreateArticleData: (state, action) => {
      const findIndex = parseInt(action?.payload?.id)
      if (findIndex > -1) {
        // new
        state.articles[findIndex].articleData = action.payload
      } else {
        // old
        state.articleData = action.payload
      }
    },
    setActiveStepper: (state, action) => {
      const findIndex = parseInt(action?.payload?.id)
      if (findIndex > -1) {
        // new
        state.articles[findIndex].stepper = action.payload.value
      } else {
        // old
        return {...state, stepper: action.payload}
      }
    },
    setSelectedRephrase: (state, action) => {
      const findIndex = parseInt(action?.payload?.id)
      if (findIndex > -1) {
        // new
        state.articles[findIndex].selectedRephraseTextId = action.payload.value
      } else {
        // old
        return {...state, selectedRephraseTextId: action.payload}
      }
    },
    removeItem: (state, action) => {
      const findIndex = parseInt(action?.payload?.id)
      if (findIndex > -1) {
        // new
        const myState = JSON.parse(JSON.stringify(state.articles[findIndex]))
        const payload = action.payload
        let titles = myState.titles
        let titles_en = myState.titles_en
        if (myState.chosen_title.id == payload.value && !myState.isIntroSelected) {
          myState.chosen_title = {}
          myState.chosen_title_en = {}
          myState.isTitleSelected = false
        }
        const index = titles.findIndex((num) => num.id == payload.value)

        titles.splice(index, 1)
        titles_en.splice(index, 1)

        return {...myState, titles: titles, titles_en: titles_en}
      } else {
        // old
        const myState = JSON.parse(JSON.stringify(state))
        const payload = action.payload

        let titles = myState.titles
        let titles_en = myState.titles_en
        if (myState.chosen_title.id == payload && !myState.isIntroSelected) {
          myState.chosen_title = {}
          myState.chosen_title_en = {}
          myState.isTitleSelected = false
        }
        const index = titles.findIndex((num) => num.id == payload)

        titles.splice(index, 1)
        titles_en.splice(index, 1)

        return {...myState, titles: titles, titles_en: titles_en}
      }
    },
    removeIntroItem: (state, action) => {
      const findIndex = parseInt(action?.payload?.id)
      if (findIndex > -1) {
        // new
        const myState = JSON.parse(JSON.stringify(state.articles[findIndex]))
        const payload = action.payload.value

        let intros = myState.intros
        let intros_en = myState.intros_en

        if (myState.chosen_intro.id == payload && !myState.isOutlineSelected) {
          myState.chosen_intro = {}
          myState.chosen_intro_en = {}
          myState.isIntroSelected = false
        }
        const index = intros.findIndex((num) => num.id == payload)
        intros.splice(index, 1)
        intros_en.splice(index, 1)

        return {...myState, intros: intros, intros_en: intros_en}
      } else {
        const myState = JSON.parse(JSON.stringify(state))
        const payload = action.payload

        let intros = myState.intros
        let intros_en = myState.intros_en

        if (myState.chosen_intro.id == payload && !myState.isOutlineSelected) {
          myState.chosen_intro = {}
          myState.chosen_intro_en = {}
          myState.isIntroSelected = false
        }
        const index = intros.findIndex((num) => num.id == payload)
        intros.splice(index, 1)
        intros_en.splice(index, 1)

        return {...myState, intros: intros, intros_en: intros_en}
      }
    },
    removeOutlineItem: (state, action) => {
      const findIndex = parseInt(action?.payload?.id)
      if (findIndex > -1) {
        // new
        const myState = JSON.parse(JSON.stringify(state.articles[findIndex]))
        const payload = action.payload.value

        let outlines = myState.outlines
        let outlines_en = myState.outlines_en

        if (myState.chosen_outlines.id == payload) {
          myState.chosen_outlines = {}
          myState.chosen_outlines_en = {}
          myState.isOutlineSelected = false
        }
        const filteredOutlines = Object.keys(outlines)
          .filter((key) => key != payload)
          .reduce((obj, key) => {
            obj[key] = outlines[key]
            return obj
          }, {})
        const filteredOutlines_en = Object.keys(outlines_en)
          .filter((key) => key != payload)
          .reduce((obj, key) => {
            obj[key] = outlines_en[key]
            return obj
          }, {})
        return {
          ...myState,
          outlines: filteredOutlines,
          outlines_en: filteredOutlines_en,
        }
      } else {
        const myState = JSON.parse(JSON.stringify(state))
        const payload = action.payload

        let outlines = myState.outlines
        let outlines_en = myState.outlines_en

        if (myState.chosen_outlines.id == payload) {
          myState.chosen_outlines = {}
          myState.chosen_outlines_en = {}
          myState.isOutlineSelected = false
        }
        const filteredOutlines = Object.keys(outlines)
          .filter((key) => key != payload)
          .reduce((obj, key) => {
            obj[key] = outlines[key]
            return obj
          }, {})
        const filteredOutlines_en = Object.keys(outlines_en)
          .filter((key) => key != payload)
          .reduce((obj, key) => {
            obj[key] = outlines_en[key]
            return obj
          }, {})
        return {
          ...myState,
          outlines: filteredOutlines,
          outlines_en: filteredOutlines_en,
        }
      }
    },
    setEditedText: (state, action) => {
      const findIndex = parseInt(action?.payload?.id)
      if (findIndex > -1) {
        // new
        const index = state.articles[findIndex].titles.findIndex(
          (num) => num.id === action.payload.key
        )
        state.articles[findIndex].titles[index].title = action.payload.value
        state.articles[findIndex].titles[index].isEdited = true
        if (
          Object.keys(state.articles[findIndex].chosen_title).length > 0 &&
          state.articles[findIndex].chosen_title.id == action.payload.key
        ) {
          state.articles[findIndex].chosen_title.value = action.payload.value
          state.articles[findIndex].chosen_title.isEdited = true
          state.articles[findIndex].chosen_title_en.isEdited = true
        }
      } else {
        // old
        const index = state.titles.findIndex((num) => num.id === action.payload.key)
        state.titles[index].title = action.payload.value
        state.titles[index].isEdited = true
        if (
          Object.keys(state.chosen_title).length > 0 &&
          state.chosen_title.id == action.payload.key
        ) {
          state.chosen_title.value = action.payload.value
          state.chosen_title.isEdited = true
          state.chosen_title_en.isEdited = true
        }
      }
    },
    setIntroEditedText: (state, action) => {
      const findIndex = parseInt(action?.payload?.id)
      if (findIndex > -1) {
        // new
        const index = state.articles[findIndex].intros.findIndex(
          (num) => num.id === action.payload.key
        )
        state.articles[findIndex].intros[index].intro = action.payload.value
        state.articles[findIndex].intros[index].isEdited = true
        if (
          Object.keys(state.articles[findIndex].chosen_intro).length > 0 &&
          state.articles[findIndex].chosen_intro.id == action.payload.key
        ) {
          state.articles[findIndex].chosen_intro.value = action.payload.value
          state.articles[findIndex].chosen_intro_en.isEdited = true
          state.articles[findIndex].chosen_intro.isEdited = true
        }
      } else {
        // old

        const index = state.intros.findIndex((num) => num.id === action.payload.key)
        state.intros[index].intro = action.payload.value
        state.intros[index].isEdited = true
        if (
          Object.keys(state.chosen_intro).length > 0 &&
          state.chosen_intro.id == action.payload.key
        ) {
          state.chosen_intro.value = action.payload.value
          state.chosen_intro_en.isEdited = true
          state.chosen_intro.isEdited = true
        }
      }
    },
    setRephrasedText: (state, action) => {
      const findIndex = parseInt(action?.payload?.id)
      if (findIndex > -1) {
        // new
        const index = state.articles[findIndex].titles.findIndex(
          (num) => num.id == state.articles[findIndex].selectedRephraseTextId
        )
        state.articles[findIndex].titles[index].title = action.payload.text
        state.articles[findIndex].titles_en[index].title = action.payload.text_en
        if (
          Object.keys(state.articles[findIndex].chosen_title).length > 0 &&
          state.articles[findIndex].chosen_title.id ==
            state.articles[findIndex].selectedRephraseTextId
        ) {
          state.articles[findIndex].chosen_title.value = action.payload.text
          state.articles[findIndex].chosen_title.isEdited = true
        }
      } else {
        // old
        const index = state.titles.findIndex((num) => num.id == state.selectedRephraseTextId)
        state.titles[index].title = action.payload.text
        state.titles_en[index].title = action.payload.text_en
        if (
          Object.keys(state.chosen_title).length > 0 &&
          state.chosen_title.id == state.selectedRephraseTextId
        ) {
          state.chosen_title.value = action.payload.text
          state.chosen_title.isEdited = true
        }
      }
    },
    setRephrasedIntroText: (state, action) => {
      const findIndex = parseInt(action?.payload?.id)
      if (findIndex > -1) {
        // new
        const index = state.articles[findIndex].intros.findIndex(
          (num) => num.id == state.articles[findIndex].selectedRephraseTextId
        )
        state.articles[findIndex].intros[index].intro = action.payload.text
        state.articles[findIndex].intros_en[index].intro = action.payload.text_en

        if (
          Object.keys(state.articles[findIndex].chosen_intro).length > 0 &&
          state.articles[findIndex].chosen_intro.id ==
            state.articles[findIndex].selectedRephraseTextId
        ) {
          state.articles[findIndex].chosen_intro.value = action.payload.text
          state.articles[findIndex].chosen_intro.isEdited = true
        }
      } else {
        // old

        const index = state.intros.findIndex((num) => num.id == state.selectedRephraseTextId)
        state.intros[index].intro = action.payload.text
        state.intros_en[index].intro = action.payload.text_en

        if (
          Object.keys(state.chosen_intro).length > 0 &&
          state.chosen_intro.id == state.selectedRephraseTextId
        ) {
          state.chosen_intro.value = action.payload.text
          state.chosen_intro.isEdited = true
        }
      }
    },
    setRephrasedOutlinedText: (state, action) => {
      const findIndex = parseInt(action?.payload?.id)

      const index = parseInt(action?.payload?.key)
      if (findIndex > -1) {
        state.articles[findIndex].outlines[index].outlineobj = action.payload.outlines
        state.articles[findIndex].outlines_en[index].outlineobj_en = action.payload.outlines_en
        // new
      } else {
        // old

        const newState = JSON.parse(JSON.stringify(state.outlines))

        state.outlines[index].outlineobj = action.payload.outlines
        state.outlines_en[index].outlineobj_en = action.payload.outlines_en
      }
    },
    setChoosenOutlines: (state, action) => {
      const findIndex = parseInt(action?.payload?.id)
      if (findIndex > -1) {
        // new
        state.articles[findIndex].chosen_outlines = action.payload.chosen_outlines
        state.articles[findIndex].chosen_outlines_en = action.payload.chosen_outlines_en
        state.articles[findIndex].isOutlineSelected = true
      } else {
        // old
        return {
          ...state,
          chosen_outlines: action.payload.chosen_outlines,
          chosen_outlines_en: action.payload.chosen_outlines_en,
          isOutlineSelected: true,
        }
      }
    },
    setArticleHTML: (state, action) => {
      const findIndex = parseInt(action?.payload?.id)
      if (findIndex > -1) {
        // new
        let articleArray = state.articles

        articleArray[findIndex].articleHTML = action.payload.value
        state.articles = articleArray
      } else {
        // old
        state.articleHTML = action.payload
      }
    },
    setEditedOutlines: (state, action) => {
      const findIndex = parseInt(action?.payload?.id)
      if (findIndex > -1) {
        // new
        let index = action.payload.key
        state.articles[findIndex].outlines[index].outlineobj = action.payload.outlines
        state.articles[findIndex].outlines_en[index].outlineobj_en = action.payload.outlines_en
        if (
          Object.keys(state.articles[findIndex].chosen_outlines).length > 0 &&
          state.articles[findIndex].chosen_outlines.id == index
        ) {
          state.articles[findIndex].chosen_outlines.value.outlineobj = action.payload.outlines
          state.articles[findIndex].chosen_outlines_en.value.outlineobj_en =
            action.payload.outlines_en
          // state.articles[findIndex].chosen_outlines = {
          //   id: index,
          //   value: action.payload.outlines,
          // }
          // state.articles[findIndex].chosen_outlines_en = {
          //   id: index,
          //   value: action.payload.outlines_en,
          // }
        }
      } else {
        let index = action.payload.key
        state.outlines[index].outlineobj = action.payload.outlines
        state.outlines_en[index].outlineobj_en = action.payload.outlines_en
        if (
          Object.keys(state.chosen_outlines.value.outlineobj).length > 0 &&
          state.chosen_outlines.id == index
        ) {
          state.chosen_outlines.value.outlineobj = action.payload.outlines
          state.chosen_outlines_en.value.outlineobj_en = action.payload.outlines_en
        }
      }
    },
    setChoosenTitle: (state, action) => {
      const findIndex = parseInt(action?.payload?.id)
      if (findIndex > -1) {
        // new

        state.articles[findIndex].chosen_title = action.payload.chosen_title
        state.articles[findIndex].chosen_title_en = action.payload.chosen_title_en
        state.articles[findIndex].isTitleSelected = action.payload.titleSelected
      } else {
        return {
          ...state,
          chosen_title: action.payload.chosen_title,
          chosen_title_en: action.payload.chosen_title_en,
          isTitleSelected: action.payload.titleSelected,
        }
      }
    },
    setChoosenIntro: (state, action) => {
      const findIndex = parseInt(action?.payload?.id)
      if (findIndex > -1) {
        // new
        state.articles[findIndex].chosen_intro = action.payload.chosen_intro
        state.articles[findIndex].chosen_intro_en = action.payload.chosen_intro_en
        state.articles[findIndex].isIntroSelected = action.payload.introSelected
      } else {
        return {
          ...state,
          chosen_intro: action.payload.chosen_intro,
          chosen_intro_en: action.payload.chosen_intro_en,
          isIntroSelected: action.payload.introSelected,
        }
      }
    },
    setArticleEmpty: () => {
      return {...initialState}
    },
    emptyArticle: (state) => {
      return {
        ...state,
        articles: [],
        Tone_en: 'None',
        Quality_type_en: 'Pro',
        Point_of_view_en: null,
        Number_of_outputs: '',
        Topic: {id: 0, value: '', isEdited: false},
        Topic_en: {id: 0, value: '', isEdited: false},
        topic_edited: 0,
        Keywords: {},
        Keywords_en: {},
        titles: [],
        titles_en: [],
        chosen_title: {},
        chosen_title_en: {},
        chosen_title_edited: 0,
        intros: [],
        intros_en: [],
        chosen_intro: {},
        chosen_intro_en: {},
        chosen_intro_edited: 0,
        outlines: {},
        outlines_en: {},
        chosen_outlines: {},
        chosen_outlines_en: {},
        chosen_outlines_edited: 0,
        paragraphs: {},
        paragraphs_en: {},
        picture_url: '',
        isTitleSelected: false,
        isIntroSelected: false,
        isOutlineSelected: false,
        isArticleSelected: false,
        stepper: 0,
        selectedRephraseTextId: null,
        isTopicSelectedError: false,
        isTitleSelectedError: false,
        isIntroSelectedError: false,
        isOutlineSelectedError: false,
        isArticleSelectedError: false,
        isLoadingAction: false,
        isRephrasing: false,
        articleData: {},
        articleHTML: '',
        currentArticleID: '',
        isEditing: false,
        steps: [
          {id: 0, name: 'TOPIC', active: true},
          {id: 1, name: 'TITLE', active: false},
          {id: 2, name: 'INTRO', active: false},
          {id: 3, name: 'OUTLINES', active: false},
          {id: 4, name: 'ARTICLE', active: false},
        ],
      }
    },
    setActiveState: (state, action) => {
      const findIndex = parseInt(action?.payload?.id)
      if (findIndex > -1) {
        // new
        const newState = JSON.parse(JSON.stringify(state.articles[findIndex]))

        let tempSteps = [...newState.steps]
        tempSteps = tempSteps.map((obj) => {
          if (obj.id === action.payload.value) {
            obj.active = true
          }
          return obj
        })
        state.articles[findIndex].steps = tempSteps
      } else {
        // old
        const newState = JSON.parse(JSON.stringify(state))

        let tempSteps = [...newState.steps]
        tempSteps = tempSteps.map((obj) => {
          if (obj.id === action.payload) {
            obj.active = true
          }
          return obj
        })
        return {...state, steps: tempSteps}
      }
    },
    setAllInactiveState: (state, action) => {
      const findIndex = parseInt(action?.payload?.id)
      if (findIndex > -1) {
        // new
        const newState = JSON.parse(JSON.stringify(state.articles[findIndex]))
        let tempSteps = [...newState.steps]
        tempSteps = tempSteps.map((obj) => {
          if (obj.id > action.payload.value) {
            obj.active = false
          }
          return obj
        })
        state.articles[findIndex].steps = tempSteps
      } else {
        // old
        const newState = JSON.parse(JSON.stringify(state))
        let tempSteps = [...newState.steps]
        tempSteps = tempSteps.map((obj) => {
          if (obj.id > action.payload.value) {
            obj.active = false
          }
          return obj
        })
        return {...state, steps: tempSteps}
      }
    },
    resetTitles: (state, action) => {
      const findIndex = parseInt(action?.payload?.id)
      if (findIndex > -1) {
        // new

        state.articles[findIndex].Keywords = {}
        state.articles[findIndex].Keywords_en = {}
        state.articles[findIndex].titles = []
        state.articles[findIndex].titles_en = []
        state.articles[findIndex].chosen_title = {}
        state.articles[findIndex].chosen_title_en = {}
        state.articles[findIndex].chosen_title_edited = 0
        state.articles[findIndex].isTitleSelected = false
      } else {
        return {
          ...state,
          Keywords: {},
          Keywords_en: {},
          titles: [],
          titles_en: [],
          chosen_title: {},
          chosen_title_en: {},
          chosen_title_edited: 0,
          isTitleSelected: false,
        }
      }
    },
    resetSteps: (state, action) => {
      const data = state.steps.map((step) => {
        return {
          ...step,
          active: false,
        }
      })
      return {
        ...state,
        steps: data,
      }
    },
    resetIntros: (state, action) => {
      const findIndex = parseInt(action?.payload?.id)
      if (findIndex > -1) {
        // new

        state.articles[findIndex].intros = []
        state.articles[findIndex].intros_en = []
        state.articles[findIndex].chosen_intro = {}
        state.articles[findIndex].chosen_intro_en = {}
        state.articles[findIndex].chosen_intro_edited = 0
        state.articles[findIndex].isIntroSelected = false
      } else {
        // old
        return {
          ...state,
          intros: [],
          intros_en: [],
          chosen_intro: {},
          chosen_intro_en: {},
          chosen_intro_edited: 0,
          isIntroSelected: false,
        }
      }
    },
    resetOutlines: (state, action) => {
      const findIndex = parseInt(action?.payload?.id)
      if (findIndex > -1) {
        // new
        state.articles[findIndex].outlines = {}
        state.articles[findIndex].outlines_en = {}
        state.articles[findIndex].chosen_outlines = {}
        state.articles[findIndex].chosen_outlines_en = {}
        state.articles[findIndex].chosen_outlines_edited = 0
        state.articles[findIndex].isOutlineSelected = false
        state.articles[findIndex].articleData = {}
        state.articles[findIndex].articleHTML = ''
      } else {
        // old
        return {
          ...state,
          outlines: {},
          outlines_en: {},
          chosen_outlines: {},
          chosen_outlines_en: {},
          chosen_outlines_edited: 0,
          isOutlineSelected: false,
          articleData: {},
          articleHTML: '',
        }
      }
    },
    resetChosenOutline: (state, action) => {
      const findIndex = parseInt(action?.payload)
      if (findIndex > -1) {
        // new
        state.articles[findIndex].chosen_outlines = {}
        state.articles[findIndex].chosen_outlines_en = {}
        state.articles[findIndex].chosen_outlines_edited = 0
        state.articles[findIndex].isOutlineSelected = false
      } else {
        state.chosen_outlines = {}
        state.chosen_outlines_en = {}
        state.chosen_outlines_edited = 0
        state.isOutlineSelected = false
      }
    },
    resetArticle: (state, action) => {
      let findIndex = -1
      findIndex = state.articles?.findIndex(
        (article) => article && article.id === parseInt(action?.payload?.id)
      )
      if (findIndex > -1) {
        // new
        state.articles[findIndex].articleHTML = ''
        state.articles[findIndex].currentArticleID = ''
      } else {
        // old
        return {
          ...state,
          articleHTML: '',
          currentArticleID: '',
        }
      }
    },
    setArticleList: (state, action) => {
      state.articleList = [...state.articleList, ...action.payload]
    },
    setChosenArticle: (state, action) => {
      state.chosenArticle = action.payload
    },
    setArticleCount: (state, action) => {
      state.articleCount = action.payload
    },
  },
})

export const useArticleSelector = () => useSelector((state) => state.articleSlice)

export const articleActions = articleSlice.actions
export const articleReducer = articleSlice.reducer
