/* eslint-disable react-hooks/exhaustive-deps */
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import {useLang} from '../../../i18n/Metronici18n'
import {useLayout} from '../../core'
import {Header} from './Header'
import {Navbar} from './Navbar'
import {useMediaQuery} from 'react-responsive'
import './Styles/Styles.css'

export function HeaderWrapper() {
  const isTabletOrMobile = useMediaQuery({query: '(max-width: 800px)'})
  const {config, classes} = useLayout()
  const lang = useLang()
  if (!config.app?.header?.display) {
    return null
  }

  return (
    <>
      <style>
        {`.headerInnerClass{
        @media screen and (max-width: 767px) {
          background-color:white;
        
        }
      }`}
      </style>
      <div
        id='kt_app_header'
        style={{
          position: 'fixed',
          zIndex: 10004,
          display: 'flex',
          alignItems: lang === 'en' ? 'center' : 'start',
        }}
        className={clsx('app-header', 'header-style')}
      >
        <div
          id='kt_app_header_container'
          className={clsx(
            'app-container flex-lg-grow-1 headerInnerClass',
            // lang === 'en' ? 'bar-left' : 'bar-right',
            lang === 'en' ? '' : 'bar-right',
            classes.headerContainer.join(' '),
            config.app?.header?.default?.containerClass
          )}
          style={{width: '100%', marginTop: lang === 'en' ? '' : '10px'}}
        >
          {config.app.sidebar?.display && (
            <>
              <div
                className='d-flex align-items-center d-lg-none ms-n2 me-2'
                title='Show sidebar menu'
                style={{
                  position: lang === 'heb' ? 'fixed' : 'relative',
                  right: lang === 'heb' ? (isTabletOrMobile ? '0px' : '60px') : '',
                  top: lang === 'heb' ? (isTabletOrMobile ? '10px' : '15px') : '',
                }}
              >
                <div
                  className='btn btn-icon btn-active-color-primary w-35px h-35px'
                  id='kt_app_sidebar_mobile_toggle'
                >
                  <KTSVG path={toAbsoluteUrl('/hamburger.svg')} className=' svg-icon-1' />
                </div>
                <div className='d-flex align-items-center flex-grow-1 flex-lg-grow-0'>
                  <Link to='/dashboard' className='d-lg-none'>
                    <img alt='Logo1' src={toAbsoluteUrl('/logo-kot.svg')} className='h-30px' />
                  </Link>
                </div>
              </div>
            </>
          )}

          {!config.app.sidebar?.display && (
            <div className='d-flex align-items-center flex-grow-1 flex-lg-grow-0 me-lg-15'>
              <Link to='/dashboard'>
                {config.layoutType !== 'dark-header' ? (
                  <img
                    alt='Logo2'
                    src={toAbsoluteUrl('/media/logos/default-dark.svg')}
                    className='h-20px h-lg-30px app-sidebar-logo-default'
                  />
                ) : (
                  <>
                    <img
                      alt='Logo3'
                      src={toAbsoluteUrl('/media/logos/default.svg')}
                      className='h-20px h-lg-30px app-sidebar-logo-default theme-light-show'
                    />
                    <img
                      alt='Logo4'
                      src={toAbsoluteUrl('/media/logos/default-dark.svg')}
                      className='h-20px h-lg-30px app-sidebar-logo-default theme-dark-show'
                    />
                  </>
                )}
              </Link>
            </div>
          )}

          <div
            id='kt_app_header_wrapper'
            className='d-flex align-items-stretch justify-content-between flex-lg-grow-1'
          >
            {config.app.header.default?.content === 'menu' &&
              config.app.header.default.menu?.display && (
                <div
                  className='app-header-menu app-header-mobile-drawer align-items-stretch'
                  data-kt-drawer='true'
                  data-kt-drawer-name='app-header-menu'
                  data-kt-drawer-activate='{default: true, lg: false}'
                  data-kt-drawer-overlay='true'
                  data-kt-drawer-width='225px'
                  data-kt-drawer-direction='end'
                  data-kt-drawer-toggle='#kt_app_header_menu_toggle'
                  data-kt-swapper='true'
                  data-kt-swapper-mode="{default: 'append', lg: 'prepend'}"
                  data-kt-swapper-parent="{default: '#kt_app_body', lg: '#kt_app_header_wrapper'}"
                >
                  <Header />
                </div>
              )}

            <div
              dir={lang === 'heb' ? 'rtl' : ''}
              style={{
                position: lang === 'heb' ? 'fixed' : 'relative',
                left: lang === 'heb' ? (isTabletOrMobile ? '20px' : '50px') : '',
              }}
            >
              {' '}
              <Navbar />
            </div>
          </div>
        </div>
        {/* {lang === 'heb' && (
          <div dir='rtl' style={{marginLeft: '50px'}}>
            {' '}
            <Navbar />
          </div>
        )} */}
      </div>
    </>
  )
}
