import {
  applyActionCode,
  confirmPasswordReset,
  EmailAuthProvider,
  reauthenticateWithCredential,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  updateEmail,
  updatePassword,
  verifyPasswordResetCode,
} from '@firebase/auth'
import {auth, firestore} from '../../../firebase/FirebaseConfig'
import {userActions} from './loginSlice'
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  updateDoc,
  where,
} from '@firebase/firestore'
import {toast} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {Action} from 'react-query/types/core/mutation'
import {Dispatch} from '@reduxjs/toolkit'
import {async} from '@firebase/util'
import {useIntl} from 'react-intl'

export const oAuth = (userData: any, action: any, intl: any) => async (dispatch: any) => {
  try {
    dispatch(userActions.setLoader(true))

    const userId: any = auth?.currentUser?.uid
    const docRef = doc(firestore, 'users', userId)

    await setDoc(docRef, userData)
    const snapshot: any = await getDoc(docRef)

    if (snapshot.exists) {
      const data = snapshot.data()

      dispatch(userActions.setUserInfoAction(data))
      dispatch(
        userActions.setAccessTokenAction({
          userID: userId,
        })
      )
      localStorage.setItem('access_token', userId)
      dispatch(userActions.setSessionStartDateAction(Date.now()))
      dispatch(userActions.setLoader(false))
      toast.success(intl.formatMessage({id: 'ACC_CREATED'}))
      action && action()
    } else {
      localStorage.setItem('access_token', userId)
      return {success: false}
    }
  } catch (error) {
    dispatch(userActions.setLoader(false))
  }
}

export const loginUser =
  (data: any, action: any, setLoading: any, intl: any) => async (dispatch: any) => {
    try {
      dispatch(userActions.setLoader(true))
      signInWithEmailAndPassword(auth, data.email, data.password)
        .then(async (userCredential: any) => {
          if (userCredential.user.emailVerified === true) {
            const userRef = collection(firestore, 'users')
            const q = query(userRef, where('email', '==', data.email))
            const querySnapshot = await getDocs(q)
            let userValue
            let userID: any
            querySnapshot.forEach((doc) => {
              userID = doc.id
              userValue = doc.data()
            })
            localStorage.setItem('authToken', userCredential?.user?.accessToken)
            localStorage.setItem('access_token', userID)
            dispatch(
              userActions.setAccessTokenAction({
                userID: userID,
              })
            )

            dispatch(userActions.setSessionStartDateAction(Date.now()))
            dispatch(userActions.setUserInfoAction(userValue))
            toast.success(intl.formatMessage({id: 'LOGIN_SUCCESSFULLY'}))
            dispatch(userActions.setLoader(false))
            action && action()
          } else {
            dispatch(userActions.setLoader(false))
            toast.success(intl.formatMessage({id: 'ACC_ACTIVATE_REQ'}))
          }
        })
        .catch(function (error) {
          // Handle Errors here.
          var errorCode = error.code
          var errorMessage = error.message

          if (error.code === 'auth/wrong-password') {
            toast.error(intl.formatMessage({id: 'WRONG_PASSWORD'}))
          } else {
            toast.error(intl.formatMessage({id: 'SOMETHING_WENT_WRONG'}))
          }
          dispatch(userActions.setLoader(false))
        })
        .finally(() => {
          setLoading(false)
        })
    } catch (error) {
      dispatch(userActions.setLoader(false))
      toast.error(intl.formatMessage({id: 'SOMETHING_WENT_WRONG'}))
    }
  }

// /////////////////////////////////////
export const setUserInfo = (value: any, userId: any, action: any) => async (dispatch: any) => {
  dispatch(userActions.setUserInfoAction(value))
  localStorage.setItem('access_token', userId)

  dispatch(
    userActions.setAccessTokenAction({
      userID: userId,
    })
  )

  // const docRef = doc(firestore, 'users', userId)
  // await setDoc(docRef, {isLoggedIn: true}, {merge: true})
  dispatch(userActions.setSessionStartDateAction(Date.now()))
  // dispatch(updatePlan(userId))
  action && action()
}

// ///////////////////////////////////Logout///////////////////

export const logout =
  (action: any, userId: any): any =>
  async (dispatch: Dispatch<any>) => {
    dispatch(
      userActions.setAccessTokenAction({
        userID: null,
      })
    )
    dispatch(userActions.setSessionStartDateAction(null))
    dispatch(userActions.setUserInfoAction({}))
    localStorage.removeItem('access_tokenn')
    localStorage.removeItem('authToken')
    const docRef = doc(firestore, 'users', userId)
    // await setDoc(docRef, {isLoggedIn: false}, {merge: true})

    // toast.success(intl.formatMessage({id: 'LOGOUT_SUCCESSFULY'}))

    action && action()
  }

// ///////////////////////////fOrgot password/////////////////////////////
export const forgotPasswordSendEmail =
  (email: any, intl: any): any =>
  async (dispatch: Dispatch<any>) => {
    try {
      dispatch(userActions.setLoader(true))
      const userRef = collection(firestore, 'users')
      const q = query(userRef, where('email', '==', email))
      const querySnapshot = await getDocs(q)
      let userValue: any
      let userID
      querySnapshot.forEach((doc) => {
        userID = doc.id
        userValue = doc.data()
      })

      var actionCodeSettings = {
        url: `${process.env.REACT_APP_NEXT_PUBLIC_FRONTEND_URL}/resetPassword?uid=${userID}`,
        handleCodeInApp: true,
      }
      // return;
      if (userValue?.registeredBy == 'google.com') {
        sendPasswordResetEmail(auth, email, actionCodeSettings)
          .then(async () => {
            toast.success(intl.formatMessage({id: 'CHECK_MAIL'}))
            dispatch(userActions.setLoader(false))
          })
          .catch(function (error) {
            // Handle Errors here.
            var errorCode = error.code
            var errorMessage = error.message
            dispatch(userActions.setLoader(false))
            if (error.code === 'auth/user-not-found') {
              toast.success(intl.formatMessage({id: 'CHECK_MAIL'}))
            } else {
              toast.error(intl.formatMessage({id: 'SOMETHING_WENT_WRONG'}))
              // toast.success('Please23 check your email')
            }
          })
      } else {
        dispatch(userActions.setLoader(false))
        toast.success(intl.formatMessage({id: 'CHECK_MAIL'}))
      }
      // }
    } catch (error) {
      dispatch(userActions.setLoader(false))
      toast.success(intl.formatMessage({id: 'CHECK_MAIL'}))
    }
  }

export const resetPass =
  (data: any, action: any, intl: any): any =>
  async (dispatch: Dispatch<any>) => {
    try {
      dispatch(userActions.setLoader(true))

      verifyPasswordResetCode(auth, data.actionCode)
        .then((email) => {
          const accountEmail = email
          const newPassword = '...'
          confirmPasswordReset(auth, data.actionCode, data.password)
            .then((resp) => {
              toast.success(intl.formatMessage({id: 'RESET_PASSWORD_SUCCESSFULLY'}))
              dispatch(userActions.setLoader(false))
              action && action()
            })
            .catch((error) => {
              toast.error(intl.formatMessage({id: 'SOMETHING_WENT_WRONG'}))
              dispatch(userActions.setLoader(false))
            })
        })
        .catch((error) => {
          if (error.code === 'auth/invalid-action-code') {
            toast.error(intl.formatMessage({id: 'RESET_TOKEN_EXPIRED'}))
          } else if (error.code === 'auth/internal-error') {
            toast.error(intl.formatMessage({id: 'RESET_TOKEN_NOTFOUND'}))
          } else {
            toast.error(intl.formatMessage({id: 'SOMETHING_WENT_WRONG'}))
          }
          dispatch(userActions.setLoader(false))
        })
    } catch (error) {
      dispatch(userActions.setLoader(false))
    }
  }

export const activateAccount =
  (uid: string, actionCode: string, action: any, intl: any): any =>
  async (dispatch: Dispatch<any>) => {
    const docRef = doc(firestore, 'users', uid)

    applyActionCode(auth, actionCode)
      .then(async () => {
        toast.success(intl.formatMessage({id: 'ACCOUNT_ACTIVED'}))

        await setDoc(docRef, {activation: true}, {merge: true})
        const snapshot = await getDoc(docRef)
        if (snapshot?.exists()) {
          const data = snapshot.data()
          action && action()
        }
      })
      .catch((error) => {
        console.trace('Error>>>', error)
        if (error.code === 'auth/invalid-action-code') {
          toast.error(intl.formatMessage({id: 'ACCOUNT_TOKEN_EXPIRED'}))
        } else {
          toast.error(intl.formatMessage({id: 'SOMETHING_WENT_WRONG'}))
        }
        action && action()
      })
  }

export const updateUserPassword =
  (oldPassword: string, newPassword: string, email: string, resetForm: any, intl: any): any =>
  (dispatch: Dispatch<any>) => {
    const currentUser = auth.currentUser
    const credential = EmailAuthProvider.credential(email, oldPassword)
    if (currentUser && currentUser !== null) {
      reauthenticateWithCredential(currentUser, credential)
        .then(() => {
          updatePassword(currentUser, newPassword)
            .then(() => {
              toast.success(intl.formatMessage({id: 'RESET_PASSWORD_SUCCESSFULLY'}))
              resetForm && resetForm()
            })
            .catch((error: any) => {
              toast.error(intl.formatMessage({id: 'SOMETHING_WENT_WRONG'}))

              resetForm && resetForm()
            })
        })
        .catch((error: any) => {
          // Handle re-authentication errors, such as incorrect password
          toast.error(intl.formatMessage({id: 'OLD_PASSWORD_INVALID'}))

          resetForm && resetForm()
        })
    } else {
      toast.error(intl.formatMessage({id: 'SOMETHING_WENT_WRONG'}))

      resetForm && resetForm()
    }
  }

export const updateUserEmail =
  (newEmail: string, intl: any): any =>
  (dispatch: Dispatch<any>) => {
    const currentUser = auth.currentUser

    if (currentUser && currentUser !== null) {
      updateEmail(currentUser, newEmail)
        .then((res: any) => {
          toast.success(intl.formatMessage({id: 'EMAIL_UPDATED'}))

          dispatch(userActions.setEmail(newEmail))
        })
        .catch((error: any) => {
          toast.error(intl.formatMessage({id: 'SOMETHING_WENT_WRONG'}))
        })
    } else {
      toast.error(intl.formatMessage({id: 'SOMETHING_WENT_WRONG'}))
    }
  }

export const updatePlan = async (userId: any) => {
  const docRef = doc(firestore, 'users', userId)
  try {
    const userDoc = await getDoc(docRef)
    if (userDoc.exists()) {
      const currentPlan = userDoc.data().plan
      if (currentPlan === '' || currentPlan === undefined || currentPlan === null) {
        await updateDoc(docRef, {
          plan: 'FREE',
        })
      }
    }
  } catch (error) {
    console.error('Error updating document: ', error)
  }
}