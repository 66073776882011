import {collection, query, where, updateDoc, doc, getFirestore, getDoc} from '@firebase/firestore'
import React, {FC, useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {auth, firestore} from '../../../firebase/FirebaseConfig'
import {PrimaryButton, SecondayButton} from '../../../_metronic/layout/components/button'
import {CommonPasswordField, CommonTextField} from '../../../_metronic/layout/components/textField'
import {PageTitle} from '../../../_metronic/layout/core'
import {BuilderPage} from './BuilderPage'
import {userActions} from '../../../store/auth/login/loginSlice'
import {
  EmailAuthProvider,
  reauthenticateWithCredential,
  updateEmail,
  updatePassword,
} from '@firebase/auth'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {updateUserEmail, updateUserPassword} from '../../../store/auth/login/loginActions'
import {useIntl} from 'react-intl'
import {toast} from 'react-toastify'

const initialValues = {
  oldPassword: '',
  newPassword: '',
  confirmPassword: '',
}

const changePasswordSchema = Yup.object().shape({
  oldPassword: Yup.string().required('Old Password is required'),
  newPassword: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('newPassword')], 'Match the password')
    .required('Confirm the password'),
})

const BuilderPageWrapper: FC = () => {
  const intl = useIntl()
  const [isEmailValid, setEmailIsValid] = useState<any>(true)
  const [userData, setUserData] = useState<any>({
    firstName: '',
    lastName: '',
    email: '',
  })

  const {user, accessToken: userId} = useSelector((state: any) => state.login)
  const dispatch = useDispatch()
  const handleUpdateUserName = async () => {
    const userRef = doc(getFirestore(), 'users', userId)
    try {
      await updateDoc(userRef, {
        firstName: userData.firstName,
        lastName: userData.lastName,
        name: `${userData.firstName} ${userData.lastName}`,
      })
      const userDoc = await getDoc(userRef)
      if (userDoc.exists()) {
        const updatedUser = userDoc.data()
        dispatch(userActions.setUserInfoAction(updatedUser))
      }
      toast.success('Name updated successfully')
    } catch (error) {
      console.error('Error updating user:', error)
      toast.error(intl.formatMessage({id: 'SOMETHING_WENT_WRONG'}))
    }
  }

  const handleGetuserName = () => {
    setUserData({
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
    })
  }

  useEffect(() => {
    if (user) {
      handleGetuserName()
    }
  }, [user])

  const formik = useFormik({
    initialValues,
    validationSchema: changePasswordSchema,
    onSubmit: async (values, {resetForm}) => {
      try {
        dispatch(updateUserPassword(values.oldPassword, values.newPassword, user.email, resetForm, intl))
      } catch (error) {}
    },
  })

  const handleUpdateEmail = () => {
    dispatch(updateUserEmail(userData.email, intl))
  }

  function isValidEmail(email: any) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return emailRegex.test(email)
  }

  return (
    <>
      <style>
        {`.mainDiv {
        box-shadow:0px 2px 4px rgba(0, 0, 0, 0.1), 0px -2px 4px rgba(0, 0, 0, 0.1);
        border-radius:10px;
        padding:30px;
      }
      
      .mainForm{
        margin-right: 20px;
        @media screen and (max-width: 767px) {
          margin-right: 0px;
        }
      }

        .btnprimaryClass{
          display: flex;
          justify-content: flex-end;
          gap: 10px;
          margin: 20px;
          @media screen and (max-width: 767px) {
            display: flex;
            justify-content: flex-start;
            margin:  20px 0px 20px 0px;
          }
        }

      .headingClass{
        @media screen and (max-width: 767px) {
          text-align: center;
        }
      }
      `}
      </style>
      {/* <PageTitle breadcrumbs={[]}>Setting</PageTitle> */}
      <div className='mainDiv text-black'>
        <p className='fs-1hx fw-bold headingClass'>
          {intl.formatMessage({id: 'PERSONAL_DETAILS'})}
        </p>
        <form className='mainForm'>
          <div className='row'>
            <div className='col-md-6'>
              <CommonTextField
                placeholder={intl.formatMessage({id: 'ISRAELI'})}
                onChange={(e: any) => {
                  setUserData({
                    ...userData,
                    firstName: e.target.value,
                  })
                }}
                value={userData?.firstName}
                label={intl.formatMessage({id: 'FIRST_NAME'})}
              />
            </div>
            <div className='col-md-6'>
              <CommonTextField
                placeholder={intl.formatMessage({id: 'ISRAELI'})}
                onChange={(e: any) => {
                  setUserData({
                    ...userData,
                    lastName: e.target.value,
                  })
                }}
                value={userData?.lastName}
                label={intl.formatMessage({id: 'LAST_NAME'})}
              />
            </div>
          </div>
        </form>

        <div className='btnprimaryClass'>
          <PrimaryButton
            onClick={() => handleUpdateUserName()}
            name={intl.formatMessage({id: 'UPDATE_NAME'})}
          />
        </div>
        <hr />
        <div className='mainForm'>
          <CommonTextField
            value={userData?.email}
            onChange={(e: any) => {
              setUserData({
                ...userData,
                email: e.target.value,
              })

              setEmailIsValid(isValidEmail(e.target.value))
            }}
            disabled
            error={!isEmailValid && 'Please enter a valid email'}
            placeholder='email@example.com'
            label={intl.formatMessage({id: 'EMAIL'})}
            pattern='[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$'
            required
          />
        </div>
        {/* <div className='btnprimaryClass'>
          <SecondayButton
            onClick={handleUpdateEmail}
            name={intl.formatMessage({id: 'CHANGE_EMAIL'})}
          />
        </div> */}
        {/* <hr />
        <p className='fs-1hx fw-bold headingClass'>{intl.formatMessage({id: 'CHANGE_PASSWORD'})}</p>
        <div className='mainForm'>
          <CommonPasswordField
            formik={formik}
            name='oldPassword'
            onChange={formik.handleChange}
            value={formik.values.oldPassword}
            placeholder={intl.formatMessage({id: 'ENTER_OLD_PASSWORD'})}
            label={intl.formatMessage({id: 'OLD_PASSWORD'})}
          />
          <CommonPasswordField
            formik={formik}
            name='newPassword'
            onChange={formik.handleChange}
            value={formik.values.newPassword}
            placeholder={intl.formatMessage({id: 'ENTER_NEW_PASSWORD'})}
            label={intl.formatMessage({id: 'NEW_PASSWORD'})}
          />
          <CommonPasswordField
            name='confirmPassword'
            formik={formik}
            onChange={formik.handleChange}
            value={formik.values.confirmPassword}
            placeholder={intl.formatMessage({id: 'ENTER_NEW_PASSWORD_CONFIRM'})}
            label={intl.formatMessage({id: 'CONFIRM_NEW_PASSWORD'})}
          />
        </div>
        <div className='btnprimaryClass'>
          <SecondayButton
            onClick={() => formik.resetForm()}
            name={intl.formatMessage({id: 'CANCEL'})}
            btnStyle={{border: 'none !important'}}
          />
          <PrimaryButton
            type='button'
            onClick={() => {

              formik.handleSubmit()
            }}
            name={intl.formatMessage({id: 'UPDATE_PASSWORD'})}
          />
        </div> */}
      </div>
      {/* <BuilderPage /> */}
    </>
  )
}

export default BuilderPageWrapper
