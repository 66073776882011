import {collection, doc, setDoc, updateDoc, arrayUnion} from 'firebase/firestore'
import {toast} from 'react-toastify'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import {lazy, useEffect, useRef, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {v4 as uuidv4} from 'uuid'
import {useIntl} from 'react-intl'
import {useNavigate, useParams} from 'react-router-dom'
// import {articleActions} from '../../../store/articleSlice/articleReducers'
import {useQuillContext} from '../../SharedComponents/context/QuillContext'
import {Loader} from '../../SharedComponents/Loader'
import LoaderGenerate from '../../SharedComponents/LoaderGenerate'
import {toAbsoluteUrl} from '../../_metronic/helpers'
import {firestore} from '../../firebase/FirebaseConfig'
import {useLang} from '../../_metronic/i18n/Metronici18n'
import {userActions} from '../../store/auth/login/loginSlice'
import {speedActions} from '../../store/speedSlice/speedReducers'
import {articeRephraseText, improveArticeText} from '../../store/speedSlice/speedActions'
const SAVEARTICLE = toAbsoluteUrl('/media/spa/Floppy.png')
const UNDO = toAbsoluteUrl('/media/spa/undo.png')
const REDO = toAbsoluteUrl('/media/spa/redo.png')

const ArticlePage = () => {
  const lang = useLang()
  const currentUser = useSelector((state) => state.login.user)
  const {accessToken: UserID} = useSelector((state) => state.login)
  const params = useParams()
  const contentId = Number(params.contentId)
  const {isLoading, isLoadingAction, articleData, articleHTML, currentArticleID, language_content} =
    useSelector((state) => state.speed.articles[contentId])
  const {feature} = useSelector((state) => state.speed.articles[contentId])

  const dispatch = useDispatch()
  const intl = useIntl()
  let htmlString = articleHTML

  // if (!articleHTML) {
  //   htmlString = `
  //   <h1 >${articleData?.chosen_title?.value}</h1>
  //    <br />
  //    ${articleData?.picture_url ? `<img src=${articleData?.picture_url} />` : ''}

  //    <br />
  //    <p>${articleData?.picture_credit}</p>
  //    <br />
  //    <p>${articleData?.intros?.value}</p>
  //    <br />
  //    ${
  //      articleData &&
  //      Object.keys(articleData).length > 0 &&
  //      Object?.keys(articleData?.outlines)
  //        ?.map(
  //          (item) =>
  //            `<h4>${articleData?.outlines[item]?.value}</h4><p>${articleData?.paragraphs[item]}</p>`
  //        )
  //        .join('<br/><br/>')
  //    }
  //    `
  // } else {
  //   htmlString = articleHTML
  // }

  const {listening, setListening, setDelta} = useQuillContext()
  useEffect(() => {
    if (listening) {
      const editor = quillRef.current.getEditor()
      const upe = quillRef.current.makeUnprivilegedEditor(editor)
      const contents = upe.getContents()
      setDelta(contents)
      setListening(false)
    }
  }, [listening])

  const {user} = useSelector((state) => state.login)
  const [selectedText, setSelectedText] = useState('')
  const [value, setValue] = useState(htmlString)
  const [showTooltip, setShowTooltip] = useState(false)
  const [tooltipPosition, setTooltipPosition] = useState({
    x: 0,
    y: 0,
  })
  const [loader, setLoader] = useState(false)
  const [allText, setAllText] = useState('')
  const [outputData, setOutputData] = useState()
  const quillRef = useRef(null)
  const tooltipRef = useRef()
  const selectedRef = useRef()
  let Quill
  const [isReady, setIsReady] = useState(false)

  useEffect(() => {
    import('./ImageResize').then(() => {
      setIsReady(true)
    })
    return () => setIsReady(true)
  }, [])
  useEffect(() => {
    const html = htmlString
    setValue(html)
  }, [articleData])

  useEffect(() => {
    const handler = setTimeout(() => {
      dispatch(speedActions.setArticleHTML({index: contentId, value: value}))
    }, 500)

    return () => {
      clearTimeout(handler)
    }
  }, [value])

  useEffect(() => {
    if (!currentArticleID && articleHTML) {
      const HTML = articleHTML
      const collectionRef = collection(firestore, 'articles')
      const docRef = doc(collectionRef)
      dispatch(speedActions.setArticleID({index: contentId, value: docRef.id}))
      const newDoc = setDoc(docRef, {
        user_id: UserID,
        article: [
          {
            id: uuidv4(),
            plan: currentUser?.plan || 'FREE',
            contentHTML: HTML,
            contentBaner: articleData?.picture_url,
            creation_date: new Date(),
            language: language_content.name,
          },
        ],
        article_creation_date: new Date(),
        article_title: articleData?.chosen_title?.value,
        type: feature.name,
      })
    }
  }, [articleHTML])

  useEffect(() => {
    if (quillRef.current) {
      const editor = quillRef.current.getEditor()
      editor.on('selection-change', (range, oldRange, source) => {
        if (range) {
          if (range.length === 0) {
          } else {
            var text = editor.getText(range.index, range.length)
          }
        } else {
        }
      })
    }
  }, [quillRef.current])

  const handleMouseUp = () => {
    const editor = document.getElementById('editor')
    if (editor) {
      const selection = window.getSelection()
      if (selection.toString().length > 0) {
        setOutputData()
        setShowTooltip(false)
        setAllText('')
        setSelectedText('')
        setSelectedText(selection.toString())
        setAllText(selection.focusNode.parentNode.textContent)
        const rangeBounds = selection.getRangeAt(0)?.getBoundingClientRect()
        const editorBounds = editor?.getBoundingClientRect()

        const top = rangeBounds.top - editorBounds.top + window.pageYOffset
        const left = rangeBounds.left - editorBounds.left + window.pageXOffset

        setTooltipPosition({x: left + 35, y: top + 10})
        setShowTooltip(true)
      } else {
        setShowTooltip(false)
      }
    }
  }

  const calculateTooltipTop = (y) => {
    const editor = document.getElementById('editor')
    if (editor) {
      const tooltipHeight = 76

      const editorBounds = editor?.getBoundingClientRect()

      const spaceAbove = y - editorBounds.top
      const spaceBelow = editorBounds.bottom - y

      if (spaceBelow >= tooltipHeight) {
        return y
      } else if (spaceAbove >= tooltipHeight) {
        return y - tooltipHeight
      } else {
        return editorBounds.top
      }
    }
  }

  const calculateTooltipLeft = (x) => {
    const editor = document.getElementById('editor')
    if (editor) {
      const tooltipWidth = 96

      const editorBounds = editor?.getBoundingClientRect()
      const editorWidth = editorBounds.width

      const spaceLeft = x - editorBounds.left
      const spaceRight = editorBounds.right - x

      if (spaceLeft >= tooltipWidth) {
        return x - tooltipWidth
      } else if (spaceRight >= tooltipWidth) {
        return x
      } else if (editorWidth >= tooltipWidth) {
        const center = (editorBounds.left + editorBounds.right) / 2
        return center - tooltipWidth / 2
      } else {
        return editorBounds.left
      }
    }
  }

  const handleReplace = (newText, closeModal = true) => {
    const editor = quillRef.current.getEditor()
    const start = selectedRef.current.start
    const end = selectedRef.current.end

    import('quill').then((mod) => {
      const Delta = mod.default.import('delta')
      const delta = new Delta().retain(start).delete(end).insert(newText)

      editor.updateContents(delta, 'user')
    })

    setOutputData()
    setShowTooltip(false)
    setAllText('')
    setSelectedText('')
  }
  const updateCredits = async (cred) => {
    let NewCredits = user?.credits - cred
    dispatch(userActions.setCredits(NewCredits))
    const userRef = doc(firestore, 'users', UserID)
    await updateDoc(userRef, {
      credits: NewCredits,
    })
  }

  const action = (data) => {
    updateCredits(data?.credits)
    setLoader(false)
    const asArray = Object.entries(data)
    const filtered = asArray.filter(([key, value]) => key != 'credits')
    setOutputData(Object.fromEntries(filtered))
  }
  const RephraseText = (e) => {
    let data = {
      index: contentId,
      user_id: UserID,
      highlighted_text: selectedText,
      all_text: allText,
    }
    dispatch(articeRephraseText(data, action, intl))
  }
  const ImproveText = (e) => {
    let data = {
      index: contentId,
      user_id: UserID,
      highlighted_text: selectedText,
      all_text: allText,
    }
    dispatch(improveArticeText(data, action, intl))
  }

  const downloadLinkRef = useRef(null)

  const modules = {
    toolbar: [
      [{header: [1, 2, false]}],
      ['bold', 'italic', 'underline', 'strike'],
      [{align: ''}, {align: 'center'}, {align: 'right'}, {align: 'justify'}],
    ],
    imageResize: {
      parchment: Quill?.import('parchment'),
      modules: ['Resize', 'DisplaySize'],
      displaySize: true,
    },
    history: {
      delay: 1000,
      maxStack: 50,
      userOnly: true,
    },
  }

  const formats = [
    'header',
    'font',
    'size',
    'bold',
    'italic',
    'underline',
    'align',
    'strike',
    'script',
    'blockquote',
    'background',
    'list',
    'bullet',
    'indent',
    'link',
    'image',
    'color',
    'code-block',
  ]

  const handleUndo = (event) => {
    event.preventDefault()
    event.stopPropagation()
    quillRef.current.focus()
    quillRef.current.getEditor().history.undo && quillRef.current.getEditor().history.undo()
  }

  const handleRedo = (event) => {
    event.preventDefault()
    event.stopPropagation()
    quillRef.current.focus()
    quillRef.current.getEditor().history.redo && quillRef.current.getEditor().history.redo()
  }

  document.querySelectorAll('.ql-picker').forEach((tool) => {
    tool.addEventListener('mousedown', function (event) {
      event.preventDefault()
      event.stopPropagation()
    })
  })
  const handleSave = async () => {
    const HTML = articleHTML
    if (!currentArticleID) {
      const collectionRef = collection(firestore, 'articles')
      const docRef = doc(collectionRef)

      dispatch(speedActions.setArticleID({index: contentId, value: docRef.id}))
      const newDoc = await setDoc(docRef, {
        user_id: UserID,
        article: [
          {
            id: uuidv4(),
            plan: currentUser?.plan || 'FREE',
            contentHTML: HTML,
            contentBaner: articleData?.picture_url,
            creation_date: new Date(),
            language: language_content.name,
          },
        ],
      })
    } else {
      const articleRef = doc(firestore, 'articles', currentArticleID)
      const newArticle = {
        id: uuidv4(),
        contentHTML: HTML,
        contentBaner: articleData?.picture_url,
        creation_date: new Date(),
        language: language_content.name,
      }
      await updateDoc(articleRef, {
        article: arrayUnion(newArticle),
      })
      toast.success(intl.formatMessage({id: 'ARTICLE_SAVED'}))
    }
  }
  return (
    <div className='t-w-full t-flex t-flex-col t-text-black'>
      {isLoading ? (
        <LoaderGenerate
          className=' t-flex t-items-center t-justify-center '
          size='t-w-8 t-h-8'
          fill='#592E84'
        />
      ) : (
        <div id='editor' className='t-block t-relative quill-container' ref={downloadLinkRef}>
          {isReady && (
            <div className=' t-w-[94%] sm:t-w-[97%] t-sticky t-top-3 t-left-5 t-z-10   '>
              <div className='t-h-4 t-flex t-justify-between t-flex-row t-z-10 t-gap-2 t-pr-8'>
                <div className='t-flex t-items-center t-gap-2'>
                  <img
                    src={UNDO}
                    alt=''
                    height={12}
                    width={16}
                    className='t-sticky t-ml-2 t-mt-1 t-cursor-pointer'
                    onClick={lang == 'heb' ? handleRedo : handleUndo}
                  />
                  <img
                    src={REDO}
                    alt=''
                    height={12}
                    width={16}
                    className='t-sticky t-mt-1 t-cursor-pointer'
                    onClick={lang == 'heb' ? handleUndo : handleRedo}
                  />
                </div>
                <img
                  src={SAVEARTICLE}
                  alt=''
                  height={100}
                  width={100}
                  className='t-sticky t-ml-2 t-mt-1 t-cursor-pointer t-w-[20px] t-h-[20px]'
                  onClick={() => {
                    handleSave()
                  }}
                />
              </div>
            </div>
          )}
          <div className='QUILL_NON_CUSTOM' onMouseUp={handleMouseUp}>
            {isReady && (
              <ReactQuill
                ref={quillRef}
                theme='snow'
                scrollingContainer='html'
                value={value}
                onChange={setValue}
                sanitize={false}
                selectedRef={selectedRef}
                modules={modules}
                formats={formats}
                onChangeSelection={(selection, source, editor) => {
                  if (!selection) return
                  selectedRef.current = {
                    start: selection.index,
                    end: selection.length,
                  }
                }}
              />
            )}
          </div>
          {showTooltip && (
            <div
              style={{
                position: 'absolute',
                top: calculateTooltipTop(tooltipPosition.y),
                left: calculateTooltipLeft(tooltipPosition.x),
                borderColor: '#959494',
                zIndex: '999999',
              }}
              className='t-bg-white t-rounded-md'
            >
              {isLoadingAction ? (
                <div className='t-bg-WHITE t-cursor-pointer t-border-2  t-rounded-md  '>
                  <Loader
                    className='t-p-10 t-items-center t-justify-center t-flex '
                    size='t-w-8 t-h-8'
                    fill='#592E84'
                  />
                </div>
              ) : (
                <>
                  {outputData ? (
                    <>
                      {Object.keys(outputData).map((item, index) => {
                        return (
                          <div
                            key={index}
                            className='t-bg-WHITE hover:t-bg-[#592E84] hover:t-text-white hover:t-cursor-pointer t-border-2 t-py-2 t-px-4  t-text-[12px]'
                            onClick={(e) => {
                              e.stopPropagation()
                              handleReplace(outputData[item])
                            }}
                            dir={language_content.direction}
                          >
                            {outputData[item]}
                          </div>
                        )
                      })}
                    </>
                  ) : (
                    <div>
                      <div
                        className='t-bg-WHITE hover:t-bg-[#592E84] hover:t-text-white hover:t-cursor-pointer t-border-2 t-p-2 t-rounded-t-md  t-text-[12px]'
                        onClick={(e) => {
                          e.stopPropagation()
                          RephraseText(e)
                        }}
                        dir={language_content.direction}
                      >
                        {intl.formatMessage({id: 'REPHRASE_TEXT'})}
                      </div>
                      <div
                        className='t-bg-WHITE hover:t-bg-[#592E84] hover:t-text-white hover:t-cursor-pointer t-border-2 t-p-2 t-rounded-b-md  t-text-[12px]'
                        onClick={(e) => {
                          e.stopPropagation()
                          ImproveText(e)
                        }}
                        dir={language_content.direction}
                      >
                        {intl.formatMessage({id: 'IMPROVE_TEXT'})}
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          )}
        </div>
      )}
      <style>{`
            .quill-container .ql-toolbar{
              position:sticky;
              top:0;
              background: #F5F5F5;
              z-index:1;
              padding-left: 80px;
            }
            .ql-editor {   direction: ${
              language_content.name === 'English' || language_content.name === 'Russia'
                ? 'ltr'
                : 'rtl'
            };
            text-align: ${
              language_content.name === 'English' || language_content.name === 'Russia'
                ? 'left'
                : 'right'
            };
}
       
            #toolbar {
              display: flex;
              flex-wrap: wrap;
              justify-content: space-between;
              background-color: #f9f9f9;
              border-radius: 4px;
              margin-bottom: 10px;
            }
            
            #toolbar select {
              width: 80px;
              margin-right: 10px
            `}</style>
    </div>
  )
}

export default ArticlePage
