import {useRef, useState} from 'react'
import {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Loader} from '../../SharedComponents/Loader'
import {toAbsoluteUrl} from '../../_metronic/helpers'
import {useIntl} from 'react-intl'
import {useNavigate, useParams} from 'react-router-dom'
import {articleActions} from '../../store/articleSlice/articleReducers'
const DELETE = toAbsoluteUrl('/media/spa/delete.png')
const DISLIKE = toAbsoluteUrl('/media/spa/Dislike.png')
const DISLIKE_ACTIVE = toAbsoluteUrl('/media/spa/Dislike_active.png')
const EDIT = toAbsoluteUrl('/media/spa/Edit.png')
const LIKE = toAbsoluteUrl('/media/spa/Like.png')
const SAVE = toAbsoluteUrl('/media/spa/check.png')
const REPHRASE = toAbsoluteUrl('/media/spa/Rephrase.png')
const LIKE_ACTIVE = toAbsoluteUrl('/media/spa/Like_active.png')

const TextContainer = ({
  data,
  isLike,
  isDislike,
  index,
  setSelected,
  selected,
  handleClick,
  handleFeedbackLike,
  handleFeedbackDisLike,
  RepharaseText,
  handleDelete,
  editIndex,
  setEditIndex,
  handleEdited,
  dir,
  textLimit,
  id,
  isLoading,
}) => {
  // const router = useNavigate()
  // const {articleId} = router.query || {}
  const params = useParams()
  const articleId = Number(params.articleId)
  const intl = useIntl()
  const {isRephrasing} = useSelector((state) =>
    articleId || articleId === 0 ? state.article.articles[articleId] : state.article
  )

  const [updatedText, setUpdatedText] = useState('')
  const [textareaHeight, setTextareaHeight] = useState('auto')
  const [rephrasingIndex, setRephrasingIndex] = useState()

  const textareaRef = useRef(null)
  const dispatch = useDispatch()

  useEffect(() => {
    setTimeout(() => {
      setTextareaHeight(textareaRef?.current?.scrollHeight + 'px')
    }, 0)
  }, [editIndex])
  useEffect(() => {
    if (editIndex === index) {
      setUpdatedText(data)
    }
  }, [index, editIndex])
  useEffect(() => {
    if (!isRephrasing) {
      setRephrasingIndex()
    }
  }, [isRephrasing])
  return (
    <div
      className={`t-flex t-w-full t-min-h-[100px] sm:t-min-h-[fit-content] t-flex-row t-border  t-rounded-md sm:t-rounded-xl t-bg-[#F8FAFC] t-mt-3 sm:t-mt-4 t-cursor-pointer ${
        selected == id ? ' t-border-2 t-border-[#592E84]' : 't-border-[#CBD5E1]'
      }`}
    >
      {editIndex === index ? (
        <div className='t-flex t-w-full t-flex-col t-py-3 470:t-py-6 t-px-3 470:t-px-6 t-justify-between t-rounded-xl t-bg-white '>
          <textarea
            ref={textareaRef}
            type={'text'}
            className='t-text-right t-text-BLACK t-px-2  t-text-[13px] sm:t-text-[16px]  t-break-words t-min-h-0 t-min-w-full t-resize-none t-bg-white '
            style={{height: textareaHeight}}
            value={updatedText}
            dir={dir}
            onChange={(e) => {
              if (e.target.value.length <= textLimit) {
                setUpdatedText(e.target.value)
                setTextareaHeight(e.target.scrollHeight + 'px')
              }
            }}
          />
          <div className='t-flex t-flex-row t-gap-3'>
            <div
              className='t-flex t-flex-row t-items-center t-cursor-pointer'
              onClick={() => {
                dispatch(articleActions.setIsEditing(false))
                setUpdatedText('')
                setEditIndex()
              }}
            >
              <p className=' t-text-xs sm:t-text-[16px] t-text-[#592E84]'>
                {intl.formatMessage({id: 'CANCEL'})}
              </p>
              <div className=''>
                <img
                  src={DELETE}
                  alt=''
                  height={20}
                  width={20}
                  className='t-pl-1 t-mx-1 t-w-[15px] md:t-w-[20px]'
                />
              </div>
            </div>
            <div
              className='t-flex t-flex-row t-items-center t-cursor-pointer'
              onClick={() => {
                dispatch(articleActions.setIsEditing(false))
                handleEdited(id, updatedText)
              }}
            >
              <p className=' t-text-xs sm:t-text-[16px] t-text-[#592E84]'>
                {intl.formatMessage({id: 'SAVE'})}
              </p>

              <div className=''>
                <img
                  src={SAVE}
                  alt=''
                  height={100}
                  width={100}
                  className='t-pl-1 t-mx-1 t-w-[15px] md:t-w-[20px]'
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          className={`t-flex t-w-full t-gap-5 t-py-4 470:t-py-6 t-px-3 470:t-px-6 t-justify-between ${
            isLoading ? 't-pointer-events-none t-cursor-default' : 't-cursor-pointer'
          }`}
          onClick={() => {
            if (!isRephrasing) {
              setSelected(index)
              sessionStorage.setItem('Rephrase', true)
              handleClick(id)
            }
          }}
        >
          <div className='t-flex t-flex-col t-justify-between '>
            <div>
              <img
                src={DELETE}
                alt=''
                height={100}
                width={100}
                className={`-t-mt-2.5 -t-ml-1 t-w-[12px] md:t-w-[20px] ${
                  isLoading ? 't-pointer-events-none t-cursor-default' : 't-cursor-pointer'
                }`}
                onClick={(e) => {
                  handleDelete(id)
                  e.stopPropagation()
                }}
              />
            </div>

            <div className='t-flex t-flex-row t-ml-auto  t-gap-5'>
              <div
                className='t-flex t-flex-row t-items-center t-cursor-pointer'
                onClick={(e) => {
                  dispatch(articleActions.setIsEditing(true))
                  setEditIndex(index)
                  e.stopPropagation()
                }}
              >
                <div className=''>
                  <img
                    src={EDIT}
                    alt=''
                    height={100}
                    width={100}
                    className='t-w-[15px] md:t-w-[20px]'
                  />
                </div>
              </div>
              <div
                className={`t-flex t-flex-row t-items-center ${
                  isLoading ? 't-pointer-events-none t-cursor-default' : 't-cursor-pointer'
                }  `}
                onClick={(e) => {
                  setRephrasingIndex(index)
                  RepharaseText(id)
                  e.stopPropagation()
                }}
              >
                <div className=''>
                  <img
                    src={REPHRASE}
                    alt=''
                    height={100}
                    width={100}
                    className='t-w-[15px] md:t-w-[20px]'
                  />
                </div>
              </div>
            </div>
          </div>
          <div className='t-flex t-w-full t-flex-col t-gap-2 t-justify-between'>
            {isRephrasing && rephrasingIndex == index ? (
              <div className=' t-flex t-justify-center t-items-start'>
                <Loader
                  className='t-pr-10 t-pt-5 t-items-center t-justify-center t-flex '
                  size='t-w-8 t-h-8'
                  fill='#592E84'
                />
              </div>
            ) : (
              <p
                className='t-text-right  t-font-medium -t-mt-[10px] mb-[10px] t-text-[13px] sm:t-text-[16px] t-h-full '
                style={{whiteSpace: 'pre-line', wordBreak: 'break-word'}}
                dir={dir}
              >
                {data}
              </p>
            )}
            <div className='t-flex t-flex-row t-ml-auto t-gap-1'>
              <img
                src={isDislike ? DISLIKE_ACTIVE : DISLIKE}
                alt=''
                height={100}
                width={100}
                className={`t-w-[10px] md:t-w-[20px] ${
                  isLoading ? 't-pointer-events-none t-cursor-default' : 't-cursor-pointer'
                }`}
                onClick={(e) => {
                  if (isDislike) {
                    return
                  } else {
                    handleFeedbackDisLike(id)
                    e.stopPropagation()
                  }
                }}
              />
              <img
                src={isLike ? LIKE_ACTIVE : LIKE}
                alt=''
                height={100}
                width={100}
                className={`t-w-[10px] md:t-w-[20px] ${
                  isLoading ? 't-pointer-events-none t-cursor-default' : 't-cursor-pointer'
                }`}
                onClick={(e) => {
                  if (isLike) {
                    return
                  } else {
                    handleFeedbackLike(id)
                    e.stopPropagation()
                  }
                }}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default TextContainer
