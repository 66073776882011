/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */
import {FC, useEffect, useState} from 'react'
import {PrivateRoutes} from './PrivateRoutes'
import {ErrorsPage} from '../modules/errors/ErrorsPage'
import {Logout, AuthPage, useAuth} from '../modules/auth'
import {App} from '../App'
import {useDispatch, useSelector} from 'react-redux'
import VerifyAccount from '../pages/accountVerification'
import IdleTimerContainer from '../../_metronic/layout/components/IdleTimerContainer'
import ArticleWriter from '../../spa'
import AuthResticted from '../../spa/components/Layouts/AuthResticted'
import {
  Routes,
  Route,
  BrowserRouter,
  Navigate,
  useRoutes,
  useParams,
  useSearchParams,
  Router,
} from 'react-router-dom'
import {logout} from '../../store/auth/login/loginActions'
import SpeedArticle from '../../speedArticle'
import Layout from '../../speedArticle/components/layouts/AuthResticted'

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const {PUBLIC_URL} = process.env
const ArticleSPA = () => {
  return (
    <AuthResticted>
      <ArticleWriter />
    </AuthResticted>
  )
}
const SpeedWriter = () => {
  return (
    <Layout>
      <SpeedArticle />
    </Layout>
  )
}

const AppRoutes: FC = () => {
  const {user, accessToken, sessionStartDate} = useSelector((state: any) => state.login)
  const dispatch = useDispatch()
  const [isValid, setIsValid] = useState(true)
  const [isChecked, setIsChecked] = useState(true)
  const userlogin = useSelector((state: any) => state.login.accessToken)

  useEffect(() => {
    if (window.location.pathname.includes('/articleWriter'))
      document.title = 'KTOVLI - Writing Assistant'
  }, [])
  useEffect(() => {
    const handleRouteChange = () => {
      // Check if the current route is "/articleWriter"
      if (window.location.pathname === '/articleWriter') {
        // Clear the article state
        alert('ARTICLE')
      }
    }

    // Add event listener to detect route changes
    window.addEventListener('popstate', handleRouteChange)

    // Cleanup the event listener
    return () => {
      window.removeEventListener('popstate', handleRouteChange)
    }
  }, [])
  const action = () => {
    localStorage.clear()
    // window.location.href === '/auth'
  }
  useEffect(() => {
    if (sessionStartDate) {
      const userId = localStorage.getItem('access_token')
      const logoutTime = sessionStartDate + 8 * 60 * 60 * 1000
      const currentTime = Date.now()

      if (currentTime >= logoutTime) {
        alert('SESSION EXPIRED')
        dispatch(logout(action, userId))
      }
    }
  }, [])

  return (
    <>
      <BrowserRouter basename={PUBLIC_URL}>
        <IdleTimerContainer />
        <Routes>
          <Route element={<App />}>
            <Route path='error/*' element={<ErrorsPage />} />
            <Route path='logout' element={<Logout />} />
            <Route path='verifyAccount' element={<VerifyAccount />} />
            {userlogin ? (
              <>
                <Route path='/*' element={<PrivateRoutes />} />
                <Route index element={<Navigate to='/dashboard' />} />
                <Route path='articleWriter' element={<ArticleSPA />} />
                <Route path='articleWriter/:articleId' element={<ArticleSPA />} />
                <Route path='speedWriter/:contentId' element={<SpeedWriter />} />
                {/* <Route path='new-template/:id' element={<div>example page</div>} /> */}
              </>
            ) : (
              <>
                <Route path='auth/*' element={<AuthPage />} />
                <Route path='*' element={<Navigate to='/auth' />} />
              </>
            )}
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  )
}

export {AppRoutes}
