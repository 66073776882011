/* eslint-disable */

import {useDispatch, useSelector} from 'react-redux'
import {useState} from 'react'
import {useRef} from 'react'
import {useIntl} from 'react-intl'
import {useNavigate, useParams} from 'react-router-dom'
import {useLang} from '../../../_metronic/i18n/Metronici18n'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import SecondaryButton from '../../../SharedComponents/buttons/SecondaryButton'
import CustomDropdown from '../../../SharedComponents/LanguageDropdown'
import {useOnClickOutside} from '../../../SharedComponents/hooks/useOutsideClick'
import {useStepper} from '../../hooks/useStepper'
import {LANGUAGES} from '../../../SharedComponents/helpers/Language'
import ContentMenuBar from '../../modules/ContentMenuBar'
import PopAlert from '../../../SharedComponents/PopAlert'
import {speedActions} from '../../../store/speedSlice/speedReducers'
const HAMMENU = toAbsoluteUrl('/media/spa/Hamburger.png')
const CROSSPURPLE = toAbsoluteUrl('/media/spa/cross_purple.png')
const KTOVLI = toAbsoluteUrl('/media/spa/ktovli_logo.png')
const UPGRADE = toAbsoluteUrl('/media/spa/UpgradeIcon.png')
const DASHBOARD_ICON = toAbsoluteUrl('/media/spa/dashboard.png')
const DASHBOARD_ICON_ACTIVE = toAbsoluteUrl('/media/spa/dashboard-active.png')
const CREATE_NEW_ICON = toAbsoluteUrl('/media/spa/creatNew.png')
const CREATE_NEW_ICON_ACTIVE = toAbsoluteUrl('/media/spa/createNew-active.png')

const ROUTES = [
  {
    id: 1,
    title: 'DASHBOARD',
    icon: DASHBOARD_ICON,
    route: '/dashboard',

    active: true,
    activeIcon: DASHBOARD_ICON_ACTIVE,
  },
  {
    id: 3,
    title: 'NEW_TEMPLATE',
    icon: CREATE_NEW_ICON,
    route: '/speedWriter',
    active: false,
    activeIcon: CREATE_NEW_ICON_ACTIVE,
  },
]

const MobileNavbar = ({open, setOpen}) => {
  const intl = useIntl()
  const lang = useLang()
  const {user, accessToken} = useSelector((state) => state.login)
  const router = useNavigate()
  const params = useParams()
  const contentId = Number(params.contentId)

  const {steps, language_content, Tone_en, Quality_type_en, feature} = useSelector(
    (state) => state.speed.articles[contentId]
  )
  function persistLocaleCookie(customLocale) {}
  const [isAlert, setIsAlert] = useState(false)
  const [selectedItem, setSelectedItem] = useState()
  const [isRedirect, setIsRedirect] = useState(false)
  const [openMenu, setOpenMenu] = useState()
  const stepper = useStepper(steps)
  const {articles} = useSelector((state) => state.speed)
  const dispatch = useDispatch()

  const handleClickk = (item) => {
    if (item.title === 'DASHBOARD') {
      setSelectedItem(item)
      setIsAlert(true)
    } else {
      handleAddNewTemplate(item.route)
    }
  }

  const handleAddNewTemplate = (url) => {
    const randomNumber = articles ? articles.length : 0
    dispatch(speedActions.addNewArticle(randomNumber))
    window.open(`${url}/${randomNumber}`, '_blank')
  }
  const handleRedirect = () => {
    window.open(selectedItem.route, '_self')
    setIsAlert(false)
  }
  const ref = useRef()
  useOnClickOutside(ref, () => {
    setOpenMenu(false)
  })

  return (
    <>
      <nav
        className={`t-flex lg:t-hidden t-h-[83px] t-relative t-px-8 md:t-px-16 xl:t-px-24 t-w-full t-border t-items-center t-justify-between t-ease-in-out t-duration-300 t-z-10 t-bg-white `}
      >
        <div className='t-flex t-items-center t-justify-between t-gap-5 t-w-full'>
          <div className='t-px-2 t-py-6 t-h-[60px] t-flex t-items-center'>
            <img src={KTOVLI} alt='' height={100} width={100} className='' />
          </div>

          <div className='t-cursor-pointer' onClick={() => setOpenMenu(!openMenu)}>
            <img
              src={HAMMENU}
              alt='hamburger menu icon'
              width={100}
              height={100}
              className='t-w-[20px] md:t-w-[30px]'
            />
          </div>
        </div>
      </nav>
      {openMenu ? (
        <div
          ref={ref}
          className={`mobile t-overflow-y-auto t-bg-white t-fixed ${
            lang == 'heb' ? ' t-right-0 ' : ' t-left-0 '
          } t-h-full t-w-full 390:t-w-[70%] sm:t-w-[50%] md:t-w-[40%] t-z-20 t-top-0 t-flex t-flex-col lg:t-hidden t-py-8 t-px-5 470:t-px-8 md:t-px-10 xl:t-px-24 t-border t-items-start t-ease-in-out t-duration-300`}
        >
          <div
            className='t-cursor-pointer t-self-end -t-mr-1 470:-t-mr-4'
            onClick={() => setOpenMenu(!openMenu)}
          >
            <img
              src={CROSSPURPLE}
              alt='hamburger menu icon'
              width={100}
              height={100}
              className='t-w-[15px] md:t-w-[20px] '
            />
          </div>
          <div className='t-w-full'>
            <div className=' t-flex t-flex-col t-items-center t-text-[16px] md:t-text-lg'>
              {ROUTES.map((item) => {
                return (
                  <Item
                    key={item.id}
                    title={intl.formatMessage({id: item.title})}
                    open={open}
                    active={router.asPath === item.route}
                    onClick={() => handleClickk(item)}
                  />
                )
              })}
            </div>
            <div className='t-flex t-flex-col t-items-center t-gap-4 t-mt-4 t-text-black t-whitespace-nowrap'>
              <div className='t-flex t-items-center t-gap-8 t-text-[16px] md:t-text-lg'>
                <p className='t-font-bold '>
                  {' '}
                  {user?.plan === 'FREE'
                    ? intl.formatMessage({id: 'FREE_PLAN'})
                    : user?.plan === 'STANDARD'
                    ? intl.formatMessage({id: 'REGULAR_PLAN'})
                    : user?.plan === 'PRO'
                    ? intl.formatMessage({id: 'PRO_PLAN'})
                    : intl.formatMessage({id: 'FREE_PLAN'})}
                </p>
                <div className='t-flex t-text-black t-items-center t-gap-1'>
                  <p>{intl.formatMessage({id: 'WORDS_LEFT'})}:</p>
                  <p className='t-font-bold'>{user?.credits < 0 ? 0 : user.credits}</p>
                </div>
              </div>
              <div className='t-relative t-flex t-flex-col t-gap-3 t-w-full t-mt-1'>
                <SecondaryButton
                  iconPosition={1}
                  isLoading={false}
                  text={intl.formatMessage({id: 'UPGRADE_PLAN'})}
                  onClick={() =>
                    window.open(
                      `https://www.ktovli.com/pricing?lang=${lang}`,
                      '_blank'
                    )
                  }
                  isIcon={true}
                  icon={UPGRADE}
                  className='t-py-[13px] t-font-medium'
                  textClass='t-text-[18.7px]'
                />

                <div className='t-text-black'>
                  <CustomDropdown
                    width='t-w-full'
                    options={LANGUAGES}
                    onSelect={(selectedOption) => {
                      SETLANGUAGE(selectedOption.code)
                      persistLocaleCookie(selectedOption.code)
                    }}
                  />
                </div>
              </div>
              <div className='t-w-full t-h-[1px] t-bg-[#CBD5E1] t-overflow-y-auto' />
              <ContentMenuBar
                language={language_content}
                tone={Tone_en}
                quality={Quality_type_en}
                feature={feature}
                stepper={stepper}
                setOpenMenu={setOpenMenu}
              />
            </div>
          </div>
        </div>
      ) : (
        ''
      )}
      {isAlert ? (
        <PopAlert
          isAlert={isAlert}
          setIsAlert={setIsAlert}
          setIsRedirect={setIsRedirect}
          handleRedirect={handleRedirect}
        />
      ) : (
        ''
      )}
    </>
  )
}
const Item = ({Key, title, icon, active, open, onClick, className}) => {
  return (
    <div
      key={Key}
      onClick={onClick}
      className={`${
        !open ? 't-justify-center' : ''
      } t-flex t-px-2 t-py-1.5 t-items-center t-font-normal t-text-gray-900 t-cursor-pointer ${
        active && open
          ? ' t-border-l-[6px] t-border-[#592E84] t-px-[10px] t-cursor-pointer t-pointer-events-none'
          : ''
      }`}
    >
      {icon ? <img src={icon} alt='' height={open ? 20 : 30} width={open ? 20 : 30} /> : ''}

      <p
        className={`t-flex-1 t-text-center t-whitespace-nowrap ${className} ${
          active ? 't-text-[#592E84] t-font-bold' : 't-text-[#303038] t-font-medium'
        }`}
      >
        {title}
      </p>
    </div>
  )
}
export default MobileNavbar
