import {Loader} from '../Loader'
// import Image from 'next/image'

const PrimaryButton = ({text, className, onClick, textClass, type, isLoading, isIcon, icon}) => {
  return (
    <div
      className={`t-text-SECONDARY ${
        isLoading ? 't-pointer-events-none ' : ' t-cursor-pointer '
      } t-text-sm lg:t-text-sm t-px-4 t-py-2 lg:t-px-4 t-flex t-items-center t-justify-center t-rounded-[6px]  ${className}`}
      onClick={onClick}
      type={type}
    >
      <div className='t-flex t-items-center t-gap-2'>
        {/* {isLoading && (
          <div>{<Loader className='t-p-0 t-pt-0' size='t-w-4 t-h-4' fill='#58cfe4' />}</div>
        )} */}
        {isIcon && <img src={icon} alt='' height={15} width={15} />}
        <p className={textClass}> {text}</p>
      </div>
    </div>
  )
}

export default PrimaryButton
