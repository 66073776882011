import {useState, useEffect} from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import {getUserByToken, register} from '../core/_requests'
import {Link, useNavigate} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {PasswordMeterComponent} from '../../../../_metronic/assets/ts/components'
import {useAuth} from '../core/Auth'
import {InputType} from './InputType'
import {BsEyeFill, BsFillEyeSlashFill} from 'react-icons/bs'
import {MdWavingHand} from 'react-icons/md'
import '../style/authStyle.css'
import {useDispatch} from 'react-redux'
import {signUp} from '../../../../store/auth/signUp/singUpActions'
// import {GoogleAuthProvider, signInWithPopup} from '@firebase/auth'

import {firestore, auth} from '../../../../firebase/FirebaseConfig'
import {doc, getDoc} from '@firebase/firestore'
import {oAuth, setUserInfo} from '../../../../store/auth/login/loginActions'
import {useMediaQuery} from 'react-responsive'
import {useIntl} from 'react-intl'
import {useLang} from '../../../../_metronic/i18n/Metronici18n'
import {GoogleAuthProvider, signInWithPopup} from '@firebase/auth'
const initialValues = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  confirmPassword: '',
  acceptTerms: false,
}

const registrationSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Last name is required'),
  lastName: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Full name is required'),
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  password: Yup.string()
    .matches(
      /^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{6,})/,
      'Password must contain at least one uppercase letter, one special character, one number, and be at least 6 characters long.'
    )
    .required('Password is required'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password')], 'Match the password')
    .required('Confirm the password'),
})

export function Registration() {
  const intl = useIntl()
  const lang = useLang()
  const isMobile = useMediaQuery({query: '(max-width:992px)'})
  const [loading, setLoading] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [confirmPassword, setConfirmPassword] = useState(false)
  const [isGoogleLogin, setIsGoogleLogin] = useState(false)

  const action = () => {
    setIsGoogleLogin(false)
    navigate('/')
  }

  // const googelSignOut = async () => {
  //   try {
  //     setIsGoogleLogin(true)
  //     const res = await signInWithPopup(auth, googleProvider)

  //     const user: any = res.user

  //     const {
  //       providerId: registeredBy,
  //       email,
  //       photoURL: profile_photo,
  //       displayName: name,
  //     } = user?.providerData[0]
  //     let userData = {
  //       registeredBy,
  //       email,
  //       profile_photo,
  //       activation: true,
  //       credits: 10000,
  //       plan: 'FREE',
  //       name,
  //     }
  //     const userId: any = auth?.currentUser?.uid
  //     const docRef = doc(firestore, 'users', userId)
  //     const snapshot = await getDoc(docRef)
  //     let data = snapshot.data()
  //     if (data != undefined) {
  //       console.log('Here comess>>>>')
  //       dispatch(setUserInfo(data, userId, action))
  //     } else {
  //       dispatch(oAuth(userData, action))
  //     }
  //     localStorage?.setItem('authToken', user?.accessToken)
  //   } catch (error) {
  //     console.log('error in google login', error)
  //   }
  // }

  const signInWithGoogle = async () => {
    try {
      const googleProvider = new GoogleAuthProvider()
      const res = await signInWithPopup(auth, googleProvider)
      const user = res.user
    } catch (err) {
      console.error(err)
    }
  }

  const dispatch = useDispatch<any>()

  const handleToggleClick = (e: any) => {
    e.preventDefault()
    setShowPassword(!showPassword)
  }
  const handleConfirmPassword = (e: any) => {
    e.preventDefault()
    setConfirmPassword(!confirmPassword)
  }

  const navigate = useNavigate()

  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      const {firstName, lastName, email, password, confirmPassword} = values
      try {
        const name = `${firstName} ${lastName}`
        dispatch(signUp({name, firstName, lastName, email, password, confirmPassword}, setLoading, intl, lang))
        navigate('/auth')
      } catch (error) {
        setLoading(true)
        console.log(error)
      }
    },
  })

  useEffect(() => {
    PasswordMeterComponent.bootstrap()
  }, [])

  const getTopBottomPadding = () => {
    if (isMobile) {
      return '30px'
    }
    return ''
  }

  return (
    <div
      className='container d-flex justify-content-center align-items-center'
      style={{
        height: isMobile ? 'inherit' : '100vh',
        paddingTop: getTopBottomPadding(),
        paddingBottom: getTopBottomPadding(),
      }}
    >
      <div
        className='row custom-row shadow  d-flex  justify-content-center align-items-center w-lg-85 w-100  box'
        dir={lang === 'heb' ? 'rtl' : 'ltr'}
        style={{
          borderRadius: '5px',
          height: '98%',
          paddingTop: getTopBottomPadding(),
          paddingBottom: getTopBottomPadding(),
        }}
      >
        <div
          className='col-md-5 d-lg-block d-none h-100'
          style={{backgroundImage: `url(${toAbsoluteUrl('/SignIn-BG.svg')})`}}
        >
          {lang === 'heb' ? (
            <img
              alt='img'
              style={{height: '100%', width: '100%'}}
              src={toAbsoluteUrl('/welcome-signup-img_heb.png')}
            />
          ) : (
            <img
              alt='img'
              style={{height: '100%', width: '100%'}}
              src={toAbsoluteUrl('/welcome-signup-img.svg')}
            />
          )}
        </div>
        {/* ------------------form area-------------------- */}

        <div className='col-md-7 col-12 h-75 d-flex h-100 flex-column justify-content-center align-items-center'>
          <div
            className='w-lg-95 w-100'
            style={{
              overflowY: 'scroll',
            }}
          >
            <form>
              <h3
                className='px-4 d-lg-none d-md-none d-block text-lg-start text-center'
                style={{fontStyle: 'italic', fontSize: isMobile ? '20px' : ''}}
              >
                {intl.formatMessage({id: 'WELCOME_TO_'})}{' '}
                <span style={{fontStyle: 'italic', color: '#592E84'}}>
                  {' '}
                  {intl.formatMessage({id: 'KOTOVLI'})}
                </span>
              </h3>
              <h3
                className={`${
                  lang === 'heb' ? 'text-lg-end' : 'text-lg-start'
                } px-4 text-lg-start text-center`}
                style={{
                  fontStyle: 'italic',
                  marginBottom: '10px',
                  color: '#000000',
                  fontSize: isMobile ? '20px' : '24px',
                }}
              >
                {intl.formatMessage({id: 'JOIN_US'})} <MdWavingHand color='orange' />
              </h3>
              <div className='w-100 d-flex justify-content-center'>
                <hr className='divider w-75 d-lg-none d-block'></hr>
              </div>

              <div className='d-flex flex-column'>
                <InputType
                  label={intl.formatMessage({id: 'FIRST_NAME'})}
                  placeholder={intl.formatMessage({id: 'ISRAELI'})}
                  type='text'
                  name='firstName'
                  onChnage={formik.handleChange}
                  value={formik.values.firstName}
                />
                <div style={{marginLeft: '1rem', padding: '5px'}}>
                  {formik.errors.firstName ? (
                    <div className='error text-danger'>{formik.errors.firstName}</div>
                  ) : null}
                </div>

                <InputType
                  label={intl.formatMessage({id: 'LAST_NAME'})}
                  placeholder={intl.formatMessage({id: 'ISRAELI'})}
                  type='text'
                  name='lastName'
                  onChnage={formik.handleChange}
                  value={formik.values.lastName}
                />
                <div style={{marginLeft: '1rem', padding: '5px'}}>
                  {formik.errors.lastName ? (
                    <div className='error text-danger'>{formik.errors.lastName}</div>
                  ) : null}
                </div>

                <InputType
                  label={intl.formatMessage({id: 'EMAIL'})}
                  placeholder={intl.formatMessage({id: 'ENTER_YOUR_EMAIL'})}
                  type='text'
                  name='email'
                  onChnage={formik.handleChange}
                  value={formik.values.email}
                />
                <div style={{marginLeft: '1rem', padding: '5px'}}>
                  {formik.errors.email ? (
                    <div className='error text-danger'>{formik.errors.email}</div>
                  ) : null}
                </div>
                <div style={{position: 'relative'}}>
                  <InputType
                    label={intl.formatMessage({id: 'PASSWORD'})}
                    placeholder={intl.formatMessage({id: 'ENTER_YOUR_PASSWORD'})}
                    type={showPassword ? 'text' : 'password'}
                    name='password'
                    onChnage={formik.handleChange}
                    value={formik.values.password}
                  />

                  <button
                    onClick={(e) => handleToggleClick(e)}
                    className='bg-transparent border-0 '
                    style={{
                      position: 'absolute',
                      top: '40px',
                      [lang === 'heb' ? 'left' : 'right']: '5%',
                    }}
                  >
                    {showPassword ? (
                      <BsEyeFill size={20} color='#9D9DA6' />
                    ) : (
                      <BsFillEyeSlashFill size={20} color='#9D9DA6' />
                    )}
                  </button>
                  <div style={{marginLeft: '1rem', padding: '5px'}}>
                    {formik.errors.password ? (
                      <div className='error text-danger'>{formik.errors.password}</div>
                    ) : null}
                  </div>
                </div>
                <div style={{position: 'relative'}}>
                  <InputType
                    label={intl.formatMessage({id: 'CONFIRM_NEW_PASSWORD'})}
                    placeholder={intl.formatMessage({id: 'ENTER_NEW_PASSWORD_CONFIRM'})}
                    type={confirmPassword ? 'text' : 'password'}
                    name='confirmPassword'
                    onChnage={formik.handleChange}
                    value={formik.values.confirmPassword}
                  />

                  <button
                    onClick={(e) => handleConfirmPassword(e)}
                    className='bg-transparent border-0 '
                    style={{
                      position: 'absolute',
                      top: '40px',
                      [lang === 'heb' ? 'left' : 'right']: '5%',
                    }}
                  >
                    {confirmPassword ? (
                      <BsEyeFill size={20} color='#9D9DA6' />
                    ) : (
                      <BsFillEyeSlashFill size={20} color='#9D9DA6' />
                    )}
                  </button>
                  <div style={{marginLeft: '1rem', padding: '5px'}}>
                    {formik.errors.confirmPassword ? (
                      <div className='error text-danger'>{formik.errors.confirmPassword}</div>
                    ) : null}
                  </div>
                </div>
              </div>

              {/* <div className=' px-4  w-100 py-6 d-flex justify-content-between'>
                <div className=' d-flex justify-content-between align-items-center'>
                  <input type='checkbox' value='Remember Me' />
                  <label style={{color: '#52525B', fontSize: '13px'}}>
                    &nbsp;&nbsp;Remember Me
                  </label>
                </div>
              </div> */}

              <div className='px-4 w-100 d-flex flex-lg-row flex-column align-items-center '>
                <button
                  onClick={() => formik.handleSubmit()}
                  disabled={loading}
                  className='w-lg-25  w-100 fw-bold sign-in-button'
                >
                  {intl.formatMessage({id: 'SIGN_UP'})}
                </button>
                {'  '}
                <p
                  style={{whiteSpace: 'nowrap', fontSize: '16px'}}
                  className='d-flex justify-content-center align-items-center mt-3 fw-light'
                >
                  <span className='fw-bold' style={{color: '#303038', fontSize: '16px'}}>
                    &nbsp; or &nbsp;
                  </span>{' '}
                  {intl.formatMessage({id: 'ALREADY_REGISTERED'})} &nbsp;{' '}
                  <Link to='/auth/login'>
                    <span
                      className='fw-bold cursor-pointer'
                      style={{color: '#592E84', fontSize: '16px'}}
                    >
                      {intl.formatMessage({id: 'CONNECT'})}
                    </span>
                  </Link>
                </p>
              </div>

              {/* <div style={{marginLeft: '1rem', padding: '5px'}}>
                {(!formik.dirty || !formik.isValid) && Object.keys(formik.errors).length > 0 ? (
                  <div className='error text-danger'>Please enter valid fields</div>
                ) : null}
              </div> */}
            </form>
            <div className='d-flex justify-content-center flex-row  w-100 px-4 mt-6'>
              <button
                onClick={signInWithGoogle}
                className='w-100 bg-transparent fw-normal auth-button border border-warning align-items-center'
              >
                <div className='d-flex justify-content-center'>
                  <img
                    alt='Logo'
                    src={toAbsoluteUrl('/media/svg/brand-logos/google-icon.svg')}
                    className=' mx-3'
                    style={{height: '21.35px', width: '20px'}}
                  />
                  <p className='' style={{color: '#592E84', fontSize: '16px'}}>
                    {intl.formatMessage({id: 'SIGN_UP_GOOGLE'})}
                  </p>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
